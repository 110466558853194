import { Box, Button, LinearProgress, Paper, Typography } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useState } from "react";
import useInventory from "hooks/useInventory";
import useInventoryTemplates from "hooks/useInventoryTemplates";
import InventoryAddField from "./InventoryAddField";
import { InventoryAddProps } from "../../types/inventory";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "./InventoryStyles";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import { useQuery } from "../../hooks/useQuery";
import { InputBase } from "../common/Input";
import { v4 as uuid } from "uuid";

const defaultInventoryAdd = (orderNumber: string, trackingNumber: string) => {
  return {
    id: uuid(),
    inventory_template_uuid: "",
    quantity: "1",
    print_labels: true,
    orderNumber: orderNumber,
    trackingNumber: trackingNumber,
  };
};

export default function VirtualPickupInventoriesNew() {
  useBreadcrumbs({
    name: "Add Virtual Pickup inventory",
    path: ``,
  });
  const navigate = useNavigate();
  const query = useQuery();

  const { createInventory, isLoading } = useInventory();
  const { searchInventoryTemplates, inventoryTemplates, setInventoryTemplates } = useInventoryTemplates();
  const [inventories, setInventories] = useState<InventoryAddProps[]>([
    defaultInventoryAdd(query.get("order_number") || "", query.get("tracking_number") || ""),
  ]);

  const addInventory = (newInventory: InventoryAddProps) => {
    setInventories([...inventories, newInventory]);
  };

  const handleSubmit = () => {
    createInventory({
      inventories,
    });
  };

  return (
    <Box>
      <Paper variant="outlined" sx={{ mx: 10, my: 8, px: 4, py: 5 }}>
        <Typography variant="h6">Add Virtual Pickup Inventory</Typography>
        <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
          <InputBase label="Tracking Numner" value={query.get("tracking_number")} disabled />
          <InputBase label="Order Number" value={query.get("order_number")} disabled />
        </Box>
        {isLoading ? <LinearProgress /> : null}
      </Paper>

      <Paper variant="outlined" sx={{ mx: 10, my: 8, px: 4, py: 5 }}>
        <Typography variant="body1">Add Items from Retrieval Box</Typography>
        <Typography color={"rgba(0, 0, 0, 0.6)"} fontSize={14} sx={{ mt: 1, mb: 3 }}>
          Additional items may include a keyboard, mouse, docking station, or second laptops.
        </Typography>
        {inventories.map((inventory, index) => (
          <InventoryAddField
            addInventory={addInventory}
            inventory={inventory}
            inventories={inventories}
            inventoryTemplates={inventoryTemplates}
            setInventories={setInventories}
            searchInventoryTemplates={searchInventoryTemplates}
            hideQuantity
            showDelete={inventories.length > 1}
            deleteInventory={(deleteId) => {
              setInventories(inventories.filter(({ id }) => id !== deleteId));
            }}
          />
        ))}
        <Button
          startIcon={<AddCircleOutlineOutlined />}
          onClick={() => {
            addInventory(defaultInventoryAdd(query.get("order_number") || "", query.get("tracking_number") || ""));
          }}
        >
          Add Additional Inventory
        </Button>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <StyledButton disabled={isLoading} variant="outlined" onClick={() => navigate(-1)}>
            Back
          </StyledButton>
          <StyledButton disabled={isLoading} variant="contained" onClick={handleSubmit}>
            Save
          </StyledButton>
        </Box>
      </Paper>
    </Box>
  );
}
