import { AxiosResponse } from "axios";
import { api } from "services/api.service";

type TaskResponsePayload = {
  jobId: string;
};

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

const checkJobStatusRequest = (jobId: string) => {
  return new Promise<void>(async (resolve) => {
    let finished = false;
    while (!finished) {
      const result = await api.get<{ finished: boolean }>(`/good_job_updates/${jobId}`);
      if (result.data.finished) {
        finished = true;
        resolve();
      } else {
        await sleep(1000);
      }
    }
  });
};

export default function useBackgroundTask(task: Promise<AxiosResponse<TaskResponsePayload, any>>) {
  return new Promise<void>(async (resolve) => {
    const result = await task;
    await checkJobStatusRequest(result.data.jobId);
    resolve();
  });
}
