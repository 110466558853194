import { useCallback, useMemo } from "react";
import { LocationCity } from "@mui/icons-material";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "index";
import { Client } from "types/client";
import { Location } from "types/location";
import { useConfirmation } from "hooks/useConfirmation";

type ClientShowProps = {
  client?: Client;
  deleteLocation: (location: Location) => any;
};

export default function LocationList({ client, deleteLocation }: ClientShowProps) {
  const activeLocation = useMemo(() => client?.locations?.filter(({ deletedAt }) => !!!deletedAt), [client]);
  const archivedLocation = useMemo(() => client?.locations?.filter(({ deletedAt }) => !!deletedAt), [client]);
  const { confirm } = useConfirmation();

  const handleLocationDelete = useCallback((location: Location) => {
    confirm({
      handler: () => deleteLocation(location),
      title: "Delete Location",
      message: `Are you sure you want to delete ${location.address} location?`,
    });
  }, []);

  return (
    <>
      <Box my={4} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: 2 }}>
        {client &&
          activeLocation &&
          activeLocation.map((location: any) => (
            <Box
              key={location.uuid}
              sx={{
                py: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
                minHeight: "150px",
              }}
            >
              <Box sx={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  fontWeight="600"
                  textTransform="uppercase"
                  sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}
                >
                  <LocationCity />
                  {location.name}
                </Typography>

                <Stack mt={2} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                  <Link sx={{ textDecoration: "none" }} component={RouterLink} to={`locations/${location.uuid}/edit`}>
                    Edit
                  </Link>
                  <Link
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                    component={"button"}
                    onClick={() => handleLocationDelete(location)}
                  >
                    Delete
                  </Link>
                  <Link sx={{ textDecoration: "none" }} component={RouterLink} to={`locations/${location.uuid}`}>
                    View
                  </Link>
                </Stack>
              </Box>
            </Box>
          ))}
      </Box>

      <Typography variant="h5">Archived</Typography>
      <Box mt={4} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: 2 }}>
        {client &&
          archivedLocation &&
          archivedLocation.map((location: any) => (
            <Box
              key={location.uuid}
              sx={{
                py: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
                minHeight: "150px",
              }}
            >
              <Box sx={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  fontWeight="600"
                  textTransform="uppercase"
                  sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}
                >
                  <LocationCity />
                  {location.name}
                </Typography>

                <Stack mt={2} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                  <Link sx={{ textDecoration: "none" }} component={RouterLink} to={`locations/${location.uuid}/edit`}>
                    Edit
                  </Link>
                  <Link sx={{ textDecoration: "none" }} component={RouterLink} to={`locations/${location.uuid}`}>
                    View
                  </Link>
                </Stack>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
}
