import { Box, useMediaQuery } from "@mui/material";
import ScanCard from "../components/Receiving/ScanCard";
import { Route, Routes } from "react-router-dom";
import InventoryAddImagesPage from "./InventoryAddImagesPage";

function ReceivingBody() {
  return (
    <>
      <Routes>
        <Route path=":inventoryId" element={<InventoryAddImagesPage />} />
        <Route index element={<ScanCard />} />
      </Routes>
    </>
  );
}
export default function ReceivingPage() {
  const greaterThan600 = useMediaQuery("(min-width:380px)", { noSsr: true });
  return (
    <Box>
      <ReceivingBody />
    </Box>
  );
}
