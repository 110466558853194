import { Button, Box } from "@mui/material";
import { Edit, Preview } from "@mui/icons-material";
import { theme } from "index";
import { Pickup } from "types/pickup";
import { address, contact, InfoCell, internalNotes, orderNumber } from "./shared";
import { useState } from "react";
import LogisticsDetailsModal from "./LogisticsDetailsModal";
import { Link as RouterLink } from "react-router-dom";

export function CompleteDrawer(props: { pickup: Pickup }) {
  const { pickup } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <InfoCell title="EXTERNAL PICKUP ID" items={orderNumber(pickup)} />
          <InfoCell title="INTERNAL PICKUP ID" items={[pickup.id]} />
          <InfoCell title="POINT OF CONTACT" items={contact(pickup)} />
          <InfoCell title="PRE-PICKUP NOTES" items={internalNotes(pickup)} />
          <InfoCell title="LOCATION" items={address(pickup)} />
          <InfoCell
            title="LOCATION INFO"
            items={[pickup?.location?.accessInstructions, pickup?.location?.internalNotes]}
          />
        </Box>
      </Box>

      <LogisticsDetailsModal open={isOpen} pickup={pickup} handleClose={() => setOpen(false)} />

      <Box mt={2} sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<Edit />}
          component={RouterLink}
          to={`/logistics/pickups/${pickup.uuid}/inventory`}
        >
          manage inventory
        </Button>
        <Button
          variant="contained"
          startIcon={<Edit />}
          component={RouterLink}
          to={`/logistics/pickups/${pickup.uuid}/logistics`}
        >
          logistics details
        </Button>

        {pickup.postPickupForms && pickup.postPickupForms[0] && (
          <Button variant="contained" startIcon={<Preview />} onClick={() => setOpen(true)}>
            post pickup report
          </Button>
        )}
      </Box>
    </Box>
  );
}
