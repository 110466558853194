import useSWR from "swr";
import { fetcher } from "services/api.service";
import { PaginatedResponse } from "types/paginatedResponse";
import { serializeFilterItem } from "components/DataGrid/helpers/serializers";
import { GridFilterItem } from "@mui/x-data-grid-pro";

interface OrderItem {
  id: number;
}

type ItemResponse = PaginatedResponse<"order_items", OrderItem>;

type Filter = Pick<GridFilterItem, "columnField" | "value" | "operatorValue">;

export function useShipstationOrderItems({ filters, enabled = true }: { filters?: Filter[]; enabled?: boolean } = {}) {
  let key: string | null = "shipstation_order_items";
  if (filters) {
    const params = new URLSearchParams();
    filters.forEach((filter) => params.append("filter[]", serializeFilterItem(filter)));
    key += "?" + params.toString();
  }
  if (!enabled) key = null;

  const { data, isLoading, mutate } = useSWR<ItemResponse>(key, fetcher);
  return {
    items: data,
    isLoading,
    mutate,
  };
}
