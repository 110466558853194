import InventoryEdit from "components/Inventories/InventoryEdit";
import { Route, Routes } from "react-router-dom";
import useInventory from "hooks/useInventory";
import { useEffect } from "react";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import useRecordHistoryEvent from "hooks/useRecordHistoryEvent";

type InventoryEditPageProps = {
  prevPath: string;
};

export default function InventoryEditPage({ prevPath }: InventoryEditPageProps) {
  const { inventory, updateInventory } = useInventory();
  const { events, fetchMoreEvents, hasMoreEvents, isLoading } = useRecordHistoryEvent({
    eventType: "inventory_events",
  });

  const { setCrumb } = useBreadcrumbs({
    name: inventory ? inventory.label || "" : "",
    path: `${prevPath}/inventories/${inventory?.id || ""}`,
  });

  useEffect(() => {
    setCrumb({
      name: inventory ? inventory.label || "" : "",
      path: `${prevPath}/inventories/${inventory?.id || ""}`,
    });
  }, [inventory, prevPath]);

  return (
    <Routes>
      <Route
        index
        element={
          <div>
            <InventoryEdit
              inventory={inventory}
              updateInventory={updateInventory}
              inventoryEvents={events}
              loadNextInventoryEvents={fetchMoreEvents}
              isLoadingInventoryEvents={isLoading || !hasMoreEvents}
            />
          </div>
        }
      />
    </Routes>
  );
}
