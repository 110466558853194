import ReactToPrint from "react-to-print";
import Printable from "components/Printable";
import PrintableQRCodes from "components/Pickups/PrintableQRCodes";
import { useRef } from "react";
import { Inventory } from "types/inventory";
import { MenuItem } from "@mui/material";

type InventoriesMenuItemPrintProps = {
  selectedInventories: Inventory[];
  onClick: () => void;
  onAfterPrint: () => void;
};

export default function InventoriesMenuItemPrint({
  selectedInventories,
  onClick,
  onAfterPrint,
}: InventoriesMenuItemPrintProps) {
  const printComponentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <ReactToPrint
        trigger={() => <MenuItem>Print labels</MenuItem>}
        content={() => (printComponentRef ? printComponentRef.current : null)}
        onBeforeGetContent={onClick}
        onAfterPrint={onAfterPrint}
      />
      {printComponentRef && (
        <Printable>
          <PrintableQRCodes selectedInventories={selectedInventories} ref={printComponentRef} />
        </Printable>
      )}
    </>
  );
}
