import { forwardRef } from "react";
import { Box } from "@mui/material";
import QRCode from "components/Scan/QRCode";
import { Inventory } from "types/inventory";

const PrintableQRCodes = forwardRef<HTMLDivElement, { selectedInventories: Inventory[] }>(
  ({ selectedInventories }, ref) => (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        display: "none",
        "@media print": {
          display: "block",
        },
      }}
    >
      {selectedInventories.map((inventory) => {
        return (
          <Box
            key={inventory.id}
            padding={1}
            sx={{
              "@media print": {
                height: "100mm",
                width: "62mm",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <QRCode inventory={inventory} />
          </Box>
        );
      })}
    </Box>
  )
);

export default PrintableQRCodes;
