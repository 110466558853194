import { Box, Button } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import ClientForm from "./ClientForm";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function ClientNew() {
  const navigate = useNavigate();

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: "Add New Company" },
        ]}
      ></Breadcrumbs>
      <Box mt={4}>
        <Button size="small" variant="text" startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
          back to clients
        </Button>
      </Box>

      <Box mt={4}>
        <ClientForm />
      </Box>
    </Box>
  );
}
