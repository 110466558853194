import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function AssetRetrievalTabs({ onTabChange }: { onTabChange: (s: string) => void }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("asset_retrieval_status") || "open");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParams({ asset_retrieval_status: newValue });
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "20px 0" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="asset retrieval tabs"
      >
        <Tab value="open" label="OPEN" />
        <Tab value="completed" label="COMPLETED" />
      </Tabs>
    </Box>
  );
}
