import React from "react";
import { PicklistProgress } from "components/Picklist/common/PicklistProgress";
import { PickingComplete } from "components/Picklist/CurrentBatch/Picking/PickingComplete";
import { FulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";
import { PickInventoryWizard } from "components/Picklist/CurrentBatch/Picking/PickInventoryWizard";

interface PickingStepProps {
  batch: FulfillmentBatch;
  onItemPickingComplete: () => void;
  onTransitionToPacking: () => void;
}

export function PickingStep({ batch, onItemPickingComplete, onTransitionToPacking }: PickingStepProps) {
  const total = batch.itemsCount;
  return (
    <>
      <PicklistProgress complete={batch.stateCompleteCount + 1} total={total} />
      {batch.currentItem && (
        <PickInventoryWizard
          key={batch.currentItem.id}
          inventory={batch.currentItem}
          onInventoryComplete={onItemPickingComplete}
        />
      )}
      {!batch.currentItem && <PickingComplete onTransitionToPacking={onTransitionToPacking} />}
    </>
  );
}
