import React from "react";
import { InventoryImage } from "types/inventory";
import { PicklistInventoryCard } from "components/Picklist/common/PicklistInventoryCard";
import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Cable, Check } from "@mui/icons-material";
import { DataList, DataListItem } from "components/Picklist/common/DataList";
import { CheckList, CheckListItem } from "components/Picklist/common/CheckList";
import { Stack } from "@mui/material";
import { Step } from "components/Picklist/CurrentBatch/Packing/PackInventoryWizard";
import { OrderItem } from "hooks/useCurrentFulfillmentBatch";

interface PackInventoryLayoutProps {
  children: React.ReactNode;
  activeStep: Step;
  inventory: OrderItem;
  image?: InventoryImage | null;
}

export function PackInventoryLayout({ children, inventory, activeStep, image }: PackInventoryLayoutProps) {
  return (
    <>
      <PicklistInventoryCard title="Inventory to Pack" inventory={inventory} condensed={true}>
        {activeStep > Step.CapturePhoto && image && (
          <img
            src={image.url}
            alt="inventory"
            width="100%"
            height="100%"
            style={{ objectFit: "contain", maxHeight: "200px" }}
          />
        )}
      </PicklistInventoryCard>
      {activeStep > Step.ScanInventory && (
        <>
          {inventory.charger && (
            <PicklistCard title="Accessories to include" Icon={Cable}>
              <DataList>
                <DataListItem label="Charger" value={inventory.charger} />
              </DataList>
            </PicklistCard>
          )}
          <PicklistCard title="Actions" Icon={Check}>
            <CheckList>
              {activeStep > Step.ScanInventory && <CheckListItem label={"Scanned Inventory"} />}
              {activeStep > Step.TestInventory && <CheckListItem label={"Tested Inventory"} />}
              {activeStep > Step.PrintShippingLabel && <CheckListItem label={"Printed Shipping Label"} />}
              {activeStep > Step.CapturePhoto && <CheckListItem label={"Photo Captured"} />}
            </CheckList>
          </PicklistCard>
        </>
      )}
      <Stack justifyItems="center" alignItems="center" gap={1}>
        {children}
      </Stack>
    </>
  );
}
