import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetcher } from "services/api.service";
import { Modal } from "components/common/Modal";
import { Box, Button, Typography } from "@mui/material";
import useSWR from "swr";
import { useAlert } from "./useAlert";

type UseTableExportProps = {
  url: string;
};

export default function useTableExport({ url }: UseTableExportProps) {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [searchParams] = useSearchParams();
  const { data, error, isLoading } = useSWR(sendRequest ? `${url}?${searchParams.toString()}` : null, fetcher);

  const handleExport = useCallback(() => {
    setSendRequest(true);
    setOpenConfirmModal(false);
  }, [setSendRequest, setOpenConfirmModal]);

  const { alertError } = useAlert();

  useEffect(() => {
    if (!sendRequest) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (error) {
      alertError(error?.message);
      setSendRequest(false);
      return;
    }

    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      link.setAttribute("href", url);
      link.setAttribute("download", `Export table (${formattedDate}).csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setSendRequest(false);
  }, [sendRequest, isLoading, error, alertError, setSendRequest, data]);

  const confirmExportModal = (
    <Modal open={openConfirmModal} setOpen={setOpenConfirmModal}>
      <Box>
        <Box>
          <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
            Export Table
          </Typography>

          <Typography mt={0.5} color="text.secondary">
            Clicking export will download a local CSV file of our current records with the filters you have applied.
          </Typography>

          <Box mt={3} sx={{ textAlign: "end" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenConfirmModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" style={{ marginLeft: "10px" }} onClick={handleExport}>
              Export
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return {
    isLoading: sendRequest,
    setOpenConfirmModal,
    confirmExportModal,
  };
}
