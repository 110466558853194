import { Route, Routes } from "react-router-dom";
import InventoryUploadsDataGrid from "components/InventoryUploads/InventoryUploadsDataGrid";

export default function InventoryUploadsPage() {
  return (
    <Routes>
      <Route index element={<InventoryUploadsDataGrid />} />
    </Routes>
  );
}
