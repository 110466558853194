import { Inventory } from "types/inventory";
import React, { useState } from "react";
import { Alert } from "@mui/material";
import { ScanAndConfirmInventoryButton } from "components/Picklist/common/ScanAndConfirmInventoryButton";

export function ScanInventoryStep({ inventory, onSuccess }: { inventory: Inventory; onSuccess: () => void }) {
  const [inventoryError, setInventoryError] = useState(false);
  return (
    <>
      {inventoryError && (
        <Alert severity="error">
          This inventory ID doesn't match the assigned inventory item, please scan the correct inventory ID.
        </Alert>
      )}
      <ScanAndConfirmInventoryButton
        inventory={inventory}
        onError={() => setInventoryError(true)}
        onSuccess={onSuccess}
      />
    </>
  );
}
