import { ScanButton } from "components/Picklist/common/ScanButton";
import React from "react";
import { Inventory } from "types/inventory";

interface ScanAndConfirmInventoryButtonProps {
  inventory: Inventory;
  onError: () => void;
  onSuccess: () => void;
}

export const ScanAndConfirmInventoryButton = ({
  inventory,
  onError,
  onSuccess,
}: ScanAndConfirmInventoryButtonProps) => {
  return (
    <ScanButton
      onScan={(scanValue) => {
        if (scanValue) {
          if (inventory.id.toString() !== scanValue) {
            onError();
          } else {
            onSuccess();
          }
        }
      }}
    >
      Scan Label
    </ScanButton>
  );
};
