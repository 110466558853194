import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import QRCodeComp from "qrcode.react";
import { InventoryPart } from "types/inventoryPart";

export default function QRCode({ part }: { part: InventoryPart }) {
  return (
    <Box sx={{ width: "fit-content" }}>
      <QRCodeComp value={part.id.toString()} />
      <Box>
        <Typography sx={{ textAlign: "center", wordSpacing: "200%" }}>{part.label}</Typography>
        <Typography sx={{ textAlign: "center", wordSpacing: "200%" }}>Model: {part.model}</Typography>
      </Box>
    </Box>
  );
}
