import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { RecordHistoryEvent } from "types/recordHistoryEvent";

export default function useRecordHistoryEvent({
  eventType,
  entityId: argEntityId,
}: {
  eventType: "inventory_part_events" | "inventory_events";
  entityId?: number;
}) {
  const params = useParams();
  const entityId = argEntityId ?? params.inventoryId;
  const [events, setEvents] = useState<RecordHistoryEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreEvents, setHasMoreEvents] = useState(true);

  const fetchMoreEvents = useCallback(() => {
    if (isLoading) return;

    const prevIdQuery = events.length === 0 ? "" : `?prev_id=${events[events.length - 1].id}`;
    setIsLoading(true);

    const endpoint = `/${
      eventType.includes("part") ? "inventory_parts" : "inventories"
    }/${entityId}/${eventType}${prevIdQuery}`;

    api
      .get(endpoint)
      .then(({ data }) => {
        setEvents((prev) => [...prev, ...data.events]);
        if (data.events.length === 0) setHasMoreEvents(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [eventType, entityId, setEvents, events, isLoading, setIsLoading]);

  useEffect(() => {
    if (entityId) {
      setEvents([]);
      setHasMoreEvents(true);
      fetchMoreEvents();
    }
  }, [eventType, entityId]);

  return {
    events,
    fetchMoreEvents,
    isLoading,
    hasMoreEvents,
  };
}
