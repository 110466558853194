import { Box, FormControl, Button } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { ArrowBack } from "@mui/icons-material";
import { useFormik } from "formik";
import { useAlert } from "hooks/useAlert";
import useContacts from "hooks/useContacts";
import { theme } from "index";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Contact, defaultContact } from "types/contact";
import * as yup from "yup";

type ContactFormProps = {
  contact?: Contact;
};

export default function ContactForm({ contact }: ContactFormProps) {
  const navigate = useNavigate();
  const { createContact, updateContact } = useContacts();
  const [, setSearchParams] = useSearchParams();
  const { alertSuccess, alertError } = useAlert();
  const params = useParams();

  const navigateAfterSubmit = () => {
    if (params.locationUuid) {
      navigate(`/logistics/clients/${params.uuid}/locations/${params.locationUuid}`);
    } else {
      navigate(`/logistics/clients/${params.uuid}/contacts`);
    }
  };
  const { handleSubmit, getFieldProps, touched, errors, isValid, isSubmitting, setFieldError, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: contact || defaultContact,
      validationSchema: yup.object({
        email: yup.string().email("Invalid Email").nullable(),
        firstName: yup.string().required("Required"),
        lastName: yup.string().required("Required"),
        phoneNumber: yup.string().required("Required"),
      }),
      onSubmit: (values, {}) => {
        if (!["new", undefined].includes(params.contactUuid)) {
          updateContact(values)
            .then(() => {
              alertSuccess("Successfully updated a contact");
              navigateAfterSubmit();
            })
            .catch(({ response: { data } }) => {
              data.errors.email && setFieldError("email", "A contact with this email already exists");
              alertError("There was an error, please try again.");
            });
        } else {
          createContact(values)
            .then(() => {
              alertSuccess("Successfully created a contact");
              navigateAfterSubmit();
            })
            .catch(({ response: { data } }) => {
              data.errors.email && setFieldError("email", "A contact with this email already exists");
              alertError("There was an error, please try again.");
              setSubmitting(false);
            });
        }
      },
    });

  return (
    <div>
      <Box component="form" onSubmit={handleSubmit} sx={{}} noValidate autoComplete="off">
        <Box
          sx={{
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button size="small" variant="text" startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
            back to contacts
          </Button>
          <Box mt={3} sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="first-name">
                First Name
              </InputLabel>
              <InputBase
                id="firstName"
                {...getFieldProps("firstName")}
                helperText={touched.firstName && errors.firstName}
                error={touched.firstName && !!errors.firstName}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="last-name">
                Last Name
              </InputLabel>
              <InputBase
                id="lastName"
                {...getFieldProps("lastName")}
                helperText={touched.lastName && errors.lastName}
                error={touched.lastName && !!errors.lastName}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="email">
                Email
              </InputLabel>
              <InputBase
                id="email"
                type="email"
                {...getFieldProps("email")}
                helperText={touched.email && errors.email}
                error={touched.email && !!errors.email}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="phoneNumber">
                Phone Number
              </InputLabel>
              <InputBase
                {...getFieldProps("phoneNumber")}
                helperText={touched.phoneNumber && errors.phoneNumber}
                error={touched.phoneNumber && !!errors.phoneNumber}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="workPhoneNumber">
                Work Phone Number
              </InputLabel>
              <InputBase id="workPhoneNumber" {...getFieldProps("workPhoneNumber")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="jobTitle">
                Job Title
              </InputLabel>
              <InputBase id="jobTitle" {...getFieldProps("jobTitle")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="linkedinProfile">
                Linkedin Profile
              </InputLabel>
              <InputBase id="linkedinProfile" {...getFieldProps("linkedinProfile")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="internalNotes">
                Revivn Internal Notes
              </InputLabel>
              <InputBase id="internalNotes" {...getFieldProps("internalNotes")} />
            </FormControl>
          </Box>
        </Box>
        <Box mt={4} sx={{ display: "flex", justifyContent: "end" }}>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting || !isValid}>
            SAVE
          </Button>
        </Box>
      </Box>
    </div>
  );
}
