export type RecordHistoryEvent = {
  id: number;
  uuid: string;
  description: string;
  createdAt: string;
};

export const defaultRecordHistoryEvent = {
  id: 0,
  uuid: "",
  description: "",
  createdAt: "",
};
