export interface BenefitingOrganization {
  id: string;
  uuid: string;
  name: string;
  logoUrl: string;
  websiteUrl: string;
  file: string;
  mainContactName?: string;
  mainContactEmail?: string;
  mainContactPhoneNumber?: string;
  state?: string;
}

export const benefitingOrgDefault: BenefitingOrganization = {
  id: "",
  uuid: "",
  name: "",
  websiteUrl: "",
  logoUrl: "",
  file: "",
  mainContactName: "",
  mainContactEmail: "",
  mainContactPhoneNumber: "",
  state: "",
};
