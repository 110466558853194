import { OutlinedInput, styled } from "@mui/material";

export const InputGroup = styled("div")({
  label: {
    display: "inline-block",
    marginBottom: "4px",
  },
});

export const Label = styled("label")({
  fontWeight: "600",
});

export const Input = styled(OutlinedInput)({
  height: "38px",
  width: "100%",
});
