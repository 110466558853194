export const revenueTeam = [
  ["courtney@revivn.com", "Courtney Bannon"],
  ["nickl@revivn.com", "Nick Lanza"],
  ["apryl@revivn.com", "Apryl Hsu"],
  ["quinn@revivn.com", "Quinn Robert"],
  ["matt@revivn.com", "Matt Deis"],
  ["john@revivn.com", "John Fazzolari"],
  ["anthony@revivn.com", "Anthony Serina"],
  ["liz@revivn.com", "Liz Caporale"],
  ["johnny@revivn.com", "Johnny Donohue"],
  ["erik@revivn.com", "Erik Sipple"],
  ["benreinwart@revivn.com", "Ben Reinwart"],
  ["stephen@revivn.com", "Stephen Conneely"],
  ["alexis@revivn.com", "Alexis Lynch"], //deactivated, but still need to show in UI
  ["sean@revivn.com", "Sean McBride"], //deactivated, but still need to show in UI
  ["gavin@revivn.com", "Gavin McCann"], //deactivated, but still need to show in UI
  ["ben@revivn.com", "Benjamin McGrail"], //deactivated, but still need to show in UI
  ["korey@revivn.com", "Korey Martin"], //deactivated, but still need to show in UI
  ["victor@revivn.com", "Victor Medina"], //deactivated, but still need to show in UI
  ["blane+partnersupport@revivn.com", "Partner Support"], //deactivated, but still need to show in UI
];
