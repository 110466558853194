import AddNewBenefitingOrganization from "components/Donations/AddNewBenefitingOrganization";
import BenefitingOrganizations from "components/Donations/BenefitingOrganizations";
import EditBenefitingOrganization from "components/Donations/EditBenefitingOrganization";
import { Route, Routes } from "react-router-dom";

export default function DonationsPage() {
  return (
    <Routes>
      <Route index element={<BenefitingOrganizations />} />
      <Route path="/new" element={<AddNewBenefitingOrganization />} />
      <Route path="/:benefitingOrganizationUuid" element={<EditBenefitingOrganization />} />
    </Routes>
  );
}
