import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { LogisticsPartner } from "types/logisticsPartner";

export default function useFreighters(defaultPageSize?: number) {
  const location = useLocation();
  const [mainFreighters, setMainFreighters] = useState<LogisticsPartner[]>([]);
  const [subFreighters, setSubFreighters] = useState<LogisticsPartner[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    getFreighters();
  }, [search]);

  const getFreighters = (query?: string, type?: string) => {
    const searchHash = new URLSearchParams(location.search);
    if (!searchHash.get("page[size]") && defaultPageSize) {
      searchHash.append("page[size]", String(defaultPageSize));
    }
    api.get(`/freighters?${searchHash.toString()}${query ? "q=" + query : ""}`).then(
      ({
        data: {
          freighters,
          meta: { totalPages, count },
        },
      }) => {
        if (type === "subFreighters") {
          setSubFreighters(freighters);
        } else if (type === "mainFreighters") {
          setMainFreighters(freighters);
        } else {
          setSubFreighters(freighters);
          setMainFreighters(freighters);
        }
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  };

  return {
    mainFreighters,
    subFreighters,
    getFreighters,
    totalPages,
    count,
  };
}
