import { CheckCircle } from "@mui/icons-material";
import { theme } from "index";
import { styled, Typography } from "@mui/material";
import React from "react";

export const CheckList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;
const StyledLi = styled("li")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const CheckListItem = ({ label }: { label: string }) => {
  return (
    <StyledLi>
      <CheckCircle sx={{ color: theme.palette.success.main }} />
      <Typography textTransform="uppercase">{label}</Typography>
    </StyledLi>
  );
};
