import { Box, Button, InputAdornment, OutlinedInput, Paper, SxProps, Theme, Typography } from "@mui/material";
import useInventoryTemplates from "../hooks/useInventoryTemplates";
import { InventoryTemplate } from "types/inventoryTemplate";
import InventoryTemplatesDataGrid from "components/InventoryTemplates/InventoryTemplatesDataGrid";
import InventoryTemplateForm from "components/InventoryTemplates/InventoryTemplateForm";
import { debounce } from "lodash";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import { Search, Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type InventoryTemplatesProps = {
  sx?: SxProps<Theme>;
  onClose?: (inventoryTemplate: InventoryTemplate) => void;
};

export default function InventoryTemplatesPage({ sx }: InventoryTemplatesProps) {
  const newTemplate: InventoryTemplate = {
    uuid: "",
    manufacturer: "",
    model: "",
    name: "",
    description: "",
    cpu: "",
    ram: "",
    hardDrive: "",
    screenSize: "",
    isBulk: false,
    deviceType: "",
  };

  const {
    inventoryTemplates,
    inventoryTemplate,
    editInventoryTemplate,
    deleteInventoryTemplate,
    createInventoryTemplate,
    meta,
    setInventoryTemplate,
  } = useInventoryTemplates();
  const pageState = useServerSideDataGridParams(inventoryTemplates);
  const { setSearchQuery } = pageState;

  const navigate = useNavigate();

  return (
    <Paper variant="outlined">
      <Box>
        <Typography variant="h6" sx={{ my: 2, mx: 4 }}>
          Inventory Templates
        </Typography>

        <Box sx={{ padding: "10px 0", margin: 3, display: "grid", gridTemplateColumns: "4fr 1fr", gap: 4 }}>
          <OutlinedInput
            size="small"
            fullWidth
            onChange={debounce((e) => setSearchQuery(e.target.value), 300)}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              navigate(`/inventory-templates`);
              setInventoryTemplate(undefined);
            }}
          >
            Add new template
          </Button>
        </Box>

        <InventoryTemplatesDataGrid
          inventoryTemplates={inventoryTemplates}
          handleDelete={deleteInventoryTemplate}
          pageState={pageState}
          meta={meta}
        />

        <InventoryTemplateForm
          createInventoryTemplate={createInventoryTemplate}
          editInventoryTemplate={editInventoryTemplate}
          defaultValues={inventoryTemplate || newTemplate}
        />
      </Box>
    </Paper>
  );
}
