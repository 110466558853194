import { Box, Button, Typography, useTheme } from "@mui/material";
import DonationRequestsTable from "./DonationRequestsTable";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
export default function DonationRequests() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: 6,
        minHeight: "calc(100vh - 64px)",
        lineHeight: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Donation Requests
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography>Add, edit or delete donation requests displayed in our Revivn platform feed.</Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/donation-requests/new")}
        >
          ADD NEW REQUEST
        </Button>
      </Box>
      <Box sx={{ border: "1px solid", borderColor: palette.divider, background: "white", mt: 3 }}>
        <DonationRequestsTable />
      </Box>
    </Box>
  );
}
