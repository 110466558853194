import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { DonationRequest } from "types/donationRequest";
import { useAlert } from "./useAlert";

export default function useDonationRequests() {
  const [donationRequests, setDonationRequests] = useState<DonationRequest[]>([]);
  const [donationRequest, setDonationRequest] = useState<DonationRequest>();
  const [key, setKey] = useState<string | null>(null);
  const params = useParams();
  const { alertError } = useAlert();

  useEffect(() => {
    if (params.donationRequestUuid) {
      getDonationRequest(params.donationRequestUuid);
    }
  }, [params.donationRequestUuid]);

  const getDonationRequest = useCallback((uuid: string) => {
    api
      .get(`donation_requests/${uuid}`)
      .then(({ data }) => {
        setDonationRequest(data);
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  }, []);

  const getDonationRequests = useCallback(() => {
    api.get(`/donation_requests`).then(({ data }) => {
      setDonationRequests(data.donationRequests);
    });
  }, []);

  const createDonationRequest = (donationRequest: DonationRequest) => {
    const {
      goalInMac,
      goalInPc,
      goalInChromebook,
      goalDescription,
      notes,
      timeline,
      mainContactName,
      mainContactEmail,
      mainContactPhoneNumber,
      address,
      address2,
      city,
      zipcode,
      state,
      country,
      eori,
      vat,
      ein,
      goalName,
      registered501c3,
      benefitingOrganizationId,
      achOnFile,
      totalRequestedAmountInDollars,
    } = donationRequest;
    api
      .post(`/donation_requests`, {
        donationRequest: {
          goalInMac,
          goalInPc,
          goalDescription,
          goalInChromebook,
          notes,
          timeline,
          mainContactName,
          mainContactEmail,
          mainContactPhoneNumber,
          address,
          address2,
          city,
          zipcode,
          state,
          country,
          eori,
          vat,
          ein,
          goalName,
          registered501c3,
          benefitingOrganizationId,
          heroFile: key,
          achOnFile,
          totalRequestedAmountInDollars,
        },
      })
      .then(() => {
        getDonationRequests();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const updateDonationRequest = (donationRequest: DonationRequest) => {
    const {
      goalInMac,
      goalInPc,
      goalInChromebook,
      goalDescription,
      notes,
      timeline,
      mainContactName,
      mainContactEmail,
      mainContactPhoneNumber,
      address,
      address2,
      city,
      zipcode,
      state,
      country,
      eori,
      vat,
      ein,
      goalName,
      registered501c3,
      benefitingOrganizationId,
      achOnFile,
      totalRequestedAmountInDollars,
    } = donationRequest;
    api
      .put(`donation_requests/${params.donationRequestUuid}`, {
        donationRequest: {
          goalInMac,
          goalInPc,
          goalInChromebook,
          goalDescription,
          notes,
          timeline,
          mainContactName,
          mainContactEmail,
          mainContactPhoneNumber,
          address,
          address2,
          city,
          zipcode,
          state,
          country,
          eori,
          vat,
          ein,
          goalName,
          registered501c3,
          benefitingOrganizationId,
          heroFile: key ?? undefined,
          achOnFile,
          totalRequestedAmountInDollars,
        },
      })
      .then(() => {
        getDonationRequests();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const deleteDonationRequest = useCallback((uuid: string) => {
    api
      .delete(`donation_requests/${uuid}`)
      .then(() => {
        getDonationRequests();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  }, []);

  const deleteHeroImage = (uuid: string) => {
    return api.delete(`donation_requests/hero_image/${uuid}`);
  };

  useEffect(() => {
    getDonationRequests();
  }, [getDonationRequests]);

  return {
    donationRequest,
    donationRequests,
    createDonationRequest,
    updateDonationRequest,
    deleteDonationRequest,
    key,
    setKey,
    deleteHeroImage,
    getDonationRequests,
  };
}
