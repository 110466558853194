import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { InfoOutlined, Person } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { FulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";

export function StartBatchStep({ batch, onStartBatch }: { batch: FulfillmentBatch; onStartBatch: () => void }) {
  const estimatedTime = batch.itemsCount * 2;
  return (
    <>
      <PicklistCard title="User" Icon={Person}>
        <Stack alignItems="center">
          <Typography>{batch.userEmail}</Typography>
          <Typography>Assigned: {batch.orderCount} Orders</Typography>
        </Stack>
      </PicklistCard>
      <PicklistCard title="Overview" Icon={InfoOutlined}>
        <Stack alignItems="center">
          <Typography>{batch.itemsCount} Macbooks</Typography>
          <Typography>Estimated Time: {estimatedTime} min</Typography>
        </Stack>
      </PicklistCard>
      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <RevivnApiForm
          initialValues={{}}
          action="current_fulfillment_batch/start"
          method="POST"
          onSuccess={onStartBatch}
        >
          <Button variant="contained" type="submit">
            Start
          </Button>
        </RevivnApiForm>
      </Stack>
    </>
  );
}
