import { Dialog, DialogTitle, DialogContent, MenuItem, Select, SelectChangeEvent, Button } from "@mui/material";

import useQrScanner, { ReadCompleted } from "hooks/useQrScanner";
import { useCallback } from "react";

const QrReader = require("react-qr-scanner");

export default function QRScanner({
  readCompleted,
  open,
  handleClose,
}: {
  readCompleted: ReadCompleted;
  open: boolean;
  handleClose: () => void;
}) {
  const { readerProps, devices, selectedDevice, setSelectedDevice, setCameraFacing, cameraFacing } = useQrScanner({
    readCompleted,
  });

  const handleDeviceChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      setSelectedDevice(devices.find(({ deviceId }) => e.target.value === deviceId));
    },
    [devices]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Scan QR Code</DialogTitle>
      <Select size="small" fullWidth value={selectedDevice?.deviceId || ""} onChange={handleDeviceChange}>
        {devices.map((device, index) => (
          <MenuItem value={device.deviceId}>
            {device.label.length === 0 ? `Camera # ${index + 1}` : device.label}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={() => setCameraFacing((currentValue) => !currentValue)}>
        {cameraFacing ? "Back Camera" : "Front Camera"}
      </Button>
      <DialogContent>{selectedDevice && <QrReader {...readerProps} />}</DialogContent>
    </Dialog>
  );
}
