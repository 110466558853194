import { conditions } from "constants/conditions";
import { deviceTypes } from "constants/device_types";
import { enrolledStatuses } from "constants/enrolled_statuses";
import { securityLevels } from "constants/security_levels";
import { inventoryEditValidation } from "validations/inventory";
import * as Yup from "yup";

export const TechnicianFormSchema = Yup.object()
  .shape({
    deviceType: Yup.string().required().oneOf(deviceTypes, "Must be a valid device type.").strict(),
    color: Yup.string().notRequired().nullable().trim("Cannot have trailing spaces.").strict(),
    condition: Yup.string()
      .notRequired()
      .nullable()
      .oneOf([...conditions, null], "Must be a valid condition grade."),
    securityLevel: Yup.string()
      .notRequired()
      .nullable()
      .oneOf([...securityLevels, null], "Must be one of the dropdown options."),
    enrolledStatus: Yup.string()
      .notRequired()
      .nullable()
      .oneOf([...enrolledStatuses, null], "Must be one of the dropdown options."),
    batteryCount: Yup.number()
      .notRequired()
      .nullable()
      .typeError("Value must be a number.")
      .integer()
      .min(0)
      .max(9999)
      .round("round"),
    batteryHealth: Yup.number()
      .notRequired()
      .nullable()
      .typeError("Value must be a number.")
      .integer()
      .min(0)
      .max(100)
      .round("round"),
    trackpadFunctionality: Yup.string().notRequired().nullable(),
    keyboardFunctionality: Yup.string().notRequired().nullable(),
    screenFunctionality: Yup.string().notRequired().nullable(),
    batteryFunctionality: Yup.string().notRequired().nullable(),
    cameraFunctionality: Yup.string().notRequired().nullable(),
    microphoneFunctionality: Yup.string().notRequired().nullable(),
    speakerFunctionality: Yup.string().notRequired().nullable(),
    portsFunctionality: Yup.string().notRequired().nullable(),
    powerFunctionality: Yup.string().notRequired().nullable(),
    logicFunctionality: Yup.string().notRequired().nullable(),
  })
  .concat(inventoryEditValidation);

export const InventoryPartsFormSchema = Yup.object().shape({
  type: Yup.string().oneOf(["Board", "Lcd", "TopCase", "Battery"], "Must be a valid inventory part type.").required(),
  apn: Yup.string(),
  model: Yup.string().required(),
  cosmeticGrade: Yup.string().oneOf(["A", "B", "C"], "Grade must be A, B, or C.").required(),
  comments: Yup.string().trim(),
  logicFunctional: Yup.string(),
  power: Yup.string(),
  trackpad: Yup.string(),
  speaker: Yup.string(),
  ioBoard: Yup.string(),
  fans: Yup.string(),
  battery: Yup.string(),
  ports: Yup.string(),
});

export const BulkInventoryPartsFormSchema = Yup.object().shape({
  type: Yup.string().oneOf(["Board", "Lcd", "TopCase", "Battery"], "Must be a valid inventory part type.").required(),
  apn: Yup.string(),
  model: Yup.string().required(),
  cosmeticGrade: Yup.string().oneOf(["A", "B", "C"], "Grade must be A, B, or C.").required(),
  warehouseLocation: Yup.string().required(),
  color: Yup.string().required(),
  comments: Yup.string().trim(),
  quantity: Yup.number().min(1, "Quantity can't be less than 1.").max(99, "Quantity limited to under 100.").required(),
  logicFunctional: Yup.string(),
  power: Yup.string(),
  trackpad: Yup.string(),
  speaker: Yup.string(),
  ioBoard: Yup.string(),
  fans: Yup.string(),
  battery: Yup.string(),
  ports: Yup.string(),
  keyboard: Yup.string(),
  microphone: Yup.string(),
});
