import { PicklistProgress } from "components/Picklist/common/PicklistProgress";
import React from "react";
import { FulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";
import { PackInventoryWizard } from "components/Picklist/CurrentBatch/Packing/PackInventoryWizard";

interface PackingStepProps {
  batch: FulfillmentBatch;
  onItemPackingComplete: () => void;
}

export function PackingStep({ batch, onItemPackingComplete }: PackingStepProps) {
  const total = batch.itemsCount;

  return (
    <>
      <PicklistProgress complete={batch.stateCompleteCount + 1} total={total} />
      {batch.currentItem && (
        <PackInventoryWizard
          key={batch.currentItem.id}
          inventory={batch.currentItem}
          onInventoryComplete={onItemPackingComplete}
        />
      )}
    </>
  );
}
