import { Button } from "@mui/material";
import useReporting from "hooks/useReporting";
import FileDownload from "@mui/icons-material/FileDownload";
import { Pickup } from "types/pickup";

type PricingProps = {
  pickup: Pickup;
};

export default function PricingToolBarButton({ pickup }: PricingProps) {
  const { getPricingReportData, pricingReportData, loadingPricing, downloadPricingFile, setDownloadPricingFile } =
    useReporting();

  if (downloadPricingFile) {
    setDownloadPricingFile(false);
    const blob = new Blob([pricingReportData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `pickup_${pickup.id}_pricing_data.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <Button
      variant="outlined"
      startIcon={<FileDownload />}
      size="large"
      disabled={loadingPricing ? true : false}
      onClick={() => {
        getPricingReportData(pickup.uuid);
      }}
    >
      Pricing
    </Button>
  );
}
