import { QrCodeScanner } from "@mui/icons-material";
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

interface ScanInventoryProps {
  getInventoryById: (id: string) => Promise<void>;
  handleOpenCameraSettings: () => void;
}

export default function ScanInventory({ getInventoryById, handleOpenCameraSettings }: ScanInventoryProps) {
  const [inventoryId, setInventoryId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const handleFetchInventory = () => {
    if (!inventoryId || isFetching) return;
    setIsFetching(true);
    getInventoryById(inventoryId)
      .then(() => setInventoryId(""))
      .finally(() => setIsFetching(false));
  };

  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Typography fontSize="24px" fontWeight={600}>
          Scan Label
        </Typography>
        <TextField
          autoFocus
          placeholder="Scan or type label"
          variant="outlined"
          InputProps={{
            startAdornment: <QrCodeScanner sx={{ mr: 1 }} />,
          }}
          disabled={isFetching}
          value={inventoryId}
          onChange={(e) => setInventoryId(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleFetchInventory();
            }
          }}
        />
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button disabled={isFetching} onClick={handleOpenCameraSettings} size="small" variant="contained">
            Camera Settings
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
