import { Box, SxProps } from "@mui/system";

type PrespacedTextProps = {
  children?: string;
  py?: number;
  sx?: SxProps;
};

export default function PrespacedText({ children, py, sx }: PrespacedTextProps) {
  const calculatedPy = children ? py : py ? py + 1 : 1;

  return <Box sx={{ ...sx, py: calculatedPy }}>{children}</Box>;
}
