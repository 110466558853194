import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { defaultOrganization, Organization } from "types/organization";

export default function useOrganizations() {
  const [organizations, setOrganizations] = useState<Organization[]>([defaultOrganization]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    api.get(`/organizations${search}`).then(
      ({
        data: {
          organizations,
          meta: { totalPages, count },
        },
      }) => {
        setOrganizations(organizations);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  }, [search]);

  const searchOrganizations = (query: string) => {
    api.get(`/organizations?q=${query}`).then(({ data: { organizations } }) => {
      setOrganizations(organizations);
    });
  };

  return {
    organizations,
    searchOrganizations,
    totalPages,
    count,
  };
}
