import { Box, Button, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import ClientForm from "./ClientForm";
import useClients from "hooks/useClients";
import { ArrowBack, SyncDisabled } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ClientDeactivateModal from "./ClientDeactivateModal";

export function ClientEdit() {
  const { client, updateStatus, deactivateOpen, setDeactivateOpen } = useClients();
  const navigate = useNavigate();

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client?.name, to: `/logistics/clients/${client?.uuid}` },
          { label: "Edit", to: `/logistics/clients/${client?.uuid}/edit` },
        ]}
      ></Breadcrumbs>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button size="small" variant="text" startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
          back to clients
        </Button>
        {client && (
          <Button
            variant="contained"
            size="small"
            onClick={() => setDeactivateOpen(true)}
            startIcon={<SyncDisabled />}
            color="error"
            disabled={client.state == "inactive"}
          >
            {client && client.state == "inactive" ? `Deactivated` : "Deactivate client"}
          </Button>
        )}
      </Box>

      <Box mt={2}>
        <ClientForm client={client} />
      </Box>
      <ClientDeactivateModal open={deactivateOpen} setOpen={setDeactivateOpen} updateStatus={updateStatus} />
    </Box>
  );
}
