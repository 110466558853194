import { Box } from "@mui/system";
import { styled } from "@mui/material";

export const Panel = styled(Box)(({ theme }) => ({
  paddingBlock: theme.spacing(3),
  paddingInline: theme.spacing(4),
  background: "#FAFAFA",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));
