import { useField } from "formik";

// A file input that works with Formik
export const FileField = ({ name, ...props }: any) => {
  const [inputProps, metaProps, helperProps] = useField<File | null>({
    name,
    ...props,
  });

  // We ignore value here because it's a File object and we don't want to try to put it in the input
  const { value, ...rest } = inputProps;

  return (
    <input
      type="file"
      key={value?.name}
      {...props}
      {...rest}
      onChange={(e: any) => helperProps.setValue(e.target.files[0])}
    />
  );
};
