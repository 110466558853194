import useDirectUpload from "hooks/useDirectUpload";
import { useState } from "react";
import { Inventory } from "types/inventory";
import { useAlert } from "./useAlert";
import useInventory from "./useInventory";

export default function useResalePhotos(inventory?: Inventory) {
  const [signedIds, setSignedIds] = useState<string[]>([]);
  const prefix = () => "resale_photos/" + (inventory?.id ?? "") + "/";
  const { uploadSync } = useDirectUpload({ keyPrefix: prefix(), service: "amazon_public", useFilename: true });
  const { updateInventory } = useInventory();
  const { alertError, alertSuccess } = useAlert();

  const upload = async (file: File) => {
    return uploadSync(file)
      .then((blob) => {
        setSignedIds((prevState) => [...prevState, blob.signed_id]);
        inventory?.resalePhotos.push({
          signed_id: blob.signed_id,
        });
        return true;
      })
      .catch((err) => {
        console.log("Upload error:", err);
        return false;
      });
  };

  const uploadFiles = async (files: File[]) => {
    if (!inventory) return Promise.reject("Inventory not assigned.");

    const promises = files.map((file) => upload(file));

    return Promise.all(promises).then((result) => {
      const successfulUploads = result.filter((value) => value === true).length;
      const failedUploads = result.length - successfulUploads;
      if (successfulUploads) alertSuccess(`${successfulUploads} Resale Photos uploaded successfully.`);
      if (failedUploads) alertError(`${failedUploads} Resale Photos failed to upload.`);
      if (inventory) {
        updateInventory(inventory).then(({ data }) => {
          inventory = data;
        });
      }
      return result;
    });
  };

  return { uploadFiles };
}
