import { Box, Button, LinearProgress, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Add, Person, Edit, InfoOutlined } from "@mui/icons-material";
import LogoIcon from "images/logo-icon.svg";
import { theme } from "index";
import useEarnings from "hooks/useEarnings";
import ActivityTable from "./ActivityTable";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "hooks/useQuery";
import { useState } from "react";

export function ClientShow() {
  const { client, clientLoading } = useClients();
  const { earnings, transactions, totalPages } = useEarnings();
  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    query.delete("page[number]");
    query.delete("q");
    query.append("q", searchQuery);
    if (value != 1) {
      query.append("page[number]", String(value));
    }

    navigate(`?${query.toString()}`);
  };

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Finance", to: "/finance/pickups" },
          { label: "Clients", to: "/finance/clients" },
          { label: client ? client?.name : "" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography mt={0.5}></Typography>
        <Box
          sx={{
            px: 3,
            py: 2,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box>
            <Typography variant="h4">{client?.name?.toUpperCase()}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 5, padding: "30px" }}>
              <Box textAlign="center">
                <Typography variant={"h3"} fontWeight={600} component="div">
                  ${earnings / 100}
                </Typography>
                <Typography fontWeight={600}>Total Earnings</Typography>
              </Box>
            </Box>
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              to={`/finance/clients/${params.uuid}/debits/new`}
              component={RouterLink}
              startIcon={<RemoveCircleOutlineIcon />}
              variant="contained"
            >
              Add Debit
            </Button>
            <Button
              to={`/finance/clients/${params.uuid}/credits/new`}
              component={RouterLink}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Add Credit
            </Button>
          </Stack>
          <Box
            sx={{
              mt: 2,
              px: 3,
              py: 2,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ mt: 4 }}>{<ActivityTable transactions={transactions || []} />}</Box>

            {totalPages > 1 && (
              <Box textAlign="center" mt={2} mb={2} sx={{ display: "flex", justifyContent: "end" }}>
                <Pagination count={totalPages} page={page} onChange={handlePageChange} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
