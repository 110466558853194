import { ClientContactEdit } from "components/Logistics/Clients/ClientContactEdit";
import { ClientContactNew } from "components/Logistics/Clients/ClientContactNew";
import { ClientContacts } from "components/Logistics/Clients/ClientContacts";
import { ClientEdit } from "components/Logistics/Clients/ClientEdit";
import { ClientNew } from "components/Logistics/Clients/ClientNew";
import { Clients } from "components/Logistics/Clients/Clients";
import { ClientShow } from "components/Logistics/Clients/ClientShow";
import { ContactEdit } from "components/Logistics/Clients/ContactEdit";
import { ContactNew } from "components/Logistics/Clients/ContactNew";
import { LocationEdit } from "components/Logistics/Clients/LocationEdit";
import { LocationNew } from "components/Logistics/Clients/LocationNew";
import { LocationShow } from "components/Logistics/Clients/LocationShow";
import LogisticsPickups from "components/Logistics/Pickups/LogisticsPickups";
import { PartnerEdit } from "components/Logistics/Partners/PartnerEdit";
import { PartnerNew } from "components/Logistics/Partners/PartnerNew";
import { Partners } from "components/Logistics/Partners/Partners";
import { PartnerShow } from "components/Logistics/Partners/PartnerShow";
import PickupTimeslotsPage from "components/Logistics/PickupTimeslots/PickupTimeslotsPage";
import { Route, Routes } from "react-router-dom";
import { LogisticsDetails } from "components/Logistics/Pickups/LogisticsInfo/LogisticsDetails";
import PartnerInventoryPage from "components/Logistics/Pickups/Inventory/PartnerInventoryPage";
import { LogisticsPickupProvider } from "hooks/useLogisticsPickup";
import LogisticsPickupDetailsEditPage from "pages/Logistics/LogisticsPickupDetailsEditPage";

export default function LogisticsPage() {
  return (
    <Routes>
      <Route index element={<LogisticsPickups />} />
      <Route
        path="/schedule-pickup"
        element={
          <LogisticsPickupProvider>
            <LogisticsPickupDetailsEditPage />
          </LogisticsPickupProvider>
        }
      ></Route>
      <Route
        path="/pickups/:pickupUuid/edit"
        element={
          <LogisticsPickupProvider>
            <LogisticsPickupDetailsEditPage />
          </LogisticsPickupProvider>
        }
      />
      <Route path="/pickups/:pickupUuid/logistics" element={<LogisticsDetails />} />
      <Route path="/pickups/:pickupUuid/inventory" element={<PartnerInventoryPage />} />
      <Route path="/pickup-timeslots" element={<PickupTimeslotsPage />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/clients/:uuid" element={<ClientShow />} />
      <Route path="/clients/:uuid/edit" element={<ClientEdit />} />
      <Route path="/clients/:uuid/contacts" element={<ClientContacts />} />
      <Route path="/clients/:uuid/contacts/new" element={<ClientContactNew />} />
      <Route path="/clients/:uuid/contacts/:contactUuid/edit" element={<ClientContactEdit />} />
      <Route path="/clients/:uuid/locations/:locationUuid/edit" element={<LocationEdit />} />
      <Route path="/clients/:uuid/locations/:locationUuid/contacts/new" element={<ContactNew />} />
      <Route path="/clients/:uuid/locations/:locationUuid/contacts/:contactUuid/edit" element={<ContactEdit />} />
      <Route path="/clients/:uuid/locations/:locationUuid" element={<LocationShow />} />
      <Route path="/clients/:uuid/locations/new" element={<LocationNew />} />
      <Route path="/clients/new" element={<ClientNew />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/partners/new" element={<PartnerNew />} />
      <Route path="/partners/:uuid" element={<PartnerShow />} />
      <Route path="/partners/:uuid/edit" element={<PartnerEdit />} />
    </Routes>
  );
}
