import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { AppSelect, InputLabel } from "components/common/Input";
import { Formik } from "formik";
import { theme } from "index";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { readableDate } from "utils/readableDate";

export function PickupFeesTransactionGroupForm() {
  const { uuid, transactionGroupId } = useParams();
  const [pickups, setPickups] = useState([]);
  const [transactionGroup, setTransactionGroup] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/organizations/${uuid}/transaction_groups/transaction_pickups`).then(({ data: { pickups } }) => {
      setPickups(pickups);
    });
  }, []);

  useEffect(() => {
    if (!transactionGroupId) return;
    api.get(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`).then(({ data }) => {
      setTransactionGroup(data);
    });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          transactionGroup || {
            debitTransaction: "",
            quickbooksId: "",
            pickupId: "",
            paymentMethod: "earnings",
            postedAt: new Date(),
            assetRetrievalOrderId: "",
          }
        }
        onSubmit={(values) => {
          api
            .post(`/organizations/${uuid}/transaction_groups`, {
              transactionGroup: { type: "PickupFees", ...values },
            })
            .then(({ data }) => {
              navigate(`/finance/clients/${uuid}/transaction_groups/PickupFees/${data.id}`, {
                replace: true,
              });
            });
        }}
      >
        {({ getFieldProps, handleSubmit }) => (
          <Box
            sx={{
              p: 4,
              mb: 4,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Debit Amount:</InputLabel>
                <TextField
                  size="small"
                  {...getFieldProps("debitTransaction")}
                  disabled={!!transactionGroupId}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Quickbooks ID:</InputLabel>
                <TextField size="small" {...getFieldProps("quickbooksId")} disabled={!!transactionGroupId} />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Posted at:</InputLabel>
                <TextField
                  size="small"
                  type={transactionGroupId ? "" : "date"}
                  {...getFieldProps("postedAt")}
                  disabled={!!transactionGroupId}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Select associated pickup:</InputLabel>
                <AppSelect {...getFieldProps("pickupId")} disabled={!!transactionGroupId}>
                  {pickups.map((pickup: any) => (
                    <MenuItem key={pickup.id} value={pickup.id}>
                      {pickup.orderNumber} - {readableDate(pickup.scheduledAt)}
                    </MenuItem>
                  ))}
                </AppSelect>
              </FormControl>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Payment Method:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  {...getFieldProps("paymentMethod")}
                >
                  <FormControlLabel
                    value="earnings"
                    control={<Radio />}
                    label="Earnings"
                    disabled={!!transactionGroupId}
                  />
                  <FormControlLabel
                    value="invoice"
                    control={<Radio />}
                    label="Invoice"
                    disabled={!!transactionGroupId}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {!transactionGroupId && (
              <Box display="flex" justifyContent="flex-end" mt={6}>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Formik>
      {transactionGroup && transactionGroup.paymentMethod === "invoice" && (
        <Formik
          enableReinitialize
          initialValues={
            transactionGroup || {
              creditTransaction: "",
              creditPostedAt: new Date(),
            }
          }
          onSubmit={({ creditTransaction, creditPostedAt }) => {
            api
              .put(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`, {
                transactionGroup: { type: "PickupFees", creditTransaction, creditPostedAt },
              })
              .then(({ data }) => {
                setTransactionGroup(data);
              });
          }}
        >
          {({ getFieldProps, handleSubmit }) => (
            <Box
              sx={{
                p: 4,
                mb: 4,
                background: "white",
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Credit Amount:</InputLabel>
                  <TextField
                    size="small"
                    {...getFieldProps("creditTransaction")}
                    disabled={transactionGroup && transactionGroup.creditTransaction}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Posted at:</InputLabel>
                  <TextField
                    size="small"
                    type={transactionGroup && transactionGroup.creditTransaction ? "" : "date"}
                    {...getFieldProps("creditPostedAt")}
                    disabled={transactionGroup && transactionGroup.creditTransaction}
                  />
                </FormControl>
              </Box>
              {!(transactionGroup && transactionGroup.creditTransaction) && (
                <Box display="flex" justifyContent="flex-end" mt={6}>
                  <Button variant="contained" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Formik>
      )}
    </>
  );
}
