import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";

export default function useEarnings() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [earnings, setEarnings] = useState<any>();
  const [transactions, setTransactions] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    getEarnings();
  }, [search]);

  const getEarnings = () => {
    setLoading(true);

    api.get(`/organizations/${params.uuid}/earnings${search}`).then(
      ({
        data: {
          earningsBalance,
          transactions,
          meta: { totalPages, count },
        },
      }) => {
        setEarnings(earningsBalance);
        setTransactions(transactions);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  };

  type Props = {
    amountInCents: number;
    airbaseId?: string;
    waived: boolean;
    description?: string;
    internalNotes?: string;
    publishedAt?: string;
    pickupId?: number | null;
  };

  const createEarningsTransaction = (earningsTransaction: Props) => {
    earningsTransaction.amountInCents = Math.ceil(earningsTransaction.amountInCents * 100);
    api.post(`/organizations/${params.uuid}/earnings`, {
      earningsTransaction,
    });
  };

  return {
    earnings,
    createEarningsTransaction,
    transactions,
    totalPages,
    count,
  };
}
