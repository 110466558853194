import { ArrowForward } from "@mui/icons-material";
import { Box, Typography, FormControl, Button, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { timezones } from "constants/timezones";
import { useFormik } from "formik";
import useLocations from "hooks/useLocations";
import { theme } from "index";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Location } from "types/location";
import { sleep } from "utils/sleep";

type LocationFormProps = {
  location?: Location;
};

export default function LocationForm({ location }: LocationFormProps) {
  const navigate = useNavigate();
  const { createLocation, updateLocation } = useLocations();
  const [, setSearchParams] = useSearchParams();
  const params = useParams();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: location?.address,
      address2: location?.address2,
      city: location?.city,
      state: location?.state,
      zipcode: location?.zipcode,
      country: location?.country,
      name: location?.name,
      description: location?.description,
      approximatePeople: location?.approximatePeople,
      timezone: location?.timezone,
      internalNotes: location?.internalNotes,
      accessInstructions: location?.accessInstructions,
    },

    onSubmit: async (values) => {
      try {
        if (params.locationUuid) {
          await updateLocation(values);
        } else {
          await createLocation(values);
        }

        await sleep(300);
        navigate(`/logistics/clients/${params.uuid}`);
      } catch {
        // alertError("There was an error, please try again");
      }
    },
  });

  return (
    <div>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          ".css-k0xjq9-MuiTypography-root": { pt: 1 },
          ".MuiChip-deleteIcon": { color: "#ffffff" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          sx={{
            mt: 3,
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" fontWeight={"600"} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ArrowForward />
            Pickup address
          </Typography>

          <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-address">
                Address Line 1
              </InputLabel>
              <InputBase
                id="location-address"
                placeholder="Street address or P.O. Box"
                {...formik.getFieldProps("address")}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="location-address2">
                Address Line 2
              </InputLabel>
              <InputBase
                id="location-address2"
                placeholder="Apt, suite, unit, building, floor, etc."
                {...formik.getFieldProps("address2")}
              />
            </FormControl>

            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="location-city">
                  City
                </InputLabel>
                <InputBase id="location-city" placeholder="City" {...formik.getFieldProps("city")} />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="location-state">
                  State
                </InputLabel>
                <InputBase id="location-state" placeholder="State" {...formik.getFieldProps("state")} />
              </FormControl>
            </Box>

            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="location-zipcode">
                  Zipcode
                </InputLabel>
                <InputBase id="location-zipcode" placeholder="Zipcode" {...formik.getFieldProps("zipcode")} />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="location-country" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  Country
                </InputLabel>

                <InputBase id="location-country" placeholder="Country" {...formik.getFieldProps("country")} />
              </FormControl>
              {/* <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="location-timezone">
                  Timezone
                </InputLabel>
                <AppSelect displayEmpty defaultValue="">
                  {timezones.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </AppSelect>
              </FormControl> */}
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="location-name">
                  (Optional) Location Nickname
                </InputLabel>
                <InputBase
                  type="text"
                  id="location-name"
                  placeholder="Name of location"
                  {...formik.getFieldProps("name")}
                />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="users-count">
                  Approximately how many end users does this location have?
                </InputLabel>
                <InputBase type="number" id="approximatePeople" {...formik.getFieldProps("approximatePeople")} />
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
          {formik.values.accessInstructions && (
            <Box>
              <Typography variant="h6" fontWeight={"bold"}>
                Client Provided Building Access Instructions
              </Typography>
              <Box>{formik.values.accessInstructions}</Box>
            </Box>
          )}
          <Box sx={{ display: "flex", gap: 1 }}>
            <ArrowForward sx={{ mt: "4px", flexShrink: 0 }} />
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <label htmlFor="access-instructions">
                <Typography variant="h6" fontWeight={"bold"}>
                  Revivn Internal Building Notes
                </Typography>
                <Typography>Any nuances we should record for future trips?</Typography>
              </label>
              <InputBase
                multiline
                rows={3}
                id="internalNotes"
                sx={{ mt: "12px !important" }}
                {...formik.getFieldProps("internalNotes")}
              />
            </FormControl>
          </Box>
        </Box>

        <Box mt={4}>
          <Button color="primary" variant="contained" type="submit">
            SAVE
          </Button>
        </Box>
      </Box>
    </div>
  );
}
