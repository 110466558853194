import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, FormControl, Grid, Radio, RadioGroup, SvgIcon, Typography } from "@mui/material";
import { Form, useFormikContext } from "formik";
import { useMemo } from "react";
import { Inventory } from "types/inventory";

const radioProps = {
  checkedIcon: <CheckCircleIcon />,
  icon: <Box component={SvgIcon} />,
  sx: {
    borderRadius: 1,
    width: "100%",
    height: "100%",
    ":hover": { outline: "1px solid" },
    transition: "ease-in-out 0.1s",
  },
};

function Functionality({
  name,
  value,
  label,
  hideUnchecked,
}: {
  name: string;
  value?: string | null;
  label: string;
  hideUnchecked?: boolean;
}) {
  const parted = value?.includes("Parted") || value?.includes("Replaced");

  return (
    <RadioGroup
      name={name}
      value={value}
      row
      sx={{ ":hover": { backgroundColor: (theme) => theme.palette.grey[200] }, transition: "ease-in-out 0.1s" }}
    >
      <Grid container columns={6} alignItems="center" justifyContent="space-around">
        <Grid item xs>
          <Typography variant="caption" paddingLeft={1}>
            {label}
          </Typography>
        </Grid>
        {parted && (
          <Typography align="left" variant="overline" fontWeight={600}>
            {value}
          </Typography>
        )}
        {!hideUnchecked && (
          <Grid item xs>
            <Radio {...radioProps} disabled={parted} color="neutral" value="null" />
          </Grid>
        )}
        <Grid item xs>
          <Radio {...radioProps} disabled={parted} color="success" value="Working" />
        </Grid>
        <Grid item xs>
          <Radio {...radioProps} disabled={parted} color="secondary" value="As-is" />
        </Grid>
        <Grid item xs>
          <Radio {...radioProps} disabled={parted} color="error" value="Broken" />
        </Grid>
        <Grid item xs>
          <Radio {...radioProps} disabled={parted} color="primary" value="Repaired" />
        </Grid>
      </Grid>
    </RadioGroup>
  );
}

export default function FunctionalityStyledSwitches({ disabled }: { disabled: boolean }) {
  const { values, handleChange, initialValues } = useFormikContext<Inventory>();
  const hideUnchecked = useMemo<boolean>(
    () =>
      initialValues.portsFunctionality != null &&
      initialValues.cameraFunctionality != null &&
      initialValues.screenFunctionality != null &&
      initialValues.batteryFunctionality != null &&
      initialValues.speakerFunctionality != null &&
      initialValues.keyboardFunctionality != null &&
      initialValues.trackpadFunctionality != null &&
      initialValues.microphoneFunctionality != null &&
      initialValues.powerFunctionality != null &&
      initialValues.logicFunctionality != null,
    [initialValues],
  );

  return (
    <Box paddingY={2}>
      <Form onChange={handleChange}>
        <FormControl disabled={disabled} fullWidth>
          <Grid container columns={6} alignItems={"center"} justifyContent={"space-around"}>
            <Grid item xs />
            {hideUnchecked ? null : (
              <Grid item xs textAlign={"center"}>
                <Typography variant="body2" width={"100%"}>
                  Unchecked
                </Typography>
              </Grid>
            )}
            <Grid item xs textAlign={"center"}>
              <Typography variant="body2" width={"100%"}>
                Working
              </Typography>
            </Grid>
            <Grid item xs textAlign={"center"}>
              <Typography variant="body2" width={"100%"}>
                As-is
              </Typography>
            </Grid>
            <Grid item xs textAlign={"center"}>
              <Typography variant="body2" width={"100%"}>
                Broken
              </Typography>
            </Grid>
            <Grid item xs textAlign={"center"}>
              <Typography variant="body2" width={"100%"}>
                Repaired
              </Typography>
            </Grid>
          </Grid>
          <Box overflow="auto">
            <Typography variant="overline">LCD</Typography>
            <Functionality
              name="screenFunctionality"
              value={values.screenFunctionality}
              label="Display"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="cameraFunctionality"
              value={values.cameraFunctionality}
              label="Webcam"
              hideUnchecked={hideUnchecked}
            />
            <Typography variant="overline">Top Case</Typography>
            <Functionality
              name="keyboardFunctionality"
              value={values.keyboardFunctionality}
              label="Keyboard"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="trackpadFunctionality"
              value={values.trackpadFunctionality}
              label="Trackpad"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="batteryFunctionality"
              value={values.batteryFunctionality}
              label="Battery"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="microphoneFunctionality"
              value={values.microphoneFunctionality}
              label="Microphone"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="speakerFunctionality"
              value={values.speakerFunctionality}
              label="Speakers"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="portsFunctionality"
              value={values.portsFunctionality}
              label="Ports"
              hideUnchecked={hideUnchecked}
            />
            <Typography variant="overline">Board</Typography>
            <Functionality
              name="powerFunctionality"
              value={values.powerFunctionality}
              label="Power"
              hideUnchecked={hideUnchecked}
            />
            <Functionality
              name="logicFunctionality"
              value={values.logicFunctionality}
              label="Logic Function"
              hideUnchecked={hideUnchecked}
            />
          </Box>
        </FormControl>
      </Form>
    </Box>
  );
}
