import BoltIcon from "@mui/icons-material/Bolt";
import CameraIcon from "@mui/icons-material/Camera";
import CheckIcon from "@mui/icons-material/Check";
import CycloneIcon from "@mui/icons-material/Cyclone";
import MemoryIcon from "@mui/icons-material/Memory";
import MouseIcon from "@mui/icons-material/Mouse";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SanitizerIcon from "@mui/icons-material/Sanitizer";
import TvIcon from "@mui/icons-material/Tv";
import UsbIcon from "@mui/icons-material/Usb";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { customAttributeIncluded, customAttributeWorking } from "constants/inventory_parts_custom_attribute_states";
import useInventoryParts from "hooks/useInventoryParts";
import _, { camelCase, startCase } from "lodash";
import React, { useCallback, useState } from "react";
import { InventoryPart } from "types/inventoryPart";

const getTopCaseChips = (part: InventoryPart) => {
  const conditions = [
    part.trackpad !== customAttributeIncluded,
    part.speaker !== customAttributeIncluded,
    part.ioBoard !== customAttributeIncluded,
    part.fans !== customAttributeIncluded,
    part.battery !== customAttributeIncluded,
    part.ports !== customAttributeIncluded,
    part.keyboard !== customAttributeIncluded,
    part.microphone !== customAttributeIncluded,
  ];

  const isAnyChipMissing = conditions.some((condition) => condition);

  return (
    <>
      {isAnyChipMissing && "Missing:"}
      {part.trackpad == customAttributeIncluded ? null : (
        <Chip
          icon={<MouseIcon />}
          color={part.trackpad == customAttributeIncluded ? "success" : "error"}
          label="Trackpad"
          size="small"
        />
      )}
      {part.speaker == customAttributeIncluded ? null : (
        <Chip
          icon={<VolumeUpIcon />}
          color={part.speaker == customAttributeIncluded ? "success" : "error"}
          label="Speaker"
          size="small"
        />
      )}
      {part.ioBoard == customAttributeIncluded ? null : (
        <Chip
          icon={<MemoryIcon />}
          color={part.ioBoard == customAttributeIncluded ? "success" : "error"}
          label="I/O Board"
          size="small"
        />
      )}
      {part.fans == customAttributeIncluded ? null : (
        <Chip
          icon={<CycloneIcon />}
          color={part.fans == customAttributeIncluded ? "success" : "error"}
          label="Fans"
          size="small"
        />
      )}
      {part.battery == customAttributeIncluded ? null : (
        <Chip
          icon={<BoltIcon />}
          color={part.battery == customAttributeIncluded ? "success" : "error"}
          label="Battery"
          size="small"
        />
      )}
      {part.ports == customAttributeIncluded ? null : (
        <Chip
          icon={<UsbIcon />}
          color={part.ports == customAttributeIncluded ? "success" : "error"}
          label="Ports"
          size="small"
        />
      )}
      {part.keyboard == customAttributeIncluded ? null : (
        <Chip
          icon={<UsbIcon />}
          color={part.keyboard == customAttributeIncluded ? "success" : "error"}
          label="Keyboard"
          size="small"
        />
      )}
      {part.microphone == customAttributeIncluded ? null : (
        <Chip
          icon={<UsbIcon />}
          color={part.microphone == customAttributeIncluded ? "success" : "error"}
          label="Microphone"
          size="small"
        />
      )}
    </>
  );
};

const getBoardChips = (part: InventoryPart) => {
  const conditions = [part.logic !== customAttributeWorking, part.power !== customAttributeWorking];
  const isAnyChipBroken = conditions.some((condition) => condition);

  return (
    <>
      {isAnyChipBroken && "Broken:"}
      {part.logic == customAttributeWorking ? null : (
        <Chip
          icon={<CheckIcon />}
          color={part.logic == customAttributeWorking ? "success" : "error"}
          label="Functional"
          size="small"
        />
      )}
      {part.power == customAttributeWorking ? null : (
        <Chip
          icon={<SanitizerIcon />}
          color={part.power == customAttributeWorking ? "success" : "error"}
          label="Data Sanitized"
          size="small"
        />
      )}
    </>
  );
};

const getLcdChips = (part: InventoryPart) => {
  const conditions = [part.webcam !== customAttributeWorking, part.display !== customAttributeWorking];
  const isAnyChipMissing = conditions.some((condition) => condition);
  return (
    <>
      {isAnyChipMissing && "Missing:"}
      {part.webcam === customAttributeWorking ? null : (
        <Chip
          icon={<CameraIcon />}
          color={part.webcam == customAttributeWorking ? "success" : "error"}
          label="Webcam"
          size="small"
        />
      )}
      {part.display === customAttributeWorking ? null : (
        <Chip
          icon={<TvIcon />}
          color={part.display == customAttributeWorking ? "success" : "error"}
          label="Display"
          size="small"
        />
      )}
    </>
  );
};

const partTypeChipsMap: { [key: string]: (part: InventoryPart) => JSX.Element | null } = {
  TopCase: getTopCaseChips,
  Board: getBoardChips,
  Lcd: getLcdChips,
};

const getPartTypeChips = (partType: string, part: InventoryPart) => {
  const getChips = partTypeChipsMap[partType];
  return getChips ? getChips(part) : null;
};

export default function ReplacePartComponent({ partType, canReplace }: { partType: string; canReplace: boolean }) {
  const { getInventoryParts, getInventoryPartsById, replaceInventoryPart } = useInventoryParts();
  const [replacementPart, setReplacementPart] = useState<InventoryPart | null>(null);
  const [inventoryPartId, setInventoryPartId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<InventoryPart[]>([]);

  const onOpenDropdownCallback = useCallback(() => {
    getInventoryParts().then((data) => {
      setOptions([...data]);
    });
  }, [getInventoryParts]);

  return (
    <React.Fragment>
      {canReplace ? (
        <Autocomplete
          sx={{ mt: 1 }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          open={open}
          onOpen={() => {
            setOpen(true);
            onOpenDropdownCallback();
          }}
          onClose={() => {
            setOptions([]);
            setOpen(false);
          }}
          options={options}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.id.toString() ?? ""}
          onInputChange={_.debounce((event, newInputValue) => {
            setInventoryPartId(newInputValue);
            if (inventoryPartId) {
              getInventoryPartsById(inventoryPartId).then((data) => {
                setOptions([data]); // originally: data && setOptions([data]);
              });
            }
          }, 1000)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Replace Inventory Part"
              placeholder="Select a part to replace"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <QrCodeScannerIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Grid>
                <Grid container columnSpacing={4} gap={2}>
                  <Grid item>
                    <Typography display="inline" variant="overline">
                      ID: {option.id}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="inline" variant="overline">
                      Label: {option.label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="inline" variant="overline">
                      SKU: {option.sku}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="inline" variant="overline">
                      Grade: {option.cosmeticGrade}
                    </Typography>
                  </Grid>
                </Grid>
                <Stack direction="row" spacing={2} mb={1}>
                  {getPartTypeChips(partType, option)}
                </Stack>
                <Divider />
              </Grid>
            </li>
          )}
          fullWidth
          size="small"
          onChange={(event, value) => {
            setReplacementPart(value);
          }}
        />
      ) : (
        <Alert severity="error">
          <Typography variant="body1">
            Part replacement requires this inventory to have the following:
            <ul>
              <li>A valid model attribute (example: MacBookPro10,1)</li>
              <li>The color attribute cannot be empty.</li>
              <li>All {startCase(camelCase(partType))} functionalities must not be unchecked.</li>
            </ul>
          </Typography>
        </Alert>
      )}
      {replacementPart && (
        <Grid container direction="column" p={2}>
          <Grid container direction="row">
            <Grid xs container item direction="column">
              <Typography variant="overline">Label</Typography>
              <Typography fontWeight="600"> {replacementPart.label}</Typography>
            </Grid>
            <Grid xs container item direction="column">
              <Typography variant="overline">Part ID</Typography>
              <Typography fontWeight="600">{replacementPart.id}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid xs container item direction="column">
              <Typography mt={1} variant="overline">
                Model EMC
              </Typography>
              <Typography fontWeight="600">{replacementPart.model}</Typography>
            </Grid>
            <Grid xs container item direction="column">
              <Typography mt={1} variant="overline">
                SKU
              </Typography>
              <Typography fontWeight="600">{replacementPart.sku}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid xs container item direction="column">
              <Typography mt={1} variant="overline">
                Cosmetic Grade
              </Typography>
              <Typography fontWeight="600">{replacementPart.cosmeticGrade}</Typography>
            </Grid>
            <Grid xs container item direction="column">
              <Typography mt={1} variant="overline">
                From Inventory ID
              </Typography>
              <Typography fontWeight="600">{replacementPart.parentInventoryId}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid xs container item direction="column">
              <Typography mt={1} variant="overline">
                Location
              </Typography>
              <Typography paragraph fontWeight="600">
                {replacementPart.warehouseLocation}
              </Typography>
            </Grid>
            <Grid xs container item direction="column">
              <Typography mt={1} variant="overline">
                APN
              </Typography>
              <Typography fontWeight="600">{replacementPart.apn}</Typography>
            </Grid>
          </Grid>

          <Grid container direction="column">
            <Typography mt={1} variant="overline">
              Comments
            </Typography>
            <Typography paragraph fontWeight="600">
              {replacementPart.comments}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Button
          disabled={replacementPart == null}
          variant="contained"
          onClick={() => {
            if (replacementPart) {
              replaceInventoryPart(replacementPart).then(() => {
                setReplacementPart(null);
              });
            }
          }}
        >
          Use this part as replacement
        </Button>
      </Box>
    </React.Fragment>
  );
}
