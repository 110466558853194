import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Check } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { mutate } from "swr";

export function BatchComplete() {
  function clearCache() {
    mutate((key) => typeof key === "string" && key.startsWith("/current_fulfillment_batch"), undefined, {
      revalidate: false,
    });
  }

  return (
    <>
      <PicklistCard title="All Inventory Packed" Icon={Check}>
        <Typography textAlign="center">You’re all done!</Typography>
      </PicklistCard>
      <Stack alignItems="center" gap={2} mt={8}>
        <Button variant="contained" component={Link} to="/picklist" onClick={clearCache}>
          Exit
        </Button>
      </Stack>
    </>
  );
}
