export default function useOCR() {
  const getVision = async (src: string) => {
    var serial: string = "";
    let vision: Response = await fetch(
      `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_VISION_API_KEY}`,
      {
        method: "POST",
        body: src.startsWith("data:image/") ? useLocalImage(src) : useRemoteImage(src),
      }
    );

    await vision.json().then((results) => {
      var output: string = "";
      if (results && results.responses[0].fullTextAnnotation) {
        output = results.responses[0].fullTextAnnotation.text;
      }
      /* We should replace these characters explicitly for Apple serial numbers.
      There is never an I or O in a Mac serial. */
      output = output.replaceAll("O", "0");
      output = output.replaceAll("I", "1");
      var match = output.match(/Serial (.{10,12})/);
      serial = match ? match[1] : output;
    });
    return serial;
  };

  const useLocalImage = (base64Data: string) => {
    return JSON.stringify({
      requests: [
        {
          image: {
            content: base64Data.replace(/data:image\/(png|jpg|jpeg|webp);base64,/, ""),
          },
          features: [
            {
              type: "TEXT_DETECTION",
            },
          ],
        },
      ],
    });
  };

  const useRemoteImage = (src: string) => {
    return JSON.stringify({
      requests: [
        {
          image: {
            source: {
              imageUri: src,
            },
          },
          features: [{ type: "TEXT_DETECTION" }],
        },
      ],
    });
  };

  return {
    getVision,
  };
}
