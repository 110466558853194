import { useFormikContext } from "formik";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const SubmitAwareButton: OverridableComponent<{
  defaultComponent: "button";
  props: ButtonProps;
}> = (props: ButtonProps) => {
  const { isSubmitting } = useFormikContext();
  const endIcon = isSubmitting ? (
    <CircularProgress size={24} sx={{ color: props.color ? undefined : "#fff" }} color={props.color} />
  ) : (
    props.endIcon
  );
  return (
    <Button disabled={isSubmitting} {...props} endIcon={endIcon}>
      {props.children}
    </Button>
  );
};
