import { Box } from "@mui/material";
import { Pickup } from "types/pickup";
import { ScheduledDrawer } from "./PickupRowStatuses/ScheduledDrawer";
import { PendingConfirmationDrawer } from "./PickupRowStatuses/PendingConfirmationDrawer";
import { ProcessingDrawer } from "./PickupRowStatuses/ProcessingDrawer";
import { CanceledDrawer } from "./PickupRowStatuses/CanceledDrawer";
import { useSearchParams } from "react-router-dom";
import { AllDrawer } from "./PickupRowStatuses/AllDrawer";
import { Divider } from "@mui/material";
import { CompleteDrawer } from "./PickupRowStatuses/CompleteDrawer";

interface PickupRowProps {
  pickup: Pickup;
  confirmPickup: (pickupUuid: string) => Promise<void>;
  cancelPickup: (pickupUuid: string, setCancellationReason: string) => Promise<void>;
}

export default function LogisticsPickupRow({ pickup, confirmPickup, cancelPickup }: PickupRowProps) {
  const [searchParams] = useSearchParams();

  return (
    <>
      <Box sx={{ my: 1 }}>
        {pickup.state === "scheduled" && <ScheduledDrawer pickup={pickup} cancelPickup={cancelPickup} />}
        {pickup.state === "pending_confirmation" && (
          <PendingConfirmationDrawer pickup={pickup} confirmPickup={confirmPickup} cancelPickup={cancelPickup} />
        )}
        {["processing", "initial_report_ready", "on_hold", "verifying"].includes(pickup.state) && (
          <ProcessingDrawer pickup={pickup} />
        )}
        {pickup.state === "complete" && <CompleteDrawer pickup={pickup} />}
        {![
          "processing",
          "initial_report_ready",
          "on_hold",
          "verifying",
          "scheduled",
          "pending_confirmation",
          "complete",
        ].includes(pickup.state) && <AllDrawer pickup={pickup} />}
        <Divider />
      </Box>
    </>
  );
}
