import { Contact } from "./contact";

export type Location = {
  id: string;
  uuid: string;
  name: string;
  description: string;
  approximatePeople: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  timezone: string;
  internalNotes: string;
  accessInstructions: string;
  deletedAt?: string;
  locationContacts?: Contact[];
};

export const defaultLocation = {
  id: "",
  uuid: "",
  name: "",
  description: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  country: "",
  timezone: "",
  approximatePeople: 0,
  internalNotes: "",
  accessInstructions: "",
  locationContacts: [],
};
