import { useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { Alert, Button, Popover, Stack, Tooltip } from "@mui/material";
import { ScreenSearchDesktop, SwapHoriz } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { SubmitAwareIconButton } from "components/Form/SubmitAwareIconButton";

const validReplacementStates = ["skipped", "created"];

export function GenerateReplacementRowAction({ row }: { row: ShipstationOrderItem }) {
  const [replacementInventory, setReplacementInventory] = useState<null | ShipstationOrderItem>(null);
  const [error, setError] = useState<null | string>(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formId = `assign_inventory_${row.id}`;
  const apiRef = useGridApiContext();

  const hasInventory = row.inventoryItem !== null;
  const initialValues: { skipInventoryIds: number[] } = { skipInventoryIds: [] };
  const tooltipLabel = hasInventory ? "Generate Replacement" : "Find and Reserve Inventory";

  if (!validReplacementStates.includes(row.state)) return null;
  return (
    <>
      <RevivnApiForm
        FormProps={{ id: formId }}
        initialValues={initialValues}
        action={`shipstation_order_items/${row.id}/override_inventory`}
        method="POST"
        onSubmit={(data) => {
          if (replacementInventory) data.skipInventoryIds.push(replacementInventory.inventoryId);
          setError(null);
          setReplacementInventory(null);
        }}
        onSuccess={(response) => {
          setOpen(hasInventory);
          const updatedItem = response.data as ShipstationOrderItem;
          setReplacementInventory(updatedItem);
          apiRef.current?.updateRows([{ ...updatedItem }]);
        }}
        onError={(err) => {
          setOpen(true);
          setError(err.response?.data?.error?.message);
        }}
      >
        <SubmitAwareIconButton color={"primary"} type="submit" onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Tooltip title={tooltipLabel} disableInteractive>
            {hasInventory ? <SwapHoriz /> : <ScreenSearchDesktop />}
          </Tooltip>
        </SubmitAwareIconButton>
      </RevivnApiForm>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        sx={{ padding: 4 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Stack sx={{ p: 2, gap: 2 }} alignItems={"center"}>
          {error && <Alert severity="error">Error: {error}</Alert>}
          {replacementInventory && (
            <Alert severity="success">
              Success! Inventory reserved:{" "}
              <Link to={`/inventories/${replacementInventory.inventoryItem}`}>
                {replacementInventory.inventoryItem}
              </Link>{" "}
              ({replacementInventory.inventoryMatchReason})
            </Alert>
          )}
          <Stack direction={"row"} justifyContent="space-between" gap={2}>
            <Button endIcon={<SwapHoriz />} type="submit" form={formId}>
              Re-Generate
            </Button>
            <Button endIcon={<CheckIcon />} variant="contained" onClick={() => setOpen(false)}>
              OK
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
