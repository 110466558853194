import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { useAlert } from "./useAlert";
import { LogisticsPartner } from "types/logisticsPartner";

export default function useLogisticsPartners(defaultPageSize?: number) {
  const location = useLocation();
  const params = useParams();
  const [logisticsPartners, setLogisticsPartners] = useState<LogisticsPartner[]>([]);
  const [logisticsPartner, setLogisticsPartner] = useState<LogisticsPartner>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();
  const { alertError } = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [partnerLoading, setPartnerLoading] = useState(false);

  useEffect(() => {
    if (params.uuid === undefined) {
      getLogisticsPartners();
    }
  }, [search]);

  useEffect(() => {
    if (params.uuid) {
      getLogisticsPartner();
    }
  }, [params.uuid, location.pathname]);

  const getLogisticsPartner = () => {
    setPartnerLoading(true);
    api
      .get(`/logistics_partners/${params.uuid}`)
      .then(({ data: { logisticsPartner } }) => {
        setLogisticsPartner(logisticsPartner);
      })
      .finally(() => {
        setPartnerLoading(false);
      });
  };

  const getLogisticsPartners = () => {
    const searchHash = new URLSearchParams(location.search);
    if (!searchHash.get("page[size]") && defaultPageSize) {
      searchHash.append("page[size]", String(defaultPageSize));
    }
    api.get(`/logistics_partners?${searchHash.toString()}`).then(
      ({
        data: {
          logisticsPartners,
          meta: { totalPages, count },
        },
      }) => {
        setLogisticsPartners(logisticsPartners);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  };

  type LogisticsPartnerProps = {
    name?: string;
    type?: string;
  };

  const createLogisticsPartner = (logisticsPartner: LogisticsPartnerProps) => {
    setLoading(true);
    api
      .post(`/logistics_partners`, {
        logisticsPartner,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateLogisticsPartner = (logisticsPartner: LogisticsPartnerProps) => {
    setLoading(true);
    api
      .put(`/logistics_partners/${params.uuid}`, {
        logisticsPartner,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    logisticsPartners,
    logisticsPartner,
    createLogisticsPartner,
    updateLogisticsPartner,
    totalPages,
    count,
    isLoading,
  };
}
