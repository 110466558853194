import { Box, Button, IconButton, Modal, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type ConfirmationModalProps = {
  open: boolean;
  handleClose: () => void;
  children: string;
  onConfirm: () => void;
};

export default function ConfirmationModal({ open, handleClose, children, onConfirm }: ConfirmationModalProps) {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ mx: 4, mt: 50 }}>
      <Paper variant="outlined" sx={{ maxWidth: 350, mx: "auto" }}>
        <IconButton onClick={handleClose} sx={{ mr: 0.5, mt: 0.5, float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 6, mb: 3, mx: 6, textAlign: "center" }}>
          {children}
          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" sx={{ mx: 1 }} onClick={handleClose}>
              No
            </Button>
            <Button variant="contained" sx={{ mx: 1 }} onClick={handleConfirm}>
              Yes
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}
