export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberToCurrency(number: number, options: Intl.NumberFormatOptions = {}) {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  });
}
