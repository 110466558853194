import { format } from "date-fns";

export function formatDate(dateString: string) {
  if (!dateString) return;
  var date = new Date(dateString.substring(0, 10)).toISOString().slice(0, -1);

  return format(new Date(date), "MMMM dd, yyyy"); // ex: April 27, 2022;
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCentsToDollar(cents: number) {
  const dollarNumber = cents / 100;
  return dollarNumber.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function getCurrentEasternTimezoneName(): string {
  const format = new Intl.DateTimeFormat("en-US", { timeZone: "America/New_York", timeZoneName: "short" });
  return format.formatToParts(new Date()).find((x) => x.type === "timeZoneName")!.value;
}
