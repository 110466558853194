import { Box, LinearProgress } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import useContacts from "hooks/useContacts";
import { useParams } from "react-router-dom";
import ContactForm from "./ContactForm";

export function ContactEdit() {
  const { client, clientLoading } = useClients();
  const { contact, contactLoading } = useContacts();
  const params = useParams();
  const location =
    client && client.locations && client?.locations.find((location) => location.uuid == params.locationUuid);

  if (clientLoading || contactLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client ? client?.name : "", to: `/logistics/clients/${client?.uuid}` },
          { label: location?.name, to: `/logistics/clients/${client?.uuid}/locations/${location?.uuid}` },
          { label: "Edit" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <ContactForm contact={contact} />
      </Box>
    </Box>
  );
}
