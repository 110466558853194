import { GridColTypeDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { getCurrentEasternTimezoneName } from "utils/helpers";

export const dateColDef: GridColTypeDef = {
  type: "date",
  renderHeader: (params) => {
    return `${params.colDef.headerName} (${getCurrentEasternTimezoneName()})`;
  },
  valueGetter: (params: GridValueGetterParams) => {
    if (!params.value) return "";
    return Intl.DateTimeFormat("en-US", { timeZone: "America/New_York" }).format(Date.parse(params.value));
  },
};
