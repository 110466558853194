import { SyncDisabled } from "@mui/icons-material";
import { Typography, Button, Box, TextField, Autocomplete } from "@mui/material";
import { Modal } from "components/common/Modal";
import { useState } from "react";

interface ClientDeactivateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (event: string, setDeactivatonReason: string) => Promise<void>;
}

export default function ClientDeactivateModal({ open, setOpen, updateStatus }: ClientDeactivateModalProps) {
  const [deactivationReason, setDeactivationReason] = useState<string>("");
  const [isValid, setValid] = useState<boolean | null>();
  const validate: boolean = deactivationReason.length > 0;
  function onClose() {
    setDeactivationReason("");
    setValid(null);
  }

  return (
    <Modal open={open} setOpen={setOpen} width={"683px"} onClose={() => onClose()}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <SyncDisabled color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Deactivate Client
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"} gutterBottom>
            Are you sure you want to proceed with deactivating this client? They will no longer be able to access their
            application.
          </Typography>
          <Autocomplete
            freeSolo
            options={[]}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                error={isValid == false}
                helperText={isValid ? null : "Enter a reason for deactivation."}
                placeholder={"Please specify a reason..."}
                onBlur={() => setValid(validate)}
                onChange={(e) => {
                  setDeactivationReason(e.target.value);
                }}
              />
            )}
          />
          <Box mt={4} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button
              disabled={validate == false}
              variant="contained"
              onClick={() => updateStatus("deactivate", deactivationReason)}
            >
              Yes, deactivate
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                onClose();
              }}
            >
              No, go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
