export function base64ImageToFile(base64String: string, filename?: string): File {
  const arr: string[] = base64String.split(",");
  const mime: string = arr[0].match(/:(.*?);/)![1];
  const fileType: string = arr[0].match(/\/(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  const pgName: string = filename ?? crypto.randomUUID().toString();
  if (n > 0) {
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  }
  return new File([u8arr], `${pgName}.${fileType}`, { type: mime });
}
