import useBreadcrumbs from "hooks/useBreadcrumbs";
import usePickup from "hooks/usePickup";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PickupInventoriesPage from "./PickupInventoriesPage";
import InventoriesNewPage from "./InventoriesNewPage";
import InventoryEditPage from "./InventoryEditPage";
import { readableDate } from "utils/readableDate";
import { defaultInventoryFilters } from "constants/default_inventory_filters";
import VirtualPickupInventoriesNew from "components/Inventories/VirtualPickupInventoriesNew";

export default function PickupShowPage() {
  const { pickup } = usePickup();
  const path = `/pickups/${pickup.uuid}${defaultInventoryFilters}`;

  const { crumb, setCrumb } = useBreadcrumbs({
    name: "",
    path: path,
  });

  useEffect(() => {
    if (pickup.scheduledAt) {
      const crumbName = `${pickup.organization.name}-${readableDate(pickup.scheduledAt)}`;

      setCrumb({
        name: pickup.scheduledAt ? crumbName : "",
        path: path,
      });
    }
  }, [pickup]);

  return crumb ? (
    <Routes>
      <Route index element={<PickupInventoriesPage />} />
      <Route path="inventories/new-virtual-pickup" element={<VirtualPickupInventoriesNew />} />
      <Route path="inventories/new/*" element={<InventoriesNewPage prevPath={path} />} />
      <Route path="inventories/:inventoryId/*" element={<InventoryEditPage prevPath={path} />} />
    </Routes>
  ) : null;
}
