import { ArrowBack } from "@mui/icons-material";
import { Box, Button, LinearProgress } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import { Link as RouterLink } from "react-router-dom";
import LocationForm from "./LocationForm";

export function LocationNew() {
  const { client, clientLoading } = useClients();

  if (clientLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client ? client?.name : "", to: `/logistics/clients/${client?.uuid}` },
          { label: "Add New Location" },
        ]}
      ></Breadcrumbs>

      <LocationForm />
    </Box>
  );
}
