import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";
import { useAlert } from "./useAlert";

export default function useLogisticsDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { alertSuccess } = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [logisticsDetails, setLogisticsDetails] = useState<any>();
  const [logisticsPickup, setLogisticsPickup] = useState<Pickup>();

  type LogisticsDetailsProps = {
    agentId?: string;
    subAgentId?: string;
    estimatedCostInCents: number;
    postEstimatedCostInCents: number;
    costInCents: number;
    estimatedInvoiceCostInCents: number;
    secondEstimatedInvoiceCostInCents: number;
    invoiceCostInCents: number;
    itadPaymentInCents: number;
  };

  useEffect(() => {
    getLogisticsDetails();
  }, [search]);

  const updateLogisticsDetails = (logisticsDetails: LogisticsDetailsProps) => {
    setLoading(true);
    let logisticsDetailsPayload = Object.assign({}, logisticsDetails);
    if (logisticsDetailsPayload.postEstimatedCostInCents !== null)
      logisticsDetailsPayload.postEstimatedCostInCents = Math.ceil(
        logisticsDetailsPayload.postEstimatedCostInCents * 100
      );
    if (logisticsDetailsPayload.estimatedCostInCents !== null)
      logisticsDetailsPayload.estimatedCostInCents = Math.ceil(logisticsDetailsPayload.estimatedCostInCents * 100);
    if (logisticsDetailsPayload.costInCents !== null)
      logisticsDetailsPayload.costInCents = Math.ceil(logisticsDetailsPayload.costInCents * 100);
    if (logisticsDetailsPayload.estimatedInvoiceCostInCents !== null)
      logisticsDetailsPayload.estimatedInvoiceCostInCents = Math.ceil(
        logisticsDetailsPayload.estimatedInvoiceCostInCents * 100
      );
    if (logisticsDetailsPayload.secondEstimatedInvoiceCostInCents !== null)
      logisticsDetailsPayload.secondEstimatedInvoiceCostInCents = Math.ceil(
        logisticsDetailsPayload.secondEstimatedInvoiceCostInCents * 100
      );

    if (logisticsDetailsPayload.invoiceCostInCents !== null)
      logisticsDetailsPayload.invoiceCostInCents = Math.ceil(logisticsDetailsPayload.invoiceCostInCents * 100);
    logisticsDetailsPayload.itadPaymentInCents = Math.ceil(logisticsDetailsPayload.itadPaymentInCents * 100);

    api
      .post(`/pickups/${params.pickupUuid}/logistics_details`, {
        logisticsDetails: logisticsDetailsPayload,
      })
      .then(() => {
        setLoading(false);
        alertSuccess("Successfully updated pickup.");
        navigate("/logistics?logistics_status=scheduled");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getLogisticsDetails = async () => {
    setLoading(true);
    try {
      await api.get(`/pickups/${params.pickupUuid}/logistics_details`).then(({ data: { logisticsDetails } }) => {
        setLogisticsDetails(logisticsDetails);
      });
      await api.get(`/logistics_pickups/${params.pickupUuid}`).then(({ data }) => {
        setLogisticsPickup(data);
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    updateLogisticsDetails,
    logisticsDetails,
    logisticsPickup,
    isLoading,
  };
}
