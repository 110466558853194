import { ArrowBack } from "@mui/icons-material";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import { useParams, Link as RouterLink } from "react-router-dom";
import ContactForm from "./ContactForm";

export function ContactNew() {
  const { client, clientLoading } = useClients();
  const params = useParams();
  const location =
    client && client.locations && client?.locations.find((location) => location.uuid == params.locationUuid);

  if (clientLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client ? client?.name : "", to: `/logistics/clients/${client?.uuid}` },
          { label: location?.name, to: `/logistics/clients/${client?.uuid}/locations/${location?.uuid}` },
          { label: "Add New Contact" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <ContactForm />
      </Box>
    </Box>
  );
}
