import { Box, styled } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMemo } from "react";
import { useConfirmation } from "hooks/useConfirmation";
import { InventoryTemplate } from "types/inventoryTemplate";
import { useNavigate } from "react-router-dom";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "40vh",
  margin: "15px",
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

type InventoryTemplateDataGridProps = {
  inventoryTemplates: InventoryTemplate[];
  handleDelete: (uuid: string) => Promise<void>;
  meta: {
    totalPages: number;
    count: number;
  };
  pageState: {
    page: number;
    pageSize: number | undefined;
    sortModel: GridSortModel;
    loading: boolean;
    filterModel: GridFilterModel;
  };
};

export default function InventoryTemplatesDataGrid({
  inventoryTemplates,
  handleDelete,
  meta,
  pageState,
}: InventoryTemplateDataGridProps) {
  const { currentUserPermissions } = useCurrentUser();
  const hasPermission = useMemo(() => {
    return (
      userHasPermission("admin", currentUserPermissions) ||
      userHasPermission("warehouse_manager", currentUserPermissions)
    );
  }, [currentUserPermissions]);

  const { confirm } = useConfirmation();
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "inventoryTemplate.name",
        headerName: "Name",
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.name,
      },
      {
        field: "inventoryTemplate.manufacturer",
        headerName: "Manufacturer",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.manufacturer,
      },
      {
        field: "inventoryTemplate.deviceType",
        headerName: "Device Type",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.deviceType,
      },
      {
        field: "inventoryTemplate.model",
        headerName: "Model",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.model,
      },
      {
        field: "inventoryTemplate.screenSize",
        headerName: "Screen Size",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.screenSize,
      },
      {
        field: "inventoryTemplate.ram",
        headerName: "RAM",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.ram,
      },
      {
        field: "inventoryTemplate.cpu",
        headerName: "CPU",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.cpu,
      },
      {
        field: "inventoryTemplate.hardDrive",
        headerName: "Hard Drive",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.hardDrive,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <EditIcon
                fontSize="small"
                sx={{ opacity: "55%" }}
                onClick={() => {
                  navigate(`/inventory-templates/${params.row.uuid}`);
                }}
              />
              {hasPermission ? (
                <DeleteIcon
                  fontSize="small"
                  sx={{ opacity: "55%" }}
                  onClick={() => {
                    confirm({
                      message: "Are you sure you want to delete this inventory template?",
                      handler: () => {
                        handleDelete(params.row.uuid);
                      },
                    });
                  }}
                />
              ) : null}
            </Box>
          );
        },
      },
    ],
    [hasPermission],
  );

  return (
    <Box>
      <StyledDataGrid
        sx={{ border: "none", mx: 3, mb: 3 }}
        rows={inventoryTemplates}
        columns={columns}
        {...pageState}
        pageSize={pageState.pageSize || 25}
        rowsPerPageOptions={[5, 10, 25]}
        rowCount={meta.count}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        disableColumnFilter
      />
    </Box>
  );
}
