import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { Crumb } from "types/crumb";

type ApplicationBreadCrumbsProps = {
  crumbs: Crumb[];
  sx?: SxProps<Theme>;
};

export default function ApplicationBreadCrumbs({ crumbs, sx }: ApplicationBreadCrumbsProps) {
  const last = crumbs[crumbs.length - 1];

  return (
    <Breadcrumbs sx={sx} separator={<NavigateNext fontSize="small" />}>
      {crumbs.slice(0, crumbs.length - 1).map((crumb, index) => {
        return (
          <Link key={index} color="inherit" underline="hover" component={RouterLink} to={crumb.path}>
            {crumb.name}
          </Link>
        );
      })}
      {last ? (
        <Typography key={crumbs.length} color="text.primary">
          {last.name}
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
}
