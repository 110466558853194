import {
  Box,
  Typography,
  Checkbox,
  TextField,
  useTheme,
  styled,
  Button,
  InputAdornment,
  IconButton,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { useFormikContext } from "formik";
import { BenefitingOrganization } from "types/benefitingOrganization";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { DonationRequest } from "types/donationRequest";
import { useDropzone } from "react-dropzone";
import { SyntheticEvent, useEffect, useState } from "react";
import useDirectUpload from "hooks/useDirectUpload";
import CloseIcon from "@mui/icons-material/Close";

const TextFieldStyled = styled(TextField)({
  ".MuiOutlinedInput-root": { padding: "0px !important" },
  ".MuiInputBase-input": {
    minHeight: "38px",
    padding: "0px 12px !important",
  },
  fieldset: {
    borderColor: "rgb(206, 212, 218)",
  },
});
interface newDonationRequestProps {
  getBenefitingOrganization: (uuid: string) => void;
  benefitingOrganization?: BenefitingOrganization;
  benefitingOrganizations: BenefitingOrganization[];
  donationRequest?: DonationRequest;
  setKey: (key: string | null) => void;
  signedKey: string | null;
  deleteHeroImage: (uuid: string) => Promise<any>;
}

export default function DonationRequestForm({
  getBenefitingOrganization,
  benefitingOrganization,
  benefitingOrganizations,
  setKey,
  signedKey,
  deleteHeroImage,
}: newDonationRequestProps) {
  const { getFieldProps, setFieldValue, values, setFieldError, errors, touched } = useFormikContext<DonationRequest>();
  const theme = useTheme();
  let [loading, setLoading] = useState(false);
  const { blob, upload, reset } = useDirectUpload({ keyPrefix: "/donation-request/hero/", service: "amazon_public" });

  const { open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png", ".jpeg"],
    },
    onDrop: async (files) => {
      setLoading(true);
      try {
        onUpload?.(files);
      } finally {
        setLoading(false);
      }
    },
  });

  const onUpload = (acceptedFiles: File[]) => {
    upload(acceptedFiles[0]);
  };
  const handleDeleteHeroImage = (uuid: string) => {
    deleteHeroImage(uuid)
      .then(() => {
        setFieldValue("heroUrl", null);
      })
      .catch(({ response: { data } }) => {
        data.error && setFieldError("heroUrl", "There was an error please try again");
      });
  };

  useEffect(() => {
    if (blob) setKey(blob.signed_id);
  }, [blob]);

  return (
    <Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 5 }}>
        <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Benefiting Organization</Typography>
            <Autocomplete
              size="small"
              autoComplete
              includeInputInList
              disableClearable
              {...getFieldProps("benefitingOrganizationID")}
              onChange={(e: SyntheticEvent<Element, Event>, newValue: any) => {
                setFieldValue("benefitingOrganizationId", newValue.id);
                getBenefitingOrganization(newValue.uuid);
              }}
              options={benefitingOrganizations.sort((a, b) => (a.name > b.name ? 1 : -1))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="benefitingOrganizationId"
                  label={values.benefitingOrganization.name || "Search Benefiting Organizations"}
                  fullWidth
                  helperText={
                    benefitingOrganization?.state === "under_review"
                      ? "Note: This organization was't approved yet"
                      : null
                  }
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.name}
                    {"  "}
                    {option.state === "under_review" ? "( Under Review )" : null}
                  </li>
                );
              }}
              getOptionLabel={(option: BenefitingOrganization) => option.name}
            ></Autocomplete>
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>POC Email</Typography>
            <TextFieldStyled {...getFieldProps("mainContactEmail")}></TextFieldStyled>
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>POC Full Name</Typography>
            <TextFieldStyled {...getFieldProps("mainContactName")}></TextFieldStyled>
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Machine Goal Mac's</Typography>
            <TextFieldStyled
              {...getFieldProps("goalInMac")}
              type="number"
              error={Boolean(touched.goalInMac && errors.goalInMac)}
              helperText={touched.goalInMac && errors.goalInMac}
            ></TextFieldStyled>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Machine Goal PC's</Typography>
            <TextFieldStyled
              {...getFieldProps("goalInPc")}
              type="number"
              error={Boolean(touched.goalInPc && errors.goalInPc)}
              helperText={touched.goalInPc && errors.goalInPc}
            ></TextFieldStyled>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>VAT # (International)</Typography>
            <TextFieldStyled {...getFieldProps("vat")}></TextFieldStyled>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>EIN #</Typography>
            <TextFieldStyled {...getFieldProps("ein")}></TextFieldStyled>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Organization Website</Typography>
              <TextFieldStyled
                value={values.benefitingOrganization.websiteUrl || benefitingOrganization?.websiteUrl}
                disabled
              ></TextFieldStyled>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Organization Logo</Typography>
              {values?.benefitingOrganization.logoUrl ? (
                <TextFieldStyled
                  disabled
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 2, border: "none" }}>
                        <AttachmentIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  value={values.benefitingOrganization.logoUrl.slice(
                    values.benefitingOrganization?.logoUrl.length - 20,
                  )}
                ></TextFieldStyled>
              ) : null}
              {benefitingOrganization ? (
                <TextFieldStyled
                  disabled
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 2, border: "none" }}>
                        <AttachmentIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  value={
                    benefitingOrganization.logoUrl
                      ? benefitingOrganization.logoUrl.slice(values.benefitingOrganization?.logoUrl.length - 20)
                      : "no logo attached"
                  }
                ></TextFieldStyled>
              ) : null}
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Phone Number</Typography>
              <TextFieldStyled {...getFieldProps("mainContactPhoneNumber")}></TextFieldStyled>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Machine Goal Chromebook's</Typography>
              <TextFieldStyled
                {...getFieldProps("goalInChromebook")}
                type="number"
                error={Boolean(touched.goalInChromebook && errors.goalInChromebook)}
                helperText={touched.goalInChromebook && errors.goalInChromebook}
              ></TextFieldStyled>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Total Requested Amount</Typography>
              <TextFieldStyled {...getFieldProps("totalRequestedAmountInDollars")}></TextFieldStyled>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>EORI # (International)</Typography>
              <TextFieldStyled {...getFieldProps("eori")}></TextFieldStyled>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 2fr 1fr",
                minHeight: "80px",
                alignItems: "center",
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography>Registered 501(c)(3)?</Typography>
              <Checkbox {...getFieldProps("registered501c3")} checked={values.registered501c3}></Checkbox>
              <Typography>ACH Submitted</Typography>
              <Checkbox {...getFieldProps("achOnFile")} checked={values.achOnFile}></Checkbox>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ mt: 3, opacity: "60%" }}> Shipping Address</Typography>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 5 }}>
          <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
            <Box sx={{ display: "grid", gridTemplateColumns: "0.5fr 2.5fr", minHeight: "80px", alignItems: "center" }}>
              <Typography> Address Line 1</Typography>
              <TextFieldStyled {...getFieldProps("address")}></TextFieldStyled>
            </Box>{" "}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "0.5fr 2.5fr",
                minHeight: "80px",
                alignItems: "center",
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography> Address Line 2</Typography>
              <TextFieldStyled {...getFieldProps("address2")}></TextFieldStyled>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 5,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography> City</Typography>
              <TextFieldStyled {...getFieldProps("city")}></TextFieldStyled>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography> Zip/Postal Code</Typography>
              <TextFieldStyled {...getFieldProps("zipcode")}></TextFieldStyled>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
                <Typography> State/Province</Typography>
                <TextFieldStyled {...getFieldProps("state")}></TextFieldStyled>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
                <Typography> Country</Typography>
                <TextFieldStyled
                  {...getFieldProps("country")}
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                ></TextFieldStyled>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 5,
            mt: 3,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                minHeight: "80px",
                alignItems: "center",
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography> Initiative Name</Typography>
              <TextFieldStyled {...getFieldProps("goalName")}></TextFieldStyled>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  minHeight: "80px",
                  alignItems: "center",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Typography>Initiative Picture</Typography>
                {values.heroUrl ? (
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                    <TextFieldStyled
                      {...getFieldProps("heroUrl")}
                      disabled
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start" sx={{ mx: 2, border: "none" }}>
                            <AttachmentIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      value={values.heroUrl.slice(values.heroUrl.length - 20)}
                      error={!!errors.heroUrl}
                      helperText={errors.heroUrl}
                    ></TextFieldStyled>

                    <IconButton
                      onClick={() => {
                        handleDeleteHeroImage(values.uuid);
                        setKey(null);
                        reset();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Tooltip title="Ideal size: width: 500px | height: 362px" arrow>
                    <Button
                      variant={signedKey ? "outlined" : "contained"}
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={open}
                      disabled={loading}
                      startIcon={signedKey ? <AttachmentIcon /> : null}
                    >
                      {signedKey ? "Image Attached" : "Upload Image"}
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 5 }}>
          <Box>
            <Box
              sx={{ display: "grid", gridTemplateColumns: "0.45fr 2.55fr", minHeight: "80px", alignItems: "center" }}
            >
              <Typography> Initiative Description</Typography>
              <TextFieldStyled sx={{ py: 2 }} multiline {...getFieldProps("goalDescription")}></TextFieldStyled>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
