import { Box, FormControl, Button, Stack, FormGroup, FormControlLabel, Switch, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { useFormik } from "formik";
import useEarnings from "hooks/useEarnings";
import usePickups from "hooks/usePickups";
import { theme } from "index";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { readableDate } from "utils/readableDate";
import { sleep } from "utils/sleep";

type EarningsFormProps = {
  type: string;
  clientName?: string;
};

export default function EarningsForm({ type, clientName }: EarningsFormProps) {
  const { createEarningsTransaction } = useEarnings();
  const [waived, setWaived] = useState(false);
  const { getPickupsByOrganization, organizationPickups } = usePickups();
  const navigate = useNavigate();
  const params = useParams();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: type,
      amountInCents: 0,
      waived: false,
      pickupId: null,
    },

    onSubmit: async (values) => {
      values.waived = waived;
      await createEarningsTransaction(values);
      await sleep(300);
      navigate(`/finance/clients/${params.uuid}`);
    },
  });

  useEffect(() => {
    if (!clientName) return;
    getPickupsByOrganization(clientName);
  }, [clientName]);

  const handleToggle = () => {
    waived ? setWaived(false) : setWaived(true);
  };

  return (
    <div>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{}} noValidate autoComplete="off">
        <Box
          sx={{
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack direction="row" spacing={2}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="amountInCents">
                Amount
              </InputLabel>
              <InputBase type="number" id="amountInCents" {...formik.getFieldProps("amountInCents")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="airbaseId">
                Airbase ID
              </InputLabel>
              <InputBase id="airbaseId" {...formik.getFieldProps("airbaseId")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="publishedAt">
                Published At
              </InputLabel>
              <InputBase type={"date"} id="publishedAt" {...formik.getFieldProps("publishedAt")} />
            </FormControl>
            {type == "EarningsDebit" && (
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="waived">
                  Waive Fee
                </InputLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={waived} name="waived" onChange={handleToggle} />}
                    label="Waived"
                  />
                </FormGroup>
              </FormControl>
            )}
          </Stack>
          <Stack direction="row" spacing={2} paddingTop={2}>
            <FormControl variant="standard" sx={{ width: "400px" }}>
              <InputLabel shrink htmlFor="pickups">
                Select Pickup (If Applicable)
              </InputLabel>
              <AppSelect id="pickupId" displayEmpty {...formik.getFieldProps("pickupId")}>
                {organizationPickups.map((pickup) => {
                  return (
                    <MenuItem key={pickup.id} value={pickup.id}>{`#${pickup.orderNumber} - ${readableDate(
                      pickup.scheduledAt,
                    )}`}</MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>
          </Stack>

          <Box sx={{ padding: "20px 0" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="description">
                Description
              </InputLabel>
              <InputBase
                sx={{ padding: "0px !important" }}
                id="description"
                multiline
                rows={3}
                {...formik.getFieldProps("description")}
              />
            </FormControl>
          </Box>
          <Box sx={{ padding: "20px 0" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="internalNotes">
                Internal Notes
              </InputLabel>
              <InputBase id="internalNotes" multiline rows={3} {...formik.getFieldProps("internalNotes")} />
            </FormControl>
          </Box>
        </Box>
        <Box mt={4} sx={{ display: "flex", justifyContent: "end" }}>
          <Button color="primary" variant="contained" type="submit">
            SAVE
          </Button>
        </Box>
      </Box>
    </div>
  );
}
