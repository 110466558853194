import { GridColTypeDef } from "@mui/x-data-grid-pro";
import useSWR from "swr";
import { fetcher } from "services/api.service";
import { useMemo } from "react";

interface StatesResponse {
  states: { [key: string]: string }[];
}

interface Options {
  only?: string[];
  machine?: string;
}

export function useStateColOptions(model: string, { only, machine }: Options = {}): GridColTypeDef {
  const { data } = useSWR<StatesResponse>(`model_states?model_name=${model}&machine=${machine}`, fetcher);
  let states = data?.states;
  if (only && states) states = states.filter((state) => only.includes(state[1]));

  return useMemo(() => {
    const valueOptions = states?.map(({ 0: label, 1: value }) => ({ value, label })) ?? [];
    return {
      type: "singleSelect",
      valueGetter: ({ value }) => valueOptions.find((option) => option.value === value)?.label ?? value,
      valueOptions,
    };
  }, [states]);
}
