import { VideocamOff } from "@mui/icons-material";
import { Box, Card, CircularProgress, Stack, Typography } from "@mui/material";
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef, useState } from "react";
import Webcam from "react-webcam";

const WebcamPanel = ({ children }: { children?: ReactNode }): JSX.Element => (
  <Box width="100%" height="100%" padding={2}>
    <Stack display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
      {children}
    </Stack>
  </Box>
);

const LoadingPanel = () => (
  <WebcamPanel>
    <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
      <CircularProgress size={30} />
      <Typography variant="body1">Initializing camera...</Typography>
    </Stack>
    <Typography color="text.secondary" variant="caption">
      Please wait while we access the camera.
    </Typography>
  </WebcamPanel>
);

const ErrorPanel = ({ error }: { error: string | DOMException }) => (
  <WebcamPanel>
    <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <VideocamOff fontSize="large" color="disabled" />
      <Typography variant="body1">MediaStream error</Typography>
    </Stack>
    <Typography color="text.secondary" variant="caption">
      {error.toString()}
    </Typography>
  </WebcamPanel>
);

type WebcamComponentProps = {
  device: MediaDeviceInfo;
};

export const WebcamComponent = forwardRef(({ device }: WebcamComponentProps, ref): JSX.Element => {
  const webcamRef = useRef<Webcam>(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | DOMException>("");

  const handleCapture = useCallback(() => {
    try {
      if (!webcamRef.current) throw new Error("Webcam reference is null.");
      if (!ready) throw new Error("Webcam is not ready.");

      const imgSrc = webcamRef.current.getScreenshot();

      if (imgSrc === null) {
        throw new Error("Screenshot is empty.");
      }
      return imgSrc;
    } catch (err) {
      console.error("Failed to capture image:", err);
    }
  }, [ready, webcamRef]);

  useImperativeHandle(ref, () => ({
    capture: handleCapture,
  }));

  return (
    <Box
      component={Card}
      variant="outlined"
      sx={{
        position: "relative",
        maxWidth: 360,
        maxHeight: 240,
        borderRadius: 2,
        borderWidth: 2,
      }}
      display="inline-block"
    >
      <Webcam
        ref={webcamRef}
        forceScreenshotSourceSize
        onUserMedia={(stream) => {
          setLoading(false);
          setError("");
          setReady(true);
          stream.onremovetrack = (event) => {
            setError(`${event.type} - MediaStreamTrack ended`);
            setReady(false);
          };
        }}
        onUserMediaError={(err) => {
          setLoading(false);
          setReady(false);
          setError(err);
          console.log("UserMedia error:", device, err);
        }}
        screenshotFormat="image/jpeg"
        screenshotQuality={0.9}
        videoConstraints={{
          deviceId: { exact: device.deviceId },
          width: { min: 1920, ideal: 3840 },
          height: { min: 1080, ideal: 2160 },
          frameRate: { ideal: 15 },
        }}
        style={{
          display: "block",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      />
      {loading && <LoadingPanel />}
      {error && <ErrorPanel error={error} />}
    </Box>
  );
});
