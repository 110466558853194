import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import InputIcon from "@mui/icons-material/Input";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import MemoryIcon from "@mui/icons-material/Memory";
import MonitorIcon from "@mui/icons-material/Monitor";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Printable from "components/Printable";
import {
  customAttributeBroken,
  customAttributeIncluded,
  customAttributeNotIncluded,
  customAttributeWorking,
} from "constants/inventory_parts_custom_attribute_states";
import { Field, Form, Formik } from "formik";
import useInventoryParts from "hooks/useInventoryParts";
import React, { useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { InventoryPart } from "types/inventoryPart";
import PartsPrintableQRCodes from "./PartsPrintableQRCodes";
import { BulkInventoryPartsFormSchema } from "./ValidationSchema";

export default function InventoryPartsBulkInbound() {
  const { bulkCreateInventoryParts, awaitingResponse, inventoryPartIds } = useInventoryParts();
  const [partType, setPartType] = useState<string>("TopCase");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const printComponentRef = useRef<HTMLDivElement>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPartType(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePrint = useReactToPrint({
    content: () => (printComponentRef ? printComponentRef.current : null),
  });

  return (
    <React.Fragment>
      <Box component={Paper} padding={2}>
        <Typography variant="h6">Inbound Inventory Parts</Typography>
        <Tabs value={partType} onChange={handleTabChange} variant="fullWidth" sx={{ mb: 2 }}>
          <Tab icon={<KeyboardIcon />} label="Case" value="TopCase" />
          <Tab icon={<MemoryIcon />} label="Logic Board" value="Board" />
          <Tab icon={<MonitorIcon />} label="LCD" value="Lcd" />
          <Tab icon={<BatteryChargingFullIcon />} label="Battery" value="Battery" />
        </Tabs>
        <Formik
          initialValues={{
            type: partType,
            quantity: 1,
            cosmeticGrade: "",
            color: "",
            warehouseLocation: "",
            apn: "",
            model: "",
            comments: "",
            logic: customAttributeWorking,
            power: customAttributeWorking,
            trackpad: customAttributeIncluded,
            speaker: customAttributeIncluded,
            ioBoard: customAttributeIncluded,
            fans: customAttributeIncluded,
            battery: customAttributeIncluded,
            ports: customAttributeIncluded,
            display: customAttributeWorking,
            webcam: customAttributeWorking,
            microphone: customAttributeWorking,
            keyboard: customAttributeWorking,
          }}
          enableReinitialize
          validationSchema={BulkInventoryPartsFormSchema}
          onSubmit={(values) => bulkCreateInventoryParts(values)}
        >
          {({ values, isValid, submitForm, errors, touched }) => {
            const handlePlurals = () => {
              const useSingular = values?.quantity == 1;
              switch (partType) {
                case "TopCase":
                  return useSingular ? "top case" : "top cases";
                case "Board":
                  return useSingular ? "logic board" : "logic boards";
                case "Lcd":
                  return useSingular ? "LCD" : "LCDs";
                case "Battery":
                  return useSingular ? "battery" : "batteries";
                default:
                  return useSingular ? "part" : "parts";
              }
            };

            return (
              <Form>
                <Grid container direction="column" spacing={2}>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Field
                        name="quantity"
                        as={TextField}
                        type="number"
                        inputProps={{ min: 1, max: 99 }}
                        size="small"
                        label="No. of parts to create"
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="warehouseLocation"
                        as={TextField}
                        size="small"
                        label="Warehouse Location"
                        placeholder="Set Location"
                        error={touched.warehouseLocation && Boolean(errors.warehouseLocation)}
                        helperText={touched.warehouseLocation && errors.warehouseLocation}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container columns={2} spacing={2}>
                    <Grid item xs>
                      <Field name="apn" as={TextField} size="small" label="APN" placeholder="000-0000" fullWidth />
                    </Grid>
                    <Grid item xs>
                      <Field
                        name="model"
                        as={TextField}
                        size="small"
                        label="EMC Model"
                        placeholder=""
                        fullWidth
                        error={touched.model && Boolean(errors.model)}
                        helperText={touched.model && errors.model}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container columns={2} spacing={2}>
                    <Grid item xs>
                      <Field
                        name="cosmeticGrade"
                        as={TextField}
                        size="small"
                        label="Cosmetic Grade"
                        select
                        fullWidth
                        error={touched.cosmeticGrade && Boolean(errors.cosmeticGrade)}
                        helperText={touched.cosmeticGrade && errors.cosmeticGrade}
                      >
                        <MenuItem value="A">A</MenuItem>
                        <MenuItem value="B">B</MenuItem>
                        <MenuItem value="C">C</MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs>
                      <Field
                        name="color"
                        as={TextField}
                        size="small"
                        label="Color"
                        select
                        fullWidth
                        error={touched.color && Boolean(errors.color)}
                        helperText={touched.color && errors.color}
                      >
                        <MenuItem value="Black">Black</MenuItem>
                        <MenuItem value="Silver">Silver</MenuItem>
                        <MenuItem value="Space Gray">Space Gray</MenuItem>
                        <MenuItem value="Gold">Gold</MenuItem>
                        <MenuItem value="Rose Gold">Rose Gold</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Field
                      name="comments"
                      as={TextField}
                      size="small"
                      label="Comments"
                      multiline
                      maxRows={4}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                {partType == "Lcd" && (
                  <Grid container direction="column" paddingTop={2}>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs />
                      <Grid container item xs>
                        <Grid item xs>
                          <Typography display="inline" variant="body2" align="center">
                            Working
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography display="inline" variant="body2" align="center">
                            Broken
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Display
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="display"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeWorking}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="display"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeBroken}
                          />
                        </Grid>
                      </Grid>
                      <Grid container alignItems={"center"} justifyContent={""}>
                        <Grid item xs>
                          <Typography display="inline" variant="caption">
                            Webcam
                          </Typography>
                        </Grid>
                        <Grid container item xs>
                          <Grid item xs>
                            <Field
                              name="webcam"
                              type="radio"
                              as={FormControlLabel}
                              control={<Radio color="success" />}
                              size="small"
                              value={customAttributeWorking}
                            />
                          </Grid>
                          <Grid item xs>
                            <Field
                              name="webcam"
                              type="radio"
                              as={FormControlLabel}
                              control={<Radio color="error" />}
                              size="small"
                              value={customAttributeBroken}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {partType == "Board" ? (
                  <Grid container direction="column" paddingTop={2}>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                      <Grid item xs />
                      <Grid container item xs>
                        <Grid item xs>
                          <Typography display="inline" variant="body2" align="center">
                            Working
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography display="inline" variant="body2" align="center">
                            Broken
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Logic Function
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="logic"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeWorking}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="logic"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeBroken}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption" title="T2 chip removed">
                          Power
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="power"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeWorking}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="power"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeBroken}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {partType == "TopCase" ? (
                  <Grid container direction="column" paddingTop={2}>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                      <Grid item xs />
                      <Grid container item xs>
                        <Grid item xs>
                          <Typography display="inline" variant="body2" align="center">
                            Included
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography display="inline" variant="body2" align="center">
                            Not Included
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Trackpad
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="trackpad"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="trackpad"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Speakers
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="speaker"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="speaker"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          I/O Board
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="ioBoard"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="ioBoard"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Fans
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="fans"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="fans"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Ports
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="ports"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="ports"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Battery
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="battery"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="battery"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Keyboard
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="keyboard"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="keyboard"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"} justifyContent={""}>
                      <Grid item xs>
                        <Typography display="inline" variant="caption">
                          Microphone
                        </Typography>
                      </Grid>
                      <Grid container item xs>
                        <Grid item xs>
                          <Field
                            name="microphone"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="success" />}
                            size="small"
                            value={customAttributeIncluded}
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="microphone"
                            type="radio"
                            as={FormControlLabel}
                            control={<Radio color="error" />}
                            size="small"
                            value={customAttributeNotIncluded}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container spacing={2} justifyContent={"flex-end"} paddingTop={2}>
                  <Grid item>
                    <Button
                      onClick={() => setOpenDialog(true)}
                      disabled={isValid == false || inventoryPartIds.length > 0}
                      variant="contained"
                    >
                      Create Parts
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<PrintIcon />}
                      disabled={inventoryPartIds.length === 0}
                      variant="contained"
                      onClick={handlePrint}
                    >
                      Print Labels
                    </Button>
                    {printComponentRef && inventoryPartIds !== undefined && (
                      <Printable>
                        <PartsPrintableQRCodes
                          selectedInventoryParts={
                            inventoryPartIds.map((id) => ({
                              id: id,
                              label: values?.apn,
                              model: values?.model,
                              type: values?.type,
                              cosmeticGrade: values?.cosmeticGrade,
                              color: values?.color,
                              logic: values?.logic,
                              power: values?.power,
                              trackpad: values?.trackpad,
                              speaker: values?.speaker,
                              ioBoard: values?.ioBoard,
                              fans: values?.fans,
                              battery: values?.battery,
                              ports: values?.ports,
                              display: values?.display,
                              webcam: values?.webcam,
                              microphone: values?.microphone,
                              comments: values?.comments,
                            })) as InventoryPart[]
                          }
                          ref={printComponentRef}
                        />
                      </Printable>
                    )}
                  </Grid>
                </Grid>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogContent>
                    <DialogTitle align="center">
                      <Typography variant="h3">
                        <InputIcon color="primary" fontSize="inherit" />
                      </Typography>
                      Inbound Parts & Print Labels
                    </DialogTitle>
                    <DialogContentText>
                      Are you sure you want to proceed with creating{" "}
                      <b>
                        {values?.quantity} {handlePlurals()}
                      </b>
                      ? We'll automatically inbound them into the system and generate the label(s) for you to print
                      next.
                    </DialogContentText>
                    <DialogActions>
                      <Button
                        disabled={awaitingResponse}
                        variant="contained"
                        onClick={() =>
                          submitForm()
                            .then(() => handleCloseDialog())
                            .then(() => handlePrint())
                        }
                      >
                        Yes, proceed
                      </Button>
                      <Button disabled={awaitingResponse} variant="contained" onClick={handleCloseDialog}>
                        No, go back
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
