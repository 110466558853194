import { api } from "../../services/api.service";
import { Inventory } from "../../types/inventory";
import { Close } from "@mui/icons-material";
import { IconButton, Avatar, useTheme } from "@mui/material";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { useState } from "react";

type InventoryImageDeleteButtonProps = {
  image: Inventory["inventoryImages"][number];
  inventory: Inventory;
  setInventory: React.Dispatch<React.SetStateAction<Inventory | undefined>>;
};

export default function InventoryImageDeleteButton({
  image,
  inventory,
  setInventory,
}: InventoryImageDeleteButtonProps) {
  const { spacing, palette } = useTheme();
  const [open, setOpen] = useState(false);

  const handleDeleteImage = () => {
    api.delete(`/inventory_images/${image.id}`).then(() => {
      if (inventory) {
        const updatedInventoryImages = [...inventory.inventoryImages];
        const image_index = updatedInventoryImages.findIndex((inventory_image) => image.id === inventory_image.id);
        updatedInventoryImages.splice(image_index, 1);
        setInventory({ ...inventory, inventoryImages: updatedInventoryImages });
      }
    });
  };

  return (
    <Avatar
      sx={{
        bgcolor: palette.primary.main,
        position: "absolute",
        right: spacing(-2),
        top: spacing(-2),
        height: 32,
        width: 32,
      }}
    >
      <IconButton onClick={() => setOpen(true)}>
        <Close fontSize="small" sx={{ color: "white" }} />
      </IconButton>
      <ConfirmationModal open={open} handleClose={() => setOpen(false)} onConfirm={handleDeleteImage}>
        Are you sure you want to delete this image
      </ConfirmationModal>
    </Avatar>
  );
}
