import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function useDriversPickups({ search: argSearch }: { search?: string } = {}) {
  const [pickups, setPickups] = useState<Pickup[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search = argSearch } = useLocation();

  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/drivers_pickups${search}`)
      .then(
        ({
          data: {
            pickups,
            meta: { totalPages, count },
          },
        }) => {
          setPickups(pickups);
          setTotalPages(totalPages);
          setCount(count);
        }
      )
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [search]);

  return {
    pickups,
    totalPages,
    count,
    error,
    loading,
  };
}
