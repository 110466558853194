export const softthinksModes = [
  "Audit/Wipe",
  "Audit/Wipe/Image",
  "Audit/Diag/Image",
  "Audit/Image",
  "Audit/Diag/Wipe",
  "Audit/Diagnostic",
  "Audit Only",
  "Full Deploy",
] as const;
