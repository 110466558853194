export type Contact = {
  id: string;
  uuid: string;
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  workPhoneNumber?: string;
  pointOfContactType?: string;
  internalNotes?: string;
  linkedinProfile?: string;
  jobTitle?: string;
};

export const defaultContact = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  workPhoneNumber: "",
  pointOfContactType: "",
  internalNotes: "",
  linkedinProfile: "",
  jobTitle: "",
};
