import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";
import { useAlert } from "./useAlert";

export default function useFinancePickups({ search: argSearch }: { search?: string } = {}) {
  const [pickups, setPickups] = useState<Pickup[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();
  const [error, setError] = useState<any>(null);
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPickups();
  }, [search]);

  const getPickups = () => {
    setLoading(true);
    api
      .get(`/finance_pickups${argSearch ? argSearch : search}`)
      .then(
        ({
          data: {
            pickups,
            meta: { totalPages, count },
          },
        }) => {
          setPickups(pickups);
          setTotalPages(totalPages);
          setCount(count);
        },
      )
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  interface UpdateProps {
    buybackAmountInCents: number;
    lotValueInCents: number;
    postWaivedFees: boolean;
    uuid: string;
  }

  const updatePickup = ({ buybackAmountInCents, lotValueInCents, uuid, postWaivedFees }: UpdateProps) => {
    return api
      .put(`/finance_pickups/${uuid}/update_buyback`, {
        pickup: { buybackAmountInCents, lotValueInCents, postWaivedFees },
      })
      .then(() => {
        alertSuccess("Successfully updated buyback");
        getPickups();
      })
      .catch((error) => {
        alertError(error?.response?.data?.errors ? error.response.data.errors : "There was an error, please try again");
      });
  };

  return {
    pickups,
    totalPages,
    count,
    error,
    loading,
    updatePickup,
  };
}
