import { User } from "./user";
import { Location } from "./location";
import { Contact } from "./contact";

export type Organization = {
  id: string;
  uuid: string;
  name: string;
  tier?: string;
  accountRep?: User;
  salesRep?: User;
  wonByRep?: User;
  locations?: Location[];
  contacts?: Contact[];
};

export const defaultOrganization = {
  id: "",
  uuid: "",
  name: "",
};
