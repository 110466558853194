import { styled } from "@mui/material";

export const DataList = styled("dl")`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0;

  dt {
    display: inline;

    &:after {
      content: ":";
    }
  }

  dd {
    display: inline;
    margin: 0 0 0 0.5ch;
    font-weight: bold;
  }
`;

export function DataListItem({ label, value }: { label: string; value?: string | number }) {
  return (
    <div>
      <dt>{label}</dt> <dd>{value}</dd>
    </div>
  );
}
