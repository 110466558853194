import { GridColTypeDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { getCurrentEasternTimezoneName } from "utils/helpers";

export const dateTimeColDef: GridColTypeDef = {
  type: "dateTime",
  renderHeader: (params) => {
    return `${params.colDef.headerName} (${getCurrentEasternTimezoneName()})`;
  },
  valueGetter: (params: GridValueGetterParams) => {
    if (!params.value) return "";
    const date = new Date(params.value);
    return Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/New_York",
    }).format(date);
  },
};
