import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useAlert } from "hooks/useAlert";
import { Button, IconButton, Popover, Stack, TextField, Tooltip } from "@mui/material";
import { ToggleOff } from "@mui/icons-material";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { Field } from "formik";

const validReplacementStates = ["skipped", "created"];
export function ManualReplacementRowAction({ row }: { row: ShipstationOrderItem }) {
  const [error, setError] = useState<null | string>(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formId = `override_inventory_${row.id}`;
  const apiRef = useGridApiContext();
  const { alertSuccess } = useAlert();

  if (!validReplacementStates.includes(row.state)) return null;

  return (
    <>
      <Tooltip title="Manual Replacement" disableInteractive>
        <IconButton
          color="primary"
          onClick={(event) => {
            setAnchorEl(event.currentTarget.parentElement as HTMLButtonElement);
            setOpen(true);
          }}
        >
          <ToggleOff />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          setError(null);
        }}
        sx={{ padding: 4 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <RevivnApiForm
          FormProps={{ id: formId }}
          initialValues={{ inventoryLabel: null }}
          action={`shipstation_order_items/${row.id}/override_inventory`}
          method="POST"
          onSubmit={() => {
            setError(null);
          }}
          onSuccess={(response) => {
            const updatedItem = response.data as ShipstationOrderItem;
            apiRef.current?.updateRows([{ ...updatedItem }]);
            alertSuccess("Inventory Assigned Successfully!");
            setOpen(false);
          }}
          onError={(err) => setError(err.response?.data?.error?.message)}
        >
          <Stack sx={{ p: 2, gap: 2 }} alignItems={"center"} direction="row">
            <Field
              label="Inventory to Assign"
              as={TextField}
              name="inventoryLabel"
              required
              size="small"
              placeholder="Inventory Label"
              fullWidth
              autoFocus
              error={!!error}
              helperText={error}
            />
            <Button variant="contained" type="submit" form={formId}>
              Submit
            </Button>
          </Stack>
        </RevivnApiForm>
      </Popover>
    </>
  );
}
