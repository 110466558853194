export type User = {
  email: string;
  firstName: string;
  lastName: string;
  uuid: string;
};

export const defaultUser = {
  email: "",
  firstName: "",
  lastName: "",
  uuid: "",
};
