import { useSearchParams } from "react-router-dom";

export function useSearchParamArray<T>(key: string, parse: (value: string) => T): [T[], (nextValue: T[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.getAll(key);
  const value = paramValue.map(parse);
  const setValue = (nextValue: T[]) => {
    const next = new URLSearchParams(window.location.search);
    next.delete(key);
    nextValue.forEach((v) => next.append(key, String(v)));
    setSearchParams(next);
  };
  return [value, setValue];
}
