import { Box, Button, Typography } from "@mui/material";
import PickupTimeslotsBody from "./PickupTimeslotsBody";

function PickupTimeslotsPage() {
  return (
    <Box sx={{ mx: 9, my: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h4" fontWeight="bold">
          Pickup Timeslots
        </Typography>
        <Button variant="contained" sx={{ maxWidth: "250px", width: "100%" }}>
          edit timeslots
        </Button>
      </Box>
      <Typography mt={1}>View or edit the timeslots by the zipcode of a location in the calendar below.</Typography>

      <Box mt={4}>
        <PickupTimeslotsBody></PickupTimeslotsBody>
      </Box>
    </Box>
  );
}

export default PickupTimeslotsPage;
