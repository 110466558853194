import { Box, Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { FileUpload } from "@mui/icons-material";
import Breadcrumbs from "components/common/Breadcrumbs";
import FinanceClientsDataGrid from "./FinanceClientsDataGrid";

export function FinanceClients() {
  return (
    <Box>
      <Breadcrumbs data={[{ label: "Finance", to: "/finance" }, { label: "Clients" }]}></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">Clients</Typography>
        <Typography mt={0.5}>View all the companies that work with Revivn, their locations and contacts.</Typography>
      </Box>

      <Box my={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button
          component={RouterLink}
          variant="contained"
          to="/finance/clients/airbase-vendor-list-upload"
          startIcon={<FileUpload />}
        >
          Upload Airbase Vendor List
        </Button>
      </Box>

      <FinanceClientsDataGrid />
    </Box>
  );
}
