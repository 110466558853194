import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { GreyButton } from "components/Picklist/common/GreyButton";

export function TestInventoryStep({
  onTestsPassed,
  onTestsFailed,
}: {
  onTestsPassed: () => void;
  onTestsFailed: () => void;
}) {
  return (
    <>
      <Typography>Is this inventory item functional?</Typography>
      <Stack direction="column" alignItems="center" gap={2} mt={2}>
        <Button variant="contained" onClick={onTestsPassed}>
          Yes, All Tests Pass
        </Button>
        <GreyButton onClick={onTestsFailed}>No, Needs Replacement</GreyButton>
      </Stack>
    </>
  );
}
