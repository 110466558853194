import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";

export default function useEarnings() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [earnings, setEarnings] = useState<any>();
  const [transactionGroups, setTransactionGroups] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    getEarnings();
  }, [search]);

  const getEarnings = () => {
    setLoading(true);

    api.get(`/organizations/${params.uuid}/transaction_groups${search}`).then(
      ({
        data: {
          earningsBalance,
          transactionGroups,
          meta: { totalPages, count },
        },
      }) => {
        setEarnings(earningsBalance);
        setTransactionGroups(transactionGroups);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  };

  return {
    earnings,
    transactionGroups,
    totalPages,
    count,
  };
}
