import { useCallback, useEffect, useState } from "react";

export enum FileUploadStatus {
  Queued = "Queued",
  Uploading = "Uploading",
  Uploaded = "Done",
  Error = "Error",
}

export type FileUpload = {
  file: File;
  status: FileUploadStatus;
};

type UseFileUploadType = {
  accept?: string[];
  onUpload: (files: File[]) => Promise<boolean[]>;
};

export default function useFileUpload({ accept, onUpload }: UseFileUploadType) {
  const [files, setFiles] = useState<FileUpload[]>([]);

  /** Returns a filtered array of files that match the accepted MIME file type. */
  const filterFiles = (fileArray: File[]) => {
    if (accept) {
      return fileArray.filter((file) => accept.includes(file.type));
    } else {
      return fileArray;
    }
  };

  /** Add files to the `files` array. */
  const addFiles = (fileList: FileList | File[]) => {
    const newFiles: File[] = filterFiles(Array.from(fileList));
    const newObjects: FileUpload[] = newFiles.map((file) => ({ file: file, status: FileUploadStatus.Queued }));
    setFiles((prevState) => [...prevState, ...newObjects]);
  };

  const removeFile = (index: number) => {
    setFiles((prevState) => prevState.filter((_, i) => i !== index));
  };

  const setUploadState = useCallback((file: FileUpload, newStatus: FileUploadStatus) => {
    setFiles((prevState) =>
      prevState.map((fileUpload) =>
        fileUpload.file === file.file ? { file: file.file, status: newStatus } : fileUpload,
      ),
    );
  }, []);

  /** Callback sending files ready to upload. */
  const upload = useCallback(
    async (items: FileUpload[]) => {
      items.map((item) => setUploadState(item, FileUploadStatus.Uploading));
      const newFiles = items.map((item) => item.file);

      onUpload(newFiles)
        .then((results) => {
          results.map((result, index) =>
            setUploadState(items[index], result ? FileUploadStatus.Uploaded : FileUploadStatus.Error),
          );
        })
        .catch((reason) => {
          items.map((item) => setUploadState(item, FileUploadStatus.Error));
          console.log(reason);
        });
    },
    [onUpload, setUploadState],
  );

  useEffect(() => {
    const queuedFiles = files.filter((file) => file.status === FileUploadStatus.Queued);
    if (queuedFiles.length) upload(queuedFiles);
  }, [files, upload]);

  return { files, addFiles, removeFile };
}
