import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { ArrowForward, Check } from "@mui/icons-material";
import { CheckList, CheckListItem } from "components/Picklist/common/CheckList";
import { Button, Stack } from "@mui/material";
import React from "react";

export function ItemCompleteStep({ onComplete, cartLocation }: { onComplete: () => void; cartLocation?: string }) {
  return (
    <>
      <PicklistCard title="Actions" Icon={Check}>
        <CheckList>
          <CheckListItem label={"Scanned Inventory"} />
          <CheckListItem label={`Scanned to ${cartLocation}`} />
        </CheckList>
      </PicklistCard>

      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <Button variant="contained" endIcon={<ArrowForward />} onClick={onComplete}>
          Next
        </Button>
      </Stack>
    </>
  );
}
