import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";
import { useAlert } from "./useAlert";

export default function useLogisticsPickups({ search: argSearch }: { search?: string } = {}) {
  const [pickups, setPickups] = useState<Pickup[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search = argSearch } = useLocation();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { alertError, alertSuccess } = useAlert();

  useEffect(() => {
    setLoading(true);
    getLogisticsPickups();
  }, [search]);

  const getLogisticsPickups = () => {
    const searchHash = new URLSearchParams(search);
    if (!searchHash.get("page[size]")) {
      searchHash.append("page[size]", String(25));
    }
    api
      .get(`/logistics_pickups?${searchHash.toString()}`)
      .then(
        ({
          data: {
            pickups,
            meta: { totalPages, count },
          },
        }) => {
          setPickups(pickups);
          setTotalPages(totalPages);
          setCount(count);
        }
      )
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmPickup = (pickupUuid: string) => {
    return api
      .put(`/logistics_pickups/${pickupUuid}/confirm_pickup_date`)
      .then(() => {
        alertSuccess("Pickup scheduled!");
        getLogisticsPickups();
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  const cancelPickup = (pickupUuid: string, cancellationReason: string) => {
    return api
      .post(`/logistics_pickups/${pickupUuid}/cancel`, { cancellationReason })
      .then(() => {
        alertSuccess(`Successfully canceled the pickup`);
        getLogisticsPickups();
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  return {
    pickups,
    totalPages,
    count,
    error,
    loading,
    getLogisticsPickups,
    confirmPickup,
    cancelPickup,
  };
}
