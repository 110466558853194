import { Box, Typography, Drawer, IconButton, Grid, Tabs, Tab, Tooltip, LinearProgress } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useInventoryTasks from "hooks/useTasks";
import ProgressList from "./ProgressList";
import InfoIcon from "@mui/icons-material/Info";
import { inventoryTaskTooltip } from "constants/inventoryTaskTooltip";
import { useInterval } from "hooks/useInterval";

type ActionProgressDrawerProps = {
  toggleDrawer: Dispatch<SetStateAction<boolean>>;
  isDrawerOpen: boolean;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ActionProgressDrawer({ toggleDrawer, isDrawerOpen }: ActionProgressDrawerProps) {
  const { getInventoryTasks, inventoryTasks, isLoading } = useInventoryTasks();
  const [warehousePhase, setWarehousePhase] = useState(0);

  const warehouseMap: Record<number, string> = {
    0: "receiving",
    1: "softthinks",
    2: "tech",
  };
  const handTasksLoad = (event: React.SyntheticEvent, warehousePhase: number) => {
    setWarehousePhase(warehousePhase);
    getInventoryTasks(warehouseMap[warehousePhase]);
  };

  useEffect(() => {
    getInventoryTasks(warehouseMap[warehousePhase]);
  }, [warehousePhase]);

  useInterval(async () => {
    await getInventoryTasks(warehouseMap[warehousePhase]);
  }, 5000);

  return (
    <Drawer anchor="right" open={isDrawerOpen} variant="permanent">
      <Box sx={{ width: 500, mt: "70px", mx: 1 }}>
        <Grid container>
          <Grid item xs={10} sx={{ justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Actions</Typography>
            <Tooltip title={inventoryTaskTooltip} sx={{ ml: 1, position: "relative" }}>
              <InfoIcon fontSize="small" color="primary" />
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} size="large" onClick={() => toggleDrawer(false)}>
              <Close fontSize="medium" color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                ".MuiTabs-flexContainer": { justifyContent: "space-around" },
              }}
            >
              <Tabs value={warehousePhase} onChange={handTasksLoad} sx={{ justifyContent: "space-around" }}>
                <Tab label="Receiving" {...tabProps(0)} id="receiving" />
                <Tab label="Softthinks" {...tabProps(1)} id="softthinks" />
                <Tab label="Tech" {...tabProps(2)} id="tech" />
              </Tabs>
            </Box>
            {isLoading ? <LinearProgress sx={{ mt: 0.5 }} /> : null}
            <TabPanel value={warehousePhase} index={0}>
              <ProgressList tasks={inventoryTasks} />
            </TabPanel>
            <TabPanel value={warehousePhase} index={1}>
              <ProgressList tasks={inventoryTasks} />
            </TabPanel>
            <TabPanel value={warehousePhase} index={2}>
              <ProgressList tasks={inventoryTasks} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
