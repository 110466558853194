import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { AssetRetrieval } from "types/assetRetrieval";
import { useAlert } from "hooks/useAlert";

export default function useAssetRetrievals() {
  const [assetRetrievals, setAssetRetrievals] = useState<any[]>([]);
  const [assetRetrievalStateOptions, setAssetRetrievalStateOptions] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { alertSuccess, alertError, alertWarning } = useAlert();

  useEffect(() => {
    setLoading(true);
    fetchAssetRetrievals();
  }, [search]);

  const fetchAssetRetrievals = () => {
    const searchHash = new URLSearchParams(search);
    if (!searchHash.get("page[size]")) {
      searchHash.append("page[size]", String(25));
    }
    api
      .get(`/asset_retrievals?${searchHash.toString()}`)
      .then(
        ({
          data: {
            assetRetrievals,
            assetRetrievalStateOptions,
            meta: { totalPages, count },
          },
        }) => {
          setAssetRetrievals(assetRetrievals);
          setAssetRetrievalStateOptions(assetRetrievalStateOptions);
          setTotalPages(totalPages);
          setCount(count);
        },
      )
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const complete = (assetRetrieval: AssetRetrieval) => {
    api.put(`/asset_retrievals/${assetRetrieval.uuid}/accept`).then(() => {
      fetchAssetRetrievals();
    });
  };

  const cancel = (assetRetrieval: AssetRetrieval) => {
    api
      .put(`/asset_retrievals/${assetRetrieval.uuid}/cancel`)
      .then(() => {
        fetchAssetRetrievals();
      })
      .catch((e) => {
        alertWarning(e.response.data.errors);
      });
  };

  const bulkCreateAssetRetrievalOrders = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    api
      .post(`/asset_retrievals_bulk`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        alertSuccess("Successfully submitted upload. This may take a few moments for the data to populate..");
      })
      .catch((error) => {
        alertError("There was an error, please try again");
      });
    return;
  };

  return {
    assetRetrievals,
    assetRetrievalStateOptions,
    totalPages,
    count,
    error,
    loading,
    bulkCreateAssetRetrievalOrders,
    fetchAssetRetrievals,
    complete,
    cancel,
  };
}
