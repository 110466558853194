import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

export default function SerialValidation({ serial }: { serial: string }) {
  const [reason, setReason] = useState<string>("");
  const [icon, setIcon] = useState(<PendingIcon fontSize="inherit" />);

  const onUpdate = () => {
    var isValid: boolean = false;
    var reason: string = "";
    var icon = <PendingIcon fontSize="small" />;

    switch (serial.length) {
      case 10:
        if (serial.match("^[0-9ABEIOSUZ]")) reason = "Number, vowel, B, S or Z in 1st digit.";
        if (serial.match("^.{1,9}[8ABEIOSUZ]")) reason = "Vowel, 8, B, S or Z in 2nd-10th digits.";
        break;

      case 11:
        if (serial.match("^(?:34|45|4H|C0|G8|H0|QP|W8|WQ|YM)") == null) reason = "Uncommon first 2 digits"; // Whitelisted: 34, 4H, C0
        if (serial.match("^.{2}[0-9]") == null) reason = "3rd digit must be 0, 1, 2, 6, 7, 8 or 9"; // Whitelisted: 6, 7, 8
        if (serial.match("^.{3}(?:[0-4W][0-9]|5[0-2])") == null) reason = "4-5th digits must be in range of 00 and 52"; // Whitelisted: W*
        if (serial.match("^.{5}[0-9A-HJ-NP-Z]{3}") == null) reason = "I or O in 6-8 digit";
        if (
          serial.match(
            "(?:0P1|1HV|20H|2QT|5PC|5PK|5RU|64C|66D|66E|66J|7XJ|7XK|8YB|AGU|AGV|AGZ|ATM|ATN|ATQ|DB5|DNM|EUE|EUF|EUG|EUH|F5W|FYN|GWR|RG3|U9E|Z5V|Z5Z|DAS|U2P|0TL|XMP)$",
          ) == null
        )
          reason = "Uncommon last 3 digits"; // Whitelisted: 0P1, 1HV, F5W, U9E, Z5V, Z5Z
        break;

      case 12:
        if (
          serial.match(
            "^(?:C02|C07|C0K|C17|C1M|C2Q|C2V|C2X|CPW|D25|D2H|D92|DCP|DX1|F5K|FVF|FVH|GCC|H12|H2W|H4T|HRT)",
          ) == null
        )
          reason = "Uncommon first 3 digits"; // Whitelisted: HRT
        if (serial.match("^.{3}[CDFGHJKLMNPQRSTVWXYZ]") == null) reason = "Number, vowel or B in 4th digit";
        if (serial.match("^.{4}[1-9CDFGHJKLMNPQRTVWXY]") == null) reason = "Zero, vowel, B, S or Z in 5th digit";
        if (serial.match("^.{5}[0-9A-HJ-NP-Z]{3}") == null) reason = "I or O in 6-8 digit";
        if (serial.match("[01DFGHJKLMPQV][0-9CDFGHJKLMNPQRTVWXY]{3}$") == null)
          reason = "Vowel, B, S or Z in last 4 digits";
        break;

      default:
        break;
    }
    if (serial.length == 0) reason = "Serial is empty.";

    isValid = reason === "";
    if (isValid) {
      reason = "This serial is valid.";
      icon = <CheckCircleIcon fontSize="inherit" color="info" />;
    } else {
      icon = <ErrorIcon fontSize="inherit" color="warning" />;
    }
    setReason(reason);
    setIcon(icon);
  };

  useEffect(onUpdate, [serial]);
  return (
    <Tooltip arrow leaveDelay={200} placement="top" title={reason}>
      {icon}
    </Tooltip>
  );
}
