import { Clients } from "components/Support/Clients/Clients";
import { Route, Routes } from "react-router-dom";

export default function SupportPage() {
  return (
    <Routes>
      <Route index element={<Clients />} />
      <Route path="/clients" element={<Clients />} />
    </Routes>
  );
}
