import { Alert, AlertColor, Box, Snackbar } from "@mui/material";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

type AlertMessage = {
  body: string;
  type: AlertColor;
};

type AlertContextValue = {
  alertError: (s: string) => void;
  alertSuccess: (s: string) => void;
  alertInfo: (s: string) => void;
  alertWarning: (s: string) => void;
};

const AlertContext = createContext<AlertContextValue>({
  alertError: (_) => {},
  alertSuccess: (_) => {},
  alertInfo: (_) => {},
  alertWarning: (_) => {},
});

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alerts, setAlerts] = useState<AlertMessage[]>([]);
  const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([]);
  const removeAlert = useCallback(() => {
    const timeout = setTimeout(() => {
      setAlerts(([_toBeRemoved, ...alerts]) => alerts);
    }, 10000);
    setTimeouts((oldTimeouts) => [timeout, ...oldTimeouts]);
  }, [setAlerts, setTimeouts]);

  const alertError = useCallback(
    (body: string) => {
      setAlerts(() => [{ body, type: "error" }]);
      removeAlert();
    },
    [setAlerts],
  );

  const alertSuccess = useCallback(
    (body: string) => {
      setAlerts(() => [{ body, type: "success" }]);
      removeAlert();
    },
    [setAlerts, alerts],
  );

  const alertInfo = useCallback(
    (body: string) => {
      setAlerts(() => [{ body, type: "info" }]);
      removeAlert();
    },
    [setAlerts],
  );

  const alertWarning = useCallback(
    (body: string) => {
      setAlerts((alerts) => [{ body, type: "warning" }]);
      removeAlert();
    },
    [setAlerts],
  );

  useEffect(() => {
    return () => {
      timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [timeouts]);

  const value = {
    alertError,
    alertSuccess,
    alertInfo,
    alertWarning,
  };

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Snackbar
        open={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box data-testid="alerts-container">
          {alerts.map((alert, index) => (
            <Alert key={index} severity={alert.type} data-testid={`alerts-message-${alert.type}`}>
              {alert.body}
            </Alert>
          ))}
        </Box>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const { alertError, alertInfo, alertSuccess, alertWarning } = useContext(AlertContext);

  return {
    alertError,
    alertInfo,
    alertSuccess,
    alertWarning,
  };
};
