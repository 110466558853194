import InventoriesNew from "components/Inventories/InventoriesNew";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { Route, Routes } from "react-router-dom";

type InventoriesNewPageProps = {
  prevPath: string;
};

export default function InventoriesNewPage({ prevPath }: InventoriesNewPageProps) {
  useBreadcrumbs({
    name: "Add Pickup Item",
    path: `${prevPath}/inventories/new`,
  });

  return (
    <Routes>
      <Route index element={<InventoriesNew />} />
    </Routes>
  );
}
