import { BatchState, useCurrentFulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";
import { PickingStep } from "components/Picklist/CurrentBatch/PickingStep";
import { PackingStep } from "components/Picklist/CurrentBatch/PackingStep";
import { Navigate } from "react-router-dom";
import React from "react";
import { Paper, Step, StepLabel, Stepper, styled } from "@mui/material";
import { BatchComplete } from "components/Picklist/CurrentBatch/BatchComplete";

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const PicklistCurrentBatchPage = () => {
  const { batch, isLoading, mutate } = useCurrentFulfillmentBatch();

  if (isLoading) return null;

  if (!batch || batch.state === BatchState.Created) return <Navigate replace={true} to="/picklist/new" />;

  function refreshBatch() {
    mutate();
  }

  const activeStep = batch.state === BatchState.Picking ? 0 : 1;

  return (
    <>
      <Stepper activeStep={activeStep} sx={{ paddingInline: "2rem" }}>
        <Step
          sx={{
            ".MuiStepIcon-root.Mui-completed": { color: "success.main", opacity: 0.7 },
            ".MuiStepLabel-label.Mui-completed": { color: "grey.500" },
          }}
        >
          <StepLabel>Pick</StepLabel>
        </Step>
        <Step>
          <StepLabel>Pack</StepLabel>
        </Step>
      </Stepper>
      <StyledPaper variant="outlined" sx={{ padding: 3 }}>
        {batch.state === BatchState.Picking && (
          <PickingStep batch={batch} onItemPickingComplete={refreshBatch} onTransitionToPacking={refreshBatch} />
        )}
        {batch.state === BatchState.Packing && <PackingStep batch={batch} onItemPackingComplete={refreshBatch} />}
        {batch.state === BatchState.Complete && <BatchComplete />}
      </StyledPaper>
    </>
  );
};
