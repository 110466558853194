import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import InventoriesDataGrid from "components/Inventories/InventoriesDataGrid";
import { Route, Routes } from "react-router-dom";
import { Crumb } from "types/crumb";

const IndexElement = ({ crumbs }: { crumbs: Crumb[] }) => {
  return (
    <>
      <ApplicationBreadCrumbs sx={{ my: 3, mx: 0 }} crumbs={crumbs} />

      <InventoriesDataGrid
        fieldsToHide={["lineId", "pickupId", "printedAt", "inventoryImages", "iqInventoryId", "iqPoIdLine", "assetTag"]}
        density="compact"
      />
    </>
  );
};

export default function InventoriesPage() {
  const crumbs: Crumb[] = [{ name: "Inventories", path: "/inventories" }];

  return (
    <Routes>
      <Route index element={<IndexElement crumbs={crumbs} />} />
    </Routes>
  );
}
