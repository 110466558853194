import { useState, useEffect, useContext, SyntheticEvent } from "react";
import { Alert, Box, Grid, FormControl, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import { defaultOrganization, Organization } from "types/organization";
import { Location } from "types/location";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { LogisticsPickupContext, useCreatePickup } from "hooks/useLogisticsPickup";
import { debounce } from "lodash";

export default function PickupLocation() {
  const [isSaved, setIsSaved] = useState(false);
  const params = useParams();
  const { organizations, organization, searchOrganizations, createPickup } = useCreatePickup();
  const { updatePickup, pickup } = useContext(LogisticsPickupContext);

  const handleLocationChange = (e: SelectChangeEvent<string>) => {
    updatePickup({ ...pickup, locationId: e.target.value }).then(() => {
      setIsSaved(true);
    });
  };

  return (
    <>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          1. Select a company and location:
        </Typography>
        {isSaved ? <Alert>Saved</Alert> : null}
      </Grid>

      <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FormControl fullWidth>
          <Autocomplete
            id="organizations"
            getOptionLabel={(option: Organization) => option.name}
            isOptionEqualToValue={(option: Organization, value: Organization) => option.name === value.name}
            options={organizations}
            value={pickup.organization || defaultOrganization}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(_event: SyntheticEvent<Element, Event>, newValue: Organization | null) => {
              if (newValue) {
                if (params.pickupUuid) {
                  updatePickup({ ...pickup, organizationId: newValue.id }).then(() => {
                    setIsSaved(true);
                  });
                } else {
                  createPickup({ organizationId: newValue.id });
                }
              }
            }}
            onInputChange={debounce((_e, newInputValue) => searchOrganizations(newInputValue), 500)}
            renderInput={(params) => {
              return (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel shrink {...params.InputLabelProps}>
                    Company
                  </InputLabel>
                  <InputBase
                    size="small"
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    placeholder="Search Companies"
                  />
                </FormControl>
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.name}</li>;
            }}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="location">
            Location
          </InputLabel>
          <AppSelect
            id="location"
            name="location"
            value={pickup.location?.id || ""}
            displayEmpty
            fullWidth
            onChange={handleLocationChange}
          >
            {organization?.locations
              ?.filter(({ deletedAt }) => !deletedAt)
              .map((location: Location) => {
                return (
                  <MenuItem key={location.id} value={location.id || ""}>
                    {`${location.name} - ${location.address || ""} ${location.address2 || ""} ${location.city || ""} ${
                      location.state || ""
                    }`}
                  </MenuItem>
                );
              })}
          </AppSelect>
        </FormControl>
      </Box>
    </>
  );
}
