import { NavigateOptions, useSearchParams } from "react-router-dom";

export function useSearchParam<T>(
  key: string,
  defaultValue: T,
  parse: (value: string) => T,
): [T, (nextValue: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);
  const value = paramValue !== null ? parse(paramValue) : defaultValue;
  const setValue = (nextValue: T, navigateOpts?: NavigateOptions) => {
    // Have to use the window.location here because reactRouter caches the search params
    // https://github.com/remix-run/react-router/issues/9757
    const next = new URLSearchParams(window.location.search);
    if (nextValue === defaultValue) next.delete(key);
    else next.set(key, String(nextValue));
    setSearchParams(next, navigateOpts);
  };
  return [value, setValue];
}
