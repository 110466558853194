import { fetcher } from "services/api.service";
import useSWRImmutable from "swr/immutable";

interface CurrentUser {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissions: any;
  warehouseName: string;
  email: string;
}

export default function useCurrentUser() {
  const { data, isLoading } = useSWRImmutable<CurrentUser>("/current_user", fetcher);
  const currentUserPermissions = data?.permissions;

  return {
    user: data,
    isLoading,
    currentUserPermissions,
  };
}
