import { Inventory } from "types/inventory";
import { api } from "services/api.service";
import React from "react";
import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Check } from "@mui/icons-material";
import { CheckList, CheckListItem } from "components/Picklist/common/CheckList";
import { Stack } from "@mui/material";
import { ScanButton } from "components/Picklist/common/ScanButton";
import { useAlert } from "hooks/useAlert";

export function ScanAndMoveInventoryStep({
  onInventoryMoved,
}: {
  inventory: Inventory;
  onInventoryMoved: (location: string) => void;
}) {
  const [scannedLocation, setScannedLocation] = React.useState<string | null | undefined>(null);
  const label = scannedLocation ? `Moving Inventory...` : `Scan Cart`;
  const { alertError } = useAlert();

  function moveToCart(cart: string) {
    setScannedLocation(cart);
    api
      .post(`/current_fulfillment_batch/item/move_to_cart`, { cart })
      .then(() => {
        onInventoryMoved(cart);
      })
      .catch(() => {
        setScannedLocation(undefined);
        alertError("Failed to move inventory.  Please Try again");
      });
  }

  function validateLocation(location: string) {
    if (location.startsWith("PICK-")) {
      return true;
    } else {
      alertError("Location is not a PICK- location. Please scan a valid location.");
      return false;
    }
  }

  return (
    <>
      <PicklistCard title="Actions" Icon={Check}>
        <CheckList>
          <CheckListItem label={"Scanned Inventory"} />
        </CheckList>
      </PicklistCard>
      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <ScanButton
          onScan={(scanValue) => {
            if (scanValue) {
              if (validateLocation(scanValue)) {
                moveToCart(scanValue);
              }
            }
          }}
        >
          {label}
        </ScanButton>
      </Stack>
    </>
  );
}
