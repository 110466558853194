import { IconButton, Menu, MenuItem, SxProps } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InventoriesMenuItemPrint from "./InventoriesMenuItemPrint";
import { useInventoryType } from "hooks/useInventory";
import { useConfirmation } from "hooks/useConfirmation";
import InventoriesBulkEditDrawer from "./InventoriesBulkEditDrawer";

type InventoriesDataGridActionsProps = {
  sx?: SxProps;
  useInventoriesState: useInventoryType;
  revertChange: () => void;
};

export default function InventoriesDataGridActions({
  sx,
  useInventoriesState,
  revertChange,
}: InventoriesDataGridActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { confirm } = useConfirmation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    revertChange();
    setDrawerOpen((isDrawerOpen) => !isDrawerOpen);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { selectedInventories, deleteInventories, getInventories, setPrintConfirmDialogOpen, updatedInventories } =
    useInventoriesState;

  const handleDelete = () => {
    handleClose();
    confirm({
      handler: () => {
        deleteInventories(selectedInventories).then(() => getInventories());
      },
      message: "Are you sure want to delete the inventory/ies?",
    });
  };

  return (
    <>
      <IconButton sx={sx} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <InventoriesMenuItemPrint
          selectedInventories={selectedInventories}
          onClick={handleClose}
          onAfterPrint={() => {
            setPrintConfirmDialogOpen(true);
          }}
        />
        <MenuItem onClick={toggleDrawer}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      {isDrawerOpen ? (
        <InventoriesBulkEditDrawer
          open={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          selectedInventories={selectedInventories}
          updatedInventories={updatedInventories}
        />
      ) : null}
    </>
  );
}
