import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { LogisticsPickupContext } from "hooks/useLogisticsPickup";
import Breadcrumbs from "components/common/Breadcrumbs";
import { ArrowBack, RemoveCircle, AddCircle } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { LogisticsDetails } from "components/Logistics/Pickups/LogisticsInfo/LogisticsDetails";
import LogisticsSchedulePickup from "components/Logistics/Pickups/SchedulePickup/LogisticsSchedulePickup";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);
const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <RemoveCircle color="primary" />
      </div>
      <div className="collapsIconWrapper">
        <AddCircle color="primary" />
      </div>
    </Box>
  );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<CustomExpandIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  height: "80px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transition: "none",
    "&.Mui-expanded": {
      transform: "none",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function LogisticsPickupDetailsEditPage() {
  const { pickup } = useContext(LogisticsPickupContext);
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState<string | false>(pathname.includes("edit") ? false : "panel1");
  const navigate = useNavigate();
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: pickup?.orderNumber ? `Edit Details #${pickup?.orderNumber}` : null },
        ]}
      ></Breadcrumbs>
      <Button size="small" variant="text" startIcon={<ArrowBack />} onClick={() => navigate(-1)} sx={{ my: "32px" }}>
        back to pickups
      </Button>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography color="primary" fontWeight={700} fontSize={16}>
            Pickup Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LogisticsSchedulePickup />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography color="primary" fontWeight={700} fontSize={16}>
            Logistics Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LogisticsDetails />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
