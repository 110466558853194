import { Box, Typography } from "@mui/material";
import ClientsDataGrid from "./ClientsDataGrid";
import Breadcrumbs from "components/common/Breadcrumbs";

export function Clients() {
  return (
    <Box>
      <Breadcrumbs
        data={[{ label: "Logistics", to: "/logistics?logistics_status=scheduled" }, { label: "Clients" }]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">Clients</Typography>
        <Typography mt={0.5}>Impersonate a client user.</Typography>
      </Box>

      <ClientsDataGrid />
    </Box>
  );
}
