import { useContext, useEffect, useState } from "react";
import { Crumb } from "types/crumb";
import { BreadcrumbsContext, useBreadcrumbsContext } from "../contexts/breadcrumbContext";

export default function useBreadcrumbs(crumb: Crumb) {
  const { crumbs, setCrumbs } = useBreadcrumbsContext();

  const [index, setIndex] = useState<number>(crumbs.length);

  useEffect(() => {
    setCrumbs((prevCrumbs) => {
      const newCrumbs = [...prevCrumbs];
      setIndex(prevCrumbs.length);
      newCrumbs[prevCrumbs.length] = crumb;
      return newCrumbs;
    });
    return () =>
      setCrumbs((prevCrumbs) => {
        const newCrumbs = [...prevCrumbs];
        newCrumbs.splice(index, prevCrumbs.length - index);
        return newCrumbs;
      });
  }, [setCrumbs]);

  const setCrumb = (newCrumb: Crumb) => {
    setCrumbs((prevCrumbs) => {
      const newCrumbs = [...prevCrumbs];
      newCrumbs[index] = newCrumb;
      return newCrumbs;
    });
  };

  return { crumb: crumbs[index], setCrumb, crumbs };
}
