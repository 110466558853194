import { GridProps, Grid } from "@mui/material";

export const RevivnGrid = (props: GridProps) => {
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ margin: "10px", paddingLeft: "10px" }}
      {...props}
    ></Grid>
  );
};
