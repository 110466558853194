import { Alert, Box, Grid, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useContext, useState } from "react";
import { LogisticsPickupContext } from "hooks/useLogisticsPickup";

export default function PickupInternalNotes() {
  const { pickup, updatePickup } = useContext(LogisticsPickupContext);
  const [isSaved, setIsSaved] = useState(false);
  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          7. Revivn Internal Notes
        </Typography>
        {isSaved ? <Alert>Saved</Alert> : null}
      </Grid>

      <Typography mt={0.5} variant="body2">
        Enter any information helpful for the internal Revivn team
      </Typography>

      <Box mt={3}>
        <TextField
          id="internalNotes"
          fullWidth
          multiline
          rows={4}
          defaultValue={pickup.internalNotes || ""}
          onChange={debounce(
            (e) =>
              updatePickup({ ...pickup, internalNotes: e.target.value }).then(() => {
                setIsSaved(true);
              }),
            750
          )}
        />
      </Box>
    </Box>
  );
}
