import { Inventory, InventoryImage } from "types/inventory";
import { init, PickerOptions } from "filestack-js";
import { api } from "services/api.service";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

export default function openInventoryPhotoPicker(
  inventory: Inventory,
  onInventoryImageAdded: (inventoryImage: InventoryImage) => void,
  options?: PickerOptions
) {
  const client = init(process.env.REACT_APP_FILESTACK_API_KEY || "");
  const filename = uuidv4();

  const setCredentials = () => {
    api.post("/filestack_credential").then((resp) => {
      client.setSecurity(resp.data);
    });
  };

  setCredentials();
  const credentials_interval = setInterval(() => {
    setCredentials();
  }, 25000);

  const saveToPlatform = () => {
    const body = {
      inventory_image: {
        inventory_id: inventory.id,
        url: `${process.env.REACT_APP_CDN_ORIGIN}/inventories/${inventory.uuid}/${filename}.png`,
      },
    };

    api.post("/inventory_images", body).then((resp) => {
      onInventoryImageAdded(resp.data.inventoryImage);
    });
  };

  const saveThumbnailToS3 = async (file: { url: string }) => {
    const url = new URL(file.url);
    url.pathname =
      `/${process.env.REACT_APP_FILESTACK_API_KEY}` +
      "/resize=height:250/resize=width:250" +
      `/security=policy:${client.session.policy},signature:${client.session.signature}` +
      url.pathname;

    return api
      .post("/s3_credential", { path: `inventories/${inventory.uuid}/${filename}-250x250.png` })
      .then(async (resp) => {
        await fetch(url.toString())
          .then((resp) => resp.blob())
          .then((blob) => {
            axios.put(resp.data.presignedUrl, blob, {
              headers: {
                "Content-Type": "application/octet-stream",
              },
            });
          });
      });
  };

  const handleFileUploadFinish = (file: { url: string }) => saveThumbnailToS3(file).then(saveToPlatform);

  const defaultOptions = {
    accept: ["image/*"],
    maxFiles: 1,
    storeTo: {
      path: `/inventories/${inventory.uuid}/${filename}.png`,
      container: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_S3_REGION,
    },
    onClose: () => clearInterval(credentials_interval),
    onFileUploadFinished: handleFileUploadFinish,
  };

  const injectedOptions = options ? { ...defaultOptions, ...options } : defaultOptions;

  client.picker(injectedOptions).open();
}
