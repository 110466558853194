import { Box, Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Add } from "@mui/icons-material";
import Breadcrumbs from "components/common/Breadcrumbs";
import PartnersDataGrid from "./PartnersDataGrid";

export function Partners() {
  return (
    <Box>
      <Breadcrumbs
        data={[{ label: "Logistics", to: "/logistics?logistics_status=scheduled" }, { label: "Partners" }]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">Partners</Typography>
        <Typography mt={0.5}>View all the partners that work with Revivn, their locations and contacts.</Typography>
      </Box>

      <Box my={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button component={RouterLink} variant="contained" to={`/logistics/partners/new`} startIcon={<Add />}>
          Add New Partner
        </Button>
      </Box>

      <PartnersDataGrid />
    </Box>
  );
}
