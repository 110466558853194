import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function LogisticsPickupTabs({ onTabChange }: { onTabChange: (s: string) => void }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("logistics_status"));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParams({ logistics_status: newValue });
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "30px 0" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="logistics pickup tabs"
      >
        <Tab value="scheduled" label="Scheduled" />
        <Tab value="pending" label="Pending" />
        <Tab value="draft" label="Draft" />
        <Tab value="verifying" label="Verifying" />
        {/* <Tab value="canceled" label="Canceled" /> */}
        <Tab value="all" label="All" />
      </Tabs>
    </Box>
  );
}
