import { Box, Button, LinearProgress, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import ContactsList from "./ContactsList";
import { Link as RouterLink } from "react-router-dom";
import { Add } from "@mui/icons-material";

export function ClientContacts() {
  const { client, clientLoading, deleteContact } = useClients();

  if (clientLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client ? client?.name : "", to: `/logistics/clients/${client?.uuid}` },
          { label: "Contacts", to: `/logistics/clients/${client?.uuid}/contacts` },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">{client?.name}</Typography>
        <Typography mt={0.5}>Total Contacts: {client?.contacts?.length}</Typography>
      </Box>

      <Button component={RouterLink} variant="contained" to="new" startIcon={<Add />}>
        Add New Contact
      </Button>

      <ContactsList client={client} deleteContact={deleteContact} />
    </Box>
  );
}
