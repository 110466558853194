import { Autocomplete, Box, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { deviceTypes } from "constants/device_types";
import { Form, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { Inventory } from "types/inventory";
import useKeyPress from "../../hooks/useKeyPress";
import useOnClickOutside from "../../hooks/useOnClickOutside";

interface IAttributeField {
  name: string;
  label: string;
  value: string | number | undefined;
  touched: boolean | undefined;
  errors: string | undefined;
  inputActive: boolean;
}

function AttributeField({ name, label, value, touched, errors, inputActive }: IAttributeField) {
  return (
    <ListItem divider={!inputActive} disablePadding>
      <ListItemText
        primary={<Typography variant="caption">{label}</Typography>}
        secondary={
          inputActive ? (
            <TextField
              fullWidth
              size="small"
              name={name}
              value={value}
              variant="standard"
              placeholder={label}
              error={Boolean(touched && errors)}
              helperText={touched && errors}
            />
          ) : (
            <Typography variant="body2">{value}</Typography>
          )
        }
      />
    </ListItem>
  );
}

export default function InventoryAttributes({ disabled }: { inventory: Inventory | undefined; disabled: boolean }) {
  const { handleChange, setFieldValue, values, errors, touched } = useFormikContext<Inventory>();
  const [isInputActive, setIsInputActive] = useState(false);
  const enter = useKeyPress("Enter");
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      setIsInputActive(false);
    }
  });

  useEffect(() => {
    if (isInputActive) {
      if (enter) {
        setIsInputActive(false);
      }
    }
  }, [enter]);

  return (
    <Box ref={wrapperRef}>
      <Form onChange={handleChange} onClick={() => (disabled ? setIsInputActive(false) : setIsInputActive(true))}>
        <List dense disablePadding>
          <ListItem divider={!isInputActive} disablePadding>
            <ListItemText
              primary={<Typography variant="caption">{"Device Type"}</Typography>}
              secondary={
                isInputActive ? (
                  <Autocomplete
                    fullWidth
                    disablePortal
                    disableClearable
                    openOnFocus
                    options={[...deviceTypes]}
                    size="small"
                    value={values.deviceType}
                    isOptionEqualToValue={(option, value) => option !== value}
                    onChange={(_e, val) => setFieldValue("deviceType", val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="deviceType"
                        variant="standard"
                        placeholder="Device Type"
                        helperText={touched.deviceType && errors.deviceType}
                        error={Boolean(touched.deviceType && errors.deviceType)}
                      />
                    )}
                  />
                ) : (
                  <Typography variant="body2">{values.deviceType}</Typography>
                )
              }
            />
          </ListItem>
          <AttributeField
            name={"serialNumber"}
            label={"Serial Number"}
            value={values.serialNumber}
            touched={touched.serialNumber}
            errors={errors.serialNumber}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"model"}
            label={"Model"}
            value={values.model}
            touched={touched.model}
            errors={errors.model}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"modelOrder"}
            label={"Model Order"}
            value={values.modelOrder}
            touched={touched.modelOrder}
            errors={errors.modelOrder}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"sku"}
            label={"SKU"}
            value={values.sku}
            touched={touched.sku}
            errors={errors.sku}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"cpu"}
            label={"CPU"}
            value={values.cpu}
            touched={touched.cpu}
            errors={errors.cpu}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"ram"}
            label={"RAM"}
            value={values.ram}
            touched={touched.ram}
            errors={errors.ram}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"gpu"}
            label={"GPU"}
            value={values.gpu}
            touched={touched.gpu}
            errors={errors.gpu}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"hardDrive"}
            label={"Hard Drive"}
            value={values.hardDrive}
            touched={touched.hardDrive}
            errors={errors.hardDrive}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"hardDriveSizeInGb"}
            label={"Drive Capacity (GB)"}
            value={values.hardDriveSizeInGb}
            touched={touched.hardDriveSizeInGb}
            errors={errors.hardDriveSizeInGb}
            inputActive={isInputActive}
          />
          <AttributeField
            name={"year"}
            label={"Year"}
            value={values.year}
            touched={touched.year}
            errors={errors.year}
            inputActive={isInputActive}
          />
        </List>
      </Form>
    </Box>
  );
}
