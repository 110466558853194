import axios, { CancelTokenSource } from "axios";
import _ from "lodash";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import { Contact } from "types/contact";
import TokenService from "./token.service";

export const HOSTNAME = process.env.REACT_APP_API_BASE_URL || "";

export const api = axios.create({
  baseURL: `${HOSTNAME}api/admin/v1`,
});

const requestCancelSourcesByKey: { [cancelKey: string]: CancelTokenSource } = {};

export const cancelRequest = (cancelKey: string) => {
  const cancelSource = requestCancelSourcesByKey[cancelKey];
  if (cancelSource) {
    cancelSource.cancel();

    delete requestCancelSourcesByKey[cancelKey];
  }
};

export const emptyStringsToNull = (data: Contact) => {
  return _.mapValues(data, (value) => (value === "" ? null : value));
};

api.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    if (config.cancelKey) {
      cancelRequest(config.cancelKey);
      const newCancelSource = axios.CancelToken.source();
      requestCancelSourcesByKey[config.cancelKey] = newCancelSource;
      config.cancelToken = newCancelSource.token;
    }
    if (config.data && config.headers["Content-Type"] !== "multipart/form-data") {
      config.data = objectToSnake(config.data);
      if (config.data.donation_request?.address_2) {
        config.data.donation_request.address2 = config.data.donation_request.address_2;
        delete config.data.donation_request.address_2;
      }

      if (config.data.location?.address_2) {
        config.data.location.address2 = config.data.location.address_2;
        delete config.data.location.address_2;
      }
      if (config.data.contact) {
        config.data.contact = emptyStringsToNull(config.data.contact);
      }
    }
    if (TokenService.get()) config.headers.Authorization = `Bearer ${TokenService.get()}`;
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    return { ...response, data: objectToCamel(response.data) };
  },
  function (error) {
    if (error.response?.status === 401) {
      TokenService.remove();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);
export const fetcher = (url: string) => api.get(url).then((res) => res.data);

export const collectionFetcher = (url: string) =>
  fetcher(url).then((data) => {
    const collectionKey = Object.keys(data).find((key) => key !== "meta");
    if (!collectionKey) throw new Error("Unable to extract collection key from response");
    return data[collectionKey];
  });
