import { Box, Button, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import useBenefitingOrganizations from "hooks/useBenefitingOrganizations";
import useDonationRequests from "hooks/useDonationRequests";
import { useNavigate } from "react-router-dom";
import { donationRequestDefault, donationRequestSchema } from "types/donationRequest";
import DonationRequestForm from "./DonationRequestForm";

export default function EditDonationRequest() {
  const { updateDonationRequest, donationRequest, setKey, key, deleteHeroImage } = useDonationRequests();
  const { benefitingOrganizations, getBenefitingOrganization, benefitingOrganization } = useBenefitingOrganizations({
    returnAllResults: true,
  });
  const { palette } = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: 6,
        minHeight: "calc(100vh - 64px)",
        lineHeight: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Donation Requests
      </Typography>
      <Typography>
        Fill out the fields below to update a donation request to be displayed in our Revivn platform feed.
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${palette.divider}`,
        }}
      >
        <Formik
          enableReinitialize
          validationSchema={donationRequestSchema}
          initialValues={donationRequest || donationRequestDefault}
          onSubmit={(values) => {
            updateDonationRequest(values);
            navigate(-1);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Box textAlign="right"></Box>
              <DonationRequestForm
                getBenefitingOrganization={getBenefitingOrganization}
                benefitingOrganization={benefitingOrganization}
                benefitingOrganizations={benefitingOrganizations}
                donationRequest={donationRequest}
                setKey={setKey}
                signedKey={key}
                deleteHeroImage={deleteHeroImage}
              />
              <Box display={"flex"} justifyContent={"center"} sx={{ gap: 2 }}>
                <Button variant="contained" type="reset" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
