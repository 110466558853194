import { FileDownload } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";
import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import ListingsDataGrid from "components/Listings/ListingsDataGrid";
import useTableExport from "hooks/useTableExport";
import { Route, Routes } from "react-router-dom";
import { Crumb } from "types/crumb";

const IndexElement = ({ crumbs }: { crumbs: Crumb[] }) => {
  const { isLoading, confirmExportModal, setOpenConfirmModal } = useTableExport({ url: `/listings_export` });

  return (
    <>
      {confirmExportModal}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ApplicationBreadCrumbs crumbs={crumbs} />
        </Grid>

        <Grid container justifyContent="flex-end" xs={6} sx={{ my: 1 }}>
          <Button
            variant="contained"
            startIcon={isLoading ? <CircularProgress size={24} /> : <FileDownload />}
            onClick={() => setOpenConfirmModal(true)}
            disabled={isLoading}
          >
            Export Listings
          </Button>
        </Grid>

        <ListingsDataGrid />
      </Grid>
    </>
  );
};

export default function ListingsPage() {
  const crumbs: Crumb[] = [{ name: "Listings", path: "/listings" }];

  return (
    <Routes>
      <Route index element={<IndexElement crumbs={crumbs} />} />
    </Routes>
  );
}
