import { Button } from "@mui/material";
import { theme } from "index";
import React from "react";

export function GreyButton({ onClick, children }: { onClick: () => void; children: React.ReactNode }) {
  return (
    <Button variant="contained" onClick={onClick} sx={{ backgroundColor: theme.palette.grey["500"] }}>
      {children}
    </Button>
  );
}
