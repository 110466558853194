import { useState } from "react";
import { Box, Paper, Button, Typography, Divider, Grid, Link, CircularProgress } from "@mui/material";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import useInventoryUploads from "hooks/useInventoryUploads";

export default function InventoryPricer() {
  const { downloadLink, createInventoryPricerUpload } = useInventoryUploads();
  const [clicked, setClicked] = useState<Boolean>(false);

  const handleClick = (event: any) => {
    setClicked(true);
    const file = event.target.files[0];
    createInventoryPricerUpload(file);
  };

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h6" sx={{ my: 1, mx: 1 }}>
          Inventory Pricer
        </Typography>
        <Divider />
        <Grid container sx={{ padding: "20px 0" }} spacing={2} direction="row">
          <Grid item>
            <Button disabled={clicked && downloadLink == " "} variant="contained" component="label" color="primary">
              <FileUploadIcon /> Upload New File
              <input id="upload-image" hidden accept="csv/*" type="file" onChange={handleClick} />
            </Button>
          </Grid>
          <Grid item>
            <Typography>When finished processing, your file will be available for download here: </Typography>
            {clicked && downloadLink == " " && <CircularProgress sx={{ mt: 3 }} />}
            {downloadLink !== " " && (
              <Link sx={{ mt: 3, fontSize: "18px" }} href={`${downloadLink}`} target="_blank" download>
                Download
              </Link>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
