import { Flag, RemoveCircle } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { audioSources } from "constants/audio_sources";
import useBeforeUnload from "hooks/useBeforeUnload";
import useDirectUpload from "hooks/useDirectUpload";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useInventory from "hooks/useInventory";
import useOCR from "hooks/useOCR";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Webcam from "react-webcam";
import { api } from "services/api.service";
import { Inventory } from "types/inventory";
import { base64ImageToFile } from "utils/blob";
import SerialValidation from "./SerialValidation";

type SerialOCRData = {
  inventory: Inventory;
  serial: string;
  images: string[];
  flagValue: string | null;
  isFlagged?: boolean;
};

export default function SerialOCR() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const QrReader = require("react-qr-scanner");
  const { getInventoryById, updateInventory } = useInventory();
  const { getVision } = useOCR();
  const { uploadSync } = useDirectUpload({ keyPrefix: "inventory_images/", service: "amazon_public" });
  const [targetId, setTargetId] = useState<string>("");
  const [tableData, setTableData] = useState<SerialOCRData[]>([]);
  const [getWaitingForResponse, setWaitingForResponse] = useState<boolean>(false);
  const [getCameraSources, setCameraSources] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string>("");
  const [showSettings, setShowSettings] = useState<boolean>(true);
  const [enableOCR, setEnableOCR] = useState<boolean>(false);
  const [enableClearOnCapture, setEnableClearOnCapture] = useState<boolean>(true);
  const [clearOnCaptureCount, setClearOnCaptureCount] = useState<number>(0);
  const [clearOnCaptureMaxCount, setClearOnCaptureMaxCount] = useState<number>(1);
  const [enableSounds, setEnableSounds] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [csvFileName, setCsvFileName] = useState<string | null>(null);
  const [csvData, setCsvData] = useState<string[]>([]);
  const { enabled } = useFeatureFlag("serial_ocr_csv_flagging");
  const webcamRef = useRef<Webcam | null>(null);
  const onCaptureAudioElement = useMemo(() => new Audio(audioSources.onCaptureReady), []);
  useBeforeUnload(tableData.length > 0);

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      const devices = mediaDevices.filter((device: MediaDeviceInfo) => device.kind === "videoinput");
      setCameraSources(devices);
      setActiveDeviceId(devices[0].deviceId);
    },
    [setCameraSources],
  );

  const handleDeviceChange = useCallback((event: SelectChangeEvent) => {
    setActiveDeviceId(event.target.value);
  }, []);

  const fetchDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => fetchDevices(), [fetchDevices]);

  const getEntry = useCallback(
    (getTargetId: string) => {
      return tableData.find((entry) => +getTargetId == entry.inventory.id);
    },
    [tableData],
  );

  const increaseCaptureCount = useCallback(() => {
    if (enableClearOnCapture) {
      const count = clearOnCaptureCount + 1;
      if (count >= clearOnCaptureMaxCount) {
        setTargetId("");
        setClearOnCaptureCount(0);
      } else {
        setClearOnCaptureCount(count);
      }
    }
  }, [clearOnCaptureCount, clearOnCaptureMaxCount, enableClearOnCapture]);

  const updateEntry = useCallback(
    (id: string, serial?: string, images?: string[], flagValue?: string | null, isFlagged?: boolean) => {
      const newData = [...tableData];
      const index = newData.findIndex((entry) => +id == entry.inventory.id);

      if (serial) newData[index].serial = serial;
      if (images) newData[index].images.push(...images);
      if (flagValue) newData[index].flagValue = flagValue;
      if (isFlagged) newData[index].isFlagged = isFlagged;
      setTableData(newData);
    },
    [tableData],
  );

  const createEntry = useCallback(
    async (
      id: string,
      serial?: string,
      images?: string[],
      inventory?: Inventory,
      flagValue?: string | null,
      isFlagged?: boolean,
    ) => {
      if (getEntry(id)) {
        updateEntry(id, serial, images, flagValue, isFlagged);
      } else {
        if (inventory) {
          setTableData((prevState) => [
            {
              inventory: inventory,
              serial: serial ?? inventory.serialNumber ?? "",
              images: images ?? [],
              flagValue: flagValue ?? null,
              isFlagged: isFlagged,
            },
            ...prevState,
          ]);
        }
      }
    },
    [getEntry, updateEntry],
  );

  const onCapture = useCallback(
    async (imgSrc: string) => {
      const entry: SerialOCRData | undefined = getEntry(targetId);
      let invObj: Inventory | undefined;
      let ocrResult: string | undefined;
      setWaitingForResponse(true);
      if (!entry) {
        invObj = await getInventoryById(targetId);
        if (!invObj) return;
      }
      if (enableOCR && (entry?.serial === "" || !entry)) {
        ocrResult = await getVision(imgSrc);
      }

      await createEntry(targetId!, ocrResult, [imgSrc], invObj ?? undefined);
      setWaitingForResponse(false);
      increaseCaptureCount();
    },
    [createEntry, enableOCR, getEntry, getInventoryById, getVision, increaseCaptureCount, targetId],
  );

  const removeEntry = (idx: number) => {
    const newData = [...tableData];
    newData.splice(idx, 1);
    setTableData(newData);
  };

  const removePhoto = (idx: number, pIdx: number) => {
    const newData = [...tableData];
    newData[idx].images.splice(pIdx, 1);
    setTableData(newData);
  };

  const handleSave = () => {
    setIsSaving(true);
    tableData.map((data) => {
      const blobPromises = data.images.map((image) => {
        // Convert base64 to File
        const file: File = base64ImageToFile(image);
        // Upload file
        return uploadSync(file);
      });

      Promise.all(blobPromises).then((blobs) => {
        const images = blobs.map((blob) => blob.signed_id);
        api
          .post("/inventory_images", { inventory_image: { inventory_id: data.inventory.id, images } })
          .then((inventoryImages) => {
            inventoryImages.data.inventoryImage.map(
              (inventoryImage: {
                id: number;
                inventory_id: number;
                url: string;
                created_at: string;
                updated_at: string;
              }) => data.inventory.inventoryImages.push(inventoryImage),
            );
          });
      });
      // Update inventory with new data.
      updateInventory({
        ...data.inventory,
        serialNumber: data.serial ?? data.inventory.serialNumber,
        comments: `${data.inventory.comments ?? ""} ${data.isFlagged ? "OCR flagged for on hold: " : ""}${data.flagValue ?? ""}`,
        tags: data.inventory.tags?.concat(data.isFlagged ? [{ id: 0, name: "OCR_FLAGGED" }] : []),
      });
    });
    setIsSaving(false);
    setTableData([]);
  };

  const capture = useCallback(() => {
    if (targetId != "") {
      onCapture(webcamRef.current?.getScreenshot() ?? "");
    }
  }, [targetId, onCapture]);
  useHotkeys("mod+g", capture, { enableOnFormTags: ["INPUT"], preventDefault: true });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      setCsvFileName(e.target.files[0].name);
      reader.readAsText(e.target.files[0], "UTF-8");
      reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
        if (readerEvent?.target?.result) {
          const data = readerEvent.target.result.toString().split("\n");

          // Remove headers
          data.shift();

          // Remove empty rows
          const filteredData = data.filter((row) => row.trim() !== "");

          // Get only the first column
          const mappedFilteredData = filteredData.map((row) => {
            return row.split(",")[0];
          });

          // Remove duplicates, sort alphabetically
          const uniqueData = Array.from(new Set(mappedFilteredData)).sort();

          // Sort alphabetically
          setCsvData(uniqueData);
        }
      };
    }
  };

  function OCRFlag({
    compareList,
    value,
    setValues,
  }: {
    compareList: string[];
    value: string | null;
    setValues: (arg1: string | null, arg2: boolean) => void;
  }) {
    const handleChange = (_event: SyntheticEvent<Element, Event>, newValue: string | null) => {
      setValues(newValue, compareList.includes(newValue ?? ""));
    };

    return (
      <Box display="flex" gap={4} alignItems="end">
        <Autocomplete
          autoSelect
          freeSolo
          options={compareList}
          groupBy={(option) => option[0].toUpperCase()}
          size="small"
          renderInput={(params) => <TextField {...params} label="Registered Name" variant="standard" />}
          onChange={handleChange}
          sx={{ minWidth: "10em", width: "15em" }}
          value={value}
        />
        {value && (
          <Chip
            icon={<Flag />}
            color={compareList.includes(value) ? "warning" : "success"}
            label={compareList.includes(value) ? "Exact match found" : "No matches found"}
          />
        )}
      </Box>
    );
  }

  return (
    <Box component={Paper} padding={1}>
      <Box display="flex" flexDirection="row" alignItems={"center"} gap={1} py={1}>
        <TextField
          label="Inventory ID"
          onChange={(e) => setTargetId(e.target.value)}
          size="small"
          value={targetId}
          InputLabelProps={{ shrink: targetId != "" }}
        />
        <Button onClick={() => setTargetId("")}>Clear</Button>
        <Button variant="outlined" onClick={() => setShowSettings((val) => !val)}>
          {showSettings ? "Hide" : "Show"} settings
        </Button>
        <Button onClick={handleSave} disabled={isSaving} color="primary" variant="outlined">
          Save
        </Button>
      </Box>
      <Divider />
      <Box display="flex" width="100%" gap={1} py={1} justifyContent={"center"}>
        <QrReader
          onScan={(result: { text: string }) => {
            if (!!result) {
              if (targetId == "") {
                setTargetId(result.text);
                if (enableSounds) {
                  onCaptureAudioElement.play();
                }
              }
            }
          }}
          delay={1000}
          constraints={{
            video: {
              deviceId: {
                exact: activeDeviceId,
              },
            },
          }}
          style={{ width: 0, height: 0 }}
        />
        <Box
          position="relative"
          width={showSettings ? 400 : 800}
          height={showSettings ? 300 : 600}
          minWidth={400}
          minHeight={300}
          maxWidth={800}
          maxHeight={600}
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            width={"100%"}
            height={"100%"}
            minScreenshotWidth={1280}
            minScreenshotHeight={720}
            videoConstraints={{
              width: 800,
              height: 600,
              deviceId: activeDeviceId,
            }}
            style={{ position: "relative" }}
          >
            {({ getScreenshot }) => (
              <Button
                onClick={() => {
                  const imgSrc = getScreenshot();
                  onCapture(imgSrc ?? "");
                }}
                color="primary"
                disabled={targetId == ""}
                size="large"
                startIcon={<PhotoCameraIcon />}
                variant="contained"
                sx={{
                  position: "absolute",
                  left: 16,
                  bottom: 16,
                  maxWidth: 400,
                  borderRadius: 8,
                }}
              >
                {targetId == "" ? "Scanning for QR Label" : "Capture"}
              </Button>
            )}
          </Webcam>
        </Box>
        {showSettings ? (
          <Card variant="outlined" sx={{ flex: 1 }}>
            <List subheader={<ListSubheader>Settings</ListSubheader>}>
              <ListItem>
                <ListItemText primary="Camera Source" />
                <Select size="small" value={activeDeviceId} placeholder="Camera Source" onChange={handleDeviceChange}>
                  {getCameraSources.map((device) => (
                    <MenuItem value={device.deviceId}>{device.label}</MenuItem>
                  ))}
                </Select>
                <IconButton onClick={fetchDevices}>
                  <RefreshIcon />
                </IconButton>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Clear ID after{" "}
                      <TextField
                        type="number"
                        size="small"
                        variant="standard"
                        value={clearOnCaptureMaxCount}
                        onChange={(e) => setClearOnCaptureMaxCount(+e.target.value)}
                        inputProps={{
                          align: "right",
                          inputMode: "numeric",
                          min: 1,
                          max: 10,
                          maxLength: 2,
                        }}
                      />{" "}
                      captures
                    </>
                  }
                />
                <Switch edge="end" checked={enableClearOnCapture} onChange={(e, c) => setEnableClearOnCapture(c)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Autofill serial number (OCR)" />
                <Switch edge="end" checked={enableOCR} onChange={(e, c) => setEnableOCR(c)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Enable sound effects" />
                <Switch edge="end" checked={enableSounds} onChange={(e, c) => setEnableSounds(c)} />
              </ListItem>
              {enabled && (
                <ListItem>
                  <ListItemText
                    primary="Import CSV"
                    secondary={csvFileName ? `${csvFileName} - ${csvData.length} rows` : "No file selected"}
                  />
                  <Button component="label" role={undefined} variant="contained" tabIndex={-1}>
                    Upload
                    <input
                      style={{
                        clip: "rect(0 0 0 0)",
                        clipPath: "inset(50%)",
                        height: 1,
                        overflow: "hidden",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        whiteSpace: "nowrap",
                        width: 1,
                      }}
                      type="file"
                      accept=".csv"
                      id="csv-input"
                      onChange={(e) => e.target.files && handleFileChange(e)}
                    />
                  </Button>
                </ListItem>
              )}
            </List>
          </Card>
        ) : null}
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Images</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getWaitingForResponse ? (
              <TableRow>
                <TableCell />
                <TableCell>
                  <Typography variant="caption">Scanning...</Typography>
                  <LinearProgress />
                </TableCell>
                <TableCell />
              </TableRow>
            ) : null}
            {tableData.map((row, idx) => (
              <TableRow key={row.inventory.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.inventory.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.inventory.label}
                </TableCell>
                <TableCell>
                  <Box>
                    <Box>
                      <TextField
                        value={row.serial}
                        onChange={(event) => {
                          updateEntry(row.inventory.id.toString(), event.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{<SerialValidation serial={row.serial} />}</InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="standard"
                      />
                      <IconButton size="small" color="error" onClick={() => removeEntry(idx)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    {enabled && csvData.length > 0 && (
                      <OCRFlag
                        compareList={csvData}
                        value={row.flagValue}
                        setValues={(value, state) =>
                          updateEntry(row.inventory.id.toString(), undefined, undefined, value, state)
                        }
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box display="flex" flexDirection="row" gap={1} overflow={"scroll"} padding={1} gridRow={1}>
                    {row.images.map((photo, pIdx) => (
                      <Tooltip
                        disableFocusListener
                        placement="left"
                        title={
                          <img
                            src={photo}
                            width={250}
                            height={250}
                            style={{ paddingTop: 4, borderRadius: 4, objectFit: "cover" }}
                          />
                        }
                      >
                        <Box position="relative">
                          <img
                            src={photo}
                            width={80}
                            height={60}
                            style={{ borderRadius: 2, outline: "1px solid black", objectFit: "cover" }}
                          />
                          <IconButton
                            onClick={() => removePhoto(idx, pIdx)}
                            color="error"
                            sx={{ position: "absolute", top: -4, right: -4 }}
                          >
                            <RemoveCircle fontSize="small" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
