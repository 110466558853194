import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { InventoryTemplate } from "types/inventoryTemplate";
import { useAlert } from "./useAlert";

export default function useInventoryTemplates() {
  const [inventoryTemplates, setInventoryTemplates] = useState<InventoryTemplate[]>([]);
  const [meta, setMeta] = useState<{ totalPages: number; count: number }>({ totalPages: 0, count: 0 });
  const [inventoryTemplate, setInventoryTemplate] = useState<InventoryTemplate>();
  const { alertSuccess, alertError } = useAlert();
  const params = useParams();
  const { search } = useLocation();

  useEffect(() => {
    api.get(`/inventory_templates${search}`).then(({ data }) => {
      setInventoryTemplates(data.inventoryTemplates);
      setMeta(data.meta);
    });
  }, [search]);

  useEffect(() => {
    if (params.templateUuid) {
      getInventoryTemplate();
    }
  }, [params.templateUuid]);

  const getInventoryTemplates = () => {
    api.get(`/inventory_templates${search}`).then(({ data }) => {
      setInventoryTemplates(data.inventoryTemplates);
      setMeta(data.meta);
    });
  };

  const getInventoryTemplate = () => {
    api.get(`/inventory_templates/${params.templateUuid}`).then(({ data }) => {
      setInventoryTemplate(data);
    });
  };

  const searchInventoryTemplates = (query: string) => {
    api.get(`/inventory_templates_search?q=${query}`).then(({ data }) => {
      setInventoryTemplates(data.inventoryTemplates);
      setMeta(data.meta);
    });
  };

  const editInventoryTemplate = useCallback((formData: InventoryTemplate) => {
    return api
      .patch(`/inventory_templates/${formData.uuid}`, {
        inventory_template: formData,
      })
      .then(() => {
        alertSuccess("This inventory template was successfully saved.");
      })
      .catch(() => {
        alertError("Something went wrong, please try again");
      });
  }, []);

  const createInventoryTemplate = (formData: InventoryTemplate) => {
    return api
      .post(`/inventory_templates`, {
        inventory_template: formData,
      })
      .then(() => {
        alertSuccess("This inventory template was successfully saved.");
      })
      .catch(() => {
        alertError("Something went wrong, please try again");
      });
  };

  const deleteInventoryTemplate = useCallback((uuid: string) => {
    return api
      .delete(`/inventory_templates/${uuid}`)
      .then(() => {
        getInventoryTemplates();
      })
      .then(() => {
        alertSuccess("This inventory template was successfully deleted.");
      })
      .catch(() => {
        alertError("Something went wrong, please try again");
      });
  }, []);

  return {
    inventoryTemplates,
    inventoryTemplate,
    setInventoryTemplates,
    setInventoryTemplate,
    createInventoryTemplate,
    editInventoryTemplate,
    searchInventoryTemplates,
    deleteInventoryTemplate,
    meta,
  };
}
