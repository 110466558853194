import { Box, Divider, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { useState } from "react";
import useInventory from "hooks/useInventory";
import useInventoryTemplates from "hooks/useInventoryTemplates";
import InventoryAddField from "./InventoryAddField";
import { defaultInventoryAdd, InventoryAddProps } from "../../types/inventory";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledButton } from "./InventoryStyles";
import { InventoryTemplate } from "types/inventoryTemplate";
import URLHashParams from "utils/URLHashParams";

const newTemplate: InventoryTemplate = {
  uuid: "",
  manufacturer: "",
  model: "",
  name: "",
  description: "",
  cpu: "",
  ram: "",
  hardDrive: "",
  screenSize: "",
  isBulk: false,
  deviceType: "",
};

export default function InventoriesNew() {
  const location = useLocation();
  const navigate = useNavigate();

  const { createInventory, isLoading } = useInventory();
  const { searchInventoryTemplates, inventoryTemplates, setInventoryTemplates } = useInventoryTemplates();
  const [inventories, setInventories] = useState<InventoryAddProps[]>([defaultInventoryAdd()]);

  const addInventory = (newInventory: InventoryAddProps) => {
    setInventories([...inventories, newInventory]);
  };

  const handleSubmit = () => {
    createInventory({
      inventories,
    });
  };

  const handleCreatedInventoryTemplate = (inventoryTemplate: InventoryTemplate) => {
    setInventoryTemplates((inventoryTemplates) => [inventoryTemplate, ...inventoryTemplates]);
    const newLocation = { ...location };
    const hash = new URLHashParams(newLocation.hash);
    hash.delete("newTemplate");
    newLocation.hash = hash.toString();
    navigate(newLocation, { replace: true });
  };

  return (
    <Box>
      <Paper variant="outlined" sx={{ mx: 10, my: 8 }}>
        <Typography variant="h6" sx={{ mx: 1, my: 1 }}>
          Add Pickup Inventory
        </Typography>
        <Divider />
        {isLoading ? <LinearProgress /> : null}
        <InventoryAddField
          addInventory={addInventory}
          inventory={inventories[inventories.length - 1]}
          inventories={inventories}
          inventoryTemplates={inventoryTemplates}
          setInventories={setInventories}
          searchInventoryTemplates={searchInventoryTemplates}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <StyledButton disabled={isLoading} variant="outlined" onClick={() => navigate(-1)}>
            Back
          </StyledButton>
          <StyledButton disabled={isLoading} variant="contained" onClick={handleSubmit}>
            Save
          </StyledButton>
        </Box>
      </Paper>
    </Box>
  );
}
