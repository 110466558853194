import { Chip } from "@mui/material";

const stateLabel: any = {
  missing_info: "Missing Info",
  pending_confirmation: "Pending Confirmation",
  canceled: "Canceled",
  scheduled: "Scheduled",
  processing: "Processing",
  on_hold: "On Hold",
  verifying: "Verifying",
  initial_report_ready: "Initial Report Ready",
  complete: "Complete",
  draft: "Draft",
  archived: "Archived",
};

interface Props {
  pickupState: string;
}

export default function PickupStateChip({ pickupState }: Props) {
  const label = stateLabel[pickupState];

  return (
    <Chip
      label={label}
      sx={[
        pickupState === "missing_info" && { backgroundColor: "#FFE0E0", color: "#AD453D" },
        pickupState === "pending_confirmation" && { backgroundColor: "#e1e3e1", color: "#414241" },
        pickupState === "scheduled" && { backgroundColor: "#DDF3FC", color: "#074E68" },
        pickupState === "canceled" && { backgroundColor: "#202020", color: "#FFF" },
        pickupState === "processing" && { backgroundColor: "#FFF7EB", color: "#ED6C02" },
        pickupState === "initial_report_ready" && { backgroundColor: "#EFEDFF", color: "#230C52" },
        pickupState === "on_hold" && { backgroundColor: "rgba(255, 214, 0, 0.17)", color: "#C77700" },
        pickupState === "verifying" && { backgroundColor: "rgba(255, 214, 0, 0.17)", color: "#C77700" },
        pickupState === "complete" && { backgroundColor: "#EDF6ED", color: "#4CAF50" },
        pickupState === "draft" && { backgroundColor: "#F3FA76", color: "#94A30D" },
        pickupState === "archived" && { backgroundColor: "#e1e3e1", color: "#414241" },
      ]}
    />
  );
}
