import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react";
import { audioSources } from "constants/audio_sources";

type AsyncHandler = (() => Promise<void>) | (() => void);

type ConfirmationContextValue = {
  showDialog: Dispatch<SetStateAction<boolean>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setContent: Dispatch<SetStateAction<string>>;
  setHandler: Dispatch<SetStateAction<AsyncHandler>>;
  setContext: Dispatch<SetStateAction<string>>;
};

const ConfirmationContext = createContext<ConfirmationContextValue>({
  showDialog: () => {},
  setHandler: () => {},
  setContent: () => {},
  setTitle: () => {},
  setContext: () => {},
});

export const ConfirmationProvider = ({ children }: { children: React.ReactNode }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [handler, setHandler] = useState<AsyncHandler>(() => {});
  const [title, setTitle] = useState("Confirmation");
  const [content, setContent] = useState("Are you sure?");
  const [context, setContext] = useState("");

  const audioElement: HTMLAudioElement = new Audio(audioSources.error);
  const alertOnContext = ["skip_user_warehouse", "skip_state"];
  const whitelistedKeys = ["Escape", "Tab", "Shift", "Control", "Alt", "Meta", " ", "Enter"];

  const handleConfirm = useCallback(async () => {
    await handler();
  }, [handler]);

  const handleInput = (ke: KeyboardEvent) => {
    if (!whitelistedKeys.includes(ke.key)) {
      (document.activeElement as HTMLElement)?.blur();
      audioElement.play();
    }
  };

  const handleClose = () => {
    setShowConfirmation(false);
    setContext("");
    window.removeEventListener("keydown", handleInput);
  };

  useEffect(() => {
    if (showConfirmation && alertOnContext.includes(context)) {
      window.addEventListener("keydown", handleInput);
    } else {
      window.removeEventListener("keydown", handleInput);
    }
  });

  return (
    <ConfirmationContext.Provider
      value={{ showDialog: setShowConfirmation, setHandler, setContent, setTitle, setContext }}
    >
      {children}
      <Dialog open={showConfirmation} onClose={handleClose} data-testid="confirmation-dialog" fullWidth>
        <DialogTitle data-testid="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText data-testid="confirmation-dialog-message">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} data-testid="confirmation-dialog-cancel-button">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
            data-testid="confirmation-dialog-confirm-button"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => {
  const { showDialog, setHandler, setTitle, setContent, setContext } = useContext(ConfirmationContext);
  const confirm = useCallback(
    ({
      handler,
      message,
      title,
      context,
    }: {
      handler: AsyncHandler;
      message?: string;
      title?: string;
      context?: string;
    }) => {
      setHandler(() => handler);
      if (message) setContent(message);
      if (title) setTitle(title);
      if (context) setContext(context);

      showDialog(true);
    },
    [setHandler, showDialog]
  );

  return {
    confirm,
  };
};
