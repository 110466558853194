import { Button, CircularProgress, Grid } from "@mui/material";
import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import ProductCatalogsDataGrid from "components/ProductCatalogs/ProductCatalogsDataGrid";
import { Route, Routes } from "react-router-dom";
import { Crumb } from "types/crumb";
import UploadIcon from "@mui/icons-material/Upload";
import { useState } from "react";
import UploadPricingModal from "components/ProductCatalogs/UploadPricingModal";
import { FileDownload } from "@mui/icons-material";
import useTableExport from "hooks/useTableExport";

const IndexElement = ({ crumbs }: { crumbs: Crumb[] }) => {
  const [open, setOpen] = useState(false);
  const { isLoading, confirmExportModal, setOpenConfirmModal } = useTableExport({ url: "/product_catalogs_export" });
  return (
    <>
      {open && <UploadPricingModal open={open} setOpen={setOpen} />}
      {confirmExportModal}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ApplicationBreadCrumbs crumbs={crumbs} />
        </Grid>

        <Grid container justifyContent="flex-end" xs={6} sx={{ my: 1 }}>
          <Button variant="contained" color="primary" startIcon={<UploadIcon />} onClick={() => setOpen(true)}>
            Upload Pricing
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={isLoading ? <CircularProgress size={24} /> : <FileDownload />}
            disabled={isLoading}
            onClick={() => setOpenConfirmModal(true)}
            sx={{ ml: 1 }}
          >
            Export Product Catalogs
          </Button>
        </Grid>
        <ProductCatalogsDataGrid />
      </Grid>
    </>
  );
};

export default function ProductCatalogsPage() {
  const crumbs: Crumb[] = [{ name: "Product Catalogs", path: "/product_catalogs" }];

  return (
    <Routes>
      <Route index element={<IndexElement crumbs={crumbs} />} />
    </Routes>
  );
}
