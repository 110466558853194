import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "hooks/useAuth";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  );
}
