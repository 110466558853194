import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Inventory } from "types/inventory";
import { useAlert } from "./useAlert";
import { useConfirmation } from "./useConfirmation";
import { useInventoryType } from "hooks/useInventory";
import { warehouseLocationValidation } from "validations/inventory";

export default function useBulkInventory(updatedInventories: useInventoryType["updatedInventories"]) {
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const { alertSuccess, alertError } = useAlert();
  const { confirm } = useConfirmation();
  const { search } = useLocation();
  const [selectedInventories, setSelectedInventories] = useState<number[]>([]);

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { handleSubmit, getFieldProps, setFieldValue } = useFormik({
    initialValues: { location: query.get("warehouse_location") ?? "" },
    validationSchema: warehouseLocationValidation,
    onSubmit: (values) => {
      confirm({
        message: `Are you sure you want to move item to "${values.location}"`,
        handler: () => {
          moveInventories(values.location);
        },
      });
    },
  });

  const moveInventories = useCallback(
    (location: string) => {
      const payload = selectedInventories.map((inventoryId) => {
        return { id: inventoryId, warehouseLocation: location };
      });
      return updatedInventories(payload)
        .then(() => {
          alertSuccess("Items have been moved successfully");
          setSelectedInventories([]);
        })
        .catch((error) => {
          alertError(error);
        });
    },
    [selectedInventories, setInventories],
  );

  const handleExScanInput = useCallback((event, key, formikHandler) => {
    event.persist();
    const value = event.target.value;
    try {
      const url = new URL(value);
      event.target.value = url.searchParams.get(key);
      formikHandler(event);
    } catch (_) {
      formikHandler(event);
    }
  }, []);

  return {
    inventories,
    selectedInventories,
    setSelectedInventories,
    submitForm: handleSubmit,
    handleExScanInput,
    locationInputProps: getFieldProps("location"),
    setLocation: (value: string) => setFieldValue("location", value),
  };
}
