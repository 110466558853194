import { Button } from "@mui/material";
import openInventoryPhotoPicker from "utils/openInventoryPhotoPicker";
import React from "react";
import { Inventory, InventoryImage } from "types/inventory";
import { useIsLocal } from "hooks/useIsLocalhost";

export interface CapturePhotoButtonProps extends React.ComponentProps<typeof Button> {
  inventory: Inventory;
  onImageCaptured: (image: InventoryImage) => void;
  children: React.ReactNode;
}

export function CapturePhotoButton({ inventory, onImageCaptured, children, ...buttonProps }: CapturePhotoButtonProps) {
  const isLocal = useIsLocal();
  return (
    <>
      {isLocal && (
        <Button
          onClick={() => {
            onImageCaptured({
              id: 1234,
              url: "https://via.placeholder.com/150",
            });
          }}
          {...buttonProps}
        >
          Bypass Photo Capture
        </Button>
      )}
      <Button
        onClick={() => {
          openInventoryPhotoPicker(
            inventory,
            (image) => {
              onImageCaptured(image);
            },
            {
              fromSources: ["local_file_system", "webcam"],
            },
          );
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    </>
  );
}
