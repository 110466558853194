import { useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Task } from "types/task";

export default function useInventoryTasks() {
  const params = useParams();
  const [inventoryTasks, setInventoryTasks] = useState<Task[]>();
  const [isLoading, setLoading] = useState(false);

  const getInventoryTasks = (warehousePhase: string) => {
    setLoading(true);
    api
      .get(`/pickups/${params.pickupId}/inventory_tasks`, { params: { warehouse_phase: warehousePhase } })
      .then(({ data }) => {
        setInventoryTasks(data.tasks);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createInventoryTasks = () => {
    api.post(`/pickups/${params.pickupId}/inventory_tasks`, { pickupId: params.pickupId }).catch((err) => {
      console.error(err);
    });
  };

  return {
    inventoryTasks,
    getInventoryTasks,
    createInventoryTasks,
    isLoading,
  };
}
