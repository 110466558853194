import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import QRCodeComp from "qrcode.react";
import { Inventory } from "types/inventory";

export default function QRCode({ inventory }: { inventory: Inventory }) {
  return (
    <Box sx={{ width: "fit-content" }}>
      <QRCodeComp value={inventory.id.toString()} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{inventory.pickup.id}</Typography>
        <Typography> - </Typography>
        <Typography> {inventory.lineId}</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{inventory.manufacturer}</Typography>
        <Typography> - </Typography>
        <Typography>{inventory.deviceType}</Typography>
      </Box>
    </Box>
  );
}
