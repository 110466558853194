import { Box, Button, Divider, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import { WebcamComponent } from "components/WebcamComponent";
import { Dispatch, SetStateAction, useMemo } from "react";

type CameraSetupProps = {
  devices: MediaDeviceInfo[];
  setShowConfigurationPanel: Dispatch<SetStateAction<boolean>>;
  frontDevice: MediaDeviceInfo | null;
  setFrontDevice: Dispatch<SetStateAction<MediaDeviceInfo | null>>;
  leftDevice: MediaDeviceInfo | null;
  setLeftDevice: Dispatch<SetStateAction<MediaDeviceInfo | null>>;
  rightDevice: MediaDeviceInfo | null;
  setRightDevice: Dispatch<SetStateAction<MediaDeviceInfo | null>>;
  topDevice: MediaDeviceInfo | null;
  setTopDevice: Dispatch<SetStateAction<MediaDeviceInfo | null>>;
  heroDevice: MediaDeviceInfo | null;
  setHeroDevice: Dispatch<SetStateAction<MediaDeviceInfo | null>>;
};

export default function CameraSetup({
  devices,
  setShowConfigurationPanel,
  frontDevice,
  setFrontDevice,
  leftDevice,
  setLeftDevice,
  rightDevice,
  setRightDevice,
  topDevice,
  setTopDevice,
  heroDevice,
  setHeroDevice,
}: CameraSetupProps) {
  const deviceOptions = useMemo(
    () =>
      devices.map((device: MediaDeviceInfo, key: number) => (
        <MenuItem key={key} value={device.deviceId}>
          {device.label}
        </MenuItem>
      )),
    [devices],
  );

  const SelectDevicePosition = useMemo(
    () =>
      ({
        label,
        deviceMap,
        setStateAction,
      }: {
        label: string;
        deviceMap: MediaDeviceInfo | null;
        setStateAction: Dispatch<SetStateAction<MediaDeviceInfo | null>>;
      }): JSX.Element => (
        <TextField
          select
          label={label}
          size="small"
          sx={{ width: 250 }}
          value={deviceMap ? deviceMap.deviceId : ""}
          defaultValue=""
          onChange={(event) => {
            const targetDevice = devices.find(({ deviceId }) => deviceId === event.target.value) || null;
            setStateAction(targetDevice);
          }}
        >
          {deviceOptions}
        </TextField>
      ),
    [deviceOptions, devices],
  );

  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Stack>
          <Typography fontSize="24px" fontWeight={600}>
            Camera Setup
          </Typography>
          <Typography fontSize="14px" fontWeight={400}>
            Configure the position of each camera. The configuration will be saved locally.
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <SelectDevicePosition label="Front Camera" deviceMap={frontDevice} setStateAction={setFrontDevice} />
          <SelectDevicePosition label="Left Camera" deviceMap={leftDevice} setStateAction={setLeftDevice} />
          <SelectDevicePosition label="Right Camera" deviceMap={rightDevice} setStateAction={setRightDevice} />
          <SelectDevicePosition label="Top Camera" deviceMap={topDevice} setStateAction={setTopDevice} />
          <SelectDevicePosition label="Hero Camera" deviceMap={heroDevice} setStateAction={setHeroDevice} />
        </Stack>

        <Stack direction="row" spacing={2}>
          {frontDevice && <WebcamComponent device={frontDevice} />}
          {leftDevice && <WebcamComponent device={leftDevice} />}
          {rightDevice && <WebcamComponent device={rightDevice} />}
          {topDevice && <WebcamComponent device={topDevice} />}
          {heroDevice && <WebcamComponent device={heroDevice} />}
        </Stack>

        <Divider />

        <Stack direction="row" spacing={2} justifyContent="end">
          <Button onClick={() => setShowConfigurationPanel(false)} sx={{ paddingX: 3 }} variant="contained">
            Save Configuration
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
