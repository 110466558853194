import HistoryIcon from "@mui/icons-material/History";
import {
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Waypoint } from "react-waypoint";
import { StyledBoxModal } from "./technicianInventoryStyles";
import useRecordHistoryEvent from "hooks/useRecordHistoryEvent";
import { RecordHistoryEvent } from "types/recordHistoryEvent";

interface InventoryEventHistoryButtonProps {
  entityId: number;
  eventType: "inventory_events" | "inventory_part_events";
  disabled?: boolean;
}

export default function InventoryEventHistoryButton({
  entityId,
  eventType,
  disabled,
}: InventoryEventHistoryButtonProps) {
  const { events, fetchMoreEvents, hasMoreEvents, isLoading } = useRecordHistoryEvent({ entityId, eventType });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title="View History" disableInteractive>
        <IconButton onClick={handleOpen} disabled={disabled} color="default">
          <HistoryIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <StyledBoxModal boxShadow={24} padding={4} gap={1} borderRadius={1} maxHeight="90vh">
          <Typography variant="h6">Event History</Typography>
          <List component={Box} height="50vh" overflow="scroll">
            {events &&
              events.map(({ id, description }: RecordHistoryEvent) => (
                <ListItem divider alignItems="flex-start" key={id}>
                  <ListItemText primary={description} />
                </ListItem>
              ))}
            {!isLoading || !hasMoreEvents ? (
              <Waypoint onEnter={fetchMoreEvents} />
            ) : (
              <>
                <Typography>Fetching events...</Typography>
                <LinearProgress />
              </>
            )}
          </List>
        </StyledBoxModal>
      </Modal>
    </>
  );
}
