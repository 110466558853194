import { Typography, styled, Switch, TextField, Card, Button, Box } from "@mui/material";

export const StyledTypography = styled(Typography)({
  paddingTop: "8.5px",
  paddingLeft: "14px",
  color: "#0089BD",
});
export const StyledSwitch = styled(Switch)({
  float: "left",
  marginRight: "20px",
});
export const StyledFormTextField = styled(TextField)({
  width: "100%",
  marginTop: "2px",
});
export const StyledCard = styled(Card)({
  padding: "10px",
  width: "60%",
});
export const StyledButton = styled(Button)({
  position: "absolute",
  bottom: "0",
  right: "0",
  variant: "contained",
  width: "120px",
});
export const StyledBox = styled(Box)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center ",
});
export const StyledBoxModal = styled(Box)({
  position: "absolute",
  display: "grid",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#FFF",
});
