import { Box, ListItemText, Stack, Typography, List, ListItem, Paper, Divider, Button } from "@mui/material";
import { CheckCircle, ArrowBack } from "@mui/icons-material";
import { useEffect, useRef } from "react";

interface PhotoCaptureProps {
  photoCaptureCheck: string[];
  gifSource: string;
  color: string;
  batch: string;
  handleCapture: () => void;
  handleReset: () => void;
}

export default function PhotoCapture({
  photoCaptureCheck,
  gifSource,
  color,
  batch,
  handleCapture,
  handleReset,
}: PhotoCaptureProps) {
  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.load();
      videoElement.current.play();
    }
  }, [gifSource]);

  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontSize="24px" fontWeight={600}>
            Capture Photos
          </Typography>
          <Typography fontSize="14px" fontWeight={400}>
            (Batch {batch})
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack spacing={1} sx={{ flexGrow: 1, width: "50%" }}>
            <Typography fontSize="18px" fontWeight={600}>
              Position Laptop
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#6e6e6e">
              Orient the laptop as pictured.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "313px",
                backgroundSize: "cover",
                overflow: "hidden",
                bgcolor: "#FAFAFA",
              }}
            >
              <video autoPlay muted style={{ height: "100%", objectFit: "cover" }} ref={videoElement}>
                <source src={gifSource} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Stack>

          <Stack spacing={1} sx={{ flexGrow: 1, width: "50%" }}>
            <Typography fontSize="18px" fontWeight={600}>
              Check Alignment & Capture Photos
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#6e6e6e" mb={3}>
              Confirm laptop position and orientation then press foot pedal to take photos.
            </Typography>

            <Stack direction="column" justifyContent="center" sx={{ height: "313px", bgcolor: "#FAFAFA" }}>
              <List sx={{ p: 2 }}>
                {photoCaptureCheck.map((item) => (
                  <ListItem key={item}>
                    <CheckCircle sx={{ fontSize: "48px", color: color }} />
                    <ListItemText primary={item} sx={{ ml: 2 }} />
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button onClick={handleReset} size="small" variant="outlined" color="primary" startIcon={<ArrowBack />}>
            Back
          </Button>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontSize="14px" fontWeight={400}>
              Press foot pedal
            </Typography>
            <Typography fontSize="14px" fontWeight={400}>
              or
            </Typography>
            <Button onClick={handleCapture} variant="contained">
              Capture Photos
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
