import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import PrintableQRCodes from "components/Pickups/PrintableQRCodes";
import Printable from "components/Printable";
import useInventory from "hooks/useInventory";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Inventory } from "types/inventory";

import PrintIcon from "@mui/icons-material/Print";

export default function InventoryPrintLabelButton({ refInventory }: { refInventory: Inventory }) {
  const printComponentRef = useRef<HTMLDivElement>(null);
  const { printConfirmDialogOpen, setPrintConfirmDialogOpen, handleClose, markInventoryPrinted } = useInventory();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Tooltip title="Print Label" disableInteractive>
            <IconButton>
              <PrintIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
        content={() => (printComponentRef ? printComponentRef.current : null)}
        onAfterPrint={() => setPrintConfirmDialogOpen(true)}
      />

      <Dialog
        open={printConfirmDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Mark inventory as printed?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking yes, we will mark this inventory label as printed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={markInventoryPrinted} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {printComponentRef && (
        <Printable>
          <PrintableQRCodes selectedInventories={[refInventory]} ref={printComponentRef} />
        </Printable>
      )}
    </>
  );
}
