import { Box, Button, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Edit, ContentCopy } from "@mui/icons-material";
import useLogisticsPartners from "hooks/useLogisticsPartners";

export function PartnerShow() {
  const { logisticsPartner } = useLogisticsPartners();

  const handleClick = () => {
    return navigator.clipboard.writeText(logisticsPartner?.uuid || "");
  };

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Partners", to: "/logistics/partners" },
          { label: logisticsPartner ? logisticsPartner?.name : "" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">{logisticsPartner?.name}</Typography>
        <Typography variant="h6" sx={{ padding: "10px 0" }}>
          <ContentCopy
            sx={{ color: "#202020", cursor: "pointer" }}
            onClick={() => {
              handleClick();
            }}
          />
          {logisticsPartner?.uuid}
        </Typography>
      </Box>

      <Box my={2} sx={{ display: "flex", alignItems: "left", gap: "10px" }}>
        <Button component={RouterLink} variant="contained" to="edit" startIcon={<Edit />}>
          Edit Partner
        </Button>
      </Box>
    </Box>
  );
}
