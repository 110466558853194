import { Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import EarningsForm from "./EarningsForm";

export function EarningsDebitNew() {
  const { client } = useClients();
  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Finance", to: "/finance/pickups" },
          { label: "Clients", to: "/finance/clients" },
          { label: client ? client?.name : "", to: `/finance/clients/${client?.uuid}` },
          { label: "Add Debit" },
        ]}
      ></Breadcrumbs>
      <Box mt={4}>
        <EarningsForm clientName={client?.name} type={"::EarningsDebit"} />
      </Box>
    </Box>
  );
}
