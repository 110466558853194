import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Pickup } from "types/pickup";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Preview } from "@mui/icons-material";
import { theme } from "index";

export const mainContact = (pickup: Pickup) => {
  return pickup.pickupContacts.find((contact) => contact.pointOfContactType === "main");
};

export const orderNumber = (pickup: Pickup) => {
  return [pickup.orderNumber];
};

export const internalNotes = (pickup: Pickup) => {
  return [pickup.internalNotes];
};

export const submittedBy = (pickup: Pickup) => {
  return [pickup.user ? `${pickup.user?.firstName} ${pickup.user?.lastName}` : "", pickup.user?.email];
};

export const pickupReason = (pickup: Pickup) => {
  if (pickup.reason) {
    return [pickup.reason];
  } else {
    return ["Please select the reason for a pickup"];
  }
};

export const pickupDate = (pickup: Pickup) => {
  return [pickup?.scheduledAt ?? "Please select a date"];
};

export const customerComments = (pickup: Pickup) => {
  if (pickup.comments) {
    return [pickup.comments];
  } else {
    return ["No customer comments"];
  }
};

export const contact = (pickup: Pickup) => {
  if (mainContact(pickup)) {
    return [
      mainContact(pickup) ? `${mainContact(pickup)?.firstName} ${mainContact(pickup)?.lastName}` : "",
      mainContact(pickup)?.email,
      mainContact(pickup)?.phoneNumber ? `mobile #: ${mainContact(pickup)?.phoneNumber}` : "",
      mainContact(pickup)?.workPhoneNumber ? `work #: ${mainContact(pickup)?.workPhoneNumber}` : "",
      ,
    ];
  } else {
    return ["Please select a main point of contact"];
  }
};

export const assets = (pickup: Pickup) => {
  if (pickup.pickupEstimatedItems[0]) {
    return pickup.pickupEstimatedItems.map((estimatedAsset) => {
      return `${estimatedAsset.quantity} ${estimatedAsset.name}`;
    });
  } else {
    return ["Please add the items that will be in your pickup"];
  }
};

export const publishedReports = (pickup: Pickup) => {
  if (pickup.pickupEstimatedItems[0]) {
    return [
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <AttachmentIcon color="primary" /> InitialReport.pdf
      </Box>,
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <AttachmentIcon color="primary" /> FinalReport.pdf
      </Box>,
    ];
  } else {
    return ["Please add the items that will be in your pickup"];
  }
};

export const postPickupReport = (pickup: Pickup) => {
  if (pickup.pickupEstimatedItems[0]) {
    return [
      <Box
        component="button"
        sx={{ display: "flex", alignItems: "center", gap: "4px", color: theme.palette.primary.main, cursor: "pointer" }}
      >
        <Preview color="primary" /> PPR_06-01-22
      </Box>,
    ];
  } else {
    return ["Please add the items that will be in your pickup"];
  }
};

export const address = (pickup: Pickup) => {
  if (pickup?.location) {
    return [
      pickup.location.address,
      pickup.location.address2 || "",
      `${pickup.location.city}, ${pickup.location.state} ${pickup.location.zipcode}`,
    ];
  } else {
    return ["Please select a location"];
  }
};

export const attachments = (pickup: Pickup) => {
  if (pickup.pickupImages.length) {
    return pickup.pickupImages.map((img, i) => {
      let type = img.filename.match(/[^\.]*$/);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <AttachmentIcon color="primary" /> Picture00{i + 1}.{type}
        </Box>
      );
    });
  } else {
    return ["No attachments"];
  }
};

export const locationNotes = (pickup: Pickup) => {
  if (pickup.location.accessInstructions || pickup.location.internalNotes) {
    return [pickup.location.accessInstructions, pickup.location.internalNotes];
  } else {
    return ["No access instructions or internal notes"];
  }
};

interface InfoCellProps {
  title: React.ReactNode;
  items: React.ReactNode[];
  sx?: SxProps<Theme>;
}

export function InfoCell({ title, items, sx }: InfoCellProps) {
  return (
    <Box sx={{ flex: 1, minWidth: "200px", ...sx }}>
      <Typography color="text.secondary" fontSize="12px" textTransform="uppercase" whiteSpace="nowrap">
        {title}
      </Typography>
      <Box mt={0.5} sx={{ wordWrap: "break-word" }}>
        {items.map((item, i) => {
          return (
            <Typography key={i} fontSize="14px" fontWeight="600">
              {item}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}
