import { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { useAlert } from "./useAlert";
import { Client } from "types/client";
import { OrganizationPlan } from "types/organizationPlan";
import { Location } from "types/location";
import { Contact } from "types/contact";

export default function useClients(defaultPageSize?: number) {
  const location = useLocation();
  const { search } = location;
  const params = useParams();
  const [clients, setClients] = useState<Client[]>([]);
  const [client, setClient] = useState<Client>();
  const [clientOrganizationPlan, setClientOrganizationPlan] = useState<OrganizationPlan>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { alertError, alertSuccess } = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  useEffect(() => {
    if (!params.uuid) {
      getClients();
    }
  }, [search]);

  useEffect(() => {
    if (params.uuid) {
      getClient();
      getClientOrganizationPlan();
    }
  }, [params.uuid, location.pathname]);

  const getClient = useCallback(() => {
    setClientLoading(true);
    api
      .get(`/clients/${params.uuid}`)
      .then(({ data: { client } }) => {
        setClient(client);
      })
      .finally(() => {
        setClientLoading(false);
      });
  }, []);

  const deleteLocation = useCallback((location: Location) => {
    setClientLoading(true);
    api.delete(`/clients/${params.uuid}/locations/${location.uuid}`).then(() => {
      getClient();
    });
  }, []);

  const deleteContact = useCallback((contact: Contact) => {
    setClientLoading(true);
    api.delete(`/clients/${params.uuid}/contacts/${contact.uuid}`).then(() => {
      getClient();
    });
  }, []);

  const getClients = useCallback(() => {
    const searchHash = new URLSearchParams(search);
    if (!searchHash.get("page[size]") && defaultPageSize) {
      searchHash.append("page[size]", String(defaultPageSize));
    }
    api.get(`/clients?${searchHash.toString()}`).then(
      ({
        data: {
          clients,
          meta: { totalPages, count },
        },
      }) => {
        setClients(clients);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  }, [search]);

  type ClientProps = {
    name?: string;
    tier?: string;
    salesRep?: string;
    accountRep?: string;
  };

  type OrganizationPlanProps = {
    id?: string;
    uuid?: string;
    priceInCents?: number;
    organizationPlanFeatures: string[];
    planId: number;
    subscriptionStartedAt?: string;
    subscriptionExpiredAt?: string;
  };

  const createClient = (client: ClientProps) => {
    setLoading(true);
    api
      .post(`/clients`, {
        client,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateClient = (client: ClientProps) => {
    setLoading(true);
    api
      .put(`/clients/${params.uuid}`, {
        client,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateStatus = useCallback((event: string, deactivationReason: string) => {
    return api
      .put(`clients/${params.uuid}/update_state`, { event: event, deactivationReason: deactivationReason })
      .then(() => {
        alertSuccess("Successfully deactivate this client");
        setDeactivateOpen(false);
        getClient();
      })
      .catch(({ response: { data } }) => {
        alertError(data.errors.toString());
      });
  }, []);

  const getClientOrganizationPlan = () => {
    api.get(`/organizations/${params.uuid}/organization_plan`).then((response) => {
      setClientOrganizationPlan(response.data);
    });
  };

  const updateOrganizationPlan = (organizationPlan: OrganizationPlanProps) => {
    api
      .put(`/organizations/${params.uuid}/organization_plan`, {
        organizationPlan,
      })
      .then((response) => {
        setClientOrganizationPlan(response.data);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  return {
    clients,
    client,
    clientOrganizationPlan,
    createClient,
    updateClient,
    updateOrganizationPlan,
    totalPages,
    count,
    isLoading,
    clientLoading,
    updateStatus,
    deactivateOpen,
    setDeactivateOpen,
    deleteLocation,
    deleteContact,
  };
}
