import { styled, Grid, Button, Chip, Box } from "@mui/material";

export const StyledGrid = styled(Grid)({
  margin: "10px",
});

export const StyledButton = styled(Button)({
  width: "120px",
  margin: "10px",
  padding: "5px",
});

export const StyledChip = styled(Chip)({
  backgroundColor: "#DDF3FC",
  border: "1px solid #bdbdbd",
  borderColor: "#0089BD",
  " .MuiChip-deleteIcon": {
    color: "#0089BD",
  },
});
