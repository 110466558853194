import { Inventory } from "types/inventory";
import React, { useState } from "react";
import { Alert, Button, Stack } from "@mui/material";
import { ScanAndConfirmInventoryButton } from "components/Picklist/common/ScanAndConfirmInventoryButton";

export function ScanAndConfirmInventoryStep({
  inventory,
  onClickNotFound,
  onInventoryScanned,
}: {
  onClickNotFound: () => void;
  onInventoryScanned: () => void;
  inventory: Inventory;
}) {
  const [inventoryError, setInventoryError] = useState(false);

  return (
    <>
      {inventoryError && (
        <Alert severity="error">
          This inventory ID doesn't match the assigned inventory item, please scan the correct inventory ID.
        </Alert>
      )}
      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <Button onClick={onClickNotFound}>Can't find item?</Button>
        <ScanAndConfirmInventoryButton
          inventory={inventory}
          onError={() => {
            setInventoryError(true);
          }}
          onSuccess={onInventoryScanned}
        />
      </Stack>
    </>
  );
}
