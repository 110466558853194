import { Box, Typography, Alert, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AddAPhoto } from "@mui/icons-material";
import FileDropSection from "./FileDropSection";
import useDirectUpload from "hooks/useDirectUpload";
import { usePickupImages } from "hooks/useLogisticsPickup";

export default function PickupImages() {
  const { upload, blob } = useDirectUpload({ keyPrefix: "/pickup/pickup_images/", service: "amazon_public" });
  const { pickup, createImage, deleteImage } = usePickupImages();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    blob &&
      createImage(blob?.signed_id).then(() => {
        setIsSaved(true);
      });
  }, [blob]);

  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          4. Upload pictures of their pickup order
        </Typography>
        {isSaved ? <Alert>Saved</Alert> : null}
      </Grid>
      <Typography mt={0.5} variant="body2">
        Pictures and videos helps us prepare the crew with the right staff and trucks for seamless processing
      </Typography>

      <Box mt={3}>
        <FileDropSection
          icon={<AddAPhoto color="primary" sx={{ fontSize: "32px" }} />}
          acceptHint={"jpg, jpeg, png"}
          accept={{
            "image/png": [".png", ".jpeg", ".jpg"],
            "image/jpeg": [".png", ".jpeg", ".jpg"],
          }}
          documents={
            pickup && pickup.pickupImages.length > 0
              ? pickup.pickupImages.map((image) => ({
                  name: image.filename,
                  url: image.url,
                  onDelete() {
                    deleteImage(image.id).then(() => {
                      setIsSaved(true);
                    });
                  },
                }))
              : undefined
          }
          onUpload={([file]) => {
            upload(file);
          }}
        />
      </Box>
    </Box>
  );
}
