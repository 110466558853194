import { Box, Button } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useConfirmation } from "hooks/useConfirmation";
import { LogisticsPickupContext, useSchedulePickup } from "hooks/useLogisticsPickup";
import { useContext } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PickupDate from "./PickupDate";
import PickupEstimatedItems from "./PickupEstimatedItems";
import PickupImages from "./PickupImages";
import PickupInternalNotes from "./PickupInternalNotes";
import PickupLocation from "./PickupLocation";
import PickupMainContact from "./PickupMainContact";
import PickupOtherContacts from "./PickupOtherContacts";
import PickupReason from "./PickupReason";
import SectionWrapper from "./SectionWrapper";

export default function LogisticsSchedulePickup() {
  const { pickup } = useContext(LogisticsPickupContext);
  const { pathname } = useLocation();
  const schedulePickup = useSchedulePickup();
  const navigate = useNavigate();
  const { confirm } = useConfirmation();

  return (
    <Box sx={{ width: "100%", mx: 0, padding: "20px" }}>
      {pathname.includes("edit") ? null : (
        <Breadcrumbs
          data={[
            { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
            { label: pickup?.orderNumber ? `Edit Pickup #${pickup?.orderNumber}` : "New Pickup" },
          ]}
        ></Breadcrumbs>
      )}
      <Box mt={{ md: 5, xs: 3 }}>
        <SectionWrapper>
          <PickupLocation />
        </SectionWrapper>
      </Box>
      {pickup?.organization?.id && (
        <>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupReason />
            </SectionWrapper>
          </Box>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupEstimatedItems />
            </SectionWrapper>
          </Box>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupImages />
            </SectionWrapper>
          </Box>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupMainContact />
            </SectionWrapper>
          </Box>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupOtherContacts />
            </SectionWrapper>
          </Box>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupInternalNotes />
            </SectionWrapper>
          </Box>
          <Box mt={{ md: 5, xs: 3 }}>
            <SectionWrapper>
              <PickupDate />
            </SectionWrapper>
          </Box>
          {pickup && (
            <Box mt={6} sx={{ display: "flex", gap: 2 }}>
              {pickup.state === "draft" || pickup.state === "pending_confirmation" ? (
                <Button
                  size="large"
                  disabled={pickup.location && pickup.scheduledAt ? false : true}
                  variant="contained"
                  onClick={() => {
                    confirm({
                      message: "Are you sure you want to schedule this pickup?",
                      handler: () => {
                        schedulePickup().then(() => {
                          navigate(`/logistics?logistics_status=scheduled`);
                        });
                      },
                    });
                  }}
                >
                  schedule
                </Button>
              ) : null}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
