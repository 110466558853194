import { createContext, useState, Dispatch, SetStateAction, useContext } from "react";
import { Crumb } from "types/crumb";

type BreadCrumbsContainerProps = {
  children: React.ReactNode;
  initialCrumbs?: Crumb[];
};

type InitialContext = {
  crumbs: Crumb[];
  setCrumbs: Dispatch<SetStateAction<Crumb[]>>;
};

export const BreadcrumbsContext = createContext<InitialContext>({
  crumbs: [],
  setCrumbs: () => {},
});

export const useBreadcrumbsContext = () => useContext(BreadcrumbsContext);

export default function BreadCrumbsContainer({ children, initialCrumbs }: BreadCrumbsContainerProps) {
  const [crumbs, setCrumbs] = useState<Crumb[]>(initialCrumbs || []);

  const value = { crumbs, setCrumbs };

  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
}
