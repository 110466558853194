import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareMenuItem } from "components/Form/SubmitAwareMenuItem";
import { useAlert } from "hooks/useAlert";
import { useGridApiContext } from "@mui/x-data-grid-pro";

const validReplacementStates = ["skipped", "created"];

function useAlertModelErrors() {
  const { alertError } = useAlert();

  return (errors: Record<string, string[]>) => {
    if (!errors) return alertError("An unknown error occurred");
    return alertError(
      Object.entries(errors)
        .map(([key, value]) => `${key} ${value.join(", ")}`)
        .join("\n"),
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MarkAsPackedRowAction({ row, showInMenu }: { row: ShipstationOrderItem; showInMenu?: boolean }) {
  const isPackable = validReplacementStates.includes(row.state.toLowerCase());

  const apiRef = useGridApiContext();
  const alertModelErrors = useAlertModelErrors();
  const initialValues: { packedReason: string | null } = { packedReason: null };
  return (
    <RevivnApiForm
      initialValues={initialValues}
      action={`shipstation_order_items/${row.id}/mark_as_packed`}
      method="post"
      onSubmit={(data) => {
        data.packedReason = prompt("Enter a reason for marking this item as packed");
      }}
      onError={(error) => {
        alertModelErrors(error.response?.data?.errors);
      }}
      onSuccess={(response) => {
        const updatedItem = response.data as ShipstationOrderItem;
        apiRef.current?.updateRows([{ ...updatedItem }]);

        // This is a workaround to get the actions column to re-render and hide the popup menu
        apiRef.current?.setColumnVisibility("actions", false);
        apiRef.current?.setColumnVisibility("actions", true);
      }}
    >
      <SubmitAwareMenuItem
        disabled={!isPackable}
        sx={{ textTransform: "none", color: "inherit", position: "relative" }}
      >
        <input
          disabled={!isPackable}
          type="submit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ opacity: 0, position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
        />
        Mark as Packed
      </SubmitAwareMenuItem>
    </RevivnApiForm>
  );
}
