import { LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";

export function PicklistProgress({ complete, total }: { complete: number; total: number }) {
  const current = complete + 1 > total ? total : complete;
  const progress = complete > total ? 100 : (complete / total) * 100;
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <LinearProgress variant="determinate" value={progress} sx={{ flexGrow: 1 }} />
      <Typography fontSize={10} color="text.secondary">
        Inventory {current}/{total}
      </Typography>
    </Stack>
  );
}
