import {
  Alert,
  SelectChangeEvent,
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Radio,
  ListItemText,
} from "@mui/material";
import { AppSelect, InputBase } from "components/common/Input";
import { LogisticsPickupContext } from "hooks/useLogisticsPickup";
import { useContext, useEffect, useState } from "react";

export default function PickupReason() {
  let [value, setValue] = useState<string>("");
  let [open, setOpen] = useState(false);
  let [otherValue, setOtherValue] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const { pickup, updatePickup } = useContext(LogisticsPickupContext);

  function handleChange(event: SelectChangeEvent) {
    let value = event.target.value;
    if (value !== "other") {
      setOtherValue("");
      updatePickup({ ...pickup, reason: value }).then(() => {
        setIsSaved(true);
      });
    }
    setValue(value);
  }

  const options = ["Standard device retiring/refresh", "Office closure"];

  useEffect(() => {
    if (pickup) {
      if (options.includes(pickup.reason)) {
        setValue(pickup.reason);
      } else if (!pickup.reason) {
        setValue("");
      } else {
        setValue("other");
        setOtherValue(pickup.reason);
      }
    }
  }, [pickup]);

  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          2. Why are they requesting a pickup?
        </Typography>
        {isSaved ? <Alert>Saved</Alert> : null}
      </Grid>

      <FormControl variant="standard" sx={{ mt: 3, width: "100%", maxWidth: "450px" }}>
        <AppSelect
          labelId="pickup-reason"
          id="pickup-reason"
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected === "other") {
              let result = "Other";
              if (otherValue) {
                result += `: ${otherValue}`;
              }
              return result;
            }
            return selected;
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e) => {
            let targetEl = e.currentTarget as HTMLDivElement;
            let isOtherSelected = targetEl.dataset.value === "other";
            if (isOtherSelected) {
              setTimeout(() => {
                (targetEl.querySelector("input[type='text']") as HTMLInputElement)?.focus();
              });
            } else {
              // close the popover
              setOpen(false);
              if (value === "other" && pickup && otherValue !== pickup.reason) {
                updatePickup({ ...pickup, reason: otherValue }).then(() => {
                  setIsSaved(true);
                });
              }
            }
          }}
        >
          {options.map((item) => {
            let isSelected = item === value;
            return (
              <MenuItem key={item} value={item}>
                <Radio size="medium" checked={isSelected} />
                <ListItemText primary={item} />
              </MenuItem>
            );
          })}
          <MenuItem key={"other"} value={"other"} data-value="other" onKeyDown={(e) => e.stopPropagation()}>
            <Radio size="medium" checked={value === "other"} />
            Other:
            <InputBase
              value={otherValue}
              style={{ marginLeft: "8px" }}
              onChange={(e: any) => setOtherValue(e.target.value)}
            ></InputBase>
          </MenuItem>
        </AppSelect>
      </FormControl>
    </Box>
  );
}
