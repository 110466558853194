import { ClientShow } from "components/Finance/Clients/ClientShow";
import { EarningsCreditNew } from "components/Finance/Clients/EarningsCreditNew";
import { EarningsDebitNew } from "components/Finance/Clients/EarningsDebitNew";
import { FinanceClients } from "components/Finance/Clients/FinanceClients";
import { TransactionGroupsEdit } from "components/Finance/Clients/TransactionGroupEdit";
import { TransactionGroupIndex } from "components/Finance/Clients/TransactionGroupIndex";
import { TransactionGroupsNew } from "components/Finance/Clients/TransactionGroupNew";
import FinancePickups from "components/Finance/Pickups/FinancePickups";
import { Route, Routes } from "react-router-dom";
import { AirbaseVendorListUpload } from "components/Finance/Clients/AirbaseVendorListUpload";

export default function FinancePage() {
  return (
    <Routes>
      <Route index element={<FinancePickups />} />
      <Route path="/pickups" element={<FinancePickups />} />
      <Route path="/clients" element={<FinanceClients />} />
      <Route path="/clients/airbase-vendor-list-upload" element={<AirbaseVendorListUpload />} />
      <Route path="/clients/:uuid" element={<ClientShow />} />
      <Route path="/clients/:uuid/debits/new" element={<EarningsDebitNew />} />
      <Route path="/clients/:uuid/credits/new" element={<EarningsCreditNew />} />
      <Route path="/clients/:uuid/transaction_groups" element={<TransactionGroupIndex />} />
      <Route path="/clients/:uuid/transaction_groups/new" element={<TransactionGroupsNew />} />
      <Route
        path="/clients/:uuid/transaction_groups/:transactionType/:transactionGroupId"
        element={<TransactionGroupsEdit />}
      />
    </Routes>
  );
}
