import { ArrowDownward, ArrowUpward, Delete, Filter, NoPhotography } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ImageOverlay from "components/ImageOverlay";
import { useAlert } from "hooks/useAlert";
import { useState } from "react";
import { api } from "services/api.service";
import { Inventory } from "types/inventory";

/** Display an Inventory's resale photos in a grid. */
export default function ResalePhotos({ inventory }: { inventory: Inventory }) {
  const [attachments, setAttachments] = useState(inventory?.resalePhotos);
  const responsiveWidth = useMediaQuery(useTheme().breakpoints.up("lg"));
  const { alertSuccess, alertError } = useAlert();

  /** Sends a request to delete this photo from the inventory. */
  function DeletePhotoButton({ index }: { index: number }) {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(false);
    };

    const handleDelete = () => {
      api
        .delete(`/inventories/${inventory.id}/resale_photos/${attachments[index].id}`)
        .then(({ data }: { data: Inventory }) => {
          inventory.resalePhotos = data.resalePhotos;
          inventory.resalePhotosOrder = data.resalePhotosOrder;
          setAttachments(data.resalePhotos);
          alertSuccess("Resale photo deleted.");
        })
        .catch(() => {
          alertError("Error deleting resale photo.");
        });
      setOpen(false);
    };

    return (
      <>
        <Tooltip title="Delete" arrow placement="top">
          <IconButton size="small" onClick={() => setOpen(true)}>
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure you want to delete this resale photo?</DialogTitle>
          <DialogContent>
            <Typography>This action is irreversible!</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete}>Delete</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  /** When clicked, shifts the position of the element up or down the array. */
  function ShiftPositionButton({
    direction = "forward",
    index,
  }: {
    direction?: "forward" | "backward";
    index: number;
  }) {
    const handleEvent = () => {
      const newAttachments = [...attachments];
      const temp = newAttachments[index];
      if (direction === "forward") {
        newAttachments[index] = newAttachments[index - 1];
        newAttachments[index - 1] = temp;
      } else {
        newAttachments[index] = newAttachments[index + 1];
        newAttachments[index + 1] = temp;
      }
      setAttachments(newAttachments);
      inventory.resalePhotosOrder = newAttachments.map((attachment) => attachment.id ?? 0);
    };

    return (
      <Tooltip title={`Move ${direction}`} arrow placement="top">
        <IconButton size="small" onClick={handleEvent}>
          {direction === "forward" ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
        </IconButton>
      </Tooltip>
    );
  }

  /** The component used to display each resale photo. */
  function PhotoCard({ image, index }: { image: string; index: number }) {
    const ImageThumb = () => (
      <>
        <img
          src={image}
          alt="Resale photo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "4px",
            zIndex: "auto",
          }}
        />

        <Typography noWrap color="text.secondary">
          <Filter fontSize="inherit" /> {index === 0 && "Hero - "} Image {index + 1}
        </Typography>
      </>
    );

    /** Used to display when there is no available image. */
    const NoImage = () => (
      <Stack direction="column" alignItems="center" spacing={0.5}>
        <NoPhotography fontSize={responsiveWidth ? "large" : "medium"} color="disabled" />
        <Typography variant="caption" color="text.secondary">
          No image
        </Typography>
      </Stack>
    );

    return (
      <Grid item component={Card} padding={1}>
        <Box display="flex" justifyContent="center" alignItems="center" width={responsiveWidth ? 196 : 128}>
          <Box
            width="100%"
            height="100%"
            position="relative"
            sx={{
              aspectRatio: "16/9",
              "& .showOnHover": { opacity: 0, transition: "0.2s opacity" },
              "&:hover .showOnHover": { opacity: 1 },
            }}
          >
            {image ? <ImageThumb /> : <NoImage />}
            <ImageOverlay image={image} showExpandButton>
              {image && index > 0 && <ShiftPositionButton direction="forward" index={index} />}
              {image && index < attachments.length - 1 && <ShiftPositionButton direction="backward" index={index} />}
              {image && <DeletePhotoButton index={index} />}
            </ImageOverlay>
          </Box>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid container gap={2} padding={0.5}>
      {attachments.map((image, index) => (
        <PhotoCard image={image.url ?? ""} index={index} key={index} />
      ))}
      {!attachments.length && (
        <Grid item>
          <Typography color="text.secondary">No resale photos have been uploaded yet.</Typography>
        </Grid>
      )}
    </Grid>
  );
}
