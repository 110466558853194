import { Box, Typography, TextField, useTheme, styled, Button, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { BenefitingOrganization } from "types/benefitingOrganization";
import { useDropzone } from "react-dropzone";
import { useEffect, useState } from "react";
import useDirectUpload from "hooks/useDirectUpload";
import CloseIcon from "@mui/icons-material/Close";

const TextFieldStyled = styled(TextField)({
  ".MuiOutlinedInput-root": { padding: "0px !important" },
  ".MuiInputBase-input": {
    minHeight: "38px",
    padding: "0px 12px !important",
  },
  fieldset: {
    borderColor: "rgb(206, 212, 218)",
  },
});

interface BenefitingOrganizationProps {
  setKey: (key: string) => void;
  signedKey?: string | null;
  deleteLogo?: (uuid: string) => void;
  benefitingOrganization?: BenefitingOrganization;
}

export default function BenefitingOrganizationForm({ setKey, signedKey, deleteLogo }: BenefitingOrganizationProps) {
  const { getFieldProps, values } = useFormikContext<BenefitingOrganization>();
  const theme = useTheme();
  let [loading, setLoading] = useState(false);
  const { blob, upload } = useDirectUpload({ keyPrefix: "/donation-request/hero/", service: "amazon_public" });

  const { open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png", ".jpeg"],
    },
    onDrop: async (files) => {
      setLoading(true);
      try {
        onUpload?.(files);
      } finally {
        setLoading(false);
      }
    },
  });

  const onUpload = (acceptedFiles: File[]) => {
    upload(acceptedFiles[0]);
  };

  useEffect(() => {
    if (blob) setKey(blob.signed_id);
  }, [blob]);

  return (
    <Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 5 }}>
        <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Organization Name</Typography>
            <TextFieldStyled {...getFieldProps("name")}></TextFieldStyled>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
            <Typography>Organization Website</Typography>{" "}
            <TextFieldStyled {...getFieldProps("websiteUrl")}></TextFieldStyled>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box sx={{ "> div:not(:last-child)": { borderBottom: `1px solid ${theme.palette.divider}` } }}>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", minHeight: "80px", alignItems: "center" }}>
              <Typography>Organization Logo</Typography>
              {values.logoUrl ? (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                  <Typography>{values.logoUrl.slice(values.logoUrl.length - 20)}</Typography>
                  <IconButton onClick={() => deleteLogo?.(values.uuid)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant={signedKey || blob ? "outlined" : "contained"}
                  size="small"
                  sx={{ mt: 1 }}
                  onClick={open}
                  disabled={loading}
                  startIcon={signedKey || blob ? <AttachmentIcon /> : null}
                >
                  {signedKey || blob ? "Logo Attached" : "Upload Logo"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
