import { Box, Typography, Autocomplete, Alert, Grid } from "@mui/material";
import { InputBase } from "components/common/Input";
import { usePickupOtherContacts } from "hooks/useLogisticsPickup";
import { useState } from "react";

export default function PickupOtherContacts() {
  const { emails, updatePickupOtherContact, organizationUserEmails } = usePickupOtherContacts();
  const [isSaved, setIsSaved] = useState(false);

  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          6. (Optional) Do you want to notify anyone else of this pickup within the company?
        </Typography>
        {isSaved ? <Alert severity={"success"}>Saved</Alert> : null}
      </Grid>

      <Typography mt={0.5} variant="body2">
        Enter the emails of people they’d like to notify about this pickup, and we’ll send them updates.
      </Typography>

      <Box mt={3}>
        <Autocomplete
          multiple
          freeSolo
          size="small"
          value={emails}
          options={organizationUserEmails}
          renderInput={(params) => <InputBase {...params} placeholder="name@company.com..." />}
          onChange={(_: any, newValue: any) => {
            updatePickupOtherContact(newValue).then(() => {
              setIsSaved(true);
            });
          }}
        />
      </Box>
    </Box>
  );
}
