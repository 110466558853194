import { Menu as MenuIcon } from "@mui/icons-material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  Avatar,
  Box,
  CssBaseline,
  Drawer as MuiDrawer,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { ListItems } from "AppNav";
import useAuth from "hooks/useAuth";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as LogoIcon } from "images/logo.svg";
import { theme as appTheme } from "index";
import AssetRetrievals from "pages/AssetRetrievalPage";
import BenefitingOrganizationsPage from "pages/BenefitingOrganizationsPage";
import DonationRequestsPage from "pages/DonationRequestsPage";
import DriversPage from "pages/DriversPage";
import EnrolledInventoriesPage from "pages/EnrolledInventoriesPage";
import FinancePage from "pages/FinancePage";
import { FulfillmentPage } from "pages/FulfillmentPage";
import InventoriesPage from "pages/InventoriesPage";
import InventoryPricerUploadsPage from "pages/InventoryPricerUploadsPage";
import InventoryTemplatesPage from "pages/InventoryTemplatesPage";
import InventoryUploadsPage from "pages/InventoryUploadsPage";
import { PicklistLayout } from "pages/Layouts/PicklistLayout";
import Login from "pages/Login";
import LoginSso from "pages/LoginSso";
import LogisticsPage from "pages/LogisticsPage";
import OutboundPage from "pages/OutboundPage";
import { PicklistCurrentBatchPage } from "pages/Picklist/PicklistCurrentBatchPage";
import { PicklistNewPage } from "pages/Picklist/PicklistNewPage";
import PickupsPage from "pages/PickupsPage";
import ReceivingPage from "pages/ReceivingPage";
import ScanAndDiscover from "pages/ScanAndDiscover";
import ScanPage from "pages/ScanPage";
import SupportPage from "pages/SupportPage";
import TechnicianInventoryPage from "pages/TechnicianInventoryPage";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import stringToColor from "utils/stringToColor";
import { userHasPermission } from "utils/userPermissions";
import "./assets/App.css";
import VirtualPickup from "./pages/VirtualPickup";
import ListingsPage from "pages/ListingsPage";
import ProductCatalogsPage from "pages/ProductCatalogsPage";
import StockImagesUploader from "pages/StockImagesUploader";
import { BatchesPage } from "pages/BatchesPage";
import ResalePhotoBoothPage from "pages/ResalePhotoBoothPage";

const drawerWidth: number = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    }),
    [theme.breakpoints.down("lg")]: { top: `0 !important`, ...(!open && { width: 0 }) },
  },
}));

const PermissionedRoute = ({
  children,
  resource,
  permissions,
}: {
  children?: React.ReactNode;
  resource: string;
  permissions: string[];
}) => {
  if (userHasPermission(resource, permissions)) {
    return <>{children}</>;
  } else {
    return <> You do not have permission to view this page</>;
  }
};

function LoggedOutApp() {
  return (
    <Routes>
      <Route path="/login" element={<LoginSso />} />
      <Route path="/phoenix_login" element={<Login />} />
    </Routes>
  );
}

function LoggedInApp() {
  const isTabletView = useMediaQuery(appTheme.breakpoints.down("lg"));
  const [open, setOpen] = useState(!isTabletView);
  const { user, currentUserPermissions } = useCurrentUser();

  function handleToggle() {
    setOpen((prev) => !prev);
  }

  useEffect(() => {
    setOpen(!isTabletView);
  }, [isTabletView, setOpen]);

  return (
    <Box>
      <CssBaseline />

      {isTabletView && (
        <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 10 }}>
          <AppBar variant="outlined" elevation={0} sx={{ bgcolor: "white", color: "#000" }}>
            <Toolbar>
              <IconButton
                color="primary"
                onClick={handleToggle}
                aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <Drawer variant="permanent" open={open} as="nav">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <LogoIcon />
          <IconButton color="primary" aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"} onClick={handleToggle}>
            {open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
        <Box
          height="max-height"
          maxHeight="max-height"
          display="flex"
          flex="1 0"
          flexDirection="column"
          justifyContent="space-between"
          overflow="hidden"
        >
          <Box overflow="scroll" sx={{ overflowX: "hidden" }}>
            <ListItems open={open} setOpen={setOpen} currentUserPermissions={currentUserPermissions} />
          </Box>
          {user && open && (
            <ListItem
              dense={!!user.warehouseName}
              sx={{
                backgroundColor: "background.paper",
                boxShadow: (theme) => `0 -8px 8px ${theme.palette.background.paper}`,
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ width: 32, height: 32, bgcolor: stringToColor(user.email) }}>
                  {user.email.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.email} secondary={user.warehouseName} />
            </ListItem>
          )}
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === "light" ? "#F9FBFD" : theme.palette.grey[900]),
          padding: 3,
          minHeight: "calc(100vh - 64px)",
          marginLeft: open ? `${drawerWidth}px` : 7,
          [appTheme.breakpoints.down("lg")]: {
            marginTop: "64px",
            marginLeft: 0,
            padding: 3,
          },
          [appTheme.breakpoints.down("sm")]: {
            padding: 2,
            minHeight: "calc(100vh - 58px)",
            marginTop: "58px",
          },
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/pickups" replace />} />
          <Route path="/stock-photos" element={<StockImagesUploader />} />
          <Route path="/pickups/*" element={<PickupsPage />} />
          <Route path="/asset_retrievals/*" element={<AssetRetrievals />} />
          <Route path="/inventories" element={<InventoriesPage />} />
          <Route path="/enrolled" element={<EnrolledInventoriesPage />} />
          <Route path="/inventory-templates" element={<InventoryTemplatesPage sx={{ mx: 10, my: 8 }} />} />
          <Route
            path="/inventory-templates/:templateUuid"
            element={<InventoryTemplatesPage sx={{ mx: 10, my: 8 }} />}
          />
          <Route path="/inventories/:inventoryId" element={<TechnicianInventoryPage />} />
          <Route path="/scan" element={<ScanPage />} />
          <Route path="/technician" element={<TechnicianInventoryPage />} />
          <Route path="/technician/:inventoryId" element={<TechnicianInventoryPage />}></Route>
          <Route path="/technician/:inventoryId/inventory-parts/:type" element={<TechnicianInventoryPage />} />
          <Route path="/receiving/*" element={<ReceivingPage />}></Route>
          <Route path="/outbound" element={<OutboundPage />}></Route>
          <Route path="/fulfillment" element={<FulfillmentPage />} />
          <Route path="/batches" element={<BatchesPage />} />
          <Route path="/picklist" element={<PicklistLayout />}>
            <Route index element={<Navigate replace={true} to="new" />} />
            <Route path="new" element={<PicklistNewPage />} />
            <Route path="current-batch" element={<PicklistCurrentBatchPage />} />
          </Route>
          <Route path="/drivers" element={<DriversPage />}></Route>
          <Route path="/scan-and-discover" element={<ScanAndDiscover />}></Route>
          <Route path="/virtual-pickup" element={<VirtualPickup />}></Route>
          <Route path="/inventory-uploads" element={<InventoryUploadsPage />}></Route>
          <Route path="/inventory-pricer-uploads" element={<InventoryPricerUploadsPage />}></Route>
          <Route path="/listings/*" element={<ListingsPage />} />
          <Route path="/product_catalogs" element={<ProductCatalogsPage />} />
          <Route path="/resale-photo-booth" element={<ResalePhotoBoothPage />} />

          <Route
            path="/logistics/*"
            element={
              <PermissionedRoute resource={"logistics"} permissions={currentUserPermissions}>
                <LogisticsPage />
              </PermissionedRoute>
            }
          />
          <Route
            path="/finance/*"
            element={
              <PermissionedRoute resource={"finance"} permissions={currentUserPermissions}>
                <FinancePage />
              </PermissionedRoute>
            }
          />
          <Route
            path="/benefiting-organization/*"
            element={
              <PermissionedRoute resource={"tech_access"} permissions={currentUserPermissions}>
                <BenefitingOrganizationsPage />
              </PermissionedRoute>
            }
          ></Route>
          <Route
            path="/donation-requests/*"
            element={
              <PermissionedRoute resource={"tech_access"} permissions={currentUserPermissions}>
                <DonationRequestsPage />
              </PermissionedRoute>
            }
          ></Route>
          <Route
            path="/support/*"
            element={
              <PermissionedRoute resource={"support"} permissions={currentUserPermissions}>
                <SupportPage />
              </PermissionedRoute>
            }
          ></Route>
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  const { loggedIn } = useAuth();

  return <>{loggedIn ? <LoggedInApp /> : <LoggedOutApp />}</>;
}

export default App;
