import { CameraAlt, CheckRounded, Upload } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { FileUpload, FileUploadStatus } from "hooks/useFileUpload";
import { useState } from "react";

/** Displays the list of items added to the file upload system. */
export const FileUploadList = ({ files }: { files: FileUpload[] }) => {
  return (
    <Grid container hidden={!files.length} gap={2} paddingY={2}>
      {files.map((item) => (
        <Grid item component={Paper} width={200} padding={1.5}>
          <Typography noWrap variant="body2" color="text.secondary" title={item.file.name}>
            {item.file.name}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {item.status === FileUploadStatus.Uploaded && <CheckRounded color="success" fontSize="inherit" />}
            {item.status === FileUploadStatus.Uploading && <CircularProgress size={20} />}
            <Typography variant="body2" color="text.secondary">
              {item.status}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export function FileUploadCapture({ onFiles }: { onFiles: (files: FileList) => void }) {
  const handleFileList = (fileList: FileList) => {
    if (!fileList.length) return;
    onFiles(fileList);
  };

  return (
    <Paper
      component="label"
      variant="outlined"
      sx={{
        "&:hover": {
          backgroundColor: (theme) => theme.palette.primary.light,
          boxShadow: (theme) => `0px 0px 8px ${theme.palette.primary.light}`,
        },
        display: "inline-block",
        width: "max-content",
        borderWidth: "2px",
        transition: "ease-in-out 0.2s",
        padding: 2,
        cusror: "pointer",
      }}
    >
      <Box display="flex" flexDirection="row" gap={1} color="text.secondary">
        <CameraAlt />
        <Typography>Capture</Typography>
      </Box>
      <Typography color="text.secondary" variant="caption">
        Take a photo with your camera
      </Typography>
    </Paper>
  );
}

export default function FileUploadDropzone({
  onFiles,
  accept = ["image/jpeg"],
  multiple = true,
}: {
  onFiles: (files: FileList) => void;
  accept?: string[];
  multiple?: boolean;
}) {
  const [active, setActive] = useState(false);

  const handleFileList = (fileList: FileList) => {
    if (!fileList.length) return;
    onFiles(fileList);
    setActive(false);
  };

  return (
    <Paper
      component="label"
      variant="outlined"
      onDragEnter={() => {
        if (!active) setActive(true);
      }}
      onDragExit={() => {
        if (active) setActive(false);
      }}
      onDragOver={(e: { dataTransfer: { files: FileList }; preventDefault: () => void }) => {
        e.preventDefault();
      }}
      onDrop={(e: { dataTransfer: { files: FileList }; preventDefault: () => void }) => {
        handleFileList(e.dataTransfer.files);
        e.preventDefault();
      }}
      sx={{
        backgroundColor: (theme) => (active ? theme.palette.primary.light : theme.palette.grey[100]),
        boxShadow: (theme) => (active ? `0px 0px 8px ${theme.palette.primary.light}` : "inherit"),
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[300],
        },
        display: "inline-block",
        width: "max-content",
        borderStyle: "dashed",
        borderWidth: "2px",
        transition: "ease-in-out 0.2s",
        padding: 2,
        cusror: "pointer",
      }}
    >
      <Box display="flex" flexDirection="row" gap={1} color="text.secondary">
        <Upload />
        <Typography>Upload</Typography>
      </Box>
      <Typography color="text.secondary" variant="caption">
        Click or drag and drop files here
      </Typography>
      <input
        hidden
        multiple={multiple}
        type="file"
        accept={accept.join(",")}
        onChange={(e: { target: { files: FileList | null } }) => e.target.files && handleFileList(e.target.files)}
      />
    </Paper>
  );
}
