import React from "react";
import { CapturePhotoButton, CapturePhotoButtonProps } from "components/Picklist/common/CapturePhotoButton";

export function CapturePhotoStep({ inventory, onImageCaptured }: Omit<CapturePhotoButtonProps, "children">) {
  return (
    <CapturePhotoButton inventory={inventory} onImageCaptured={onImageCaptured}>
      Capture Photo
    </CapturePhotoButton>
  );
}
