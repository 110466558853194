import AddNewDonationRequest from "components/Donations/AddNewDonationRequest";
import DonationRequests from "components/Donations/DonationsRequests";
import EditDonationRequest from "components/Donations/EditDonationRequest";
import { Route, Routes } from "react-router-dom";

export default function DonationRequestsPage() {
  return (
    <Routes>
      <Route index element={<DonationRequests />} />
      <Route path="/new" element={<AddNewDonationRequest />} />
      <Route path="/:donationRequestUuid" element={<EditDonationRequest />} />
    </Routes>
  );
}
