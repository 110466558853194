import { Box } from "@mui/material";
import InventoryCard from "components/Receiving/InventoryCard";
import IssueCard from "components/Receiving/IssueCard";

export default function InventoryAddImagePage() {
  return (
    <Box display={"grid"} gap={2}>
      <InventoryCard />
      <IssueCard />
    </Box>
  );
}
