import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import BreadCrumbsContainer from "contexts/breadcrumbContext";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { Route, Routes } from "react-router-dom";
import PickupsDataGrid from "../components/Pickups/PickupsDataGrid";
import PickupShowPage from "./PickupShowPage";
import AssetRetrievalsGrid from "components/AssetRetrievals/AssetRetrievalsGrid";

function AssetRetrievalsBody() {
  return (
    <>
      <Routes>
        <Route index element={<AssetRetrievalsGrid />} />
      </Routes>
    </>
  );
}

export default function AssetRetrievals() {
  return <AssetRetrievalsBody />;
}
