import { memo, useState } from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Button } from "@mui/material";
import ActionProgressDrawer from "./ActionProgressDrawer";
import useInventoryTasks from "hooks/useTasks";

export const ActionsProgressButton = memo(() => {
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const { createInventoryTasks } = useInventoryTasks();

  const handleClick = () => {
    toggleDrawer(!isDrawerOpen);
    createInventoryTasks();
  };

  return (
    <div>
      <Button startIcon={<ListAltIcon />} onClick={handleClick} size="small">
        View Actions
      </Button>
      {isDrawerOpen ? <ActionProgressDrawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} /> : null}
    </div>
  );
});
