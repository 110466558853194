export type LogisticsPartner = {
  id: string;
  uuid: string;
  name: string;
  type: string;
  googleDriveLink?: string;
};

export const defaultLogisticsPartner = {
  id: "",
  uuid: "",
  name: "",
  type: "",
};
