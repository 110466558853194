import { Box, Button, Collapse, Pagination, Typography } from "@mui/material";
import { theme } from "index";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useQuery } from "../hooks/useQuery";
import { Pickup } from "types/pickup";
import { assets, contact } from "components/Logistics/Pickups/PickupRowStatuses/shared";
import useDriversPickups from "hooks/useDriversPickups";
import { readableDate } from "utils/readableDate";
import "./ImageHover.css";

export default function DriversPage() {
  let query = useQuery();
  let navigate = useNavigate();

  let page = query.get("page[number]") ?? 1;
  page = +page;

  let { pickups, totalPages, loading } = useDriversPickups({
    search: `?page%5Bnumber%5D=${page}`,
  });

  if (loading) {
    return null;
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    query.delete("page[number]");
    if (value != 1) {
      query.append("page[number]", String(value));
    }
    navigate(`?${query.toString()}`);
  };

  return (
    <Box>
      <Box>
        <Typography variant="h4">Upcoming Pickups</Typography>
        <Typography mt={0.5}>View details of all upcoming pickups assigned to you.</Typography>
      </Box>

      <Box mt={2.5}>
        <a href={"https://revivn.com/pickups"} target="_blank" style={{ textDecoration: "none" }}>
          <Button variant="contained">Post pickup report</Button>
        </a>
      </Box>

      <Box
        mt={3}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(min(300px, 100%), 1fr))",
          gap: 2,
          alignItems: "start",
        }}
      >
        {pickups.map((pickup) => {
          return <PickupCard key={pickup.id} pickup={pickup} />;
        })}
      </Box>

      <Box>
        {totalPages > 1 && (
          <Box sx={{ padding: "32px 0", display: "flex", justifyContent: "center" }}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

interface PickupCardProps {
  pickup: Pickup;
}

function PickupCard({ pickup }: PickupCardProps) {
  let [isExpanded, setIsExpanded] = useState(false);

  const images = () => {
    return pickup.pickupImages.filter((img) => img.url.toLowerCase().match(/\.(xls|csv)$/) == null);
  };

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        p: 2,
      }}
    >
      <Box textAlign={"center"} fontSize="14px" fontWeight="600">
        {readableDate(pickup?.scheduledAt)} - {pickup.organization.name}
      </Box>
      <Box mt={1} textAlign={"center"} fontSize="14px" minHeight="42px">
        {pickup.location && (
          <>
            {pickup.location.address} {pickup.location.address2} <br /> {pickup.location.city} {pickup.location.state}{" "}
            {pickup.location.zipcode}
          </>
        )}
      </Box>

      <Collapse timeout={300} in={isExpanded}>
        <Box py={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ height: 100, borderRadius: 1, overflow: "hidden" }}>
            <iframe
              title="map"
              style={{ width: "100%", height: "100%", border: "none" }}
              src={`https://maps.google.com/maps?q=${pickup?.location?.address}${pickup?.location?.city}${pickup?.location?.state}${pickup?.location?.zipcode}&ie=UTF8&iwloc=&output=embed`}
              allowFullScreen
              scrolling="no"
            ></iframe>
          </Box>

          <Box sx={{ borderRadius: 1, background: "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
            <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>PICKUP ID</Box>

            <Box mt={0.5}>
              <Box sx={{ fontWeight: "600" }}>{pickup.id}</Box>
            </Box>
          </Box>

          {pickup?.pickupContacts[0] && (
            <Box sx={{ borderRadius: 1, background: "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
              <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>POINT OF CONTACT</Box>

              <Box mt={0.5}>
                {contact(pickup).map((t, i) => {
                  return (
                    <Box key={i} sx={{ fontWeight: "600" }}>
                      {t}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {pickup?.pickupEstimatedItems[0] && (
            <Box sx={{ borderRadius: 1, background: "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
              <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>ASSETS</Box>

              <Box mt={0.5}>
                {assets(pickup).map((t, i) => {
                  return (
                    <Box key={i} sx={{ fontWeight: "600" }}>
                      {t}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {images()[0] && (
            <Box sx={{ borderRadius: 1, background: "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
              <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>PHOTOS</Box>

              <Box mt={0.5} sx={{ display: "flex", gap: 0.5, minHeight: "68px" }}>
                {images().map((img, i) => {
                  return (
                    <img key={i} className="ImageHover" src={img.url} alt={"pickup photo"} height={75} width={75} />
                  );
                })}
              </Box>
            </Box>
          )}

          {pickup?.internalNotes && (
            <Box sx={{ borderRadius: 1, background: "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
              <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>REVIVN PICKUP NOTES</Box>

              <Box mt={0.5}>
                <Box sx={{ fontWeight: "600" }}>{pickup?.internalNotes}</Box>
              </Box>
            </Box>
          )}

          {pickup?.location?.internalNotes && (
            <Box sx={{ borderRadius: 1, background: "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
              <Box sx={{ color: "rgba(0, 0, 0, 0.6)", textTransform: "uppercase" }}>LOCATION NOTES</Box>

              <Box mt={0.5}>
                <Box sx={{ fontWeight: "600" }}>{pickup?.location?.internalNotes}</Box>
              </Box>
            </Box>
          )}
        </Box>
      </Collapse>

      <Box mt={0.5} sx={{ textAlign: "right" }}>
        <Box
          component="button"
          mr={"20%"}
          onClick={() => setIsExpanded((f) => !f)}
          sx={{ p: 0.5, display: "inline-flex", lineHeight: 1 }}
        >
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
        </Box>
      </Box>
    </Box>
  );
}
