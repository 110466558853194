import { useFormikContext } from "formik";
import { CircularProgress, MenuItem, MenuItemProps } from "@mui/material";

export const SubmitAwareMenuItem = (props: MenuItemProps) => {
  const { isSubmitting } = useFormikContext();
  return (
    <MenuItem disabled={isSubmitting} {...props}>
      {props.children}
      {isSubmitting && <CircularProgress size={24} />}
    </MenuItem>
  );
};
