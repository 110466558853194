import { Box, Button, Checkbox, FormControl, FormControlLabel, MenuItem, TextField, Typography } from "@mui/material";
import { AppSelect, InputLabel } from "components/common/Input";
import { useAlert } from "hooks/useAlert";
import { revenueTeam } from "constants/revenueTeam";
import { Formik, useFormik } from "formik";
import useClients from "hooks/useClients";
import usePlans from "hooks/usePlans";
import { theme } from "index";
import { useNavigate, useParams } from "react-router-dom";
import { Client } from "types/client";
import { OrganizationPlan } from "types/organizationPlan";
import { OrganizationPlanFeature } from "types/organizationPlanFeature";
import { sleep } from "utils/sleep";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Feature } from "types/feature";
import usePricing from "hooks/usePricing";

type ClientFormProps = {
  client?: Client;
  clientOrganizationPlan?: OrganizationPlan;
};

export default function ClientForm({ client }: ClientFormProps) {
  const { pricing, isLoading, isError, updatePricing } = usePricing(client?.uuid || "");
  const { currentUserPermissions } = useCurrentUser();
  const navigate = useNavigate();
  const { createClient, updateClient, clientOrganizationPlan, updateOrganizationPlan } = useClients();
  const { plans } = usePlans();
  const params = useParams();
  const { alertError } = useAlert();
  const [featuresEnabled, setFeaturesEnabled] = useState<string[]>([]);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    setHasPermission(userHasPermission("admin", currentUserPermissions));
  }, [currentUserPermissions]);

  useEffect(() => {
    if (clientOrganizationPlan) {
      const names = clientOrganizationPlan?.organizationPlanFeatures.map(
        (e: OrganizationPlanFeature) => e.feature.name,
      );
      setFeaturesEnabled(names);
    }
  }, [clientOrganizationPlan?.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: client?.name || "",
      tier: client?.tier || "",
      accountRep: client?.accountRep?.email || "",
      salesRep: client?.salesRep?.email || "",
      wonByRep: client?.wonByRep?.email || "",
      airbaseVendorId: client?.airbaseVendorId || "",
      airbaseOnboarded: client?.airbaseOnboarded || "",
    },

    onSubmit: async (values) => {
      try {
        if (params.uuid) {
          await updateClient(values);
          navigate(`/logistics/clients/${client?.uuid}`);
        } else {
          await createClient(values);
          await sleep(300);
          navigate(`/logistics/clients`);
        }
      } catch {
        alertError("There was an error, please try again");
      }
    },
  });

  const organizationPlanFormik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      subscriptionStartedAt: Yup.date().max(new Date(), "Must be current date or a past date"),
    }),
    initialValues: {
      planId: clientOrganizationPlan?.plan.id || 0,
      priceInCents: (clientOrganizationPlan?.priceInCents && clientOrganizationPlan?.priceInCents / 100) || 0.0,
      subscriptionStartedAt: clientOrganizationPlan?.subscription.startedAt.slice(0, 10) || "",
      subscriptionExpiredAt: clientOrganizationPlan?.subscription.expiredAt.slice(0, 10) || "",
    },

    onSubmit: async (values) => {
      const payload = { ...values, organizationPlanFeatures: featuresEnabled };
      payload.priceInCents = values.priceInCents * 100;
      try {
        updateOrganizationPlan(payload);
        navigate(`/logistics/clients/${client?.uuid}`);
      } catch {
        alertError("There was an error, please try again");
      }
    },
  });

  const setFeatures = (featureName: string) => {
    if (featuresEnabled.includes(featureName)) {
      setFeaturesEnabled(featuresEnabled.filter((e) => e != featureName));
    } else {
      setFeaturesEnabled([...featuresEnabled, featureName]);
    }
  };

  return (
    <div>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{}} noValidate autoComplete="off">
        <Box
          sx={{
            p: 4,
            mb: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography fontWeight="600" fontSize="20px" mb={2}>
            Account Details
          </Typography>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Name:
              </InputLabel>
              <TextField id="name" size="small" {...formik.getFieldProps("name")} />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Account Manager
              </InputLabel>
              <AppSelect defaultValue="" id="name" {...formik.getFieldProps("accountRep")}>
                {revenueTeam.map((person) => {
                  return (
                    <MenuItem key={person[0]} value={person[0]}>
                      {person[1]}
                    </MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="salesRep">
                Sales Rep
              </InputLabel>
              <AppSelect defaultValue="" id="salesRep" {...formik.getFieldProps("salesRep")}>
                {revenueTeam.map((person) => {
                  return (
                    <MenuItem key={person[0]} value={person[0]}>
                      {person[1]}
                    </MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="salesRep">
                Won By Rep
              </InputLabel>
              <AppSelect defaultValue="" id="wonByRep" {...formik.getFieldProps("wonByRep")}>
                {revenueTeam.map((person) => {
                  return (
                    <MenuItem key={person[0]} value={person[0]}>
                      {person[1]}
                    </MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="tier">
                Sales Tier
              </InputLabel>
              <AppSelect defaultValue="" id="tier" {...formik.getFieldProps("tier")}>
                <MenuItem value={"starter"}>starter</MenuItem>
                <MenuItem value={"growth"}>growth</MenuItem>
                <MenuItem value={"enterprise"}>enterprise</MenuItem>
                <MenuItem value={"named"}>named</MenuItem>
              </AppSelect>
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="airbaseVendorId">
                Airbase Vendor ID:
              </InputLabel>
              <TextField id="airbaseVendorId" size="small" {...formik.getFieldProps("airbaseVendorId")} />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  id="airbaseOnboarded"
                  size="small"
                  {...formik.getFieldProps("airbaseOnboarded")}
                  checked={formik.getFieldProps("airbaseOnboarded").value}
                />
                Airbase Onboarded
              </InputLabel>
            </FormControl>
          </Box>
          <Box mt={4} sx={{ display: "flex", justifyContent: "end" }}>
            <Button color="primary" variant="contained" type="submit">
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>

      <Box component="form" onSubmit={organizationPlanFormik.handleSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography fontWeight="600" fontSize="20px" mb={2}>
            Plan Details
          </Typography>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
            <FormControl variant="standard" fullWidth disabled={!hasPermission}>
              <InputLabel shrink htmlFor="name">
                Product Plan:
              </InputLabel>
              <AppSelect fullWidth defaultValue="" {...organizationPlanFormik.getFieldProps("planId")}>
                {plans.map((plan) => {
                  return (
                    <MenuItem key={plan.id} value={plan.id}>
                      {plan.name}
                    </MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Subscription Price:
              </InputLabel>
              <TextField
                disabled={!hasPermission}
                fullWidth
                size="small"
                defaultValue=""
                {...organizationPlanFormik.getFieldProps("priceInCents")}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Subscription Start Date:
              </InputLabel>
              <TextField
                disabled={!hasPermission}
                fullWidth
                size="small"
                {...organizationPlanFormik.getFieldProps("subscriptionStartedAt")}
                type="date"
                error={Boolean(
                  organizationPlanFormik.touched.subscriptionStartedAt &&
                    organizationPlanFormik.errors.subscriptionStartedAt,
                )}
                helperText={
                  organizationPlanFormik.touched.subscriptionStartedAt &&
                  organizationPlanFormik.errors.subscriptionStartedAt
                }
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Subscription End Date:
              </InputLabel>
              <TextField
                disabled={!hasPermission}
                fullWidth
                size="small"
                {...organizationPlanFormik.getFieldProps("subscriptionExpiredAt")}
                type="date"
              />
            </FormControl>
          </Box>

          <FormControl variant="standard" sx={{ mt: 2 }} disabled={!hasPermission}>
            <InputLabel shrink htmlFor="name">
              Features Enabled:
            </InputLabel>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              {plans.map((plan) => {
                return plan.features.map((feature: Feature) => {
                  return (
                    <FormControlLabel
                      sx={{
                        width: "190px",
                        outline: "#A0A0A0 solid 1px",
                        borderRadius: "4px",
                        mx: 0,
                        my: 0,
                        pr: 2,
                      }}
                      label={feature.name}
                      key={feature.id}
                      value={feature.id}
                      control={
                        <Checkbox
                          checked={featuresEnabled.includes(feature.name)}
                          onChange={() => setFeatures(feature.name)}
                        ></Checkbox>
                      }
                    />
                  );
                });
              })}
            </Box>
          </FormControl>

          <Box mt={4} sx={{ display: "flex", justifyContent: "end" }}>
            <Button color="primary" variant="contained" type="submit">
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>

      {hasPermission ? (
        <Box component="form" onSubmit={organizationPlanFormik.handleSubmit} noValidate autoComplete="off">
          <Box
            sx={{
              mt: 4,
              p: 4,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography fontWeight="600" fontSize="20px" mb={2}>
              Pricing
            </Typography>
            {!isLoading &&
              !isError &&
              pricing &&
              pricing.pricings.map((price) => (
                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }} key={price.priceableType}>
                  <Formik
                    initialValues={price}
                    onSubmit={(values) => {
                      updatePricing(values);
                    }}
                  >
                    {({ handleSubmit, getFieldProps }) => (
                      <>
                        <FormControl variant="standard" fullWidth disabled={!hasPermission}>
                          <InputLabel shrink htmlFor="name">
                            {price.priceableType} discount percentage.
                          </InputLabel>
                          <TextField
                            disabled={!hasPermission}
                            fullWidth
                            size="small"
                            defaultValue=""
                            {...getFieldProps("discountPercentage")}
                          />
                        </FormControl>
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                          <div>
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={(e: any) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            >
                              SAVE
                            </Button>
                          </div>
                        </Box>
                      </>
                    )}
                  </Formik>
                </Box>
              ))}
          </Box>
        </Box>
      ) : null}
    </div>
  );
}
