import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import { Button, Chip, Modal, Tooltip, Typography } from "@mui/material";
import useInventory from "hooks/useInventory";
import { useMemo, useState } from "react";
import { Inventory } from "types/inventory";
import { StyledBoxModal } from "./technicianInventoryStyles";

const manualWipeState: string = "Manual";

export default function DeviceWipeStatus({
  refInventory,
  size,
  readonly,
}: {
  refInventory?: Inventory;
  size?: "medium" | "small";
  readonly?: boolean;
}) {
  const { updateInventory } = useInventory();
  const [open, setOpen] = useState(false);
  const [chipProps, setChipProps] = useState<{
    color: "success" | "warning" | "error" | "info";
    label: string;
    icon: any;
    desc: string;
  }>({
    label: "Wipe Status",
    icon: null,
    color: "info",
    desc: "",
  });

  const updateChipProps = useMemo(() => {
    switch (refInventory?.drive1ErasureStatus) {
      case null:
      case "":
        setChipProps({
          label: "Not Wiped",
          icon: <ErrorIcon />,
          color: "warning",
          desc: "This device has not been securely wiped yet.",
        });
        break;

      case "Passed":
      case "PASSED":
        setChipProps({
          label: "Data Wiped",
          icon: <CheckCircleIcon />,
          color: "success",
          desc: "Data was successfully erased.",
        });
        break;

      case "Failed":
      case "FAILED":
      case "N/A":
      case "%WIPE%":
        setChipProps({
          label: "Wipe Failed",
          icon: <CancelIcon />,
          color: "error",
          desc: "A disk wipe was attempted, but did not successfully pass. There may still be sensitive information on this device.",
        });
        break;

      case "Manual":
        setChipProps({
          label: "Manually Wiped",
          icon: <CheckCircleOutlineIcon />,
          color: "success",
          desc: "Data was secreuly erased or physically removed from the device manually.",
        });
        break;

      default:
        setChipProps({
          label: `Wipe Status: ${refInventory?.drive1ErasureStatus}`,
          icon: null,
          color: "info",
          desc: "Unexpected disk wipe status.",
        });
        break;
    }
  }, [refInventory?.drive1ErasureStatus]);

  const canOverride = useMemo(
    () =>
      refInventory?.drive1ErasureStatus?.toUpperCase() != "PASSED" &&
      refInventory?.drive1ErasureStatus?.toUpperCase() != manualWipeState.toUpperCase(),
    [refInventory?.drive1ErasureStatus]
  );
  const handleOpen = () => (canOverride && !readonly ? setOpen(true) : null);
  const handleClose = () => setOpen(false);
  const handleConfirm = async () => {
    if (refInventory)
      updateInventory({ ...refInventory, drive1ErasureStatus: manualWipeState }).then(() => {
        handleClose();
        location.reload();
      });
  };

  return (
    <>
      <Tooltip arrow disableInteractive title={chipProps.desc}>
        <Chip
          clickable={readonly ? false : canOverride}
          color={chipProps.color}
          onClick={handleOpen}
          icon={chipProps.icon}
          label={chipProps.label}
          variant="outlined"
          size={size}
        />
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <StyledBoxModal boxShadow={24} padding={4} gap={2} borderRadius={1}>
          <Typography variant="h6">Set Disk Wipe Status: Manually Wiped</Typography>
          <Typography variant="body1">
            Are you sure you want to set the wipe status to Manually Wiped? This should only be done for devices that
            cannot be audited by SoftThinks.
          </Typography>
          <Button variant="outlined" onClick={handleConfirm}>
            Yes, this device's data has been manually, securely erased.
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            No, nevermind.
          </Button>
        </StyledBoxModal>
      </Modal>
    </>
  );
}
