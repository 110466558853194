import { defaultOrganization, Organization } from "./organization";
import { defaultUser, User } from "./user";
import { defaultLocation, Location } from "./location";
import { PostPickupForm } from "./postPickupForm";
import { PickupContact } from "./pickupContact";
import { PickupEstimatedItem } from "./pickupEstimatedItem";
import { defaultLogisticsPartner, LogisticsPartner } from "./logisticsPartner";
import { PickupAgent } from "./pickupAgent";
import { ActiveStorageAttachment } from "./activeStorageAttachment";
import { PickupItad } from "./pickupItad";

export type Pickup = {
  id: number;
  uuid: string;
  orderNumber: string;
  scheduledAt: string;
  reason: string;
  organization: Organization;
  organizationId: string;
  user: User;
  softthinksMode: string;
  ewasteInLbs: number | "";
  location: Location;
  locationId: string;
  state: string;
  appleLaptopCount?: number;
  pcLaptopCount?: number;
  pickupContacts: PickupContact[];
  pickupEstimatedItems: PickupEstimatedItem[];
  pickupImages: ActiveStorageAttachment[];
  postPickupForms: PostPickupForm[];
  internalNotes: string;
  pickupAgent?: PickupAgent;
  agent?: LogisticsPartner;
  pickupItad?: PickupItad;
  itad?: LogisticsPartner;
  freighter?: LogisticsPartner;
  accountRep?: User;
  lotValueInCents?: number;
  estimatedLotValueInCents?: number;
  agentFees?: number;
  customerFees?: number;
  freightFees?: number;
  buybackInCents?: number;
  requestedTime?: string;
  confirmedTime?: string;
  comments?: string;
  initialReportPublishedAt?: string;
  finalReportPublishedAt?: string;
  revivnCoordinator?: User;
  logisticsClickupLink?: string;
  virtualPickup?: boolean;
};

export type NewPickup = {
  id: number;
  uuid: string;
  orderNumber: string;
  scheduledAt: string;
  reason: string;
  organization?: Organization;
  organizationId: string;
  user: User;
  softthinksMode: string;
  ewasteInLbs: number | "";
  location?: Location;
  locationId: string;
  state: string;
  pickupContacts: PickupContact[];
  contacts?: PickupContact[];
  pickupEstimatedItems: PickupEstimatedItem[];
  pickupImages: ActiveStorageAttachment[];
  postPickupForms: PostPickupForm[];
  internalNotes: string;
  pickupAgent?: PickupAgent;
  pickupItad?: PickupItad;
  agent?: LogisticsPartner;
  accountRep?: User;
  lotValueInCents?: number;
  buybackInCents?: number;
  requestedTime?: string;
  confirmedTime?: string;
  comments?: string;
  initialReportPublishedAt?: string;
  finalReportPublishedAt?: string;
  revivnCoordinator?: User;
  logisticsClickupLink?: string;
};
export const defaultPickup: Pickup = {
  id: 0,
  uuid: "",
  orderNumber: "",
  scheduledAt: "",
  organization: defaultOrganization,
  organizationId: "",
  locationId: "",
  user: defaultUser,
  softthinksMode: "",
  ewasteInLbs: "",
  reason: "",
  location: defaultLocation,
  state: "",
  internalNotes: "",
  pickupContacts: [],
  pickupEstimatedItems: [],
  pickupImages: [],
  postPickupForms: [],
  pickupAgent: defaultLogisticsPartner,
  pickupItad: defaultLogisticsPartner,
  logisticsClickupLink: "",
};

export const defaultTestPickup: NewPickup = {
  id: 1,
  uuid: "6986f",
  orderNumber: "",
  scheduledAt: "",
  organization: {
    id: "1",
    uuid: "12345uuid",
    name: "Test Org",
  },
  organizationId: "1",
  locationId: "1",
  user: defaultUser,
  softthinksMode: "",
  ewasteInLbs: "",
  reason: "",
  location: defaultLocation,
  state: "",
  internalNotes: "",
  pickupContacts: [],
  pickupEstimatedItems: [],
  pickupImages: [],
  postPickupForms: [],
  pickupAgent: defaultLogisticsPartner,
  pickupItad: defaultLogisticsPartner,
};
