import { ShipstationOrderItem } from "types/shipstationOrderItem";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareMenuItem } from "components/Form/SubmitAwareMenuItem";
import { useAlert } from "hooks/useAlert";
import { useSWRConfig } from "swr";

const validReplacementStates = ["skipped", "created"];

function useAlertModelErrors() {
  const { alertError } = useAlert();

  return (errors: Record<string, string[]>) => {
    if (!errors) return alertError("An unknown error occurred");
    return alertError(
      Object.entries(errors)
        .map(([key, value]) => `${key} ${value.join(", ")}`)
        .join("\n"),
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MarkAsHighPriorityRowAction({ row, showInMenu }: { row: ShipstationOrderItem; showInMenu?: boolean }) {
  const isPackable = validReplacementStates.includes(row.state.toLowerCase());

  const { mutate } = useSWRConfig();

  const { alertSuccess } = useAlert();
  const alertModelErrors = useAlertModelErrors();
  return (
    <RevivnApiForm
      initialValues={{ priority: 1 }}
      action={`shipstation_order_items/${row.id}`}
      method="PUT"
      onError={(error) => {
        alertModelErrors(error.response?.data?.errors);
      }}
      onSuccess={() => {
        alertSuccess("Succesfully marked as high priority, refreshing...");
        mutate((key) => typeof key === "string" && key.startsWith("shipstation_order_items"), undefined, {
          revalidate: true,
        });
      }}
    >
      <SubmitAwareMenuItem
        disabled={!isPackable}
        sx={{ textTransform: "none", color: "inherit", position: "relative" }}
      >
        <input
          disabled={!isPackable}
          type="submit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ opacity: 0, position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
        />
        Mark as High Priority
      </SubmitAwareMenuItem>
    </RevivnApiForm>
  );
}
