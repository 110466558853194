import { IconButton, Menu, MenuItem, SxProps } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useInventoryType } from "hooks/useInventory";
import { useConfirmation } from "hooks/useConfirmation";
import PrespacedText from "components/PrespacedText";

type InventoriesDataGridActionsProps = {
  sx?: SxProps;
  useInventoriesState: useInventoryType;
};

export default function EnrolledInventoriesDataGridActions({
  sx,
  useInventoriesState,
}: InventoriesDataGridActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { confirm } = useConfirmation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { selectedInventories, updateEnrolledInventories, getEnrolledInventories } = useInventoriesState;

  const handleEnrolled = (message: string) => {
    handleClose();
    confirm({
      handler: () => {
        updateEnrolledInventories(selectedInventories, message).then(() => getEnrolledInventories());
      },
      message: `Mark ${message}?`,
    });
  };

  return (
    <>
      <PrespacedText sx={{ ml: 2, mr: 1, display: "inline-block" }}>
        {selectedInventories.length ? selectedInventories.length + " selected" : ""}
      </PrespacedText>
      <IconButton sx={sx} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleEnrolled("unenrolled");
          }}
        >
          Mark as Unenrolled
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleEnrolled("enrolled");
          }}
        >
          Mark as Still Enrolled
        </MenuItem>
      </Menu>
    </>
  );
}
