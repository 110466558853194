import { Box, Button, CircularProgress, Grid, InputAdornment, Link, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputBase } from "components/common/Input";
import useDirectUpload from "../hooks/useDirectUpload";
import { useAlert } from "hooks/useAlert";
import { api, collectionFetcher, fetcher } from "services/api.service";
import { ProductCatalog } from "types/productCatalog";
import { useDropzone } from "react-dropzone";
import { theme } from "index";
import useSWR from "swr";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { useSearchParam } from "hooks/useSearchParam";

const StyledGrid = styled(Grid)(() => ({
  "& .MuiGrid-item": {
    "border-bottom": "1px solid #e0e0e0",
  },
}));

interface StockPhoto {
  id: number;
  url: string;
}

export default function StockImagesUploader() {
  const [querySku, setQuerySku] = useSearchParam("sku", "", String);
  const [sku, setSku] = useState(querySku);
  const { uploadSync } = useDirectUpload({
    keyPrefix: `${process.env.REACT_APP_STOCK_IMAGE_PREFIX}/${sku}/`,
    service: "amazon_s3_stock",
    useFilename: true,
  });
  const { alertError, alertSuccess } = useAlert();

  const { data: productCatalog } = useSWR<ProductCatalog>(
    sku ? `/product_catalogs?filter[]=sku,equals,${sku}` : null,
    fetcher,
  );
  const {
    data: stockPhotos,
    mutate,
    isLoading,
  } = useSWR<StockPhoto[]>(sku ? `/stock_photos/${sku}` : null, collectionFetcher, {
    keepPreviousData: true,
  });

  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".jpg", ".jpeg"],
    },
    disabled: !productCatalog,
    onDrop: async (files) => {
      setLoading(true);
      if (files) {
        const uploaders = files.map((file: File) => {
          return uploadSync(file);
        });
        Promise.all(uploaders)
          .then((blobs) => {
            api
              .put(`/product_catalogs/update_by_sku/${sku}`, {
                productCatalog: {
                  stockPhotos: blobs.map(({ signed_id }) => signed_id),
                },
              })
              .finally(() => {
                alertSuccess("Image uploaded successfully");
                setLoading(false);
                mutate();
              });
          })
          .catch(() => {
            alertError("Error uploading image");
          });
      }
    },
  });

  useEffect(() => {
    setSku((p) => (querySku === p ? p : querySku));
  }, [querySku]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography variant="h4">Stock Image uploader</Typography>
      </Box>
      <InputBase
        label="SKU"
        value={sku}
        onChange={(e) => setSku(e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{isLoading && <CircularProgress size={20} />}</InputAdornment>,
        }}
        onBlur={() => {
          if (sku.trim().length > 0 && sku !== querySku) setQuerySku(sku);
        }}
      />
      <Paper elevation={0} sx={{ padding: 2 }}>
        <Box
          {...getRootProps({
            sx: [
              {
                height: "190px",
                border: `1px dashed ${theme.palette.divider}`,
                borderRadius: 1,
                display: "grid",
                placeItems: "center",
              },
              isFocused && { borderColor: theme.palette.primary.main },
              isDragAccept && { borderColor: theme.palette.success.main },
              isDragReject && { borderColor: theme.palette.error.main },
              loading && { opacity: 0.5 },
            ],
          })}
        >
          <Box sx={{ textAlign: "center" }}>
            <input {...getInputProps()} />

            <Typography color="text.secondary" fontWeight={"600"}>
              Drag and drop <br />
              or
            </Typography>
            <Button sx={{ mt: 0.5 }} variant="outlined" onClick={open} disabled={!productCatalog}>
              BROWSE FILES
            </Button>
          </Box>
        </Box>
      </Paper>
      {stockPhotos && sku && (
        <Paper elevation={0} sx={{ padding: 2 }}>
          {stockPhotos.length == 0 ? (
            <Grid item xs={12}>
              <Typography my={4} sx={{ textAlign: "center" }} color={"grey.500"}>
                No images found for SKU: {sku}
              </Typography>
            </Grid>
          ) : (
            <StyledGrid container spacing={1}>
              <Grid item xs={10} />
              <Grid item xs={2}>
                <RevivnApiForm
                  initialValues={{}}
                  action={`/stock_photos/${sku}`}
                  method="delete"
                  onSuccess={() => {
                    mutate();
                    alertSuccess(`All stock photos for SKU: ${sku} deleted successfully`);
                  }}
                >
                  <SubmitAwareButton
                    variant="outlined"
                    type={"submit"}
                    color="error"
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    DELETE ALL
                  </SubmitAwareButton>
                </RevivnApiForm>
              </Grid>

              {stockPhotos.map(({ url, id }) => (
                <>
                  <Grid item xs={10}>
                    <Link href={url}>
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {url.slice(0, 75)}...{url.slice(url.lastIndexOf("/"))}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={2}>
                    <RevivnApiForm
                      initialValues={{}}
                      action={`/stock_photos/${sku}/${id}`}
                      method="delete"
                      onSuccess={() => {
                        mutate();
                        alertSuccess("Image deleted successfully");
                      }}
                    >
                      <SubmitAwareButton
                        type={"submit"}
                        variant="outlined"
                        color="error"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        DELETE
                      </SubmitAwareButton>
                    </RevivnApiForm>
                  </Grid>
                </>
              ))}
            </StyledGrid>
          )}
        </Paper>
      )}
    </Box>
  );
}
