import { useCallback, useMemo, useState } from "react";
import { Blob, DirectUpload, DirectUploadDelegate } from "activestorage";
import { HOSTNAME } from "services/api.service";

type Props = {
  keyPrefix: string;
  service?: "local" | "amazon" | "amazon_public" | "amazon_s3_stock";
  useFilename?: boolean;
};

export default function useDirectUpload({ keyPrefix, service = "amazon", useFilename = false }: Props) {
  const defaultService = process.env.REACT_APP_USE_PUBLIC_S3_SERVICE;
  const [blob, setBlob] = useState<Blob>();

  const delegate: DirectUploadDelegate = useMemo(
    () => ({
      directUploadWillCreateBlobWithXHR: (xhr: XMLHttpRequest) => {
        xhr.setRequestHeader("Key-Prefix", keyPrefix);
        xhr.setRequestHeader("Service", defaultService || service);
        if (useFilename) xhr.setRequestHeader("Use-Filename", "true");
      },
    }),
    [defaultService, keyPrefix, service, useFilename],
  );

  const reset = useCallback(() => {
    setBlob(undefined);
  }, [setBlob]);

  const upload = useCallback(
    (file: File) => {
      const directUpload = new DirectUpload(file, `${HOSTNAME}/api/direct_uploads`, delegate);
      directUpload.create((error: Error, b: Blob) => {
        if (error) {
          console.error(error);
        } else if (setBlob) {
          setBlob(b);
        }
      });
    },
    [delegate],
  );

  const uploadSync = useCallback(
    (file: File) => {
      return new Promise<Blob>((resolve, reject) => {
        const directUpload = new DirectUpload(file, `${HOSTNAME}/api/direct_uploads`, delegate);
        directUpload.create((error: Error, b: Blob) => {
          if (error) {
            console.error(error);
            reject(error);
          } else {
            resolve(b);
          }
        });
      });
    },
    [delegate],
  );

  return {
    blob,
    uploadSync,
    upload,
    reset,
  };
}
