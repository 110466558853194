import { Box, Button, FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import { deviceTypes } from "constants/device_types";
import { useEffect } from "react";
import { InventoryTemplate } from "types/inventoryTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";

type InventoryTemplateFormProps = {
  editInventoryTemplate: (formData: InventoryTemplate) => Promise<void>;
  defaultValues: InventoryTemplate;
  createInventoryTemplate: (formData: InventoryTemplate) => Promise<void>;
};

const inventoryTemplateSchema = Yup.object().shape({
  name: Yup.string().required(),
});

export default function InventoryTemplateForm({
  createInventoryTemplate,
  defaultValues,
  editInventoryTemplate,
}: InventoryTemplateFormProps) {
  const params = useParams();

  const handleSave = (values: InventoryTemplate) =>
    params.templateUuid ? editInventoryTemplate(values) : createInventoryTemplate(values);

  const { handleSubmit, getFieldProps, setValues, touched, errors, isValid, isSubmitting, resetForm } = useFormik({
    initialValues: defaultValues,
    validationSchema: inventoryTemplateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleSave(values);
      setSubmitting(false);
      resetForm();
    },
  });

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <Box sx={{ m: 3 }}>
      <form onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns={"1fr 1fr 1fr"} gap={2}>
          <TextField
            required
            fullWidth
            size="small"
            id="name"
            label="Name"
            {...getFieldProps("name")}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField fullWidth size="small" id="manufacturer" label="Manufacturer" {...getFieldProps("manufacturer")} />
          <FormControl size="small">
            <InputLabel id="demo-select-small-label">Device Type</InputLabel>
            <Select id="deviceType" size="small" {...getFieldProps("deviceType")} label="Device Type">
              {deviceTypes.map((device) => {
                return <MenuItem value={device}>{device}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <TextField fullWidth size="small" id="model" label="Model" {...getFieldProps("model")} />
          <TextField fullWidth size="small" id="description" label="Description" {...getFieldProps("description")} />
          <TextField fullWidth size="small" id="screenSize" label="Screen Size" {...getFieldProps("screenSize")} />
          <TextField fullWidth size="small" id="ram" label="RAM" {...getFieldProps("ram")} />
          <TextField fullWidth size="small" id="cpu" label="CPU" {...getFieldProps("cpu")} />
          <TextField fullWidth size="small" id="hardDrive" label="Hard Drive" {...getFieldProps("hardDriver")} />
        </Box>

        <Box display="flex" justifyContent="center" mt={4}>
          <Button variant="contained" type="submit" disabled={isSubmitting || !isValid} sx={{ width: "85px" }}>
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
}
