import { Box, Button, FormControl, TextField } from "@mui/material";
import { InputLabel } from "components/common/Input";
import { Formik } from "formik";
import { theme } from "index";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";

export function DonationCreditTransactionGroupForm() {
  const { uuid, transactionGroupId } = useParams();
  const navigate = useNavigate();
  const [transactionGroup, setTransactionGroup] = useState<any>(null);

  useEffect(() => {
    if (!transactionGroupId) return;
    api.get(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`).then(({ data }) => {
      setTransactionGroup(data);
    });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          transactionGroup || {
            donationCredit: "",
            postedAt: new Date(),
          }
        }
        onSubmit={(values) => {
          api
            .post(`/organizations/${uuid}/transaction_groups`, {
              transactionGroup: { type: "DonationCredit", ...values },
            })
            .then(({ data }) => {
              navigate(`/finance/clients/${uuid}/transaction_groups/DonationCredit/${data.id}`, {
                replace: true,
              });
            });
        }}
      >
        {({ getFieldProps, handleSubmit }) => (
          <Box
            sx={{
              p: 4,
              mb: 4,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Credit Amount:</InputLabel>
                <TextField size="small" {...getFieldProps("donationCredit")} disabled={!!transactionGroupId} />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Posted At:</InputLabel>
                <TextField
                  id="name"
                  size="small"
                  type={transactionGroupId ? "" : "date"}
                  {...getFieldProps("postedAt")}
                  disabled={!!transactionGroupId}
                />
              </FormControl>
            </Box>
            {!transactionGroupId && (
              <Box display="flex" justifyContent="flex-end" mt={6}>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
