import PrespacedText from "components/PrespacedText";
import { useInventoryType } from "hooks/useInventory";
import InventoriesDataGridActions from "./InventoriesDataGridActions";

type InventoriesDataGridSelectedProps = {
  useInventoriesState: useInventoryType;
  revertChange: () => void;
};

export default function InventoriesDataGridSelected({
  useInventoriesState,
  revertChange,
}: InventoriesDataGridSelectedProps) {
  const { selectedInventories } = useInventoriesState;
  return (
    <>
      <PrespacedText sx={{ ml: 2, mr: 1, display: "inline-block" }}>
        {selectedInventories.length ? selectedInventories.length + " selected" : ""}
      </PrespacedText>
      {selectedInventories.length ? (
        <InventoriesDataGridActions useInventoriesState={useInventoriesState} revertChange={revertChange} />
      ) : null}
    </>
  );
}
