import React, { useMemo, useState } from "react";
import { PicklistInventoryCard } from "components/Picklist/common/PicklistInventoryCard";
import { ScanAndConfirmInventoryStep } from "components/Picklist/CurrentBatch/Picking/Steps/ScanAndConfirmInventoryStep";
import { ScanAndMoveInventoryStep } from "components/Picklist/CurrentBatch/Picking/Steps/ScanAndMoveInventoryStep";
import { ItemCompleteStep } from "components/Picklist/CurrentBatch/Picking/Steps/ItemCompleteStep";
import { NotFoundStep } from "components/Picklist/CurrentBatch/Picking/Steps/NotFoundStep";
import { OrderItem } from "hooks/useCurrentFulfillmentBatch";

enum Step {
  NotFound = 0,
  ScanInventory = 1,
  ScanCart = 2,
  Complete = 3,
}

interface PickInventoryWizardProps {
  inventory: OrderItem;
  onInventoryComplete: () => void;
}

export function PickInventoryWizard({ inventory, onInventoryComplete }: PickInventoryWizardProps) {
  const [step, setStep] = useState(Step.ScanInventory);
  const [cartLocation, setCartLocation] = useState<string | undefined>();

  const stepComponent = useMemo(() => {
    switch (step) {
      case Step.ScanInventory:
        return (
          <ScanAndConfirmInventoryStep
            onClickNotFound={() => setStep(Step.NotFound)}
            onInventoryScanned={() => setStep(Step.ScanCart)}
            inventory={inventory}
          />
        );
      case Step.ScanCart:
        return (
          <ScanAndMoveInventoryStep
            inventory={inventory}
            onInventoryMoved={(location) => {
              setCartLocation(location);
              setStep(Step.Complete);
            }}
          />
        );
      case Step.NotFound:
        return <NotFoundStep onCancel={() => setStep(Step.ScanInventory)} onNotFound={onInventoryComplete} />;
      case Step.Complete:
        return <ItemCompleteStep onComplete={onInventoryComplete} cartLocation={cartLocation} />;
    }
  }, [cartLocation, inventory, onInventoryComplete, step]);

  return (
    <>
      <PicklistInventoryCard inventory={inventory} />
      {stepComponent}
    </>
  );
}
