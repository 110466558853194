import { Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useLogisticsDetails from "hooks/useLogisticsDetails";
import Form from "./Form";

export function LogisticsDetails() {
  const props = useLogisticsDetails();
  const { logisticsPickup } = props;
  return (
    <Box>
      <Box mt={4}>
        <Form {...props} />
      </Box>
    </Box>
  );
}
