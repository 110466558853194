import {
  DataGridPro,
  GridColDef,
  getGridStringOperators,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { Box, styled, Paper, LinearProgress, Stack, Button } from "@mui/material";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import useClients from "hooks/useClients";
import { Client } from "types/client";
import { api } from "services/api.service";
import TokenService from "services/token.service";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export default function ClientsDataGrid() {
  const defaultPageSize = 25;
  const useClientsState = useClients(defaultPageSize);
  const { clients, count, isLoading } = useClientsState;

  const apiRef = useGridApiRef();

  const handleImpersonate = (orgId?: number) => {
    return api
      .post("impersonation", {
        organizationUuid: orgId,
      })
      .then(({ data: { redirectUrl } }) => {
        window.open(redirectUrl, "_blank");
      })
      .catch(() => {});
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Link to={`/logistics/clients/${params.row.uuid}`}>{params.row.name}</Link>;
      },
    },
    {
      field: "salesRep",
      headerName: "Sales Rep",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return (
          <Box>
            <Button sx={{ mt: 0.5 }} variant="contained" onClick={() => handleImpersonate(params.row.uuid)}>
              Impersonate
            </Button>
          </Box>
        );
      },
    },
  ];

  const pageState = useServerSideDataGridParams(clients);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ mt: 2, p: 1 }}>
        <StyledDataGrid
          rows={clients}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 100]}
          rowCount={count}
          filterMode="server"
          apiRef={apiRef}
          checkboxSelection={false}
          checkboxSelectionVisibleOnly={false}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No clients matching your filters
              </Stack>
            ),
          }}
          componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
        />
      </Paper>
    </Box>
  );
}
