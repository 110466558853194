import { Box, Button, LinearProgress, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useClients from "hooks/useClients";
import LocationList from "./LocationList";
import { Link as RouterLink } from "react-router-dom";
import { Add, Person, Edit } from "@mui/icons-material";

export function ClientShow() {
  const { client, clientLoading, deleteLocation } = useClients();

  if (clientLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client ? client?.name : "" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">{client?.name}</Typography>
        <Typography mt={0.5}>Total Locations: {client?.locations?.length}</Typography>
      </Box>

      <Box my={2} sx={{ display: "flex", alignItems: "left", gap: "10px" }}>
        <Button component={RouterLink} variant="contained" to="edit" startIcon={<Edit />}>
          Edit Client
        </Button>
        <Button component={RouterLink} variant="contained" to="contacts" startIcon={<Person />}>
          Manage Contacts
        </Button>
        <Button component={RouterLink} variant="contained" to="locations/new" startIcon={<Add />}>
          Add New Location
        </Button>
      </Box>

      <LocationList client={client} deleteLocation={deleteLocation} />
    </Box>
  );
}
