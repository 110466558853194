import { GridFilterItem } from "@mui/x-data-grid-pro";
import { snakeCase } from "lodash";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";

const operatorsThatRequireValue = [
  "equals",
  "notEquals",
  "contains",
  "notContains",
  "startsWith",
  "endsWith",
  "is",
  "not",
  "isAnyOf",
  "after",
  "before",
  "onOrAfter",
  "onOrBefore",
  "=",
  "!=",
  ">",
  ">=",
  "<",
  "<=",
];

function removeEmptyItems(item: GridFilterItem) {
  if (operatorsThatRequireValue.includes(item.operatorValue || ""))
    return item.value !== undefined && item.value !== "";
  return true;
}

export function serializeFilterItem(item: GridFilterItem) {
  const snakeName = snakeCase(item.columnField);
  const isOperandString = /\w+/.test(item.operatorValue ?? "");
  const parts = [snakeName, isOperandString ? snakeCase(item.operatorValue ?? "") : item.operatorValue];
  if (operatorsThatRequireValue.includes(item.operatorValue ?? "")) parts.push(item.value);
  return parts.join(",");
}

export function serializeFilterModel(filterModel: GridFilterModel): string[] {
  return filterModel.items.filter(removeEmptyItems).map(serializeFilterItem);
}

export function serializeSortModel(sortModel: GridSortModel): string {
  return sortModel.map((item) => `${item.sort === "asc" ? "" : "-"}${snakeCase(item.field)}`).join(",");
}
