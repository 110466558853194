import {
  Box,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  tableCellClasses,
} from "@mui/material";
import { Donation } from "types/donation";
import { formatNumberToCurrency } from "utils/currency";
import { readableDate } from "utils/readableDate";

interface DonationHistoryDrawerProps {
  donations?: Donation[];
}
export default function DonationHistoryDrawer({ donations }: DonationHistoryDrawerProps) {
  return (
    <Box py={2}>
      <Typography fontWeight={"600"} fontSize={"18"} pl={"16px"}>
        History
      </Typography>
      {donations && donations.length > 0 ? (
        <TableContainer>
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              width: "600px",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="center">Client</TableCell>
                <TableCell align="center">Donation Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {donations.map((donation) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {readableDate(donation.createdAt)}
                  </TableCell>
                  <TableCell align="center">{donation.organization.name}</TableCell>
                  <TableCell align="center">{formatNumberToCurrency(donation.totalDollarsAmount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box p={2}>
          <Typography>There are no donations made yet.</Typography>
        </Box>
      )}
    </Box>
  );
}
