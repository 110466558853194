import { Box, Typography, Drawer, IconButton, Grid, TextField, Button, Tooltip, LinearProgress } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Inventory } from "types/inventory";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useInventoryType } from "hooks/useInventory";
import useBulkInventory from "hooks/useBulkInventory";
import { InventoryBulkEditProps } from "types/inventory";

type InventoryDrawerProps = {
  selectedInventories: Inventory[];
  open: boolean;
  toggleDrawer: () => void;
  updatedInventories: useInventoryType["updatedInventories"];
};
export default function InventoriesBulkEditDrawer({
  selectedInventories,
  toggleDrawer,
  open,
  updatedInventories,
}: InventoryDrawerProps) {
  const [loading, setLoading] = useState(false);
  const { locationInputProps, handleExScanInput, submitForm, setSelectedInventories } = useBulkInventory(
    async (invToUpdate: InventoryBulkEditProps[]) => {
      setLoading(true);
      await updatedInventories(invToUpdate);
      return toggleDrawer();
    },
  );

  useEffect(() => {
    setSelectedInventories(selectedInventories.map(({ id }) => id));
  }, [selectedInventories]);

  return (
    <Drawer anchor="right" open={open} variant="permanent">
      <Box sx={{ width: 500, m: "20px" }}>
        <Grid container>
          <Grid item xs={10} sx={{ justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Bulk change</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} size="large" onClick={() => toggleDrawer()}>
              <Close fontSize="medium" color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
            <Typography>{selectedInventories.length} inventories selected</Typography>
            <Tooltip
              title={selectedInventories.map((inventory) => {
                return inventory.id + "," + "\n";
              })}
            >
              <IconButton>
                <ArrowDropDownIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            {" "}
            {loading ? <LinearProgress /> : null}
            <Typography variant="overline">New location</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                fullWidth
                {...locationInputProps}
                onChange={(event) => {
                  handleExScanInput(event, "warehouse_location", locationInputProps.onChange);
                }}
              />
              <form onSubmit={submitForm}>
                <Button variant="contained" type="submit" sx={{ mx: 2 }}>
                  Move
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
