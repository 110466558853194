export const logisticsTeam = [
  ["2703", "Bailey Johns"],
  ["2213", "Christina Plaza"],
  ["2211", "Jack Simon"],
  ["2212", "Walker Wolfson"],
  ["9792", "Tiffany Armstrong"],
  ["10932", "Ben Eichler"],
  ["10931", "Angel Sagbigsal"],
  ["10933", "Manny Lopez"],
  ["11256", "Rory Goode"],
];
