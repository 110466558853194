import { Box, Button, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import useBenefitingOrganizations from "hooks/useBenefitingOrganizations";
import { useNavigate } from "react-router-dom";
import { benefitingOrgDefault } from "types/benefitingOrganization";
import BenefitingOrganizationForm from "./BenefitingOrganizationForm";

export default function AddNewBenefitingOrganization() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { createBenefitingOrganization, setKey, key, deleteLogo } = useBenefitingOrganizations();

  return (
    <Box
      sx={{
        padding: 6,
        minHeight: "calc(100vh - 64px)",
        lineHeight: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Benefiting Organizations
      </Typography>
      <Typography>
        Fill out the fields below to create a benefiting organization to assign further donation requests to.
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${palette.divider}`,
        }}
      >
        <Formik
          initialValues={benefitingOrgDefault}
          onSubmit={(values) => {
            createBenefitingOrganization(values);
            navigate(-1);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Box textAlign="right"></Box>
              <BenefitingOrganizationForm setKey={setKey} signedKey={key} deleteLogo={deleteLogo} />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  Add benefiting organization
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
