import {
  DataGridPro,
  GridColDef,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { Box, styled, Paper, LinearProgress, Stack } from "@mui/material";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import useClients from "hooks/useClients";
import { Client } from "types/client";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export default function FinanceClientsDataGrid() {
  const defaultPageSize = 25;
  const useClientsState = useClients(defaultPageSize);
  const { clients, count, isLoading } = useClientsState;

  const apiRef = useGridApiRef();

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Link to={`/finance/clients/${params.row.uuid}/transaction_groups`}>{params.row.name}</Link>;
      },
    },
    {
      field: "tier",
      headerName: "Tier",
      width: 200,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "salesRep",
      headerName: "Sales Rep",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Box>{params.row.salesRep && `${params.row.salesRep.firstName} ${params.row.salesRep.lastName}`}</Box>;
      },
    },
    {
      field: "accountRep",
      headerName: "Account Rep",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return (
          <Box>{params.row.accountRep && `${params.row.accountRep.firstName} ${params.row.accountRep.lastName}`}</Box>
        );
      },
    },
    {
      field: "accountingPointOfContactEmail",
      headerName: "Accounting Email",
      width: 200,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
  ];

  const pageState = useServerSideDataGridParams(clients);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ mt: 2, p: 1 }}>
        <StyledDataGrid
          rows={clients}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 100]}
          rowCount={count}
          filterMode="server"
          apiRef={apiRef}
          checkboxSelection={false}
          checkboxSelectionVisibleOnly={false}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No clients matching your filters
              </Stack>
            ),
          }}
          componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
        />
      </Paper>
    </Box>
  );
}
