import { AddCircleOutline } from "@mui/icons-material";
import { Box, Typography, MenuItem, FormControl, Grid, Alert, SelectChangeEvent } from "@mui/material";
import { AppSelect } from "components/common/Input";
import { usePickupContact } from "hooks/useLogisticsPickup";
import { useState } from "react";
import { Contact } from "types/contact";
import { PickupContact } from "types/pickupContact";

export default function PickupMainContact() {
  const { updatePickupContact, mainContact, setMainContact, organization } = usePickupContact();
  const [isSaved, setIsSaved] = useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const contact = organization?.contacts?.find((u: { id: string }) => u.id === event.target.value);
    if (!contact) return;
    updatePickupContact(contact as PickupContact).then(() => {
      setIsSaved(true);
    });
    setMainContact(event.target.value);
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          5. Choose a main point of contact for this pickup
        </Typography>
        {isSaved ? <Alert severity={"success"}>Saved</Alert> : null}
      </Grid>

      <Typography mt={0.5} variant="body2">
        On the day of pickup, this is who we will communicate with.
      </Typography>

      <Box mt={3}>
        <Typography fontWeight={600}>Select one:</Typography>

        <FormControl variant="standard" sx={{ mt: 0.5, width: "100%" }}>
          <AppSelect fullWidth value={mainContact || ""} onChange={(e) => handleChange(e)}>
            {organization &&
              organization?.contacts?.map((contact: Contact) => {
                return (
                  <MenuItem value={contact.id} key={contact.id}>
                    <strong>{`${contact?.firstName} ${contact?.lastName} ${contact?.email || ""} ${
                      contact?.phoneNumber || ""
                    }`}</strong>
                  </MenuItem>
                );
              })}
          </AppSelect>
        </FormControl>
      </Box>
    </Box>
  );
}
