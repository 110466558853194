import { Box, Paper } from "@mui/material";
import EnrolledInventoriesDataGrid from "components/EnrolledInventories/EnrolledInventoriesDataGrid";

export default function EnrolledInventoriesPage() {
  return (
    <Box>
      <Paper variant="outlined" sx={{ mx: 3.5, mt: 4, paddingBottom: "30px" }}>
        <EnrolledInventoriesDataGrid />
      </Paper>
    </Box>
  );
}
