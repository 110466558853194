import { Box, FormControl, InputLabel, Typography } from "@mui/material";
import { Label, Input, InputGroup } from "components/Form/Input";
import { theme } from "index";
import React from "react";
import { Calendar } from "./Calendar";

function PickupTimeslotsBody() {
  return (
    <div>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ flex: 1, p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
          <Typography fontSize={"18px"} fontWeight="bold">
            View availabilities for a location by entering in the following details:
          </Typography>

          <InputGroup sx={{ mt: 2 }}>
            <Label htmlFor="zipcode">Zipcode</Label>
            <Input type="text" id="zipcode" />
          </InputGroup>
        </Box>

        <Box sx={{ flexShrink: 0, height: "170px", borderRadius: 1, overflow: "hidden" }}>
          <iframe
            title="map"
            style={{ width: "100%", height: "100%", border: "none" }}
            src={`https://maps.google.com/maps?q=21611&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen
            scrolling="no"
          ></iframe>
        </Box>
      </Box>

      <Box mt={4} sx={{ p: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}>
        <Calendar />
      </Box>
    </div>
  );
}

export default PickupTimeslotsBody;
