import { useFormikContext } from "formik";
import { CircularProgress, IconButton, IconButtonProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const SubmitAwareIconButton: OverridableComponent<{
  defaultComponent: "button";
  props: IconButtonProps;
}> = (props: IconButtonProps) => {
  const { isSubmitting } = useFormikContext();
  return (
    <IconButton disabled={isSubmitting} {...props}>
      {isSubmitting ? <CircularProgress size={24} sx={{ color: "primary" }} /> : props.children}
    </IconButton>
  );
};
