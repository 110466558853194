import { useEffect, useState } from "react";
import { api } from "services/api.service";
import { Tag } from "types/tag";

export default function useTags() {
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    api.get("tags").then(({ data: { tags } }) => {
      setTags(tags);
    });
  }, []);

  return {
    tags,
  };
}
