import { fetcher } from "services/api.service";
import useSWR from "swr";
import { Inventory } from "types/inventory";

export type OrderItem = Inventory & { charger: string; trackingNumber?: string };

export enum BatchState {
  Created = "created",
  Picking = "picking",
  Packing = "packing",
  Complete = "complete",
}

export interface FulfillmentBatch {
  id: number;
  currentItem: OrderItem;
  itemsCount: number;
  orderCount: number;
  state: BatchState;
  userEmail: string;
  stateCompleteCount: number;
  pickingAt: string;
  packingAt: string;
  completeAt: string;
  pickingDuration: string;
  packingDuration: string;
  endedPickingAt: string;
}

export function useCurrentFulfillmentBatch({ except }: { except?: BatchState[] } = {}) {
  const { data, isLoading, mutate, error } = useSWR<FulfillmentBatch>("/current_fulfillment_batch", fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  return {
    batch: except && data && except.includes(data.state) ? undefined : data,
    isLoading,
    mutate,
    error,
  };
}
