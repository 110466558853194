import { Box, Button, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import useBenefitingOrganizations from "hooks/useBenefitingOrganizations";
import { useNavigate } from "react-router-dom";
import { benefitingOrgDefault } from "types/benefitingOrganization";
import BenefitingOrganizationForm from "./BenefitingOrganizationForm";

export default function EditBenefitingOrganization() {
  const { updateBenefitingOrganization, setKey, benefitingOrganization, deleteLogo } = useBenefitingOrganizations();
  const { palette } = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: 6,
        minHeight: "calc(100vh - 64px)",
        lineHeight: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Benefiting Organizations
      </Typography>
      <Typography>
        Fill out the fields below to update a benefiting organization to assign donation requests to.
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 5,
          py: 3,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${palette.divider}`,
        }}
      >
        <Formik
          enableReinitialize
          initialValues={benefitingOrganization || benefitingOrgDefault}
          onSubmit={(values) => {
            updateBenefitingOrganization(values);
            navigate(-1);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Box textAlign="right"></Box>
              <BenefitingOrganizationForm benefitingOrganization={benefitingOrganization} setKey={setKey} />
              <Box display={"flex"} justifyContent={"center"} sx={{ gap: 2 }}>
                <Button variant="contained" type="reset" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
