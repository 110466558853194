import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { BatchState, useCurrentFulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";
import React from "react";
import { Navigate } from "react-router-dom";
import { StartBatchStep } from "components/Picklist/New/StartBatchStep";
import { ClaimBatchStep } from "components/Picklist/New/ClaimBatchStep";

function PageTitle({ text, subtitle }: { text: string; subtitle?: string }) {
  return (
    <div>
      <Typography variant="h5">{text}</Typography>
      {subtitle && <Typography mt={0.5}>{subtitle}</Typography>}
    </div>
  );
}

export function PicklistNewPage() {
  const { batch, isLoading, mutate, error } = useCurrentFulfillmentBatch({ except: [BatchState.Complete] });

  if (isLoading) return null;

  if (error && error.response.status === 500) {
    return (
      <Alert severity="error">
        <AlertTitle>Batch Request Failed</AlertTitle>
        Reason: {error.message}
      </Alert>
    );
  }

  if (batch && batch.state !== "created" && batch.state !== "complete")
    return <Navigate to="/picklist/current-batch" replace={true} />;

  function refreshBatch() {
    mutate();
  }

  return (
    <>
      <PageTitle text="Outbound Units" subtitle="Fulfill e-commerce units for laptops in batches." />
      <Paper variant="outlined" sx={{ padding: 3, display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        {!batch ? (
          <ClaimBatchStep onClaim={refreshBatch} />
        ) : (
          <StartBatchStep batch={batch} onStartBatch={refreshBatch} />
        )}
      </Paper>
    </>
  );
}
