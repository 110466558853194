import {
  Autocomplete,
  Box,
  createFilterOptions,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { uniqBy } from "lodash";
import useTags from "hooks/useTags";
import { Tag } from "types/tag";
import { useState } from "react";

type TagsProps = {
  tags: TagOptionType[];
  onChange: any;
  disabled?: boolean;
  size?: any;
};

interface TagOptionType extends Tag {
  inputValue?: String;
}

export default function Tags({ tags, onChange, disabled, size }: TagsProps) {
  const { tags: defaultTags } = useTags();
  const filter = createFilterOptions<TagOptionType>();
  const [dialogIsOpen, setDialog] = useState(false);
  const [tempNewTag, setTempNewTag] = useState("");

  const handleClose = () => {
    setDialog(false);
  };

  const addTag = () => {
    onChange([...tags, { id: null, name: tempNewTag }]);
    setTempNewTag("");
    setDialog(false);
  };

  return (
    <Box
      sx={{
        ".MuiAutocomplete-tag": { background: "#0089BD", color: "#ffffff" },
        ".css-z73dk5-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon": { color: "#ffffff" },
      }}
    >
      <Autocomplete
        disabled={disabled}
        multiple
        freeSolo
        filterSelectedOptions
        inputValue={tempNewTag}
        value={tags}
        onChange={(e, newValue) => {
          onChange(
            uniqBy(
              newValue.map((t) => {
                const o = t as TagOptionType;
                if (typeof o === "string") {
                  return {
                    id: null,
                    name: o,
                  };
                } else if (o.inputValue && typeof o.inputValue === "string") {
                  return {
                    id: null,
                    name: o.inputValue,
                  };
                }
                return o;
              }),
              "name",
            ),
          );
        }}
        options={defaultTags as TagOptionType[]}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              id: 0,
              name: `This tag is not on the approved list, do you still want to add it? Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        isOptionEqualToValue={(t1, t2) => t1.name === t2.name}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.name;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: "100%" }}
            size={size}
            value={tempNewTag}
            onChange={(e) => setTempNewTag(e.target.value)}
            onKeyDown={(e) => {
              const isExisting = defaultTags.some((option) => tempNewTag === option.name);
              if (e.key === "Enter" && !isExisting) {
                e.preventDefault();
                e.stopPropagation();
                setDialog(true);
              }
            }}
          />
        )}
      />
      <div>
        <Dialog
          open={dialogIsOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Do you want to create a new tag?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By clicking yes, you are adding a new tag that doesn't exist on the approved tag list. If you didn't
              intend to, please double check your input.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={addTag}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
