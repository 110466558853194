import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Location } from "types/location";

export default function useLocations() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location>();
  const location = useLocation();

  type LocationProps = {
    name?: string;
    description?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    timezone?: string;
    approximatePeople?: number;
    internalNotes?: string;
    accessInstructions?: string;
  };

  useEffect(() => {
    if (params.locationUuid) {
      getLocation();
    }
  }, [params.locationUuid, location.pathname]);

  const getLocation = () => {
    setLocationLoading(true);
    api
      .get(`/clients/${params.uuid}/locations/${params.locationUuid}`)
      .then(({ data }) => {
        setCurrentLocation(data);
      })
      .finally(() => {
        setLocationLoading(false);
      });
  };

  const createLocation = (location: LocationProps) => {
    setLoading(true);
    api
      .post(`/clients/${params.uuid}/locations`, {
        location,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateLocation = (location: LocationProps) => {
    setLoading(true);
    api
      .put(`/clients/${params.uuid}/locations/${params.locationUuid}`, {
        location,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    currentLocation,
    createLocation,
    updateLocation,
    isLoading,
    locationLoading,
  };
}
