import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Pickup } from "types/pickup";
import { readableDate } from "utils/readableDate";

type LogisticsDetailsModalProps = {
  open: boolean;
  handleClose: () => void;
  pickup: Pickup;
};

export default function LogisticsDetailsModal({ open, handleClose, pickup }: LogisticsDetailsModalProps) {
  const typeForm = pickup?.postPickupForms.find(({ source }) => source === "typeform");
  const blueprint = pickup?.postPickupForms.find(({ source }) => source === "blueprint");

  return (
    <Modal open={open} onClose={handleClose} sx={{ mx: 4, mt: 10, overflow: "scroll" }}>
      <Paper variant="outlined" sx={{ maxWidth: 650, mx: "auto" }}>
        <IconButton onClick={handleClose} sx={{ mr: 0.5, mt: 0.5, float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 6, mb: 3, mx: 6 }}>
          <Box sx={{ paddingBottom: "20px" }}>
            <Typography variant="h5">Post Pickup Form</Typography>
            <Typography variant="h6">
              {pickup.organization.name} - {readableDate(pickup.scheduledAt)}
            </Typography>
          </Box>
          {typeForm && (
            <Box sx={{ fontSize: "18px" }}>
              <Box>
                <Box component="span" fontWeight="bold">
                  Created By:
                </Box>{" "}
                {typeForm?.fullName}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Other Crew Members:
                </Box>{" "}
                {typeForm?.otherCrewNames}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Apple Desktop Count:
                </Box>{" "}
                {typeForm?.appleDesktopCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Apple Laptop Count:
                </Box>{" "}
                {typeForm?.appleLaptopCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Large Items Count:
                </Box>{" "}
                {typeForm?.largeItemsCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Mac Mini Count:
                </Box>{" "}
                {typeForm?.macMiniCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Misc Count:
                </Box>{" "}
                {typeForm?.miscellaneousCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  PC Desktop Count:
                </Box>{" "}
                {typeForm?.pcDesktopCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  PC Laptop Count:
                </Box>{" "}
                {typeForm?.pcLaptopCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  PC Mini Count:
                </Box>{" "}
                {typeForm?.pcMiniCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Revivn Bins Count:
                </Box>{" "}
                {typeForm?.revivnBinsCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Cages Count:
                </Box>{" "}
                {typeForm?.cagesCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Servers and Switches Count:
                </Box>{" "}
                {typeForm?.serversAndSwitchesCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Tablets and Phones Count:
                </Box>{" "}
                {typeForm?.tablesPhonesMobileCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Thunderbolt Count:
                </Box>{" "}
                {typeForm?.thunderboltCount}
              </Box>
              <Box>
                <img height={250} width={250} src={typeForm?.pictureOne}></img>
                <img height={250} width={250} src={typeForm?.pictureTwo}></img>
              </Box>
            </Box>
          )}
          {!typeForm && blueprint && (
            <Box sx={{ fontSize: "18px" }}>
              <Box>
                <Box component="span" fontWeight="bold">
                  Created By:
                </Box>{" "}
                {blueprint?.answers?.completedBy}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Pickup Agents:
                </Box>{" "}
                {blueprint?.answers?.pickupAgents}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Post Pickup Report:
                </Box>{" "}
                {blueprint?.answers?.postPickupReport}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Bins Collected:
                </Box>{" "}
                {blueprint?.answers?.binsCollected}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Macbook Count:
                </Box>{" "}
                {blueprint?.answers?.macbookCount}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Mac Mini Count:
                </Box>{" "}
                {blueprint?.answers?.macminiCount}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Modal>
  );
}
