import { Button } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid-pro";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { Link } from "react-router-dom";
import { ProductCatalog } from "types/productCatalog";
import { CheckCircle, Add } from "@mui/icons-material";
const toTimestamp = (value: string) => new Date(value).toLocaleString();
const toPrice = (value: string | number) => Number(value).toFixed(2);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const timestampRenderer = (params: GridRenderCellParams<any, ProductCatalog, any>) => toTimestamp(params.value);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const priceRenderer =
  (field: "startingPrice" | "backmarketPrice" | "reebeloPrice") =>
  (params: GridRenderCellParams<any, ProductCatalog, any>) =>
    params.row[field] ? `$${toPrice(params.row[field])}` : "-";

// TODO: Fix me. There are some issues with the images because they are constantly reloaded when scrolling
// const imageRenderer = (params: GridRenderCellParams<any, ProductCatalog, any>) => {
//   return params.value ? <img src={params.value || ""} alt="No Image" width={50} height={50} /> : "";
// };

export default function ProductCatalogsDataGrid() {
  const columns: GridColDef<ProductCatalog>[] = [
    { field: "id", headerName: "Product ID", hide: true },
    { field: "merchantProductNumber", headerName: "Merchant Product Number" },
    { field: "quantity", headerName: "Quantity", type: "number" },
    { field: "sku", headerName: "SKU" },
    { field: "inventoryId", headerName: "Phoenix ID" },
    { field: "deviceType", headerName: "Device Type" },
    { field: "inventoryGroup", headerName: "Inventory Group" },
    { field: "backmarketId", headerName: "Backmarket ID" },
    { field: "reebeloId", headerName: "Reebelo ID" },
    {
      field: "startingPriceInCents",
      headerName: "Starting Price",
      renderCell: priceRenderer("startingPrice"),
      type: "number",
    },
    {
      field: "backmarketPriceInCents",
      headerName: "Backmarket Price",
      renderCell: priceRenderer("backmarketPrice"),
      type: "number",
    },
    {
      field: "reebeloPriceInCents",
      headerName: "Reebelo Price",
      renderCell: priceRenderer("reebeloPrice"),
      type: "number",
    },
    { field: "title", headerName: "Title" },
    { field: "subtitle", headerName: "Subtitle", hide: true },
    { field: "description", headerName: "Description", hide: true },
    { field: "categoryId", headerName: "Category ID", hide: true },
    { field: "primaryCategory", headerName: "Primary Category" },
    { field: "secondaryCategory", headerName: "Secondary Category", hide: true },
    { field: "condition", headerName: "Condition" },
    { field: "conditionNote", headerName: "Condition Note", hide: true },
    { field: "brand", headerName: "Brand" },
    { field: "screenSize", headerName: "Screen Size" },
    { field: "processor", headerName: "Processor" },
    { field: "model", headerName: "Model" },
    { field: "releaseYear", headerName: "Release Year" },
    { field: "hardDriveCapacity", headerName: "Hard Drive Capacity", hide: true },
    { field: "color", headerName: "Color" },
    { field: "ram", headerName: "RAM" },
    { field: "storageType", headerName: "Storage Type" },
    { field: "gpu", headerName: "GPU" },
    { field: "processorSpeed", headerName: "Processor Speed" },
    { field: "operatingSystem", headerName: "Operating System" },
    { field: "maximumResolution", headerName: "Maximum Resolution", hide: true },
    // { field: "photoUrl1", headerName: "Photo 1", renderCell: imageRenderer },
    // { field: "photoUrl2", headerName: "Photo 2", renderCell: imageRenderer },
    // { field: "photoUrl3", headerName: "Photo 3", renderCell: imageRenderer },
    // { field: "photoUrl4", headerName: "Photo 4", renderCell: imageRenderer },
    // { field: "photoUrl5", headerName: "Photo 5", renderCell: imageRenderer },
    // { field: "photoUrl6", headerName: "Photo 6", renderCell: imageRenderer },
    // { field: "photoUrl7", headerName: "Photo 7", renderCell: imageRenderer },
    // { field: "photoUrl8", headerName: "Photo 8", renderCell: imageRenderer },
    // { field: "photoUrl9", headerName: "Photo 9", renderCell: imageRenderer },
    // { field: "photoUrl10", headerName: "Photo 10", renderCell: imageRenderer },
    // { field: "photoUrl11", headerName: "Photo 11", renderCell: imageRenderer },
    // { field: "photoUrl12", headerName: "Photo 12", renderCell: imageRenderer },
    { field: "itemHeight", headerName: "Item Height", hide: true },
    { field: "itemWidth", headerName: "Item Width", hide: true },
    { field: "itemLength", headerName: "Item Length", hide: true },
    { field: "itemWeight", headerName: "Item Weight", hide: true },
    { field: "shippingPolicyName", headerName: "Shipping Policy Name", hide: true },
    { field: "daysOnShelf", headerName: "Days On Shelf", hide: true },
    { field: "daysSincePurchase", headerName: "Days Since Purchase", hide: true },
    {
      field: "createdAt",
      headerName: "Created",
      renderCell: timestampRenderer,
      filterable: false,
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      renderCell: timestampRenderer,
      filterable: false,
      width: 200,
    },
    {
      field: "stockPhotos",
      headerName: "Stock Photos",
      renderCell: (params) =>
        params.row.individualSellableProduct ? null : (
          <Link to={`/stock-photos?sku=${params.row.sku}`} style={{ textDecoration: "none" }}>
            <Button
              size="small"
              variant="text"
              color="primary"
              startIcon={params.row.hasStockPhotos ? <CheckCircle /> : <Add />}
            >
              {params.row.hasStockPhotos ? "View Photos" : "Add Photos"}
            </Button>
          </Link>
        ),
      filterable: false,
      width: 200,
    },
  ];

  return (
    <RevivnApiDataGrid
      sx={{
        backgroundColor: "background.paper",
        minHeight: 600,
        height: "calc(100vh - 10em)",
      }}
      columns={columns}
      getDetailPanelHeight={() => "auto"}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        filterPanel: { visible: false },
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true, fileName: `product_catalogs_${Date.now()}` },
        },
      }}
      url={"product_catalogs"}
    />
  );
}
