import { RevivnApiForm } from "components/Form/RevivnApiForm";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { GreyButton } from "components/Picklist/common/GreyButton";
import React, { useState } from "react";
import { Field } from "formik";

interface DefectReasonPromptProps {
  onReasonProvided: (reason: string, otherReason: string | undefined) => void;
}

function DefectReasonPrompt({ onReasonProvided }: DefectReasonPromptProps) {
  const [showOther, setShowOther] = useState(false);
  const [reason, setReason] = useState<undefined | string>();
  const [otherReason, setOtherReason] = useState<undefined | string>();
  const [error, setError] = useState<undefined | string>();
  const [otherError, setOtherError] = useState<undefined | string>();

  return (
    <>
      <FormControl error={!!error} required={true}>
        <FormLabel>
          Are you sure this unit needs to be replaced?
          <br />
          What seems to be the defect?
        </FormLabel>
        <RadioGroup
          name="reason"
          onChange={(event, value) => {
            setShowOther(value === "other");
            setReason(value);
            setOtherReason(undefined);
            setOtherError(undefined);
          }}
        >
          <FormControlLabel control={<Radio />} value="Cosmetic Defect" label="Cosmetic Defect" />
          <FormControlLabel control={<Radio />} value="Ports Defect" label="Ports Defect" />
          <FormControlLabel control={<Radio />} value="Enrolled" label="Enrolled" />
          <FormControlLabel control={<Radio />} value="other" label="Other" />
        </RadioGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
      {showOther && (
        <TextField
          size="small"
          name="other_reason"
          label="Other Reason"
          fullWidth={true}
          required={true}
          error={!!otherError}
          helperText={otherError}
          autoFocus={true}
          onChange={(event) => setOtherReason(event.target.value)}
        />
      )}
      <Button
        variant="contained"
        onClick={() => {
          setError(undefined);
          setOtherError(undefined);
          if (!reason) {
            setError("Please select a reason");
            return;
          }
          if (reason === "other" && (!otherReason || otherReason.trim().length === 0)) {
            setOtherError("Reason is required");
            return;
          }
          onReasonProvided(reason, otherReason);
        }}
      >
        Yes, and scan to new location
      </Button>
    </>
  );
}

export function TestsFailedStep({ onCancel, onComplete }: { onCancel: () => void; onComplete: () => void }) {
  const [defectReason, setDefectReason] = useState<undefined | string>();
  return (
    <Stack alignItems="center" direction="column" gap={2}>
      {!defectReason && (
        <DefectReasonPrompt
          onReasonProvided={(reason, otherReason) => {
            if (reason === "other") {
              setDefectReason(`Other: ${otherReason}`);
            } else {
              setDefectReason(reason);
            }
          }}
        />
      )}
      {defectReason && (
        <RevivnApiForm
          initialValues={{ reason: defectReason, warehouse_location: "" }}
          action="/current_fulfillment_batch/item/tests_failed"
          method="POST"
          onSuccess={onComplete}
        >
          <Stack gap={2} alignItems="center">
            <input type="hidden" name="reason" value={defectReason} />
            <Field
              name="warehouse_location"
              as={TextField}
              label="Set New Location"
              required={true}
              autoFocus={true}
              fullWidth={true}
              size="small"
            />
            <Button variant="contained" type="submit">
              Save, and move to next item
            </Button>
          </Stack>
        </RevivnApiForm>
      )}
      <GreyButton onClick={onCancel}>No, Go Back</GreyButton>
    </Stack>
  );
}
