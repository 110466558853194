import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useInventory from "hooks/useInventory";
import openInventoryPhotoPicker from "../../utils/openInventoryPhotoPicker";
import InventoryImageDeleteButton from "components/Inventories/InventoryImageDeleteButton";
import { useNavigate } from "react-router-dom";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export default function InventoryCard() {
  const [isInputActive, setIsInputActive] = useState(false);
  const wrapperRef = useRef(null);
  const { inventory, updateInventory, setInventory } = useInventory();
  const navigate = useNavigate();

  useOnClickOutside(wrapperRef, () => {
    handleSave();
  });
  const handleSave = () => {
    if (isInputActive && inventory) {
      updateInventory(inventory);
      setIsInputActive(false);
    }
  };

  const handleSerialChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (inventory) {
      const inventoryUpdated = { ...inventory, ["serialNumber"]: e.target.value };
      setInventory(inventoryUpdated);
    }
  };

  const card = (
    <React.Fragment>
      <Box ref={wrapperRef}>
        <CardContent>
          <Typography variant={"h6"}>Add images</Typography>
          <Grid container spacing={2} sx={{ padding: "5px" }}>
            <Grid item xs={6}>
              {inventory?.inventoryImages.map((image) => {
                return (
                  <Box
                    key={image.id}
                    sx={{ display: "inline-block", mx: 0.5, pb: 1.5, float: "left", position: "relative" }}
                  >
                    <InventoryImageDeleteButton image={image} inventory={inventory} setInventory={setInventory} />
                    <img width="120" height="90" src={image.url.replace(".png", "-250x250.png")} />
                  </Box>
                );
              })}
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Box sx={{ pb: "10x" }}>
                    <Typography variant={"overline"}>Inventory id</Typography>
                    <Typography variant="body1">{inventory?.id}</Typography>
                  </Box>
                  <Box sx={{ pb: "10px" }}>
                    <Typography variant={"overline"}>Pickup id</Typography>
                    <Typography variant="body1">{inventory?.pickup.id}</Typography>
                  </Box>
                  <Box
                    sx={{ pb: "10px" }}
                    onClick={() => {
                      setIsInputActive(true);
                    }}
                  >
                    <Typography variant={"overline"}>Serial number</Typography>
                    {isInputActive ? (
                      <TextField
                        size="small"
                        name="Serial number"
                        onChange={handleSerialChange}
                        value={inventory?.serialNumber}
                      />
                    ) : (
                      <Box sx={{ height: "40px" }}>
                        <Typography variant="body1">{inventory?.serialNumber}</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" alignContent="center">
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Button
                variant="outlined"
                endIcon={<AddAPhotoIcon />}
                sx={{ width: "280px", m: "10px" }}
                onClick={() => {
                  inventory
                    ? openInventoryPhotoPicker(
                        inventory,
                        (image) => {
                          setInventory((p) => {
                            if (!p) return undefined;
                            return { ...p, inventoryImages: [...p.inventoryImages, image] };
                          });
                        },
                        {
                          fromSources: ["local_file_system", "webcam"],
                        }
                      )
                    : null;
                }}
              >
                Take photo
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "280px", backgroundColor: "#ffffff", m: "10px" }}
                onClick={() => {
                  handleSave();
                  navigate("/receiving");
                }}
                endIcon={<QrCodeScannerIcon />}
              >
                Save {"&"} Scan new
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </React.Fragment>
  );
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
