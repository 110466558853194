import { Box } from "@mui/material";
import { theme } from "index";

export default function SectionWrapper({ children }: React.PropsWithChildren<any>) {
  return (
    <Box
      sx={{
        p: 4,
        background: "white",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        position: "relative",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
          p: 2,
        },
      }}
    >
      {children}
    </Box>
  );
}
