import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Laptop from "@mui/icons-material/Laptop";
import * as Yup from "yup";
import { softthinksModes } from "constants/softthinks_modes";
import { useCallback, useEffect, useState } from "react";
import usePickup from "hooks/usePickup";
import { useNavigate } from "react-router-dom";
import InventoriesDataGrid from "components/Inventories/InventoriesDataGrid";
import { debounce } from "lodash";
import { Modal } from "components/common/Modal";
import { Check, PublishedWithChanges } from "@mui/icons-material";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";
import PickupStateChip from "components/PickupState";
import { format, parseISO } from "date-fns";

export default function PickupInventoriesPage() {
  const navigate = useNavigate();
  const { pickup, updatePickup, irReady, frReady, forceFrReady } = usePickup();
  const [irOpen, setIrOpen] = useState(false);
  const [frOpen, setFrOpen] = useState(false);
  const [softthinksValue, setSoftthinkValue] = useState(pickup.softthinksMode);
  const [ewasteValue, setEwasteValue] = useState(pickup.ewasteInLbs);
  const [ewasteValueMissingError, setEwasteValueMissingError] = useState(false);
  const { currentUserPermissions } = useCurrentUser();

  const debounceInput = useCallback(
    debounce((ewasteValue, softthinksValue) => {
      updatePickup({ ...pickup, ewasteInLbs: ewasteValue, softthinksMode: softthinksValue });
    }, 1000),
    [pickup],
  );

  useEffect(() => {
    setEwasteValue(pickup.ewasteInLbs);
    setSoftthinkValue(pickup.softthinksMode);
  }, [pickup, setEwasteValue, setSoftthinkValue]);

  const handleEwasteChange = (e: any) => {
    setEwasteValueMissingError(false);
    setEwasteValue(e.target.value);
    debounceInput(e.target.value, softthinksValue);
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setSoftthinkValue(e.target.value);
    debounceInput(ewasteValue, e.target.value);
  };

  const handleIrReady = () => {
    irReady(pickup.uuid).then(() => setIrOpen(false));
  };

  const handleFrReady = () => {
    frReady(pickup.uuid).then(() => setFrOpen(false));
  };

  const handleForceFrReady = () => {
    forceFrReady(pickup.uuid).then(() => setFrOpen(false));
  };

  const macBackgroundColor = () => {
    const estimate = pickup.postPickupForms[0]?.appleLaptopCount;
    const actual = pickup.appleLaptopCount;

    if (estimate === actual) {
      return "#FFF";
    } else {
      return "#FFF2F1";
    }
  };

  const pcBackgroundColor = () => {
    const estimate = pickup.postPickupForms[0]?.pcLaptopCount;
    const actual = pickup.pcLaptopCount;

    if (estimate === actual) {
      return "#FFF";
    } else {
      return "#FFF2F1";
    }
  };

  return (
    <Box>
      <Paper variant="outlined" sx={{ mx: 3.5, mt: 4, paddingBottom: "30px" }}>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{ padding: "20px 25px 0 25px" }}
        >
          <Grid>
            <Typography variant="h6">
              Pickup inventory <PickupStateChip pickupState={pickup.state} />
              {pickup.virtualPickup && <Chip label={"Virtual Pickup"} />}
            </Typography>
          </Grid>
          <Grid>
            <FormControl>
              <TextField
                id="ewasteInLbs"
                name="ewasteInLbs"
                value={ewasteValue}
                size="small"
                label="E-waste"
                autoComplete="off"
                onChange={handleEwasteChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                }}
                error={ewasteValueMissingError}
                helperText={ewasteValueMissingError && "E-waste should be either 0 or a positive"}
                sx={{ width: "150px", pr: "20px" }}
              ></TextField>
            </FormControl>

            <Button
              variant="contained"
              sx={{ width: "150px", mr: "20px", marginBottom: "5px" }}
              startIcon={<Laptop />}
              onClick={() => navigate("inventories/new")}
            >
              Add Items
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "175px", marginBottom: "5px" }}
              startIcon={<AddAPhotoIcon />}
              onClick={() => navigate("/receiving")}
            >
              Add Photos
            </Button>
          </Grid>

          {userHasPermission("warehouse_manager", currentUserPermissions) && (
            <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ mt: 2 }}>
              <Grid>
                <Box display="flex" flexDirection="row">
                  <Button
                    startIcon={<PublishedWithChanges />}
                    onClick={() => {
                      if (Yup.number().min(0).isValidSync(ewasteValue)) {
                        setIrOpen(true);
                      } else {
                        setEwasteValueMissingError(true);
                      }
                    }}
                    variant="contained"
                    disabled={!!pickup.initialReportPublishedAt}
                    sx={{ margin: "0 10px 0 0" }}
                  >
                    Publish IR{" "}
                    {pickup.initialReportPublishedAt && format(parseISO(pickup.initialReportPublishedAt), "MM/dd/yy")}
                  </Button>
                  <IRModal open={irOpen} setOpen={setIrOpen} handleIrReady={handleIrReady} />
                  <Button
                    startIcon={<PublishedWithChanges />}
                    onClick={() => {
                      setFrOpen(true);
                    }}
                    disabled={!!pickup.finalReportPublishedAt}
                    variant="contained"
                  >
                    Publish FR{" "}
                    {pickup.finalReportPublishedAt && format(parseISO(pickup.finalReportPublishedAt), "MM/dd/yy")}
                  </Button>
                  <FRModal
                    open={frOpen}
                    setOpen={setFrOpen}
                    handleFrReady={handleFrReady}
                    handleForceFrReady={handleForceFrReady}
                  />
                </Box>
              </Grid>
              <Grid>
                <Box>
                  <TextField
                    label="Estimate Macs"
                    size="small"
                    style={{ width: 115, backgroundColor: macBackgroundColor() }}
                    value={pickup.postPickupForms[0] && pickup.postPickupForms[0].appleLaptopCount}
                    InputProps={{
                      readOnly: true,
                    }}
                    focused
                  />
                  <TextField
                    label="Processed Macs"
                    size="small"
                    style={{ marginLeft: 10, width: 115 }}
                    value={pickup.appleLaptopCount}
                    InputProps={{
                      readOnly: true,
                    }}
                    focused
                  />
                  <TextField
                    label="Estimate PCs"
                    size="small"
                    style={{ marginLeft: 10, width: 115, backgroundColor: pcBackgroundColor() }}
                    value={pickup.postPickupForms[0] && pickup.postPickupForms[0].pcLaptopCount}
                    InputProps={{
                      readOnly: true,
                    }}
                    focused
                  />
                  <TextField
                    label="Processed PCs"
                    size="small"
                    style={{ marginLeft: 10, width: 115 }}
                    value={pickup.pcLaptopCount}
                    InputProps={{
                      readOnly: true,
                    }}
                    focused
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <InventoriesDataGrid
          density="compact"
          fieldsToHide={[
            "lineId",
            "pickupId",
            "tags",
            "pickupsId",
            "iqInventoryId",
            "iqPoIdLine",
            "warehouseId",
            "manifestId",
          ]}
        />
        <Box marginTop={9} mx={3.5}>
          <FormControl size="small">
            <InputLabel>Softthinks Mode</InputLabel>
            <Select
              id="softthinksMode"
              name="softthinksMode"
              value={softthinksValue}
              sx={{ width: "200px" }}
              input={<OutlinedInput label="Softthinks mode" />}
              onChange={handleSelectChange}
            >
              {softthinksModes.map((softthinksMode) => {
                return (
                  <MenuItem key={softthinksMode} value={softthinksMode}>
                    {softthinksMode}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Paper>
    </Box>
  );
}

interface IRModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleIrReady(): void;
}

export function IRModal({ open, setOpen, handleIrReady }: IRModalProps) {
  return (
    <Modal open={open} setOpen={setOpen} width={"683px"}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <Check color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Initial Report Ready{" "}
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"}>
            Are you sure you want to proceed with publishing the initial report? (Note: The user(s) will receive an
            email notification and see this reflected under their pickup.){" "}
          </Typography>

          <Box mt={4.5} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" onClick={() => handleIrReady()}>
              Yes, publish
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              no, go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

interface FRModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleFrReady(): void;
  handleForceFrReady(): void;
}

export function FRModal({ open, setOpen, handleFrReady, handleForceFrReady }: FRModalProps) {
  const [bypassOnHold, setBypassOnHold] = useState(false);

  const publish = useCallback(() => {
    bypassOnHold ? handleForceFrReady() : handleFrReady();
  }, [handleFrReady, bypassOnHold]);

  return (
    <Modal open={open} setOpen={setOpen} width={"683px"}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <Check color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Final Report Ready
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"}>
            Are you sure you want to proceed with publishing the final report? (Note: The user(s) will receive an email
            notification and see this reflected under their pickup.)
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={bypassOnHold} onChange={(e) => setBypassOnHold(e.target.checked)}></Checkbox>}
              label={
                <Typography color="text.secondary" fontSize={"16px"}>
                  Bypass 'On Hold' state and complete pickup
                </Typography>
              }
            />
          </Box>

          <Box mt={4.5} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" onClick={publish}>
              Yes, publish
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              no, go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
