import { Box, Button, Card, Checkbox, FormGroup, FormControlLabel, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useInventory from "hooks/useInventory";
import { useNavigate } from "react-router-dom";
import { Tag } from "types/tag";
import { receivingIssueCardTags } from "constants/receivingIssueCardTags";

export default function IssueCard() {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const { inventory, updateInventory } = useInventory();
  const [statesInitialized, setStatesInitialized] = useState<boolean>(false);
  const [checkboxTagStates, setCheckboxTagStates] = useState<{ [x: string]: boolean }>(initializeCheckboxStates());
  const unlistedInventoryTags = useRef<string[]>([]);

  function initializeCheckboxStates() {
    const updateState: { [x: string]: boolean } = {};
    receivingIssueCardTags.forEach((tagName) => {
      updateState[tagName] = false;
    });
    return updateState;
  }

  useEffect(
    function getTagsFromInventory() {
      if (inventory) {
        if (inventory.tags && inventory.tags.length > 0) {
          const updateState: { [x: string]: boolean } = checkboxTagStates;
          inventory.tags?.map(({ name }) => {
            receivingIssueCardTags.includes(name)
              ? (updateState[name] = true)
              : unlistedInventoryTags.current.push(name);
          });
          setCheckboxTagStates(updateState);
        }
        setStatesInitialized(true);
      }
    },
    [inventory]
  );

  function renderCheckboxes() {
    return receivingIssueCardTags.map((tag) => (
      <FormControlLabel
        control={<Checkbox name={tag} checked={checkboxTagStates[tag]} onChange={handleChange} />}
        key={tag}
        label={tag}
        sx={{
          mx: 0,
          my: 0,
          pr: 2,
          outline: "#A0A0A0 solid 1px",
          borderRadius: "4px",
        }}
      />
    ));
  }

  function repackTags(tagList: string[]) {
    const tags: Tag[] = [];
    tagList.forEach((tag) => {
      tags.push({ id: 0, name: tag });
    });
    return tags;
  }

  function setSecurityLevel(tagList: string[]) {
    if (tagList.includes("BIOS")) {
      return "BIOS locked";
    }
    if (tagList.includes("EFI")) {
      return "EFI locked";
    }
    if (tagList.includes("ICLD")) {
      return "Icloud locked";
    }
    if (tagList.includes("ACT")) {
      return "Activation lock";
    }
    if (tagList.includes("ES")) {
      return "Elevated security";
    } else {
      return "Not locked";
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagName = e.target.name;
    const newState = e.target.checked;
    setCheckboxTagStates({ ...checkboxTagStates, [tagName]: newState });
  };

  const handleSave = () => {
    if (inventory && inventory.tags) {
      const tagsToUpdate: string[] = [];
      if (unlistedInventoryTags) tagsToUpdate.push(...unlistedInventoryTags.current);
      tagsToUpdate.push(...receivingIssueCardTags.filter((tag) => checkboxTagStates[tag] == true));
      const updatedInventory = { ...inventory, ["tags"]: repackTags(tagsToUpdate) };
      updatedInventory.securityLevel = setSecurityLevel(tagsToUpdate);
      updateInventory(updatedInventory).then(() => {
        navigate("/receiving");
      });
    }
  };

  const card = (
    <React.Fragment>
      <Box ref={wrapperRef}>
        <Typography variant={"h6"} sx={{ margin: 2 }}>
          Inventory Item Conditions
        </Typography>
        <FormGroup row={true} sx={{ gap: 2, margin: 2 }}>
          {statesInitialized ? (
            renderCheckboxes()
          ) : (
            <Skeleton variant="rectangular" animation="wave" width={"100%"} height={"80px"} />
          )}
        </FormGroup>
        <Box display={"flex"} justifyContent={"center"}>
          {statesInitialized ? (
            <Button fullWidth={true} onClick={handleSave} sx={{ margin: 2 }} variant={"contained"}>
              Save
            </Button>
          ) : (
            <Skeleton animation="pulse" variant="rectangular" width={"100%"} height={"40px"} sx={{ margin: 2 }} />
          )}
        </Box>
      </Box>
    </React.Fragment>
  );

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
