import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";

export const StyledDiv = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const PicklistLayout = () => {
  return (
    <StyledDiv>
      <Outlet />
    </StyledDiv>
  );
};
