import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Contact } from "types/contact";

export default function useContacts() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [contact, setContact] = useState<Contact>();

  type LocationContactProps = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    workPhoneNumber?: string;
    pointOfContactType?: string;
    internalNotes?: string;
  };

  useEffect(() => {
    if (params.contactUuid && params.contactUuid != "new") {
      getContact();
    }
  }, [params.contactUuid, location.pathname]);

  const getContact = () => {
    setContactLoading(true);
    api
      .get(buildUrl())
      .then(({ data }) => {
        setContact(data);
      })
      .finally(() => {
        setContactLoading(false);
      });
  };

  const buildUrl = () => {
    if (params.locationUuid && params.contactUuid) {
      return `/clients/${params.uuid}/locations/${params.locationUuid}/contacts/${params.contactUuid}`;
    } else if (params.locationUuid && !params.contactUuid) {
      return `/clients/${params.uuid}/locations/${params.locationUuid}/contacts`;
    } else if (!params.locationUuid && params.contactUuid) {
      return `/clients/${params.uuid}/contacts/${params.contactUuid}`;
    } else {
      return `/clients/${params.uuid}/contacts`;
    }
  };

  const createContact = (contact: LocationContactProps) => {
    setLoading(true);
    return api.post(buildUrl(), {
      contact,
    });
  };

  const updateContact = (contact: LocationContactProps) => {
    setLoading(true);
    return api.put(buildUrl(), {
      contact,
    });
  };

  return {
    contact,
    createContact,
    updateContact,
    contactLoading,
  };
}
