import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import { GridDensity } from "@mui/x-data-grid";
import {
  DataGridPro,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridStopCellEditModeParams } from "@mui/x-data-grid/models/api/gridEditingApi";
import { GridCellExpand } from "components/GridCellExpand";
import { colors } from "constants/colors";
import { conditions } from "constants/conditions";
import { defaultInventoryFilters } from "constants/default_inventory_filters";
import { deviceTypes } from "constants/device_types";
import { enrolledStatuses } from "constants/enrolled_statuses";
import { years } from "constants/years";
import { useAlert } from "hooks/useAlert";
import useInventory from "hooks/useInventory";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import useTags from "hooks/useTags";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Inventory } from "types/inventory";
import { Tag } from "types/tag";
import { inventoryEditValidation } from "validations/inventory";
import { labelTooltip } from "../../constants/inventory_tooltips";
import { ActionsProgressButton } from "./ActionsProgressButton";
import InventoriesDataGridSelected from "./InventoriesDataGridSelected";
import { StyledChip } from "./InventoryStyles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

type InventoriesDataGridProps = {
  fieldsToHide: Array<string>;
  density: GridDensity;
};

export default function InventoriesDataGrid({ fieldsToHide, density }: InventoriesDataGridProps) {
  const defaultPageSize = 200;
  const useInventoriesState = useInventory(defaultPageSize);
  const {
    inventories,
    count,
    selectedInventoryIds,
    setSelectedInventoryIds,
    updatedInventories,
    getInventories,
    removeTag,
    isLoading,
    printConfirmDialogOpen,
    handleClose,
    markInventoriesPrinted,
  } = useInventoriesState;
  const [alertChange, setAlertChange] = useState(false);
  const { alertSuccess, alertError } = useAlert();
  const [rowsToEdit, setRowsToEdit] = useState<{ id: number; ram?: string; serial_number?: string; sku?: string }[]>(
    [],
  );
  const [lastEditedCell, setLastEditedCell] = useState<GridStopCellEditModeParams>();
  const [isPickupAvailable, setIsPickupAvailable] = useState(false);
  const params = useParams();

  useEffect(() => {
    setIsPickupAvailable(params.pickupId ? true : false);
  }, [params.pickupId]);

  const apiRef = useGridApiRef();

  const { tags } = useTags();

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {isPickupAvailable ? <ActionsProgressButton /> : null}
      </GridToolbarContainer>
    );
  };

  const onCellEditCommit = (editedCell: {
    id: GridRowId;
    field: string;
    value: { id: number; ram?: string | undefined; serial_number?: string | undefined; sku?: string | undefined }[];
  }) => {
    let newRowsToEdit = [];
    const index = rowsToEdit.findIndex((row) => row.id === editedCell.id);
    if (index === -1) {
      const inventoryIndex = inventories.findIndex((inventory) => inventory.id === editedCell.id);

      const { id, ram, serialNumber, sku, warehouseLocation, assetTag, model } = inventories[inventoryIndex];
      const cell = {
        id: id,
        ram: ram,
        serialNumber: serialNumber,
        sku: sku,
        warehouseLocation: warehouseLocation,
        assetTag: assetTag,
        model: model,
      };
      newRowsToEdit = [...rowsToEdit, { ...cell, [editedCell.field]: editedCell.value }];
      setRowsToEdit(newRowsToEdit);
      setLastEditedCell({ id: editedCell.id, field: editedCell.field });
    } else {
      newRowsToEdit = [...rowsToEdit];
      newRowsToEdit[index] = { ...newRowsToEdit[index], [editedCell.field]: editedCell.value };
      setRowsToEdit(newRowsToEdit);
      setLastEditedCell({ id: editedCell.id, field: editedCell.field });
    }
    if (editedCell.value) {
      setAlertChange(true);
    }
    return newRowsToEdit;
  };

  function renderCommentsCell(cellParams: GridRenderCellParams<string>) {
    return <GridCellExpand value={cellParams.row.comments || ""} width={cellParams.colDef.computedWidth} />;
  }

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Label",
      description: labelTooltip,
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "equals"),
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        if (cellParams.row.pickup) {
          return (
            <Link to={`/pickups/${cellParams.row.pickup.uuid}/inventories/${cellParams.row.id}`}>
              {cellParams.row.label}
            </Link>
          );
        } else {
          return <div>{cellParams.row.label}</div>;
        }
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "equals"),
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        if (cellParams.row.pickup && !cellParams.row.label) {
          return (
            <Link to={`/pickups/${cellParams.row.pickup.uuid}/inventories/${cellParams.row.id}`}>
              {cellParams.row.id}
            </Link>
          );
        } else {
          return <div>{cellParams.row.id}</div>;
        }
      },
    },
    {
      field: "lineId",
      headerName: "Line ID",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "equals"),
    },
    {
      field: "warehouses.name",
      headerName: "WH",
      width: 75,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: [
        { label: "NY", value: "New York" },
        { label: "SF", value: "San Francisco" },
        { label: "3PL", value: "3PL" },
      ],
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        const warehouseAcronymMap = {
          "New York": "NY",
          "San Francisco": "SF",
          "3PL": "3PL",
        };
        const warehouseName: keyof typeof warehouseAcronymMap = cellParams.row.warehouse
          ? cellParams.row.warehouse.name
          : "";
        return warehouseAcronymMap[warehouseName] ? warehouseAcronymMap[warehouseName] : "";
      },
    },
    {
      field: "pickupId",
      headerName: "Pickup",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "equals"),
      hide: true,
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        if (cellParams.row.pickup) {
          return (
            <Link to={`/pickups/${cellParams.row.pickup.uuid}${defaultInventoryFilters}`}>
              {cellParams.row.pickup.id}
            </Link>
          );
        } else {
          return <div> --- </div>;
        }
      },
    },
    {
      field: "printedAt",
      headerName: "Printed",
      width: 125,
      filterable: false,
      sortable: false,
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        return <div>{!!cellParams.row.printedAt ? "Yes" : "No"}</div>;
      },
    },
    {
      field: "inventoryImages",
      headerName: "Images",
      width: 125,
      filterable: false,
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        const imageCount = cellParams.row.inventoryImages.length;
        return <div>{imageCount > 0 ? imageCount : "---"}</div>;
      },
    },
    {
      field: "iqInventoryId",
      headerName: "IQ ID",
      filterable: false,
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "iqPoIdLine",
      headerName: "IQ PO ID LINE",
      sortable: false,
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "warehouseLocation",
      headerName: "Location",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      editable: true,
    },
    {
      field: "manifestId",
      headerName: "Manifest ID",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "deviceType",
      headerName: "Device Type",
      width: 150,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: deviceTypes,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      preProcessEditCellProps: (cellParams: GridPreProcessEditCellProps<Inventory>) => {
        const hasError = !inventoryEditValidation.isValidSync({
          ...cellParams.row,
          serialNumber: cellParams.props.value,
        });
        return { ...cellParams.props, error: hasError };
      },
      editable: true,
    },
    {
      field: "model",
      headerName: "Model",
      width: 200,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      editable: true,
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "condition",
      headerName: "Condition",
      width: 125,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: conditions,
    },
    {
      field: "batteryCount",
      headerName: "Battery Cycles",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "batteryHealth",
      headerName: "Battery Health",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "inventoryGroup",
      headerName: "Inventory Grp",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "enrolledStatus",
      headerName: "Enrolled",
      width: 150,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: enrolledStatuses,
    },
    {
      field: "enrolledOrganization",
      headerName: "Enrolled Org",
      width: 175,
      filterOperators: getGridStringOperators().filter((op) => ["contains"].includes(op.value)),
    },
    {
      field: "drive1ErasureStatus",
      headerName: "Data Wipe",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => ["contains"].includes(op.value)),
    },
    {
      field: "securityLevel",
      headerName: "Security Level",
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => ["contains"].includes(op.value)),
    },
    {
      field: "year",
      headerName: "Year",
      width: 100,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: years,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "listed",
      headerName: "Listed As",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "color",
      headerName: "Color",
      width: 150,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: colors,
    },
    {
      field: "ram",
      headerName: "Ram",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "cpu",
      headerName: "CPU",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "gpu",
      headerName: "GPU",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "hardDrive",
      headerName: "Hard Drive",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "hardDriveSizeInGb",
      headerName: "Drive Size (GB)",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },

    {
      field: "comments",
      headerName: "Comments",
      width: 300,
      renderCell: renderCommentsCell,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },

    {
      field: "tags.name",
      headerName: "Tags",
      width: 250,
      sortable: false,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: tags.map(({ name }) => name),
      renderCell: (cellParams: GridRenderCellParams<Inventory>) => {
        return cellParams.row.tags.map((t: Tag) => (
          <StyledChip
            variant="outlined"
            onDelete={() => {
              removeTag(t, cellParams.id);
            }}
            key={t.id}
            label={t.name}
          />
        ));
      },
    },

    {
      field: "assetTag",
      headerName: "Asset Tag",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      editable: true,
    },
  ];

  const handleSave = () => {
    let rowsToSubmit = rowsToEdit;
    if (lastEditedCell?.id) {
      apiRef.current.commitCellChange(lastEditedCell);
      rowsToSubmit = onCellEditCommit({
        id: lastEditedCell.id,
        field: lastEditedCell.field,
        value: apiRef.current.getCellValue(lastEditedCell.id, lastEditedCell.field),
      });
    }

    return updatedInventories(rowsToSubmit)
      .then(() => {
        alertSuccess("Successfully submitted your changes");
        setAlertChange(false);
        setRowsToEdit([]);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };
  const pageState = useServerSideDataGridParams(inventories);

  const handleRevertChange = () => {
    getInventories();
    setAlertChange(false);
  };
  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ p: 1 }}>
        {alertChange ? (
          <Alert
            severity="warning"
            action={
              <Box>
                <Button color="inherit" size="small" onClick={handleSave}>
                  Save
                </Button>
                <Button color="inherit" size="small" onClick={handleRevertChange}>
                  UNDO
                </Button>
              </Box>
            }
          >
            You have unsaved changes, please press save or reverse your changes.
          </Alert>
        ) : null}

        <InventoriesDataGridSelected useInventoriesState={useInventoriesState} revertChange={handleRevertChange} />

        <div>
          <Dialog
            open={printConfirmDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Mark inventory as printed?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                By clicking yes, we will mark these inventory labels as printed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={markInventoriesPrinted} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <StyledDataGrid
          rows={inventories}
          columns={columns}
          density={density}
          checkboxSelection
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 100, 200]}
          rowCount={count}
          selectionModel={selectedInventoryIds}
          onSelectionModelChange={setSelectedInventoryIds}
          filterMode="server"
          onCellEditStart={setLastEditedCell}
          onCellEditStop={() => {
            setLastEditedCell(undefined);
          }}
          onCellEditCommit={onCellEditCommit}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns color & sku, the other columns will remain visible
                id: !fieldsToHide.includes("id"),
                lineId: !fieldsToHide.includes("lineId"),
                pickupId: !fieldsToHide.includes("pickupId"),
                printedAt: !fieldsToHide.includes("printedAt"),
                inventoryImages: !fieldsToHide.includes("inventoryImages"),
                tags: !fieldsToHide.includes("tags"),
                color: false,
                pickupsId: !fieldsToHide.includes("pickupsId"),
                warehouseId: !fieldsToHide.includes("warehouseId"),
                manifestId: !fieldsToHide.includes("manifestId"),
                ram: false,
                hardDrive: false,
                hardDriveSizeInGb: false,
                cpu: false,
                gpu: false,
                iqInventoryId: !fieldsToHide.includes("iqInventoryId"),
                iqPoIdLine: !fieldsToHide.includes("iqPoIdLine"),
                assetTag: !fieldsToHide.includes("assetTag"),
              },
            },
          }}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No inventories matching your filters
              </Stack>
            ),
          }}
          componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
        />
      </Paper>
    </Box>
  );
}
