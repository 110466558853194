import { Box, Button, Chip, styled, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useConfirmation } from "hooks/useConfirmation";
import { BenefitingOrganization } from "types/benefitingOrganization";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import useBenefitingOrganizations from "hooks/useBenefitingOrganizations";
import { useQuery } from "hooks/useQuery";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

interface BenefitingOrganizationProps {
  benefitingOrganizations: BenefitingOrganization[];
  deleteBenefitingOrganization: (benefiting_org: any) => void;
  updateBenefitingOrganizationStatus: (uuid: string, status: string) => void;
  meta: { totalPages: number; count: number };
}

export default function BenefitingOrganizationTable() {
  const defaultPageSize = 25;
  const query = useQuery();
  let page = query.get("page[number]") ?? 1;
  page = +page;
  const { meta, benefitingOrganizations, deleteBenefitingOrganization, updateBenefitingOrganizationStatus } =
    useBenefitingOrganizations({
      search: `?page%5Bnumber%5D=${page}`,
    });
  const navigate = useNavigate();
  const { confirm } = useConfirmation();

  const pageState = useServerSideDataGridParams(benefitingOrganizations);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DeleteIcon
                fontSize="small"
                sx={{ opacity: "55%" }}
                onClick={() => {
                  confirm({
                    message: "Are you sure you want to delete this benefiting organization?",
                    handler: () => {
                      deleteBenefitingOrganization(params.row.uuid);
                    },
                  });
                }}
              />
              <EditIcon
                fontSize="small"
                sx={{ opacity: "55%" }}
                onClick={() => {
                  navigate(`/benefiting-organization/${params.row.uuid}`);
                }}
              />
            </Box>
          );
        },
      },
      {
        field: "benefitingOrganization.name",
        headerName: "Benefiting Organization",
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.name,
      },
      {
        field: "benefitingOrganization.websiteUrl",
        headerName: "Website",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.websiteUrl,
      },
      {
        field: "benefitingOrganization.state",
        headerName: "Status",
        width: 150,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Chip
              label={params.row.state}
              sx={{
                backgroundColor: params.row.state === "under_review" ? "#FFF7EB" : "#EDF6ED",
                color: params.row.state === "under_review" ? "#FFB547" : "#4CAF50",
              }}
            />
          );
        },
      },
      {
        field: "benefitingOrganization.mainContactName",
        headerName: "Contact Name",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.mainContactName,
      },
      {
        field: "benefitingOrganization.mainContactEmail",
        headerName: "Contact Email",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.mainContactEmail,
      },
      {
        field: "benefitingOrganization.mainContactPhoneNumber",
        headerName: "Contact Phone Number",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.mainContactPhoneNumber,
      },
      {
        field: "Approve/Decline",
        headerName: "Approve/Decline",
        width: 200,

        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box>
              {params.row.state === "under_review" ? (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      confirm({
                        message: `Are you sure you want to approve this benefiting organization?"`,
                        handler: () => {
                          updateBenefitingOrganizationStatus(params.row.uuid, "approved");
                        },
                      });
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      confirm({
                        message: `Are you sure you want to decline this benefiting organization?"`,
                        handler: () => {
                          updateBenefitingOrganizationStatus(params.row.uuid, "declined");
                        },
                      });
                    }}
                  >
                    Decline
                  </Button>
                </Box>
              ) : (
                <Typography>Approved</Typography>
              )}
            </Box>
          );
        },
      },
    ],
    [],
  );
  return (
    <StyledDataGrid
      sx={{ border: "none", mx: 3, mb: 3 }}
      rows={benefitingOrganizations}
      columns={columns}
      rowsPerPageOptions={[5, 10, 25]}
      disableColumnFilter
      {...pageState}
      pageSize={pageState.pageSize || defaultPageSize}
      rowCount={meta.count}
    />
  );
}
