import { useMemo } from "react";
import { Box, Button, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid";
import useScan from "hooks/useScan";
import { Inventory } from "types/inventory";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { defaultInventoryFilters } from "constants/default_inventory_filters";

const InventoryGrid = ({
  inventories,
  removeInventory,
  selectedInventories,
  setSelectedInventories,
}: {
  inventories: Inventory[];
  selectedInventories: GridSelectionModel;
  setSelectedInventories: any;
  removeInventory: (inventoryId: number) => void;
}) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 100,
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          if (params.row.pickup) {
            return <Link to={`/pickups/${params.row.pickup.uuid}/inventories/${params.row.id}`}>{params.row.id}</Link>;
          } else {
            return <div>{params.row.id}</div>;
          }
        },
      },
      {
        field: "pickupsId",
        headerName: "Pickup",
        width: 100,
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          if (params.row.pickup) {
            return (
              <Link to={`/pickups/${params.row.pickup.uuid}${defaultInventoryFilters}`}>{params.row.pickup.id}</Link>
            );
          } else {
            return <div> --- </div>;
          }
        },
      },
      { field: "lineId", headerName: "Line ID", width: 150 },
      { field: "sku", headerName: "SKU", width: 150 },
      { field: "deviceType", headerName: "Device", width: 175 },
      { field: "manufacturer", headerName: "Brand", width: 125 },
      { field: "securityLevel", headerName: "Security", width: 150 },
      { field: "enrolledStatus", headerName: "Enrolled Status", width: 125 },
      { field: "warehouseLocation", headerName: "Location", width: 175 },
      { field: "serialNumber", headerName: "Serial Number", width: 150 },
      {
        field: "inventoryImages",
        headerName: "Images",
        width: 125,
        filterable: false,
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          const imageCount = params.row.inventoryImages.length;
          return <div>{imageCount > 0 ? imageCount : "---"}</div>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          return (
            <IconButton onClick={() => removeInventory(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    [removeInventory],
  );
  return (
    <DataGrid
      checkboxSelection
      rows={inventories}
      columns={columns}
      selectionModel={selectedInventories}
      onSelectionModelChange={setSelectedInventories}
    />
  );
};

export default function ScanPage() {
  const {
    addInventory,
    removeInventory,
    inventories,
    selectedInventories,
    setSelectedInventories,
    submitForm,
    errors,
    locationInputProps,
    handleExScanInput,
  } = useScan();

  return (
    <Box>
      <Paper variant="outlined">
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{ p: "10px", pr: "10px" }}
        >
          <Typography variant={"h6"}>Move Inventory</Typography>
          <Formik
            initialValues={{ inventoryId: "" }}
            onSubmit={async (values, { resetForm }) => {
              await addInventory(values.inventoryId);
              resetForm();
            }}
          >
            {({ handleSubmit, getFieldProps }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoFocus
                  autoComplete="off"
                  label="Inventory ID"
                  variant="outlined"
                  size="small"
                  {...getFieldProps("inventoryId")}
                  onChange={(event) => {
                    handleExScanInput(event, "inventory_id", getFieldProps("inventoryId").onChange);
                  }}
                />
              </form>
            )}
          </Formik>
        </Grid>
        <Box>
          <Box sx={{ height: "500px", m: "10px" }}>
            <InventoryGrid
              removeInventory={removeInventory}
              inventories={inventories.reverse()}
              selectedInventories={selectedInventories}
              setSelectedInventories={setSelectedInventories}
            />
          </Box>

          <form onSubmit={submitForm}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", m: "10px" }}>
              <TextField
                label="New Location"
                autoComplete="off"
                variant="outlined"
                size="small"
                {...locationInputProps}
                onChange={(event) => {
                  handleExScanInput(event, "warehouse_location", locationInputProps.onChange);
                }}
                error={Boolean(errors.location)}
                helperText={errors.location}
              />
              <Button
                variant="contained"
                type="submit"
                size="small"
                sx={{ ml: "10px", width: "120px" }}
                endIcon={<ArrowForwardIcon />}
                {...locationInputProps}
                disabled={selectedInventories.length === 0 || locationInputProps.value === ""}
              >
                Move
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
}
