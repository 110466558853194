export const receivingIssueCardTags: string[] = [
  "SLD",
  "LOAD",
  "DNB",
  "LCD",
  "NET",
  "ES",
  "EFI",
  "ICLD",
  "BIOS",
  "ACT",
  "BIT",
  "NEW",
];
