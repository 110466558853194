import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import React, { useCallback, useEffect, useState } from "react";
import { ReadCompleted } from "hooks/useQrScanner";
import useScan from "hooks/useScan";
import QRScanner from "components/Scan/QRScanner";
import { useNavigate } from "react-router-dom";
import SerialOCR from "components/Inventories/SerialOCR";

export const SelectedInventoryContext = React.createContext([]);

export default function ScanCard() {
  const [showScan, setShowScan] = useState(false);
  const { addInventory, inventories } = useScan();
  const navigate = useNavigate();

  const handleScannedQR = useCallback<ReadCompleted>(
    (inventoryId) => {
      if (inventoryId) {
        addInventory(inventoryId);
      }
      setShowScan(false);
    },
    [setShowScan],
  );
  useEffect(() => {
    if (inventories.length === 1) {
      navigate(`/receiving/${inventories[0].id}`);
    }
  }, [inventories]);
  const card = (
    <React.Fragment>
      <CardActions onClick={() => setShowScan(true)} sx={{ justifyContent: "center" }}>
        <CardContent sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <QrCodeScannerIcon fontSize="large" />
          <Typography fontSize="large" color="text.secondary">
            Scan (original receiving page)
          </Typography>
        </CardContent>
      </CardActions>
    </React.Fragment>
  );
  return (
    <Box>
      <Box sx={{ width: "330px" }}>
        {showScan && (
          <QRScanner open={showScan} handleClose={() => setShowScan(false)} readCompleted={handleScannedQR} />
        )}
      </Box>
      <Box sx={{ minWidth: "330px", p: 2 }}>
        <Card variant="outlined" sx={{ justifyContent: "center", cursor: "pointer" }}>
          {card}
        </Card>
      </Box>
      <SerialOCR />
    </Box>
  );
}
