import { Route, Routes } from "react-router-dom";
import InventoryPricer from "components/InventoryUploads/InventoryPricer";

export default function InventoryPricerUploadsPage() {
  return (
    <Routes>
      <Route index element={<InventoryPricer />} />
    </Routes>
  );
}
