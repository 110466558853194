import BoltIcon from "@mui/icons-material/Bolt";
import CheckIcon from "@mui/icons-material/Check";
import CycloneIcon from "@mui/icons-material/Cyclone";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";
import MemoryIcon from "@mui/icons-material/Memory";
import MouseIcon from "@mui/icons-material/Mouse";
import SanitizerIcon from "@mui/icons-material/Sanitizer";
import UsbIcon from "@mui/icons-material/Usb";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PrintIcon from "@mui/icons-material/Print";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Stack,
  Switch,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import Printable from "components/Printable";
import {
  customAttributeWorking,
  customAttributeBroken,
  customAttributeIncluded,
  customAttributeNotIncluded,
} from "constants/inventory_parts_custom_attribute_states";
import { Field, Form, Formik } from "formik";
import useInventoryParts from "hooks/useInventoryParts";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Inventory } from "types/inventory";
import { InventoryPart } from "types/inventoryPart";
import InventoryEventHistoryButton from "./InventoryEventHistoryButton";
import PartsPrintableQRCodes from "./PartsPrintableQRCodes";
import { InventoryPartsFormSchema } from "./ValidationSchema";
import ReplacePartComponent from "./InventoryPartsReplace";
import { camelCase, startCase } from "lodash";

const getDefaultValueForIncludedTypes = (
  inventoryPartValue: string | undefined,
  inventoryFunctionality: string | null | undefined,
) => {
  if (inventoryPartValue !== null && inventoryPartValue !== undefined) {
    return inventoryPartValue;
  }
  if (inventoryFunctionality === "Broken") {
    return customAttributeNotIncluded;
  }
  return customAttributeIncluded;
};

const getDefaultValueForWorkingTypes = (
  inventoryPartValue: string | undefined,
  inventoryFunctionality: string | null | undefined,
) => {
  if (inventoryPartValue !== null && inventoryPartValue !== undefined) {
    return inventoryPartValue;
  }
  if (inventoryFunctionality === "Broken") {
    return customAttributeBroken;
  }
  return customAttributeWorking;
};

function isValidModel(model: string | undefined): boolean {
  return !!model?.trim().match(/i?Mac(?:Book(?:Air|Pro)?|mini|Pro)?\d+,\d+/);
}
function inventoryHasColor(color: string | undefined) {
  return color ? true : false;
}
function inventoryHasFunctionalities(inventory: Inventory, partType: string) {
  switch (partType) {
    case "Lcd":
      return inventory.screenFunctionality !== null && inventory.cameraFunctionality !== null;
    case "Board":
      return inventory.powerFunctionality !== null && inventory.logicFunctionality !== null;
    case "TopCase":
      return (
        inventory.trackpadFunctionality !== null &&
        inventory.speakerFunctionality !== null &&
        inventory.batteryFunctionality !== null &&
        inventory.keyboardFunctionality !== null &&
        inventory.microphoneFunctionality !== null &&
        inventory.portsFunctionality !== null
      );
    default:
      return true;
  }
}

/**
 * The RadioButtonGroup for `InboundPartComponent`.
 * @param name The Formik value it is bound to.
 * @param label The label displayed on the left-hand side.
 * @param value1 The value for the first Radio button when set.
 * @param value2 The value for the second Radio button when set.
 */
function RadioButtonGroup({
  name,
  label,
  value1,
  value2,
}: {
  name: string;
  label: string;
  value1: string;
  value2: string;
}) {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs>
        <Typography display="inline" variant="overline">
          {label}
        </Typography>
      </Grid>
      <Grid container item xs>
        <Grid item xs>
          <Field
            name={name}
            type="radio"
            as={FormControlLabel}
            control={<Radio color="success" />}
            size="small"
            value={value1}
          />
        </Grid>
        <Grid item xs>
          <Field
            name={name}
            type="radio"
            as={FormControlLabel}
            control={<Radio color="error" />}
            size="small"
            value={value2}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const areFunctionalitiesPresent = (inventory: Inventory, partType: string) => {
  switch (partType) {
    case "Lcd":
      return inventory.screenFunctionality !== null && inventory.cameraFunctionality !== null;
    case "Board":
      return inventory.powerFunctionality !== null && inventory.logicFunctionality !== null;
    case "TopCase":
      return (
        inventory.trackpadFunctionality !== null &&
        inventory.speakerFunctionality !== null &&
        inventory.batteryFunctionality !== null &&
        inventory.keyboardFunctionality !== null &&
        inventory.microphoneFunctionality !== null &&
        inventory.portsFunctionality !== null
      );
    default:
      return true;
  }
};

function InboundPartComponent({ partType, inventory }: { partType: string; inventory: Inventory }) {
  const { inventoryPart, createInventoryPart, updateInventoryPart, awaitingResponse } = useInventoryParts();
  const [printConfirmDialogOpen, setPrintConfirmDialogOpen] = useState(false);
  const [partLocation, setPartLocation] = useState("");
  const printComponentRef = useRef<HTMLDivElement>(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [areFunctionalitiesMissing, setAreFunctionalitiesMissing] = useState(false);

  useEffect(() => {
    if (!inventory.color || !inventory.model) {
      setIsFormDisabled(true);
    }
  }, [inventory]);

  useEffect(() => {
    const missingFlag = !areFunctionalitiesPresent(inventory, partType);
    setAreFunctionalitiesMissing(missingFlag);
  }, [partType, inventory]);

  const closePrintConfirmDialog = () => {
    setPrintConfirmDialogOpen(false);
  };

  const PrintLabelButton = ({ label }: { label: string }) => (
    <ReactToPrint
      trigger={() => (
        <Button startIcon={<PrintIcon />} disabled={awaitingResponse} variant="contained">
          {label}
        </Button>
      )}
      content={() => (printComponentRef ? printComponentRef.current : null)}
      onAfterPrint={() => setPrintConfirmDialogOpen(true)}
    />
  );

  const defaultValues = {
    id: inventoryPart?.id ?? 0,
    type: partType,
    apn: inventoryPart?.apn ?? "",
    model: inventoryPart?.model ?? "",
    cosmeticGrade: inventoryPart?.cosmeticGrade ?? "",
    comments: inventoryPart?.comments ?? "",
    quantity: 1,
    printedAt: inventoryPart?.printedAt ?? "",
    logic: inventoryPart?.logic ?? getDefaultValueForWorkingTypes(inventoryPart?.logic, inventory.logicFunctionality),
    power:
      inventoryPart?.power ?? getDefaultValueForWorkingTypes(inventoryPart?.trackpad, inventory.powerFunctionality),
    trackpad: getDefaultValueForIncludedTypes(inventoryPart?.trackpad, inventory.trackpadFunctionality),
    speaker: getDefaultValueForIncludedTypes(inventoryPart?.speaker, inventory.speakerFunctionality),
    ioBoard: getDefaultValueForIncludedTypes(inventoryPart?.ioBoard, null),
    fans: getDefaultValueForIncludedTypes(inventoryPart?.fans, null),
    battery: getDefaultValueForIncludedTypes(inventoryPart?.battery, inventory.batteryFunctionality),
    ports: getDefaultValueForIncludedTypes(inventoryPart?.ports, inventory.portsFunctionality),
    display: getDefaultValueForWorkingTypes(inventoryPart?.display, inventory.screenFunctionality),
    webcam: getDefaultValueForWorkingTypes(inventoryPart?.webcam, inventory.cameraFunctionality),
    keyboard: getDefaultValueForIncludedTypes(inventoryPart?.keyboard, inventory.keyboardFunctionality),
    microphone: getDefaultValueForIncludedTypes(inventoryPart?.microphone, inventory.microphoneFunctionality),
  };

  return (
    <>
      {" "}
      {isFormDisabled || areFunctionalitiesMissing ? (
        <Alert severity="error">
          <Typography variant="body1">
            Part inbounding requires this inventory to have the following:
            <ul>
              <li>A valid model attribute (example: MacBookPro10,1)</li>
              <li>The color attribute cannot be empty.</li>
              <li>All {startCase(camelCase(partType))} functionalities must not be unchecked.</li>
            </ul>
          </Typography>
        </Alert>
      ) : (
        <Formik
          initialValues={defaultValues}
          enableReinitialize={true}
          validationSchema={InventoryPartsFormSchema}
          onSubmit={(values) => {
            if (inventoryPart) {
              updateInventoryPart(values).then(() => {});
            } else {
              createInventoryPart(values).then(() => {});
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container direction="row" my={2}>
                <Grid xs container item direction="row" gap={1}>
                  <Typography>Label:</Typography>
                  <Typography fontWeight="bold">{inventoryPart?.label}</Typography>
                </Grid>
                <Grid xs container item direction="row" gap={1}>
                  <Typography>Part ID:</Typography>
                  <Typography fontWeight="bold">{inventoryPart?.id}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" spacing={2}>
                <Grid item container columns={2} spacing={2}>
                  <Grid item xs>
                    <Field
                      name="apn"
                      as={TextField}
                      size="small"
                      label="APN (optional)"
                      placeholder="000-0000"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      name="model"
                      required
                      as={TextField}
                      size="small"
                      label="EMC Model"
                      placeholder=""
                      fullWidth
                      error={touched.model && Boolean(errors.model)}
                      helperText={touched.model && errors.model}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Field
                    name="cosmeticGrade"
                    required
                    as={TextField}
                    size="small"
                    label="Cosmetic Grade"
                    select
                    fullWidth
                    error={touched.cosmeticGrade && Boolean(errors.cosmeticGrade)}
                    helperText={touched.cosmeticGrade && errors.cosmeticGrade}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                  </Field>
                </Grid>
                <Grid item>
                  <Field name="comments" as={TextField} size="small" label="Comments" multiline maxRows={4} fullWidth />
                </Grid>
              </Grid>
              {partType == "Lcd" && (
                <Grid container direction="column" paddingTop={4}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs />
                    <Grid container item xs>
                      <Grid item xs>
                        <Typography display="inline" variant="body2" align="center">
                          Working
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography display="inline" variant="body2" align="center">
                          Broken
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <RadioButtonGroup
                    name="display"
                    label="Display"
                    value1={customAttributeWorking}
                    value2={customAttributeBroken}
                  />
                  <RadioButtonGroup
                    name="webcam"
                    label="Webcam"
                    value1={customAttributeWorking}
                    value2={customAttributeBroken}
                  />
                </Grid>
              )}
              {partType == "Board" && (
                <Grid container direction="column" paddingTop={4}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs />
                    <Grid container item xs>
                      <Grid item xs>
                        <Typography display="inline" variant="body2" align="center">
                          Working
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography display="inline" variant="body2" align="center">
                          Broken
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <RadioButtonGroup
                    name="logic"
                    label="Logic Function"
                    value1={customAttributeWorking}
                    value2={customAttributeBroken}
                  />
                  <RadioButtonGroup
                    name="power"
                    label="Power"
                    value1={customAttributeWorking}
                    value2={customAttributeBroken}
                  />
                </Grid>
              )}
              {partType == "TopCase" && (
                <Grid container direction="column" paddingTop={4}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs />
                    <Grid container item xs>
                      <Grid item xs>
                        <Typography display="inline" variant="overline" align="center">
                          Included
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography display="inline" variant="overline" align="center">
                          Not Included
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <RadioButtonGroup
                    name="trackpad"
                    label="Trackpad"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="speaker"
                    label="Speakers"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="ioBoard"
                    label="IO Board"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="fans"
                    label="Fans"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="ports"
                    label="Ports"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="battery"
                    label="Battery"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="keyboard"
                    label="Keyboard"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                  <RadioButtonGroup
                    name="microphone"
                    label="Microphone"
                    value1={customAttributeIncluded}
                    value2={customAttributeNotIncluded}
                  />
                </Grid>
              )}
              <Box my={6} display="grid" gridTemplateColumns="(2fr, 4fr)" gap={3}>
                <Grid container direction="row" gap={2}>
                  <Grid item xs={1}>
                    <Typography noWrap variant="overline">
                      Step 1:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} alignItems="center" gap={2}>
                    <Button startIcon={<CheckIcon />} type="submit" variant="contained">
                      {inventoryPart ? "Update" : "Save & create part"}
                    </Button>
                    {inventoryPart && (
                      <InventoryEventHistoryButton entityId={inventoryPart.id} eventType="inventory_part_events" />
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="row" gap={2}>
                  <Grid item xs={1}>
                    <Typography noWrap variant="overline">
                      Step 2:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <PrintLabelButton label={inventoryPart?.printedAt ? "Reprint Label" : "Print Label"} />
                  </Grid>
                </Grid>
                <Grid container direction="row" gap={2}>
                  <Grid item xs={1}>
                    <Typography noWrap variant="overline">
                      Step 3:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} alignItems="center" gap={2}>
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder={inventoryPart?.warehouseLocation || "Set Location"}
                      value={partLocation}
                      onChange={(e) => setPartLocation(e.target.value)}
                    />
                    <Button
                      size="medium"
                      startIcon={<EditLocationAltIcon />}
                      disabled={awaitingResponse || partLocation == ""}
                      variant="text"
                      onClick={() => {
                        if (inventoryPart) {
                          updateInventoryPart({ ...inventoryPart, warehouseLocation: partLocation });
                        }
                      }}
                    >
                      Change
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Dialog open={printConfirmDialogOpen} onClose={closePrintConfirmDialog}>
                <DialogTitle>Label Printed Confirmation</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    By clicking yes, we will mark this inventory part label as printed.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closePrintConfirmDialog}>No</Button>
                  <Button
                    onClick={() => {
                      if (inventoryPart) {
                        updateInventoryPart({ ...inventoryPart, printedAt: new Date().toISOString() });
                        closePrintConfirmDialog();
                      }
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              {printComponentRef && inventoryPart !== undefined && (
                <Printable>
                  <PartsPrintableQRCodes selectedInventoryParts={[inventoryPart]} ref={printComponentRef} />
                </Printable>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

function replaceOrInboundComponent(replaceMode: boolean, parentInventory: Inventory, type: string) {
  const hasValidModel = isValidModel(parentInventory?.model);
  const hasColor = inventoryHasColor(parentInventory?.color);
  const hasFunctionalities = inventoryHasFunctionalities(parentInventory, type);
  const canReplace = hasValidModel && hasColor && hasFunctionalities;

  if (replaceMode) {
    return <ReplacePartComponent partType={type} canReplace={canReplace} />;
  } else if (!!parentInventory) {
    return <InboundPartComponent partType={type} inventory={parentInventory} />;
  }
}

const getTopCaseChips = (part: InventoryPart) => {
  return (
    <>
      {part.trackpad == customAttributeIncluded ? null : (
        <Chip
          icon={<MouseIcon />}
          color={part.trackpad == customAttributeIncluded ? "success" : "error"}
          label="Trackpad"
          size="small"
        />
      )}
      {part.speaker == customAttributeIncluded ? null : (
        <Chip
          icon={<VolumeUpIcon />}
          color={part.speaker == customAttributeIncluded ? "success" : "error"}
          label="Speaker"
          size="small"
        />
      )}
      {part.ioBoard == customAttributeIncluded ? null : (
        <Chip
          icon={<MemoryIcon />}
          color={part.ioBoard == customAttributeIncluded ? "success" : "error"}
          label="I/O Board"
          size="small"
        />
      )}
      {part.fans == customAttributeIncluded ? null : (
        <Chip
          icon={<CycloneIcon />}
          color={part.fans == customAttributeIncluded ? "success" : "error"}
          label="Fans"
          size="small"
        />
      )}
      {part.battery == customAttributeIncluded ? null : (
        <Chip
          icon={<BoltIcon />}
          color={part.battery == customAttributeIncluded ? "success" : "error"}
          label="Battery"
          size="small"
        />
      )}
      {part.ports == customAttributeIncluded ? null : (
        <Chip
          icon={<UsbIcon />}
          color={part.ports == customAttributeIncluded ? "success" : "error"}
          label="Ports"
          size="small"
        />
      )}
    </>
  );
};

const getBoardChips = (part: InventoryPart) => {
  return (
    <>
      {part.logic == customAttributeWorking ? null : (
        <Chip
          icon={<CheckIcon />}
          color={part.logic == customAttributeWorking ? "success" : "error"}
          label="Functional"
          size="small"
        />
      )}
      {part.power == customAttributeWorking ? null : (
        <Chip
          icon={<SanitizerIcon />}
          color={part.power == customAttributeWorking ? "success" : "error"}
          label="Data Sanitized"
          size="small"
        />
      )}
    </>
  );
};

const partTypeChipsMap: { [key: string]: (part: InventoryPart) => JSX.Element | null } = {
  TopCase: getTopCaseChips,
  Board: getBoardChips,
};

const getPartTypeChips = (partType: string, part: InventoryPart) => {
  const getChips = partTypeChipsMap[partType];
  return getChips ? getChips(part) : null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PopulateReplacementParts = ({
  partType,
  inventoryParts,
}: {
  partType: string;
  inventoryParts: Array<InventoryPart>;
}) => {
  const parts = inventoryParts.map((part, index, array) => (
    <MenuItem key={part.id} value={index} dense divider={index != array.length - 1}>
      <Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Typography display="inline" variant="caption">
              Label:{" "}
            </Typography>
            <Typography display="inline" variant="overline">
              {part.label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography display="inline" variant="caption">
              Part ID:
            </Typography>
            <Typography display="inline" variant="overline">
              {part.id}
            </Typography>
          </Grid>
          <Grid item>
            <Typography display="inline" variant="caption">
              Grade:{" "}
            </Typography>
            <Typography display="inline" variant="overline">
              {part.cosmeticGrade}
            </Typography>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1}>
          {getPartTypeChips(partType, part)}
        </Stack>
      </Grid>
    </MenuItem>
  ));

  if (inventoryParts.length > 0) {
    return [
      <MenuItem disabled divider dense>
        <Typography variant="body2">Select a part you would like to use as a replacement:</Typography>
      </MenuItem>,
      parts,
    ];
  } else {
    return (
      <MenuItem disabled>
        <Stack direction="row" spacing={1} alignItems="center">
          <DeveloperBoardOffIcon />
          <Typography variant="body1">No compatible inventory parts found.</Typography>
        </Stack>
      </MenuItem>
    );
  }
};

export default function InventoryPartsPanel({
  partType,
  parentInventory,
}: {
  partType: string;
  parentInventory?: Inventory;
}) {
  const [replaceMode, setReplaceMode] = useState<boolean>(false);
  const handleReplaceModeChange = useCallback(
    (event, checked) => {
      setReplaceMode(checked);
    },
    [setReplaceMode],
  );

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="start" mt={2}>
        <FormControlLabel
          control={
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      color: "primary.main",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "secondary.main",
                    },
                    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                      backgroundColor: "primary.main",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "secondary.main",
                    },
                  }}
                />
              }
              label="Replace"
              checked={replaceMode}
              onChange={handleReplaceModeChange}
              sx={{ ml: 1 }}
            />
          }
          label="Inbound"
          labelPlacement="start"
        />
      </Box>
      {parentInventory && replaceOrInboundComponent(replaceMode, parentInventory, partType)}
    </React.Fragment>
  );
}
