import { Fullscreen } from "@mui/icons-material";
import { alpha, Box, Dialog, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export default function ImageOverlay({
  image,
  showExpandButton,
  children,
}: {
  image?: string;
  showExpandButton?: boolean;
  children?: React.ReactNode;
}): JSX.Element {
  function ExpandPhotoButton() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
      <>
        <Tooltip title="Expand" arrow placement="top">
          <IconButton size="small" onClick={handleOpen}>
            <Fullscreen fontSize="small" />
          </IconButton>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <img src={image} loading="lazy" />
        </Dialog>
      </>
    );
  }

  return (
    <Box
      position="absolute"
      className="showOnHover"
      top={0}
      right={0}
      display="flex"
      flexDirection="row"
      gap="0.25em"
      bgcolor={alpha("#fff", 0.8)}
      borderRadius="0 0 0 4px"
    >
      {showExpandButton && <ExpandPhotoButton />}
      {children}
    </Box>
  );
}
