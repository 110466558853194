import { Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import PartnerForm from "./PartnerForm";

export function PartnerNew() {
  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Partners", to: "/logistics/partners" },
          { label: "Add New Partner" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <PartnerForm />
      </Box>
    </Box>
  );
}
