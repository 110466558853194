import { useCallback } from "react";
import { Person } from "@mui/icons-material";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "index";
import { Client } from "types/client";
import { Contact } from "types/contact";
import { useConfirmation } from "hooks/useConfirmation";

type ClientShowProps = {
  client?: Client;
  deleteContact: (contact: Contact) => any;
};

export default function ContactsList({ client, deleteContact }: ClientShowProps) {
  const { confirm } = useConfirmation();

  const handleContactDelete = useCallback((contact: Contact) => {
    confirm({
      handler: () => deleteContact(contact),
      title: "Delete Contact",
      message: `Are you sure you want to delete ${contact.firstName} ${contact.lastName}?`,
    });
  }, []);

  return (
    <Box mt={4} sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: 2 }}>
      {client &&
        client.contacts &&
        client.contacts.map((contact: any) => (
          <Box
            key={contact.uuid}
            sx={{
              py: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              minHeight: "150px",
            }}
          >
            <Box sx={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
              <Typography
                variant="body2"
                fontWeight="400"
                sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}
              >
                <Person />
                {`${contact.firstName} ${contact.lastName}`}
                <Box>{contact.email}</Box>
                <Box>{contact.phoneNumber}</Box>
                <Box>{contact.internalNotes}</Box>
              </Typography>

              <Stack mt={2} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Link
                  sx={{ textDecoration: "none" }}
                  component={RouterLink}
                  to={`/logistics/clients/${client.uuid}/contacts/${contact.uuid}/edit`}
                >
                  Edit
                </Link>
                <Link
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                  component={"button"}
                  onClick={() => handleContactDelete(contact)}
                >
                  Delete
                </Link>
              </Stack>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
