import { useState, useEffect } from "react";
import { api } from "services/api.service";
import { Plan } from "types/plan";

export default function usePlans(defaultPageSize?: number) {
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = () => {
    api.get(`/plans`).then(({ data: { plans } }) => {
      setPlans(plans);
    });
  };

  return {
    plans,
  };
}
