import EditLocationIcon from "@mui/icons-material/EditLocation";
import { Button, Grid, IconButton, Popover, TextField, Tooltip } from "@mui/material";
import useInventory from "hooks/useInventory";
import { useState } from "react";
import { Inventory } from "types/inventory";

export default function InventoryChangeLocationButton({ refInventory }: { refInventory: Inventory }) {
  const { updateInventory } = useInventory();
  const [newLocation, setNewLocation] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setNewLocation("");
    setAnchorEl(null);
  };

  const onSetLocation = async () => {
    updateInventory({ ...refInventory, warehouseLocation: newLocation }).then(() => {
      location.reload();
      handleClose();
    });
  };

  return (
    <>
      <Tooltip title="Change Location" disableInteractive>
        <IconButton onClick={handleClick}>
          <EditLocationIcon color="primary" />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container margin={1.5} alignItems="center" gap={1}>
          <TextField
            autoFocus
            label="Set New Location"
            onChange={(e) => setNewLocation(e.target.value)}
            placeholder={refInventory.warehouseLocation}
            value={newLocation}
            name="newLocation"
            size="small"
            variant="outlined"
          />
          <Button
            size="small"
            variant="contained"
            onClick={onSetLocation}
            disabled={newLocation == "" || newLocation == refInventory.warehouseLocation}
          >
            Change
          </Button>
        </Grid>
      </Popover>
    </>
  );
}
