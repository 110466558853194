import useSWR from "swr";
import { fetcher } from "services/api.service";
import { useEffect, useMemo } from "react";
import { DataGridPro, GridNoRowsOverlay, GridToolbar } from "@mui/x-data-grid-pro";
import { LinearProgress } from "@mui/material";
import { ResponseWithMeta } from "types/paginatedResponse";
import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";
import { ServerErrorOverlay } from "components/DataGrid/helpers/ServerErrorOverlay";
import { useLazyColumns } from "components/DataGrid/helpers/useLazyColumns";
import { useSearchParam } from "hooks/useSearchParam";
import { useSearchParamArray } from "hooks/useSearchParamArray";
import { deserializeFilterModel, deserializeSortModel } from "components/DataGrid/helpers/deserializers";
import { serializeFilterModel, serializeSortModel } from "components/DataGrid/helpers/serializers";

interface RevivnApiDataGridProProps extends Partial<DataGridProProps> {
  url: string;
  filtersStorageName?: string;
}

function extractCollectionKey(data: ResponseWithMeta) {
  const collectionKey = Object.keys(data).find((key) => key !== "meta");
  if (!collectionKey) throw new Error("Unable to extract collection key from response");
  return collectionKey;
}

const DEFAULT_PAGE_SIZE = 25;

export const RevivnApiDataGrid = (props: RevivnApiDataGridProProps) => {
  const [pageSize, setPageSize] = useSearchParam("page[size]", DEFAULT_PAGE_SIZE, Number);
  const [page, setPage] = useSearchParam("page[number]", 1, Number);
  const [sort, setSort] = useSearchParam("sort", "", String);
  const [filters, setFilters] = useSearchParamArray("filter[]", String);
  const [filterLinkOperator, setFilterLinkOperator] = useSearchParam("filter_link_operator", "AND", String);

  const { data, isLoading, error } = useSWR(() => {
    if (props.url.includes("?")) {
      return props.url + "&" + window.location.search.slice(1);
    } else {
      return props.url + window.location.search;
    }
  }, fetcher);

  const rows = useMemo(() => (data ? data[extractCollectionKey(data)] : []), [data]);
  const lazyColumns = useLazyColumns(rows);
  const columns = props.columns || lazyColumns;
  const NoRowsOverlay = error ? ServerErrorOverlay : GridNoRowsOverlay;
  const totalCount = data?.meta.count || 0;

  const storedFilters = props.filtersStorageName && JSON.parse(localStorage.getItem(props.filtersStorageName) || "[]");
  const storedFilterOperator =
    (props.filtersStorageName && localStorage.getItem(`${props.filtersStorageName}Operator`)) || "";

  useEffect(() => {
    if (props.filtersStorageName) {
      setFilters(storedFilters);
      if (storedFilterOperator) {
        setFilterLinkOperator(storedFilterOperator);
      }
    }
  }, []);
  return (
    <DataGridPro
      autoHeight
      sx={{ background: "background.paper" }}
      rows={rows}
      rowCount={totalCount}
      columns={columns}
      loading={isLoading}
      initialState={{
        sorting: { sortModel: deserializeSortModel(sort) },
        filter: {
          filterModel: deserializeFilterModel(storedFilters || filters, storedFilterOperator || filterLinkOperator),
        },
        pagination: { pageSize },
      }}
      pagination={true}
      paginationMode="server"
      page={page - 1}
      onPageChange={(newPage) => setPage(newPage + 1)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 25, 50, 100]}
      filterMode="server"
      onFilterModelChange={(model) => {
        const serializedFilters = serializeFilterModel(model);
        setFilters(serializedFilters);
        setPage(1);
        if (props.filtersStorageName) {
          localStorage.setItem(props.filtersStorageName, JSON.stringify(serializedFilters));
          localStorage.setItem(`${props.filtersStorageName}Operator`, model.linkOperator?.toUpperCase() || "AND");
        }
        if (model.items.length > 1 && model.linkOperator) setFilterLinkOperator(model.linkOperator.toUpperCase());
      }}
      sortingMode="server"
      onSortModelChange={(model) => {
        setPage(1);
        setSort(serializeSortModel(model));
      }}
      components={{ LoadingOverlay: LinearProgress, Toolbar: GridToolbar, NoRowsOverlay }}
      componentsProps={{
        noRowsOverlay: { error },
      }}
      {...props}
    />
  );
};
