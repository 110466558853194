import { Box, Button, Grid, Link, Paper, Popover, imageListClasses } from "@mui/material";
import useInventory from "hooks/useInventory";
import React, { useRef, useState } from "react";
import openInventoryPhotoPicker from "utils/openInventoryPhotoPicker";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function TechnicianInventoryImages() {
  const wrapperRef = useRef(null);
  const { inventory, setInventory } = useInventory();
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const open = Boolean(anchorElement);
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorElement(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
    setImgSrc("");
  };

  return (
    <React.Fragment>
      <Box ref={wrapperRef} display="block">
        <Grid container gap={2}>
          {inventory?.inventoryImages.map((image) => {
            return (
              <Box
                key={image.id}
                width={72}
                height={72}
                onClick={(e) => {
                  setImgSrc(image.url);
                  handleClick(e);
                }}
                sx={{ cursor: "pointer" }}
              >
                <img width="100%" height="100%" src={image.url.replace(".png", "-250x250.png")} />
              </Box>
            );
          })}
          <Popover
            id="expand-image-popover"
            open={open}
            anchorEl={anchorElement}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Grid container sx={{ width: 500, height: 500 }}>
              {imgSrc ? <img src={imgSrc} width="100%" height="100%" style={{ objectFit: "contain" }} /> : null}
              <a href={imgSrc} target="_blank">
                <OpenInNewIcon
                  color="action"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                />
              </a>
            </Grid>
          </Popover>
          <Box
            component={Button}
            variant="outlined"
            alignSelf="center"
            width={64}
            height={64}
            onClick={() => {
              inventory
                ? openInventoryPhotoPicker(
                    inventory,
                    (image) => {
                      setInventory((p) => {
                        if (!p) return undefined;
                        return { ...p, inventoryImages: [...p.inventoryImages, image] };
                      });
                    },
                    {
                      fromSources: ["local_file_system", "webcam"],
                    }
                  )
                : null;
            }}
          >
            <AddAPhotoIcon />
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
