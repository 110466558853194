import { Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import PartnerForm from "./PartnerForm";
import useLogisticsPartners from "hooks/useLogisticsPartners";

export function PartnerEdit() {
  const { logisticsPartner } = useLogisticsPartners();
  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Partners", to: "/logistics/partners" },
          { label: logisticsPartner?.name, to: `/logistics/partners/${logisticsPartner?.uuid}` },
          { label: "Edit", to: `/logistics/partners/${logisticsPartner?.uuid}/edit` },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <PartnerForm logisticsPartner={logisticsPartner} />
      </Box>
    </Box>
  );
}
