import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { PartnerInventory } from "types/partnerInventory";
import { Pickup } from "types/pickup";
import useBackgroundTask from "./useBackgroundTask";

export default function usePartnerInventories() {
  const params = useParams();
  const [partnerInventories, setPartnerInventories] = useState<PartnerInventory[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    getPartnerInventories();
  }, [search]);

  const getPartnerInventories = () => {
    api.get(`/pickups/${params.pickupUuid}/partner_inventories${search}`).then(
      ({
        data: {
          partnerInventories,
          meta: { totalPages, count },
        },
      }) => {
        setPartnerInventories(partnerInventories);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  };

  const uploadPartnerInventory = useCallback(
    (imageKey: string) => {
      return useBackgroundTask(
        api.post<{ jobId: string }>("/partner_inventory_uploads", {
          partnerInventoryUpload: { file: imageKey, pickupUuid: params.pickupUuid },
        })
      ).then(() => {
        getPartnerInventories();
      });
    },
    [params.pickupUuid]
  );

  return {
    uploadPartnerInventory,
    partnerInventories,
    count,
    totalPages,
  };
}
