import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function usePickups() {
  const [pickups, setPickups] = useState<Pickup[]>([]);
  const [organizationPickups, setOrganizationPickups] = useState<Pickup[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    api.get(`/pickups${search}`).then(
      ({
        data: {
          pickups,
          meta: { totalPages, count },
        },
      }) => {
        setPickups(pickups);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  }, [search]);

  const getPickupsByOrganization = (organization: String) => {
    api.get(`/pickups?q=${organization}`).then(
      ({
        data: {
          pickups,
          meta: { totalPages, count },
        },
      }) => {
        setOrganizationPickups(pickups);
        setTotalPages(totalPages);
        setCount(count);
      }
    );
  };

  return {
    pickups,
    organizationPickups,
    getPickupsByOrganization,
    totalPages,
    count,
  };
}
