import { Button, Box } from "@mui/material";
import { Edit, Preview, Cancel } from "@mui/icons-material";
import { theme } from "index";
import { Pickup } from "types/pickup";
import {
  assets,
  contact,
  InfoCell,
  internalNotes,
  pickupReason,
  submittedBy,
  customerComments,
  attachments,
  locationNotes,
  orderNumber,
  address,
} from "./shared";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import LogisticsDetailsModal from "./LogisticsDetailsModal";
import PickupCancelModal from "../PickupCancelModal";

interface ScheduledDrawerProps {
  pickup: Pickup;
  cancelPickup: (pickupUuid: string, setCancellationReason: string) => Promise<void>;
}

export function ScheduledDrawer({ pickup, cancelPickup }: ScheduledDrawerProps) {
  const [isOpen, setOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
          <InfoCell title="EXTERNAL PICKUP ID" items={orderNumber(pickup)} />
          <InfoCell title="INTERNAL PICKUP ID" items={[pickup.id]} />
          <InfoCell title="PICKUP REASON" items={pickupReason(pickup)} />
          <InfoCell title="SUBMITTED BY" items={submittedBy(pickup)} />
          <InfoCell title="POINT OF CONTACT" items={contact(pickup)} />
          <InfoCell title="ASSETS" items={assets(pickup)} />
          <InfoCell title="ATTACHMENTS" items={attachments(pickup)} />
          <InfoCell title="LOCATION" items={address(pickup)} />
          <InfoCell title="LOCATION NOTES" items={locationNotes(pickup)} />
          <InfoCell title="CUSTOMER COMMENTS" items={customerComments(pickup)} />
          <InfoCell title="PRE-PICKUP NOTES" items={internalNotes(pickup)} />
        </Box>
      </Box>

      <LogisticsDetailsModal open={isOpen} pickup={pickup} handleClose={() => setOpen(false)} />
      <PickupCancelModal
        open={isCancelModalOpen}
        setOpen={setCancelModalOpen}
        cancelPickup={cancelPickup}
        pickupUuid={pickup.uuid}
      />
      <Box mt={2} sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<Edit />}
          component={RouterLink}
          to={`/logistics/pickups/${pickup.uuid}/edit`}
        >
          edit details
        </Button>

        <Button variant="contained" startIcon={<Cancel />} onClick={() => setCancelModalOpen(true)}>
          cancel
        </Button>

        {pickup.postPickupForms && pickup.postPickupForms[0] && (
          <Button variant="contained" startIcon={<Preview />} onClick={() => setOpen(true)}>
            post pickup form
          </Button>
        )}
      </Box>
    </Box>
  );
}
