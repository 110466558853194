import { Dispatch, SetStateAction } from "react";
import { Autocomplete, Box, FormControl, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { InventoryAddProps } from "../../types/inventory";
import { RevivnGrid } from "../revivn-mui/RevivnGrid";
import { InventoryTemplate } from "types/inventoryTemplate";

type InventoryAddFieldProps = {
  inventory: InventoryAddProps;
  inventories: InventoryAddProps[];
  inventoryTemplates: InventoryTemplate[];
  addInventory?: (arg0: InventoryAddProps) => void;
  setInventories: Dispatch<SetStateAction<InventoryAddProps[]>>;
  searchInventoryTemplates: (query: string) => void;
  hideQuantity?: boolean;
  showDelete?: boolean;
  deleteInventory?: (arg0: string) => void;
};

type InventoryTemplateOption = {
  label?: any;
  uuid?: any;
  manufacturer?: any;
  deviceType?: any;
  isBulk?: any;
};

export default function InventoryAddField({
  inventory,
  inventories,
  setInventories,
  inventoryTemplates,
  searchInventoryTemplates,
  hideQuantity,
  showDelete,
  deleteInventory,
}: InventoryAddFieldProps) {
  const updateInventory = (key: string, value: string) => {
    const inventoryIndex = inventories?.findIndex((listedInventory) => inventory?.id === listedInventory.id);
    const newInventories = [...inventories];
    newInventories[inventoryIndex] = { ...inventory, [key]: value };
    setInventories(newInventories);
  };

  const selectOptions = () => {
    const options: Array<InventoryTemplateOption> = [];
    inventoryTemplates.forEach((template) => {
      options.push({
        label: template.name,
        uuid: template.uuid,
        manufacturer: template.manufacturer,
        deviceType: template.deviceType,
        isBulk: template.isBulk,
      });
    });

    return options;
  };

  return (
    <Box>
      <RevivnGrid>
        <Grid item xs={hideQuantity ? 10 : 8}>
          <FormControl fullWidth>
            <Autocomplete
              id="inventory-template"
              getOptionLabel={(option: InventoryTemplateOption) => option.label}
              isOptionEqualToValue={(option: any, value: any) => option.uuid === value.uuid}
              filterOptions={(x) => x}
              options={selectOptions()}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onChange={(_event, newValue: any) => {
                if (newValue) {
                  updateInventory("inventory_template_uuid", newValue.uuid);
                }
              }}
              onInputChange={(_event, newInputValue) => {
                searchInventoryTemplates(newInputValue);
              }}
              renderInput={(params) => <TextField {...params} label="Search inventory templates" fullWidth />}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        {option.label}
                        <Typography variant="body2" color="text.secondary">
                          {`${option.manufacturer} ${option.deviceType}
                          ${option.isBulk ? "- BULK ITEM" : ""}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </FormControl>
        </Grid>
        {!hideQuantity && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                id="quantity"
                label="Quantity"
                type="number"
                value={inventory?.quantity}
                onChange={(e: any) => updateInventory(e.target.id, e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        )}
        {showDelete && (
          <Grid item xs={2}>
            <IconButton onClick={() => deleteInventory && deleteInventory(inventory.id)}>
              <Delete />
            </IconButton>
          </Grid>
        )}
      </RevivnGrid>
    </Box>
  );
}
