import { Cancel } from "@mui/icons-material";
import { Typography, Button, Box, TextField } from "@mui/material";
import { Modal } from "components/common/Modal";
import { useState } from "react";

interface PickupCancelModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelPickup: (pickupUuid: string, setCancellationReason: string) => Promise<void>;
  pickupUuid: string;
}

export default function PickupCancelModal({ open, setOpen, cancelPickup, pickupUuid }: PickupCancelModalProps) {
  const [cancellationReason, setCancellationReason] = useState<string>("");

  return (
    <Modal open={open} setOpen={setOpen} width={"683px"}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <Cancel color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Cancel Pickup
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"}>
            Please confirm that you’d like to cancel this pickup. A cancellation email will be sent to the user as well.
          </Typography>
          <TextField
            id="cancellationReason"
            name="cancellationReason"
            fullWidth
            onChange={(e) => {
              setCancellationReason(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            helperText="Please enter in a cancellation reason."
            sx={{ mt: 3 }}
            multiline
          />
          <Box mt={4.5} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" onClick={() => cancelPickup(pickupUuid, cancellationReason)}>
              Yes, cancel
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              No, don't cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
