import { BenefitingOrganization, benefitingOrgDefault } from "./benefitingOrganization";
import * as Yup from "yup";
import { Donation } from "./donation";

export type DonationRequest = {
  id: number;
  uuid: string;
  goalInMac: number;
  goalInPc: number;
  goalInChromebook: number;
  heroUrl: string;
  goalDescription: string;
  notes: string;
  timeline: string;
  mainContactName: string;
  mainContactEmail: string;
  mainContactPhoneNumber: string;
  address: string;
  address2: string;
  city: string;
  zipcode: string;
  state: string;
  status: string;
  country: string;
  eori: string;
  vat: string;
  ein: string;
  registered501c3: boolean;
  goalName: string;
  estimatedCost: string;
  websiteUrl: string;
  benefitingOrganization: BenefitingOrganization;
  donations?: Donation[];
  benefitingOrganizationId: number;
  heroFile: string;
  totalDollarsDonated: number;
  achOnFile: boolean;
  totalRequestedAmountInDollars: number;
};

export const donationRequestDefault: DonationRequest = {
  id: 0,
  uuid: "",
  goalInMac: 0,
  goalInPc: 0,
  goalInChromebook: 0,
  heroUrl: "",
  goalDescription: "",
  notes: "",
  timeline: "",
  mainContactName: "",
  mainContactEmail: "",
  mainContactPhoneNumber: "",
  address: "",
  address2: "",
  city: "",
  zipcode: "",
  state: "",
  status: "",
  country: "",
  eori: "",
  vat: "",
  ein: "",
  registered501c3: false,
  goalName: "",
  estimatedCost: "",
  benefitingOrganization: benefitingOrgDefault,
  benefitingOrganizationId: 0,
  websiteUrl: "",
  heroFile: "",
  totalDollarsDonated: 0,
  achOnFile: false,
  totalRequestedAmountInDollars: 0,
};

export const donationRequestSchema = Yup.object()
  .shape(
    {
      country: Yup.string()
        .when("country", {
          is: "USA",
          then: Yup.string().oneOf(
            ["United States"],
            "Has to be United States to calculate estimated price of donation"
          ),
          otherwise: Yup.string(),
        })
        .required(),
    },
    [["country", "country"]]
  )
  .shape(
    {
      goalInPc: Yup.number()
        .typeError("goalInPc must be a number")
        .when(["goalInMac", "goalInChromebook"], {
          is: 0,
          then: Yup.number().moreThan(0, "Either Mac, PC or Chromebook goal should be more than 0"),
          otherwise: Yup.number(),
        })
        .required(),

      goalInMac: Yup.number()
        .typeError("goalInMac must be a number")
        .when(["goalInPc", "goalInChromebook"], {
          is: 0,
          then: Yup.number().moreThan(0, "Either Mac, PC or Chromebook goal should be more than 0"),
          otherwise: Yup.number(),
        })
        .required(),
      goalInChromebook: Yup.number()
        .typeError("goalInChromebook must be a number")
        .when(["goalInMac", "goalInPc"], {
          is: 0,
          then: Yup.number().moreThan(0, "Either Mac, PC or Chromebook goal should be more than 0"),
          otherwise: Yup.number(),
        })
        .required(),
    },
    [
      ["goalInMac", "goalInChromebook"],
      ["goalInPc", "goalInChromebook"],
      ["goalInMac", "goalInPc"],
    ]
  );
