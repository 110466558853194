import { useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";

export default function useReporting() {
  const [loadingPricing, setLoadingPricing] = useState<Boolean>(false);
  const [loadingRevivnData, setLoadingRevivnData] = useState<Boolean>(false);

  const [downloadPricingFile, setDownloadPricingFile] = useState<Boolean>(false);
  const [downloadRevivnFile, setDownloadRevivnFile] = useState<Boolean>(false);

  const [pricingReportData, setPricingReportData] = useState<any>("");
  const [revivnAppData, setRevivnAppData] = useState<any>("");

  const params = useParams();

  const getPricingReportData = (pickupUuid: string) => {
    setLoadingPricing(true);
    api
      .get(`/pickups/${pickupUuid}/legacy_revivn_app_pricing.csv`)
      .then(({ data }) => {
        setPricingReportData(data);
      })
      .then(() => {
        setLoadingPricing(false);
        setDownloadPricingFile(true);
      });
  };

  return {
    getPricingReportData,
    pricingReportData,
    revivnAppData,
    loadingPricing,
    loadingRevivnData,
    downloadPricingFile,
    downloadRevivnFile,
    setDownloadPricingFile,
    setDownloadRevivnFile,
  };
}
