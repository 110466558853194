import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  styled,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Pickup } from "types/pickup";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { readableDate } from "utils/readableDate";
import { InfoCell, orderNumber } from "components/Logistics/Pickups/PickupRowStatuses/shared";
import { AppSelect, InputBase } from "components/common/Input";
import PricingToolBarButton from "components/Inventories/PricingToolBarButton";
import { formatNumberToCurrency } from "utils/currency";
import { Panel } from "components/common/Panel";
import { useAlert } from "../../../hooks/useAlert";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 29,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "#fff",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function currencyFormat(number: number) {
  return formatNumberToCurrency(number, { minimumFractionDigits: 0 });
}

export default function FinancePickupRow({ pickup, updatePickup }: { pickup: Pickup; updatePickup: any }) {
  const [open, setOpen] = useState(false);
  const [buyback, setBuyback] = useState(0);
  const [lotValue, setLotValue] = useState(0);
  const [showWaivedFees, setShowWaivedFees] = useState(true);

  const { alertError } = useAlert();

  const estimatedLotValue: any = () => {
    return (pickup.estimatedLotValueInCents || 0) / 100;
  };

  const agentFees: any = () => {
    return (pickup.agentFees || 0) / 100;
  };

  const customerFees: any = () => {
    return (pickup.customerFees || 0) / 100;
  };

  const freightFees: any = () => {
    return (pickup.freightFees || 0) / 100;
  };

  const handleChange = (e: any) => {
    setBuyback(Math.round((e.target.value / 100.0) * estimatedLotValue()));
  };

  const handleLotValueChange = (e: any) => {
    setLotValue(e.target.value);
  };

  const handleManualChange = (e: any) => {
    setBuyback(e.target.value);
  };

  const handleSubmit = () => {
    if (!showWaivedFees && buyback < 1) {
      alertError("Publishing buyback requires waived fees to be visible");
      return;
    }
    updatePickup({
      postWaivedFees: showWaivedFees,
      buybackAmountInCents: Math.ceil(buyback * 100),
      lotValueInCents: Math.ceil(lotValue * 100),
      uuid: pickup.uuid,
    });
  };

  const noBuyback = () => {
    updatePickup({
      buybackAmountInCents: 0,
      lotValueInCents: Math.ceil(lotValue * 100),
      uuid: pickup.uuid,
      postWaivedFees: showWaivedFees,
    });
  };

  const calculatePercent: any = () => {
    if (!pickup?.buybackInCents) return 15;
    if (!pickup?.estimatedLotValueInCents) return 15;
    const value = Math.round((pickup?.buybackInCents / pickup?.estimatedLotValueInCents) * 10000);
    if (value == 0) return 15;
    return value;
  };

  return (
    <>
      <TableRow key={pickup.id} sx={[{ "&:last-child td, &:last-child th": { border: 0 } }, open && { border: 0 }]}>
        <TableCell sx={[open && { border: 0 }]}>{pickup.scheduledAt && readableDate(pickup.scheduledAt)}</TableCell>
        <TableCell sx={[open && { border: 0 }]}>{pickup.id}</TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          {pickup?.accountRep ? `${pickup?.accountRep?.firstName} ${pickup?.accountRep?.lastName}` : ""}
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          {formatNumberToCurrency((pickup?.estimatedLotValueInCents || 0) / 100)}
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}>{pickup?.organization?.name}</TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          {pickup.location && (
            <>
              {pickup.location.address} {pickup.location.address2} {pickup.location.city} {pickup.location.state}{" "}
              {pickup.location.zipcode}
            </>
          )}
        </TableCell>
        <TableCell sx={[open && { border: 0 }]}></TableCell>
        <TableCell sx={[open && { border: 0 }]}>
          <IconButton onClick={() => setOpen((f) => !f)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: open ? undefined : 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Panel sx={{ mt: 2, mx: 2 }}>
              <Stack spacing={4}>
                <Stack spacing={4} direction="row">
                  <Stack spacing={2} divider={<Divider />} flexGrow={0.3}>
                    <Typography color="text.secondary" textTransform="uppercase">
                      Pickup Details
                    </Typography>
                    <Stack direction="row" spacing={4}>
                      <InfoCell sx={{ minWidth: "unset" }} title="External Pickup ID" items={orderNumber(pickup)} />
                      <InfoCell sx={{ minWidth: "unset" }} title="ITAD" items={[pickup?.itad?.name]} />
                    </Stack>
                  </Stack>
                  <Stack spacing={2} divider={<Divider />} flexGrow={1}>
                    <Typography color="text.secondary" textTransform="uppercase">
                      Earnings Details
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" spacing={4}>
                      <InfoCell
                        sx={{ minWidth: "unset" }}
                        title="Estimated Lot Value"
                        items={[currencyFormat(estimatedLotValue())]}
                      />
                      <InfoCell
                        sx={{ minWidth: "unset" }}
                        title="Customer Fees"
                        items={[currencyFormat(customerFees())]}
                      />
                      <InfoCell sx={{ minWidth: "unset" }} title="Agent Fees" items={[currencyFormat(agentFees())]} />
                      <InfoCell
                        sx={{ minWidth: "unset" }}
                        title="Freight Fees"
                        items={[currencyFormat(freightFees())]}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={4} justifyContent="flex-start">
                    <Box sx={{ minWidth: "200px" }}>
                      <Typography color="text.secondary" fontSize="12px" textTransform="uppercase">
                        Final Lot Value
                      </Typography>
                      <InputBase
                        type="number"
                        id="lotValueInCents"
                        value={lotValue}
                        onChange={(e) => {
                          handleLotValueChange(e);
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography color="text.secondary" fontSize="12px" textTransform="uppercase">
                        Total Buyback %
                      </Typography>
                      <AppSelect
                        defaultValue={calculatePercent()}
                        onChange={(e) => handleChange(e)}
                        sx={{ width: "120px" }}
                      >
                        {Array(100)
                          .fill(1)
                          .map((x, y) => x + y)
                          .map((choice) => {
                            return (
                              <MenuItem key={choice} value={choice}>
                                {choice}%
                              </MenuItem>
                            );
                          })}
                      </AppSelect>
                    </Box>
                    <Box sx={{ minWidth: "200px" }}>
                      <Typography color="text.secondary" fontSize="12px" textTransform="uppercase">
                        Total Buyback Amount
                      </Typography>
                      <InputBase
                        type="number"
                        id="amountInCents"
                        value={buyback}
                        onChange={(e) => {
                          handleManualChange(e);
                        }}
                      />
                    </Box>
                  </Stack>
                  <FormControlLabel
                    control={
                      <AntSwitch
                        sx={{ marginRight: 2 }}
                        checked={showWaivedFees}
                        onChange={(_, checked) => setShowWaivedFees(checked)}
                      />
                    }
                    label="Waived fees shown"
                  />
                </Stack>
              </Stack>
            </Panel>
            <Box mt={3} mb={4} mx={2}>
              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <Button
                  href={`https://brain.revivn.com/dashboards/1983-pickup-snapshot?p_Pickup%20ID=${pickup.id}`}
                  target="_blank"
                  variant="outlined"
                >
                  View Redash Insights
                </Button>
                {pickup.id && <PricingToolBarButton pickup={pickup} />}
                <Button variant="contained" color="error" onClick={() => noBuyback()}>
                  no buyback
                </Button>
                <Button variant="contained" color="success" onClick={() => handleSubmit()}>
                  publish buyback
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
