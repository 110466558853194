import { GridSelectionModel } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { Inventory } from "types/inventory";
import { useAlert } from "./useAlert";
import { useConfirmation } from "./useConfirmation";
import { warehouseLocationValidation } from "validations/inventory";

export default function useScan() {
  const { search } = useLocation();
  const { alertSuccess, alertError } = useAlert();
  const { confirm } = useConfirmation();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [selectedInventories, setSelectedInventories] = useState<GridSelectionModel>([]);

  const { handleSubmit, getFieldProps, setFieldValue, errors, resetForm } = useFormik({
    initialValues: { location: query.get("warehouse_location") ?? "" },
    validationSchema: warehouseLocationValidation,
    onSubmit: (values) => {
      confirm({
        message: `Are you sure you want to move item to "${values.location}"`,
        handler: () => {
          moveInventories(values.location);
          resetForm();
        },
      });
    },
  });

  const getInventoryByWarehouseLocation = (warehouseLocation: string) => {
    return api
      .get(`/inventories_by_warehouse_location?warehouse_location=${warehouseLocation}`)
      .then(({ data }) => {
        setInventories((inventories) => [...inventories, ...data.inventories]);
        setSelectedInventories((selectedInventories) => [
          ...selectedInventories,
          ...data.inventories.map((inventory: Inventory) => inventory.id),
        ]);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          alertError(`Can't find inventory with location ${warehouseLocation}`);
        }
      });
  };

  const addInventory = useCallback(
    (inventoryId) => {
      if (inventories.find(({ id }) => id.toString() === inventoryId.toString())) {
        return;
      }
      return api
        .get(`/inventories/${inventoryId}`)
        .then(({ data }) => {
          setInventories((inventories) => [...inventories, data]);
          setSelectedInventories((selectedInventories) => [...selectedInventories, data.id]);
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            alertError(`Can't find inventory with id ${inventoryId}`);
          }
        });
    },
    [inventories, setInventories, selectedInventories],
  );

  const removeInventory = useCallback(
    (inventoryId) => {
      setInventories((inventories) => inventories.filter(({ id }) => id != inventoryId));
    },
    [setInventories],
  );

  const invoiceInventory = () => {
    const payload = selectedInventories.map((id) => ({ id, state: "invoiced", warehouse_location: "invoiced" }));

    api
      .put("inventories/bulk/edit", { inventories: payload })
      .then(() => {
        setInventories((inventories) => inventories.filter(({ id }) => !selectedInventories.includes(id)));
        alertSuccess("Inventory has been successfully invoiced.");
      })
      .catch((error) => {
        alertError(error);
      });
  };

  const moveInventories = useCallback(
    (location: string, skipActions?: any | undefined) => {
      const payload = selectedInventories.map((inventoryId) => ({
        id: inventoryId,
        warehouse_location: location,
        ...((skipActions || {})[inventoryId] || []).reduce((acc: any, c: string) => ({ ...acc, [c]: true }), {}),
      }));
      api
        .put("inventories/bulk/edit", { inventories: payload })
        .then(() => {
          setInventories((inventories) => inventories.filter(({ id }) => !selectedInventories.includes(id)));
          alertSuccess("Items have been moved successfully");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            confirm({
              handler: () => {
                const id = error.response.data.errors.id;
                const newParam = error.response.data.errors.skip_param;
                const inventoryParamsArray = [...((skipActions || {})[id] || []), newParam];
                moveInventories(location, { ...(skipActions || {}), [id]: inventoryParamsArray });
              },
              message: error.response.data.errors.message,
              context: error.response.data.errors.skip_param,
            });
          }
          alertError("Failed to update!");
        });
    },
    [selectedInventories, setInventories],
  );

  const handleExScanInput = useCallback((event, key, formikHandler) => {
    event.persist();
    const value = event.target.value;
    try {
      const url = new URL(value);
      event.target.value = url.searchParams.get(key);
      formikHandler(event);
    } catch (_) {
      formikHandler(event);
    }
  }, []);

  useEffect(() => {
    const inventoryId = query.get("inventory_id");
    inventoryId && addInventory(inventoryId);
  }, []);

  return {
    inventories,
    selectedInventories,
    setSelectedInventories,
    addInventory,
    getInventoryByWarehouseLocation,
    removeInventory,
    handleExScanInput,
    submitForm: handleSubmit,
    errors,
    invoiceInventory,
    locationInputProps: getFieldProps("location"),
    setLocation: (value: string) => setFieldValue("location", value),
  };
}
