import React from "react";

import { PrintLabelButton } from "components/Picklist/common/PrintLabelButton";
import { Inventory } from "types/inventory";

export function PrintShippingLabelStep(props: { onClick: () => void; inventory: Inventory }) {
  return (
    <PrintLabelButton inventory={props.inventory} onClick={props.onClick}>
      Print Shipping Label
    </PrintLabelButton>
  );
}
