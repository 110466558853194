import { Box, Button, Typography } from "@mui/material";
import ClientsDataGrid from "./ClientsDataGrid";
import { Link as RouterLink } from "react-router-dom";
import { Add } from "@mui/icons-material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useCurrentUser from "hooks/useCurrentUser";

export function Clients() {
  const { currentUserPermissions } = useCurrentUser();

  return (
    <Box>
      <Breadcrumbs
        data={[{ label: "Logistics", to: "/logistics?logistics_status=scheduled" }, { label: "Clients" }]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">Clients</Typography>
        <Typography mt={0.5}>View all the companies that work with Revivn, their locations and contacts.</Typography>
      </Box>

      {currentUserPermissions?.includes("admin") && (
        <Box my={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button component={RouterLink} variant="contained" to={`/logistics/clients/new`} startIcon={<Add />}>
            Add New Company
          </Button>
        </Box>
      )}

      <ClientsDataGrid />
    </Box>
  );
}
