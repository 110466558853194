import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import React from "react";
import { Stack } from "@mui/material";
import { PrintLabelButton } from "components/Picklist/common/PrintLabelButton";
import { CapturePhotoButton } from "components/Picklist/common/CapturePhotoButton";
import { Inventory, InventoryImage } from "types/inventory";

export function CompletePackingStep(props: {
  inventory: Inventory;
  onImageRecapture: (image: InventoryImage) => void;
  onSuccess: () => void;
}) {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={1} alignSelf="stretch" mb={1}>
        <PrintLabelButton inventory={props.inventory}>Re-Print Label</PrintLabelButton>
        <CapturePhotoButton inventory={props.inventory} onImageCaptured={props.onImageRecapture}>
          Re-capture photo
        </CapturePhotoButton>
      </Stack>
      <RevivnApiForm
        initialValues={{}}
        action="current_fulfillment_batch/item/complete_packing"
        method="POST"
        onSuccess={props.onSuccess}
      >
        <SubmitAwareButton type="submit" variant="contained">
          Next
        </SubmitAwareButton>
      </RevivnApiForm>
    </>
  );
}
