import { PicklistCard } from "components/Picklist/common/PicklistCard";
import { Check } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";

export function PickingComplete({ onTransitionToPacking }: { onTransitionToPacking: () => void }) {
  return (
    <>
      <PicklistCard title="All Inventory Picked" Icon={Check}>
        <Typography textAlign="center">
          Next, move your cart to the packing area of the facility to package the orders and ship them out.
        </Typography>
      </PicklistCard>
      <Stack justifyItems="center" alignItems="center" gap={1} mt={8}>
        <RevivnApiForm
          initialValues={{}}
          action="/current_fulfillment_batch/start_packing"
          method="POST"
          onSuccess={onTransitionToPacking}
        >
          <SubmitAwareButton variant="contained" type="submit">
            Pack
          </SubmitAwareButton>
        </RevivnApiForm>
      </Stack>
    </>
  );
}
